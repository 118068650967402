import { Box } from "@mui/material";
import React, { useState } from "react";
import styles from "./Carousel.module.css"; //will be added later
import { Close } from "icons";


const Carousel = (props: any) => {
  const { children, imageIndex, onClose } = props;
  const [currentIndex, setCurrentIndex] = useState(imageIndex);

  const next = () => {
    setCurrentIndex((prevState: any) => (prevState + 1) % children.length); //
  };
  
  const prev = () => {
    setCurrentIndex(
      (prevState: any) => (children.length + prevState - 1) % children.length
    );
  };

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: "100%",
      }}
    >
      <Box className={styles.carouselcontainer}>
        <Box
          sx={{ transform: `translateX(-${currentIndex * 100}%)` }}
          className={styles.carouselcontent}
        >
          {children}
        </Box>
      </Box>
      <Box>
        <button onClick={prev} className={styles.leftarrow}>
          &lt;
        </button>
        <Box className={styles.carouselcontentwrapper}>{/*...*/}</Box>

        <button onClick={next} className={styles.rightarrow}>
          &gt;
        </button>
        

        <div className={styles.closeItem} onClick={onClose}><Close /></div>
      </Box>
    </Box>
  );
};

export default Carousel;
