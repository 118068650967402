// import { useForm } from "../../hooks/useForm";
import { ChangeEmail, ChangePassword } from "./components";
import { Container, Grid } from "@mui/material";
import Header from "components/header/Header";
// import { style } from "@mui/system";
// import { useForm } from "../../hooks/useForm";



const ChangeLoginData = () => {
  return (
    <Container>
      <Header header={"Ihr Benutzerprofil"}/>
      <Grid container xs={6} lg={12} md={12} sx={{display: "flex", pt: 3}}>
        <ChangeEmail />
        <ChangePassword />
      </Grid>
    </Container>
	);
}   

export default ChangeLoginData;