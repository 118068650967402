import axiosInstance from "app/axios";
import { AxiosResponse } from "axios";
import { UserRoles } from "interfaces/UserRoles";
import { LoginInformation } from "./userSlice";
export interface LoginResponseInterface {
  data: {
    role: UserRoles;
    customer_required: boolean;
    errors: {
      email: string;
      password?: string;
    }
  };
}
export interface loginDataArgument {
  loginData: LoginInformation;
}

export function postLogin({loginData}: loginDataArgument): Promise<AxiosResponse<LoginResponseInterface, any>> {
  return axiosInstance.post("user/login", loginData);
}

