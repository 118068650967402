import * as React from "react";
import { SVGProps } from "react";
const SvgCircumference = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
    <g data-name="Gruppe 671">
      <path d="M8 16a7.741 7.741 0 0 1-3.1-.63A8.06 8.06 0 0 1 .63 11.1a7.991 7.991 0 0 1 0-6.22 8 8 0 0 1 1.72-2.54A8.2 8.2 0 0 1 4.9.63a7.991 7.991 0 0 1 6.22 0 7.987 7.987 0 0 1 4.25 4.25 7.991 7.991 0 0 1 0 6.22 8.2 8.2 0 0 1-1.71 2.55 8 8 0 0 1-2.54 1.72A7.79 7.79 0 0 1 8 16Zm0-1.2a6.543 6.543 0 0 0 4.82-1.99A6.57 6.57 0 0 0 14.8 8a6.56 6.56 0 0 0-1.98-4.82A6.56 6.56 0 0 0 8 1.2a6.57 6.57 0 0 0-4.81 1.98A6.543 6.543 0 0 0 1.2 8a6.553 6.553 0 0 0 1.99 4.81A6.553 6.553 0 0 0 8 14.8Z" />
      <path d="m3.626 7.339 1.202-.014v2.998l6.321-6.322.849.849-6.336 6.336H8.66v1.188H3.626Z" />
      <path
        data-name="trending_flat_FILL0_wght400_GRAD0_opsz48"
        d="m12.374 8.661-1.202.014V5.677l-6.321 6.322-.849-.849 6.336-6.336H7.34V3.626h5.034Z"
      />
    </g>
  </svg>
);
export default SvgCircumference;
