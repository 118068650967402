import { Divider, Paper } from "@mui/material";
import { useAppDispatch, useAppSelector } from "app/hooks";
import HolzfinderSlider from "components/inputs/HolzfinderSlider";
import { FilterLotSearchText } from "components/typography";
import { selectLotLocationFilter, selectLotLocationFilterSettings, setLength } from "features/lotLocations/lotLocationsSlice";

export default function LengthFilterSection(props: any) {
  const dispatch = useAppDispatch();
  const valueSettings = useAppSelector(selectLotLocationFilterSettings).average_length_value;
  const value = useAppSelector(selectLotLocationFilter).average_length_value;

  const handleLength = (event: any) => {
    if (Array.isArray(event.target.value)) dispatch(setLength(event.target.value));
    else dispatch(setLength([event.target.value, event.target.value]));
  };

  if (valueSettings[0] === valueSettings[1]) return <div></div>;
  return (
    <Paper
      sx={{
        backgroundColor: "white",
        minWidth: { xs: "320px", md: "360px", lg: "360px" },
        borderRadius: "26px",
        display: "flex",
        padding: "2px 4px",
        alignItems: "center",
        height: "50px",
      }}
    >
      <FilterLotSearchText whiteSpace="nowrap">Länge in m</FilterLotSearchText>
      <Divider sx={{ height: 28, m: 1, color: "#1F2A40", border: 1 }} orientation="vertical" />
      <FilterLotSearchText mx={1}>{valueSettings[0]}</FilterLotSearchText>

      <HolzfinderSlider
        value={value}
        onChange={handleLength}
        min={valueSettings[0]}
        max={valueSettings[1]}
        valueLabelDisplay="auto"
        size="small"
        sx={{
          ml: 1,
        }}
      />
      <FilterLotSearchText mx={1}>{valueSettings[1]}</FilterLotSearchText>
    </Paper>
  );
}
