import * as React from "react";
import { SVGProps } from "react";
const SvgSeller = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36.09 36.05" {...props}>
    <path d="M23.44 24.78c-.6-1.05-1.53-1.84-2.62-2.22a4.21 4.21 0 0 0-1.95-.2c1-1.38 1.11-3.39.13-5.1-1.29-2.24-3.99-3.12-6-1.96l-6.06 3.46c-1 .58-1.7 1.6-1.92 2.8-.17.9-.05 1.85.31 2.73-1.1-.38-2.31-.32-3.33.27-1 .58-1.7 1.6-1.92 2.8-.21 1.13.01 2.33.62 3.38.91 1.57 2.5 2.47 4.06 2.47.67 0 1.33-.17 1.94-.51.36-.21.65-.48.92-.77.1.57.31 1.14.61 1.66.91 1.57 2.5 2.47 4.06 2.47.67 0 1.33-.17 1.94-.51s7.91-4.58 7.91-4.58c1-.58 1.7-1.6 1.92-2.8.21-1.13-.01-2.33-.62-3.38ZM7.97 22.1c.04-.2.15-.56.47-.74a.87.87 0 0 1 .45-.12c.17 0 .33.04.43.08.41.14.78.47 1.02.9.25.43.34.91.26 1.33-.04.2-.15.56-.47.74-.33.19-.69.1-.88.04-.41-.14-.78-.47-1.02-.9s-.34-.91-.26-1.33Zm6.53-4.2c.57-.33 1.44.06 1.9.86.46.8.36 1.75-.2 2.07l-2.65 1.51a5.202 5.202 0 0 0-1.69-2.94l2.64-1.5ZM5.2 30.09c-.57.33-1.44-.06-1.9-.86-.25-.42-.34-.91-.26-1.33.04-.2.15-.56.47-.74.14-.08.29-.12.46-.12.51 0 1.1.37 1.45.98.46.8.36 1.75-.21 2.08Zm5.63 1.99c-.25-.42-.34-.91-.26-1.33.04-.2.15-.56.47-.74a.87.87 0 0 1 .45-.12c.17 0 .33.04.43.08.41.14.78.47 1.02.9.46.8.36 1.75-.21 2.08-.57.33-1.44-.06-1.9-.86Zm10.29-4.47c-.04.2-.15.56-.47.75l-4.51 2.61c-.1-.55-.29-1.09-.59-1.61-.3-.51-.67-.96-1.1-1.33l4.51-2.61c.33-.19.69-.1.88-.04.41.14.78.47 1.02.9.25.42.34.91.26 1.33ZM15.69 8.3h12.2v3h-12.2z" />
    <circle cx={9.09} cy={9.8} r={1.5} />
    <path d="M27.89 24.7H25.6c.39.96.55 1.98.49 3h1.79v-3ZM27.89 16.5h-7.04c.5.96.79 1.98.83 3h6.22v-3Z" />
    <path d="M35.19.9c-.6-.6-1.3-.9-2.1-.9h-30c-.8 0-1.5.3-2.1.9-.6.6-.9 1.3-.9 2.1v20.48c.28-.24.58-.47.91-.66.6-.35 1.41-.61 2.09-.73V3h30v30h-10.5l-5.18 3h15.68c.8 0 1.5-.3 2.1-.9.6-.6.9-1.3.9-2.1V3c0-.8-.3-1.5-.9-2.1Z" />
  </svg>
);
export default SvgSeller;
