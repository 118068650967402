import React from "react";
import { SVGProps } from "react";

const Eye = (props: SVGProps<SVGSVGElement>) => (
    <svg 
        xmlns="http://www.w3.org/2000/svg"  
        viewBox="0 0 24 24" 
        strokeWidth="2" 
        stroke="#000000"
        xmlSpace="preserve"
        strokeLinecap="round" 
        strokeLinejoin="round"
        {...props}
    >
        <path 
            fill="#FFF"
            d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"
        ></path>
        <circle 
            fill="#FFF"
            cx="12" 
            cy="12" 
            r="3"
        ></circle>
    </svg>
);
    
export default Eye;
