import axiosInstance from "app/axios";
import { AxiosResponse } from "axios";
import { UserRoles } from "interfaces/UserRoles";
import { UserStatus } from "interfaces/UserStatus";

export interface patchAgentUserInterface {
  first_name: string;
  last_name: string;
  id: string;
  status: string;
  endpoint?: UserRoles
}

export interface PostAgentResponseInterface {
  data: {
    errors: {
      id?: string[],
    }
  };
}

export function updateAgentUser({ first_name, last_name, id, endpoint = UserRoles.Admin, status }: patchAgentUserInterface): Promise<AxiosResponse<PostAgentResponseInterface, any>> {
  return axiosInstance.patch(`${endpoint}/users/${id}`, {
    first_name,
    last_name,
    status
  });
}