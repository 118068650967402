import * as React from "react";
import { SVGProps } from "react";
const SvgFindLot = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80" {...props}>
    <g data-name="Gruppe 753">
      <path data-name="Rechteck 1517" fill="#fff" d="M0 0h80v80H0z" />
      <g data-name="Gruppe 72">
        <g data-name="Gruppe 71">
          <path
            data-name="Pfad 117"
            d="M8.548 29.711a27.366 27.366 0 0 1 45.08-20.86c5.423 4.609 5.271 7.66 6.858 5.7.524-.482-.551-3.412-5.34-7.482A29.71 29.71 0 0 0 6.201 29.711c0 5.329 1.553 11.377 4.617 17.974a100.177 100.177 0 0 0 10.011 16.569 1.172 1.172 0 1 0 1.893-1.382c-6.466-8.859-14.174-21.8-14.174-33.161Z"
            fill="#005851"
          />
          <path
            data-name="Pfad 119"
            d="M59.167 46.093a2.587 2.587 0 0 0-.245.123l-3.355-3.351a5.236 5.236 0 0 0-1.519-3.7l-1-1a19.442 19.442 0 1 0-7.339 7.339l1 1a5.234 5.234 0 0 0 3.705 1.52l3.353 3.346a2.628 2.628 0 0 0 .426 3.147l.31.31A141.788 141.788 0 0 1 37.34 77.041a1.952 1.952 0 0 1-2.846 0 144.59 144.59 0 0 1-11.549-13.89 1.172 1.172 0 0 0-1.836 1.457 166.517 166.517 0 0 0 11.677 14.038A4.313 4.313 0 0 0 35.916 80a4.313 4.313 0 0 0 3.131-1.354 144.934 144.934 0 0 0 17.161-22.118l8.087 8.087a5.574 5.574 0 0 0 7.874 0 5.574 5.574 0 0 0 0-7.874l-10.1-10.1a2.648 2.648 0 0 0-.432-.348c2.648-6.055 3.993-11.632 3.993-16.583a29.414 29.414 0 0 0-6.309-18.306c-3.3-4.217-4.272-3.906-4.781-3.507s-2-1.35 2.936 4.953a27.092 27.092 0 0 1 5.81 16.861c0 4.82-1.391 10.342-4.121 16.381Zm-40.108-17.5a17.086 17.086 0 1 1 17.082 17.085 17.106 17.106 0 0 1-17.086-17.086Zm28.659 15.6a19.6 19.6 0 0 0 4.024-4.024l.652.652a2.845 2.845 0 1 1-4.025 4.023Zm6.334 2.309a5.219 5.219 0 0 0 .8-1.035l2.341 2.341-1.839 1.839-2.341-2.341a5.219 5.219 0 0 0 1.035-.8Zm16.463 16.464a3.228 3.228 0 0 1-4.56 0l-10.1-10.1a.282.282 0 0 1 0-.4l4.162-4.162a.281.281 0 0 1 .4 0l10.1 10.1a3.228 3.228 0 0 1-.005 4.558Z"
            fill="#005851"
          />
          <g
            data-name="Ellipse 282"
            transform="translate(22.25 15)"
            fill="none"
            stroke="#005851"
            strokeWidth={2.5}
          >
            <circle cx={13.75} cy={13.75} r={13.75} stroke="none" />
            <circle cx={13.75} cy={13.75} r={12.5} />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default SvgFindLot;
