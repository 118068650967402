import { Typography } from "@mui/material";
import { useAppSelector } from "app/hooks";
import { selectCustomer, selectCustomersList } from "features/customer/customerSlice";
import { selectSeller } from "features/seller/sellerSlice";
import SellerData from "../../admin/seller/SellerData";
import SellerGrid from "../../admin/seller/SellerGrid";
import { useParams } from "react-router-dom";

const ContactDataLabels = {
  phone: "Telefon",
  mobile: "Mobil",
  fax: "Fax",
  email: "E-Mail",
  tax_number: "Steuernummer",
  vat_identification_number: "Mehrwertsteuer-Identifikationsnummer",
  payment_provider_reference: "Referenz des Zahlungsanbieters",
  eldat_reference_id: "Eldat Referenz ID",
};
export default function CustomerInformation(props: any) {
  const customerDatas = useAppSelector(selectCustomer);
  const selectCustomersLists = useAppSelector(selectCustomersList);
  const userIdFromRedux = useAppSelector((state) => state.customerSettings.customer?.id);

  const { customer_id } = useParams<{ customer_id: string }>();
  const customerData = selectCustomersLists?.find((c: any) => c.id === customer_id || c.id === userIdFromRedux);
  return (
    <SellerGrid>
      <Typography
        fontWeight={"bold"}
        variant="h5"
        sx={{
          mt: 2,
          mb: 1,
          color: "#072D26",
        }}
      >
        Kunden Informationen
      </Typography>
      {
        customerData?.phone ? (
          <SellerData
            label={ContactDataLabels.phone}
            data={customerData?.phone}
          />
        ) : (
          <></>
        )
      }
      {
        customerData?.mobile ? (
          <SellerData
            label={ContactDataLabels.mobile}
            data={customerData?.mobile}
          />
        ) : (
          <></>
        )
      }
      {
        customerData?.fax ? (
          <SellerData
            label={ContactDataLabels.fax}
            data={customerData?.fax}
          />
        ) : (
          <></>
        )
      }
      {
        customerData?.email ? (
          <SellerData
            label={ContactDataLabels.email}
            data={customerData?.email}
          />
        ) : (
          <></>
        )
      }

    </SellerGrid>
  );
}
