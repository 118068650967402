import axiosInstance from "app/axios";
import { AxiosResponse } from "axios";
import { AgentInterface } from "interfaces/Agent";
import { CustomerDataInterface } from "interfaces/CustomerDataInterface";
import { CustomerSettingsInterface } from "interfaces/CustomerSettingsInterface";
import { UserRoles } from "interfaces/UserRoles";

export interface fetchCustomersListArgument {
  items_per_page: number;
  page: number;
  search: string | undefined;
  endpoint?: UserRoles;
}

export interface CustomersResponseInterface {
  data: {
    total: number;
    pages: number;
    customers: CustomerSettingsInterface[];
  };
}

export function fetchCustomersList({ items_per_page, page, search, endpoint }: fetchCustomersListArgument): Promise<AxiosResponse<CustomersResponseInterface, any>> {
  return axiosInstance.get(`${endpoint}/customers`, {
    params: {
      items_per_page,
      page,
      search,
    },
  });
}

export interface fetchCustomerArgument {
  id: string;
  endpoint?: UserRoles;
}

export interface CustomerResponseInterface {
  data: CustomerDataInterface;
}


export function fetchCustomer({ id, endpoint = UserRoles.Admin }: fetchCustomerArgument): Promise<AxiosResponse<CustomerResponseInterface, any>> {
  return axiosInstance.get(`${endpoint}/customers/${id}`);
}


// export function fetchCustomer({ id, endpoint = UserRoles.Admin }: fetchCustomerArgument): Promise<AxiosResponse<CustomerResponseInterface, any>> {
//   console.log('fetchCustomers' ,id )
//   return axiosInstance.get(`${endpoint}/customers/${id}`);
// }


// let currentUserRole = UserRoles.Agent; // Initialize with the default role

// export function fetchCustomer({ id }: fetchCustomerArgument): Promise<AxiosResponse<CustomerResponseInterface, any>> {
//   let constructedEndpoint = '';

//   if (currentUserRole === UserRoles.Admin) {
//     constructedEndpoint = 'admin';
//   } else if (currentUserRole === UserRoles.Agent) {
//     constructedEndpoint = 'agent';
//   } else {
//     // Handle the case when currentUserRole is neither "admin" nor "agent"
//     throw new Error(`Invalid user role: ${currentUserRole}`);
//   }

//   console.log('fetchCustomers - Agent/admin', id);
//   return axiosInstance.get(`${constructedEndpoint}/customers/${id}`);
// }

// export function fetchAgentCustomer({ id, endpoint = UserRoles.Agent }: fetchCustomerArgument): Promise<AxiosResponse<CustomerResponseInterface, any>> {
//   console.log('Agent Customer' ,id )
//   debugger
//   return axiosInstance.get(`${endpoint}/customers/${id}`);
// }