import * as React from "react";
import {
  AppBar,
  Box,
  Toolbar,
  MenuItem,
  IconButton,
  Button,
  useMediaQuery,
} from "@mui/material";

import { LargeText, MediumText, XXLargeText } from "../typography/index";
import { AccountMenu, LoginButton, ShoppingCartButton, NavigationProfileButton } from "./components/index";
import { Link, useNavigate } from "react-router-dom";
import { Nav } from "icons";
import styles from "./HeaderNavbar.module.css";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  selectUserRole,
  userData,
} from "features/user/userSlice";
import { useEffect } from "react";
import { match } from "assert";

const pages = [
  {
    label: "KONTO ERSTELLEN",
    route: "registrierung",
    className: styles.markedNavElement,
    textPadding: "8px 32px",
    showCheck: (loggedIn: boolean) => !loggedIn,
    leftNav: true,
  },
  {
    label: "LOSSUCHE",
    route: "lossuche",
    className: styles.hoverLine,
    showCheck: () => true,
    leftNav: true,
  },
  // {
  //   label: "KONTAKT",
  //   route: "#contact",
  //   className: styles.hoverLine,
  //   showCheck: (loggedIn: boolean) => true,
  //   leftNav: true,
  // },
  {
    label: "LOGIN",
    route: "login",
    className: styles.hoverLine,
    showCheck: () => true,
  },
  {
    label: "EINKAUFSWAGEN",
    route: "einkaufswagen",
    className: styles.hoverLine,
    showCheck: () => true,
  },
];

const linkGaps = { xs: "16px", md: "20px", xl: "32px" };

interface Props {
  loggedIn: boolean;
}

export function capitalizeFirstLetter(string: string | undefined) {
  if (typeof string === "string") return string.charAt(0).toUpperCase() + string.slice(1);
}

export function HeaderNavbar({ loggedIn }: Props) {
  // const [user, setUser] = useState()
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (loggedIn) {
      dispatch(userData());
    }
  }, [loggedIn, dispatch]);

  const [navIsOpen, setNavIsOpen] = React.useState(false);
  const userRole = useAppSelector(selectUserRole);
  const parsedUserRole = capitalizeFirstLetter(userRole);
  const navigate = useNavigate();
  const handleClickAccordionMenu = (event: React.MouseEvent<HTMLElement>) => {
    setNavIsOpen(!navIsOpen);
  };

  const handleCloseAccordionMenu = () => {
    setNavIsOpen(false);
  };
  // const handleCloseUserMenu = () => {
  //   setAnchorElUser(null);
  // };
  const matches799 = useMediaQuery('(min-width: 799px)')

  return (
    <div>
      <div lang="de" className={styles.navbarFixedPadding}></div>

      <AppBar
        position="fixed"
        sx={{
          zIndex: 9002,
          backgroundColor: "#072C25",
        }}
      >
        <Toolbar
          disableGutters
          sx={{
            height: { xs: "48px", md: "64px" },
            padding: { xs: "0px 12px", md: "12px 32px" },
            justifyContent: "space-between",
          }}
          className={styles.toolbarContainer}
        >
          {/* Left aligned menue items */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: linkGaps,
            }}

          >
            <Box
              className={styles.headerMobileViewHamburger}
              sx={{
                display: {
                  xs: "contents",
                  lg: "none",
                },
              }}
            >
              <IconButton
                onClick={handleClickAccordionMenu}
                size="small"
                sx={{
                  width: "32px",
                  height: "32px",
                }}
              // aria-controls={navIsOpen ? "account-menu" : undefined}
              // aria-haspopup="true"
              // aria-expanded={navIsOpen ? "true" : "false"}
              >
                <Nav />
              </IconButton>
            </Box>

            <Link to="/" style={{ textDecoration: "none" }}>
              <XXLargeText
                color="#FFFFFF"
                fWeight="bold"
                // border="2px solid white"

                mx={0}
                my={2}
              >
                HOLZFINDER
              </XXLargeText>
            </Link>

            {pages.map((page, index) =>
              page.leftNav && page.showCheck(loggedIn) ? (
                page.label === "KONTAKT" ? (
                  <Box
                    key={page.route}
                    className={page.className}
                    sx={{
                      display: { xs: "none", lg: "flex" },
                    }}
                  >
                    <Button
                      key={page.route}
                      onClick={() => {
                        navigate("/#contact", {
                          state: { targetId: "contact-section" },
                        });
                      }}
                      // style={{
                      //   textDecoration: "none",
                      // }}
                      className={styles.underline}
                    >
                      <MediumText fWeight="bold" color="#FFFFFF" padding={page.textPadding}>
                        {page.label}
                      </MediumText>
                    </Button>
                  </Box>
                ) : (
                  <Box
                    key={page.route}
                    className={page.className}
                    sx={{
                      display: { xs: "none", lg: "flex" },
                    }}
                  >
                    <Link
                      key={page.route}
                      // onClick={handleCloseNavMenu}
                      to={page.route}
                      style={{
                        textDecoration: "none",
                      }}
                      className={styles.underline}
                    >
                      <MediumText fWeight="bold" color="#FFFFFF" padding={page.textPadding}>
                        {page.label}
                      </MediumText>
                    </Link>
                  </Box>
                )
              ) : null
            )}
          </Box>

          {/* center aligned menue items */}
          {userRole === "admin" || userRole === "agent" ? (
            <Box
              sx={{
                justifySelf: "center",
              }}
            >
              <Link
                key={"adminlotlist"}
                // onClick={handleCloseNavMenu}
                to={`/${userRole}/losliste`}
                style={{ textDecoration: "none", padding: "8px 16px" }}
              >
                <MediumText fWeight="bold" color="#FFFFFF" padding="8px 16px">
                  {parsedUserRole} Bereich
                </MediumText>
              </Link>
            </Box>
          ) : (
            <></>
          )}

          {/* right aligned menue items */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: linkGaps,
            }}
          >
            {/* ~~~~~~~~~~~~~~ smartphone view icons ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */}
            <Box
              className={styles.menuContainer}
              sx={{
                display: { xs: "flex", lg: "none" },
              }}
            >
              {loggedIn ? (
                <AccountMenu />
              ) : (
                <Link to="/login" style={{ textDecoration: "none" }}>
                  <LoginButton mt={"auto"} />
                </Link>
              )}
            </Box>
            <Box
              className={styles.menuContainer}
              sx={{
                display: { xs: "flex", lg: "none" },
              }}
            >
              <ShoppingCartButton className={styles.ShoppingCartButton} mt={"auto"} />
            </Box>
            {/* ~~~~~~~~~~~~~~ xl view ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */}
            <Box
              className={styles.menuContainer}
              sx={{
                display: { xs: "none", lg: "flex" },
              }}
            >
              <NavigationProfileButton />
            </Box>
            <Box
              className={styles.menuContainer}
              sx={{
                display: { xs: "none", lg: "flex" },
                textAlign: "baseline",
              }}
            >
              <ShoppingCartButton text="EINKAUFSWAGEN" />
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <div
        className={styles.accordionMenu}
        style={{
          height: navIsOpen ? "100vh" : "0px",
        }}
        onClick={handleCloseAccordionMenu}
      >
        <div className={styles.accordionMenuList}>
          {pages.map((page) => (
            <Link
              key={page.route}
              to={page.route}
              style={{
                textDecoration: "none",
              }}
            >
              <MenuItem
                key={page.route}
                onClick={handleClickAccordionMenu}
              // sx={{
              //   padding: "0px"
              // }}
              >
                <LargeText fWeight="bold" color="#FFFFFF" padding="0px">
                  {page.label}
                </LargeText>
              </MenuItem>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}
