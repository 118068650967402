import { useAppDispatch, useAppSelector } from "app/hooks";
import { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom'
import { Box, Button, Container, FormControl, IconButton, InputAdornment, TextField } from "@mui/material";
import { LargeText, MediumText } from "components/typography";
import { userConfirm } from "features/user/userSlice";
import { clearCustomer } from "features/customer/customerSlice";
import { useNavigate } from "react-router-dom"
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { clearConfirmationErrors, confirmAgent, selectAgentRegisterStatus, selectConfirmationErrors } from "features/agent/agentSlice";
import Header from "components/header/Header";
import styles from "./InviteAgent.module.css";


let lastSearch: string

export default function AgentConfirm() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const loc = useLocation();

  let [confirmSuccess, setConfirmSuccess] = useState(false);
  const errors: any = useAppSelector(selectConfirmationErrors) || {};
  const agentRegistered: boolean = useAppSelector(selectAgentRegisterStatus) || false;
  const [password, setPassword] = useState("");
  const [password_confirmation, setPasswordConfirmation] = useState("");
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [token, setToken] = useState("")

  function errorExists(name: string): boolean {
    return !!errors[name] as boolean;
  }
  function extractError(name: string): string {
    return !!errors[name] ? (errors[name].join(", ") as string) : "";
  }

  useEffect(() => {
    if (loc.search) {
      const query = new URLSearchParams(loc.search);
      const token = query.get("token");
      if (!token || lastSearch === token) return;
      setToken(token)
    }
  }, [loc.search]);

  useEffect(() => {
    if (errors && errors.length > 0) {
      setTimeout(() => {
        dispatch(clearConfirmationErrors());
      }, 10000);
    }
  }, [errors]);

  useEffect(() => {
    if (errors && Object.keys(errors).length === 0 && agentRegistered) {
      setConfirmSuccess(true);
    }}, [errors, agentRegistered]);

  return (
    // <Container sx={{ display: "flex", flexDirection: "column", gap: 2, mt: 5, mb: 5, alignItems: "center", pt: 2 }}>

    <Container
      maxWidth="xl"
      sx={{

        pt: 4,
        pb: 4,
        // ml: 4,
        mb: 3,
      }}
    >
      {confirmSuccess ? (
        <Container sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
          <LargeText fWeight="bold"> Vielen Dank!</LargeText>
          <MediumText> Ihre Registrierung war erfolgreich.</MediumText>
          <Button
            variant="contained"
            sx={{
              color: '#072D26',
              my: 2,
              backgroundColor: '#C4D661',
              borderColor: '#C4D661',
              borderRadius: 30,
              fontSize: 'small',
              paddingY: 1.5,
              paddingX: 5,
              '&:hover': {
                backgroundColor: '#C0D904'
              }
            }}
            onClick={() => {
              navigate("/login", { replace: true })
            }}
          >
            <MediumText color="#072D26" fWeight="bold">
              ZUM LOGIN
            </MediumText>
          </Button>
        </Container>
      ) : (
        <>
          <Header header="Passwort festlegen" />
          <FormControl
            sx={{
              width: { xl: "33%", lg: "60%", md: "80%", xs: "100%" },
              gap: 0.5,
              pt: 2,
            }}
          // className={styles.form}
          >

            <MediumText>Passwort</MediumText>
            <TextField
              error={errorExists("password")}
              helperText={extractError("password")}
              type={showPassword1 ? "text" : "password"}
              placeholder="Passwort wählen"
              value={password}
              onChange={(event: any) => {
                setPassword(event.target.value);
              }}
              name={"password"}
              InputProps={{
                className: styles.input,
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => {
                        setShowPassword1(false);
                      }}
                      onMouseDown={() => {
                        setShowPassword1(true);
                      }}
                    >
                      {" "}
                      <VisibilityOffOutlinedIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <MediumText>Passwort wiederholen</MediumText>

            <TextField
              error={errorExists("password_confirmation")}
              helperText={extractError("password_confirmation")}
              type={showPassword2 ? "text" : "password"}
              placeholder="Passwort wiederholen"
              value={password_confirmation}
              onChange={(event: any) => {
                setPasswordConfirmation(event.target.value);
              }}
              name={"password_confirmation"}
              InputProps={{
                className: styles.input,
                style: { borderColor: "#D0C2A8" },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton aria-label="toggle password visibility" onClick={() => setShowPassword2(false)} onMouseDown={() => setShowPassword2(true)}>
                      {" "}
                      <VisibilityOffOutlinedIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              sx={{
                color: "#072D26",
                my: 2,
                mt: 2,
                backgroundColor: "#C4D661",
                borderColor: "#C4D661",
                borderRadius: 30,
                fontSize: "small",
                paddingY: 1.5,
                paddingX: 5,
                "&:hover": {
                  backgroundColor: "#C0D904",
                },
              }}
              onClick={() => {
                dispatch(
                  confirmAgent({
                    token,
                    password,
                    password_confirmation,
                  })
                );
              }}
            >
              <MediumText fWeight="bold">Passwort setzen</MediumText>
            </Button>
          </FormControl>
        </>
      )}
    </Container>

  );
}