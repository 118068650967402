import { useAppDispatch, useAppSelector } from "app/hooks";
import { selectLotLocationsFiltered, selectLotLocationsUserLocation } from "features/lotLocations/lotLocationsSlice";
import L, { Icon, LatLng, Point } from "leaflet";
import { Marker, useMap } from "react-leaflet";
import MarkerClusterGroup from "./MakeClusterGroup";
import polter from "assets/icons/Polter.svg";
import clusterIcon from "assets/icons/Cluster.svg";
import { clearClickedMarker, setMarkerClicked } from "features/lotOffer/lotOfferSlice";
import { useEffect, useRef } from "react";

let markerclusterOptions = {
  maxClusterRadius: 100,
  spiderfyDistanceMultiplier: 2,
  spiderfyOnMaxZoom: true,
  showCoverageOnHover: true,
  zoomToBoundsOnClick: true,
  animate: true,
  iconCreateFunction: (cluster: any): any => new L.Icon({ iconUrl: clusterIcon, iconSize: [38, 95] }),
};

export default function LotClusterMarker() {

  const lotLocations = useAppSelector(selectLotLocationsFiltered);
  const location = useAppSelector(selectLotLocationsUserLocation);
  const dispatch = useAppDispatch();
  const map = useMap();
  const markerGroup = useRef<any | undefined>(undefined);
  let LotIcon = new Icon({
    iconUrl: polter,
    iconRetinaUrl: polter,
    iconSize: [38, 95], // size of the icon
    shadowSize: [50, 64], // size of the shadow
    iconAnchor: [22, 94], // point of the icon which will correspond to marker's location
    shadowAnchor: [4, 62], // the same for the shadow
    popupAnchor: [-3, -76], // point from which the popup should open relative to the iconAnchor
  });
  useEffect(() => {
    if (markerGroup.current && location && lotLocations.length > 0) {
      const markerBounds = markerGroup.current.getBounds();
      if(location.latitude && location.longitude) {
        const locationPoint = new LatLng(location.latitude, location.longitude);
        markerBounds.extend(locationPoint);
      }
      // for(let i=0;i<lotLocations.length; i++){
      //   if(lotLocations[i].wood_piles[0].longitude === 0) {
      //     console.log(lotLocations[i])
      //   }
      // }
      map.fitBounds(markerBounds.pad(0.1));
    }
  }, [lotLocations, map, location]);
  return (
    <MarkerClusterGroup {...markerclusterOptions} ref={markerGroup}>
      {lotLocations.map((lotLocation, locIndex) => {
        if (lotLocation.wood_piles[0].longitude === 0) {
          return null;
        } else {
          return lotLocation.wood_piles.map((wood_pile, woodPileIndex) => (
            <Marker
              key={locIndex + "-" + woodPileIndex}
              eventHandlers={{
                mousedown: (e) => {
                  dispatch(setMarkerClicked(lotLocation));
                },
                click: (e) => {
                  dispatch(clearClickedMarker());
                  dispatch(setMarkerClicked(lotLocation));
                },
              }}
              data-location={lotLocation}
              position={[wood_pile.latitude, wood_pile.longitude]}
              icon={LotIcon}
            />
          ))
        }
      }
      )}
    </MarkerClusterGroup>
  );
}
