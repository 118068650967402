import { Checkbox, MenuItem, Select } from "@mui/material";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { FilterLotSearchText } from "components/typography";
import {
  selectLotLocationFilter,
  selectLotLocationFilterSettings,
  setWoodTypes,
} from "features/lotLocations/lotLocationsSlice";

export default function WoodTypeFilterSelection() {
  const valueSettings = useAppSelector(selectLotLocationFilterSettings).wood_types;
  const value = useAppSelector(selectLotLocationFilter).wood_types;

  
  const dispatch = useAppDispatch();
  const handleWoodType = (event: any) => {
    dispatch(setWoodTypes(event.target.value));
  };

  if (valueSettings.length <= 1) return <div></div>;
  //Holzart
  return (
    <Select
      value={value}
      // label="Holzart"
      multiple
      onChange={handleWoodType}
      native={false}
      // renderValue={(selected) => {
      //   return selected.length == 0 ? (
      //     <FilterLotSearchText color={"gray"}>Holzart</FilterLotSearchText>
      //   ) : (
      //     <FilterLotSearchText>{selected.join(",")}</FilterLotSearchText>
      //   );
      // }}
      displayEmpty
      renderValue={
        value.length > 0
          ? () => (
              <FilterLotSearchText>
                {value.length} ausgewählt
              </FilterLotSearchText>
            )
          : () => (
              <FilterLotSearchText color={"gray"}>Holzart</FilterLotSearchText>
            )
      }
      sx={{
        backgroundColor: "white",
        minWidth: { xs: "180px", lg: "176px" },
        borderRadius: "26px",
        display: "flex",
        padding: "2px 4px",
        alignItems: "center",
        height: "50px",
      }}
    >
      {/* variantName.findIndex(item => item.id === variant.id) >= 0 */}
      {valueSettings.map((woodType: string) => (
        <MenuItem key={woodType} value={woodType}>
          <Checkbox
            checked={
              value.findIndex((item) => item === woodType) >= 0
            }
          />
          {/* <Checkbox checked={woodTypeOptions.includes(woodType)} /> */}
          {woodType}
        </MenuItem>
      ))}
    </Select>
  );
}
