import axiosInstance from "app/axios";
import { AxiosResponse } from "axios";
import { SellerInterface } from "interfaces/Seller";
import { UserRoles } from "interfaces/UserRoles";

export interface fetchSellersListArgument {
  items_per_page: number;
  page: number;
  search: string | undefined;
  endpoint?: UserRoles;
}

export interface SellerResponseInterface {
  data: {
    total: number;
    pages: number;
    sellers: SellerInterface[];
  };
}

export function fetchSellersList({ items_per_page, page, search, endpoint = UserRoles.Agent }: fetchSellersListArgument): Promise<AxiosResponse<SellerResponseInterface, any>> {
  return axiosInstance.get(`${endpoint}/sellers`, {
    params: {
      items_per_page,
      page,
      search,
    },
  });
}
