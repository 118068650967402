import axiosInstance from "app/axios";
import { AxiosResponse } from "axios";
import { CustomerSettingsInterface } from "interfaces/CustomerSettingsInterface";
import { UserDataInterface } from "interfaces/UserDataInterface";

export interface UserDataResponseInterface {
  data: UserDataInterface;
}

export function getUserData(): Promise<
  AxiosResponse<UserDataResponseInterface, any>
> {
  return axiosInstance.get("user");
}

export interface saveUserDataArgument {
  userData: UserDataInterface;
}

export function saveUserData({ userData }: saveUserDataArgument) {
  return axiosInstance.patch("user", userData);
}
