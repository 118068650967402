import {
  FormControl,
  Box,
  Container,
  Typography,
  Autocomplete,
  TextField,
} from "@mui/material";
import styles from "../Account.module.css";
import { MediumText } from "../../../../components/typography";
import { useAppDispatch, useAppSelector } from "app/hooks";
import HolzfinderFormInput from "components/inputs/HolzfinderFormInput";
import { selectCountries, updateCity, updateStreet, updateStreetNumber, updateZipCode, updateCountryId, selectCustomer, selectErrors, customerSettings, updatePhone, customerCountries } from "features/customer/customerSlice";
import { CustomerCountriesInterface, CustomerCountryInterface } from "interfaces/CustomerCountriesInterface";
import { useEffect, useState } from "react";
import { mapCustomerCountries } from "features/customer/mapCustomerCountries";


interface Props {
  zipCodeError: string;
  customerData: any;
  setCustomerData: any;
}

export default function AccountSettingsForm(props: Props) {
  const { zipCodeError, customerData, setCustomerData } = props
  const dispatch = useAppDispatch()
  const countries = useAppSelector(selectCountries)

  useEffect(() => {
    dispatch(customerSettings())
  }, [])

  function getCountryName() {
    if (!customerData.country_id || !countries) {
      return "Land";
    }
    const selectedCountry = countries.find(
      (el: CustomerCountryInterface) => el.id === customerData.country_id
    )
    
    return selectedCountry ? selectedCountry.name : "Land";
  }


  const errors: any = useAppSelector(selectErrors) || {};

  const handleChanges = (name: any, value: any) => {
    setCustomerData({
      ...customerData,
      [name]: value,
    });
  }


  return (
    <Box>
      <Box>
        <Typography
          fontWeight={"bold"}
          variant="h5"
          sx={{
            mt: 2,

            color: "#072D26",
          }}
        >
          ANSCHRIFT
        </Typography>
      </Box>

      <FormControl
        sx={{
          width: { lg: "60%", md: "80%", xs: "100%" },
          pt: 2, gap: 0.5
        }}
        className={styles.form}
      >
        <MediumText>Straße*</MediumText>
        <TextField
          type="text"
          placeholder="Straße"
          onChange={(event) => {
            handleChanges(event.target.name, event.target.value)
          }}
          name={"street"}
          required
          value={customerData.street}
          InputProps={{
            className: styles.input
          }}

        />

        <MediumText>Hausnummer*</MediumText>
        <TextField
          type="tel"
          placeholder="Hausnummer"
          InputProps={{
            className: styles.input
          }}
          required
          onChange={(event) => { handleChanges(event.target.name, event.target.value) }}
          name={"street_number"}
          value={customerData.street_number}
        />

        <MediumText>PLZ*</MediumText>
        <TextField
          error={Boolean(zipCodeError)}
          helperText={zipCodeError}
          required
          type="tel"
          placeholder="PLZ"
          onChange={(event) => { 
            // const inputValue = event.target.value;
            // const numericInput = inputValue.replace(/[^0-9]/g, '');
            handleChanges(event.target.name, event.target.value);
          }}
          name={"zip_code"}
          value={customerData.zip_code}
          InputProps={{
            className: styles.input
          }}
        />

        <MediumText>Ort*</MediumText>
        <TextField
          required
          type="text"
          placeholder="Ort"
          onChange={(event) => {
            const inputValue = event.target.value;
            const nonNumericInput = inputValue.replace(/[0-9]/g, '');
            handleChanges(event.target.name, nonNumericInput)
          }}
          name="city"
          value={customerData.city}
          InputProps={{
            className: styles.input
          }}
        />


        <MediumText>Telefon</MediumText>
        <TextField

          type="tel"
          placeholder="Telefon"
          onChange={(event) => { handleChanges(event.target.name, event.target.value) }}
          name={"phone"}
          value={customerData.phone}
          InputProps={{
            className: styles.input
          }}
        />

        <MediumText>Land*</MediumText>
        <Autocomplete
          options={countries || []}
          groupBy={(option: any) => option.firstLetter}
          getOptionLabel={(option: any) => option.label}
          renderInput={(params) =>
            <TextField
              {...params}
              placeholder={getCountryName()}
              sx={{ ".MuiInputBase-root": { borderRadius: "26px" } }}
            />
          }
          onChange={(event, value) => {
            if (value) {
              handleChanges("country_id", value.id)
            }
          }}
        />

        {/* <MediumText>Land</MediumText> */}
        {/* <Select type="text" placeholder="Land" className={styles.input} onChange={handleChange} name={"town"}>
        </Select> */}
        {/* <Link
          color={"#000000"}
          fontWeight={"bold"}
          sx={{ mt: 1, mb: 1, whiteSpace: "pre" }}
        >
          + ABWEICHENDE RECHNUNGSADRESSE
        </Link> */}
      </FormControl>
    </Box>
  );
}