import { store } from "app/store";

function setValue(map: Map<string, any>, key: string, value: any) {
  if (!map.has(key)) {
    map.set(key, []);
    map.get(key).push(value)
    return;
  }
  map.get(key).push(value);
}


export function mapCustomerCountries() {
  const state = store.getState()
  const countries = state.customerSettings.countries
  let mappedCustomerCountries = new Map();
  if(countries && countries.length >= 1){

    countries.forEach(function (item: any) {
      mappedCustomerCountries.set(item.id, item)
    })
  }
  return  mappedCustomerCountries
  
}