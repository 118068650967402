import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { persistore, store } from "./app/store";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import "./assets/fonts/DMSans-Regular.ttf";
import { PersistGate } from "redux-persist/integration/react";
import HolzfinderThemeProvider from "provider/HolzfinderThemeProvider";
import HolzfinderBrowserRouterProvider from "provider/HolzfinderBrowserRouterProvider";

const container = document.getElementById("root")!;
const root = createRoot(container);


root.render(
  // <React.StrictMode> hat zu doppeltem Rendering geführt
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistore}>
        <HolzfinderThemeProvider>
          <HolzfinderBrowserRouterProvider />
        </HolzfinderThemeProvider>
      </PersistGate>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
console.info(`HOLZFINDER VERSION: ${process.env.REACT_APP_VERSION}`);
