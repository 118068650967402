import axiosInstance from "app/axios";
import { AxiosResponse } from "axios";
import { CustomerDataInterface } from "interfaces/CustomerDataInterface";
import { CustomerSettingsInterface } from "interfaces/CustomerSettingsInterface";

export interface CustomerSettingsResponseInterface {
  data: CustomerSettingsInterface;
}

export interface saveCustomerDataArgument {
  customerData: CustomerDataInterface;
}

export interface NewCustomerDataResponseInterface {
  data?: {
    errors: {
      zip_code?: string[],
    }
  };
}

export function saveCustomerData({customerData}: saveCustomerDataArgument): Promise<AxiosResponse<NewCustomerDataResponseInterface, any>> {
  return axiosInstance.patch("customer/settings", customerData);
}

export function getCustomerSettings(): Promise<
  AxiosResponse<CustomerSettingsResponseInterface, any>
> {
  return axiosInstance.get("customer/settings");
}