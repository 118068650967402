import { Box, Button, Slide, SvgIcon, useMediaQuery } from "@mui/material";
import "leaflet/dist/leaflet.css";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  setHighlightedMarkerLocation,
  clearHighlightMarkerLocation,
  selectLotLocationsFiltered,
} from "features/lotLocations/lotLocationsSlice";
import HolzpolterInfoSmall from "./HolzpolterInfoSmall";
import { useState } from "react";
import { LargeText } from "components/typography";
import { DeleteSmall } from "icons";
import holzfinderDefaultLotImage from "assets/images/Holzfinder_Los_Platzhalter.jpg"
import styles from "./LosSectionSmall.module.css";
export default function LosSuche() {
  const [expanded, setExpanded] = useState(false);
  const lotLocationOffers = useAppSelector(selectLotLocationsFiltered);
  const baseURL = process.env.REACT_APP_API_DOMAIN;
  const dispatch = useAppDispatch();
  const highlightMarker = (data: any) => {
    dispatch(setHighlightedMarkerLocation(data[0]));
  };
  const matches600 = useMediaQuery('(max-width: 600px)')
  const matches430 = useMediaQuery('(max-width: 430px)')

  const clearHighlightMarker = () => {
    dispatch(clearHighlightMarkerLocation());
  };
  // console.log(lotLocationOffers.length)
  return (
    <Box sx={{
      position: "absolute",
      width: "100%",
      borderRadius: "13px 13px 0px 0px",
      zIndex: 1101,
      bottom: 0,
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      height: expanded ? "75%" : "5%"
    }}>
      <Box sx={{ display: "flex", position: "relative", zIndex: 1102, backgroundColor: "white", alignItems: "center", width: "100%" }}>
        <Button
          sx={{ pt: 2, zIndex: 1102, width: "100%" }}
          onClick={() => {
            setExpanded(!expanded);
          }}
        >
          <LargeText fWeight="bold" color="#072D26">
            {lotLocationOffers.length > 100 ? "Mehr als 100" : lotLocationOffers.length} Holzpolter
          </LargeText>
        </Button>
        {expanded ? (
          <SvgIcon
            sx={{ zIndex: 1102, ml: "auto", pr:2 }}
            onClick={() => {
              setExpanded(!expanded);
            }}
          >
            <DeleteSmall />
          </SvgIcon>
        ) : null}
      </Box>
      <Slide
        direction="up"
        in={expanded}
        timeout={{
          enter: matches600 ? 400 : 200,
          exit: matches600 ? 200 : 100,
        }}
      >
        <Box
          sx={{
            zIndex: 1100,
            width: "100%",
            maxHeight: "95vh",
            height: "100%",
            alignSelf: "bottom",
            overflow: "hidden",
            display: { xs: "block", lg: "none" },
            backgroundColor: "white",

          }}
        >
          <Box
            sx={{
              overflowY: "auto",
              width: "100%",
              height: "100%",
              paddingRight: "17px",
              boxSizing: "content-box",

            }}
          >
            {lotLocationOffers.map((lotLocationOffer, index) => (
              <Box className={styles.losSectionSmallDiv}
                key={index}
                sx={{
                  mt: "10px",
                  padding: "15px 15px",

                }}
                onMouseDown={() => {
                  highlightMarker(lotLocationOffer.wood_piles);
                }}
                onMouseUp={() => {
                  clearHighlightMarker();
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    overflow: "hidden",
                    height: "80%",
                    width: "inherit",
                    flexDirection: "column",
                    "&:hover img": {
                      transform: "scale(1.25)",
                      transformOrigin: "50% 50%",
                      transition: "transform 0.4s",

                    },
                  }}
                >
                  <Box
                    component="div"
                    sx={{
                      overflow: "hidden",
                      width: "100%",
                      mt: 2,
                      maxWidth: "80%"
                    }}
                  >
                    <Box
                      component="img"
                      sx={{
                        aspectRatio: "4/3",
                        objectFit: "cover",
                        width: "inherit",
                        maxWidth: "100%",
                        overflow: "hidden",


                      }}
                      alt="image"
                      src={lotLocationOffer.image_urls[0] ? baseURL + lotLocationOffer.image_urls[0] : holzfinderDefaultLotImage}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      position: "relative",
                      height: "inherit",
                      maxWidth: "80%"
                    }}
                  >
                    <HolzpolterInfoSmall lotInformation={lotLocationOffer} />
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Slide>
    </Box>
  );
}
