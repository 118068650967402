import Grid from "@mui/system/Unstable_Grid";
import React from "react";

export default function LotOfferGrid({ children }: React.PropsWithChildren) {
  return (
    <Grid xs={12} md={6} lg={6} sx={{maxHeight: "100%", maxWidth: "500px", width: "100%!important"}}>
      {children}
    </Grid>
  );
}
