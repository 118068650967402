import axios, { AxiosError, AxiosResponse } from "axios";
import { updateToken, updateUserName, updateSessionGone, logout } from "features/user/userSlice";
import { store } from "./store";

const MAX_RETRIES = 3;
const STATUS_UNAUTHORIZED = 401;
const STATUS_GONE = 410;
const STATUS_LOCKED = 423;

var navigateTo: Function

export function setupNavigator(navigate: Function) {
  navigateTo = navigate;
}

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_DOMAIN,
});

function onAxiosSuccess(response: AxiosResponse) {
  // console.log("axios response: ", response)
  if (response.headers.jwt) {
    store.dispatch(updateToken(response.headers.jwt));
    localStorage.setItem('token', response.headers.jwt);
  }
  // TODO: wozu wurde das benötigt ... es scheint aber große probleme zu verursachen wenn zumbeipiel der user des agents gefetchtwird
  // if (response.data?.data?.first_name && response.data.data.last_name){
  //   store.dispatch(updateUserName(response.data.data))
  // } 

  return response;
}


function onAxiosError(error: AxiosError) {
  // console.log("axios error: ", error)
  const { config, response } = error;
  if (
    response && response.status === STATUS_UNAUTHORIZED &&
    (
      config && (
        !config.headers ||
        !config.headers.retry ||
        typeof config.headers.retry != "number" ||  
        config.headers.retry < MAX_RETRIES
      )
    )
  ) {
    config.headers = config.headers || { retry: 1 };
    switch (typeof config.headers.retry) {
      case "string":
        config.headers.retry = parseInt(config.headers.retry) + 1;
        break;
      case "number":
        config.headers.retry = config.headers.retry + 1;
        break;
      default:
        config.headers.retry = 1;
        break;
    }
    return new Promise((resolve) => {
      resolve(axiosInstance(config));
    });
  } 
  
  if(response && response.status === STATUS_GONE) {
    // clear session and redirect to login and show gone message
    store.dispatch(updateSessionGone(""));
    localStorage.removeItem('token')
    store.dispatch(logout());
    //Todo when you click on LOSSUCHE in the navbar, you get redirected to the login page. cycle through the pages and you will see that the LOSSUCHE page is not displayed.
    // temporary outcommented
    // navigateTo("/login");
    // window.history.pushState({}, '', "/login");

  } else if (error.response?.headers?.jwt) {
    store.dispatch(updateToken(error.response.headers.jwt));
  }
  else if (error.response?.status === STATUS_LOCKED) {
    store.dispatch(updateSessionGone(""));
    localStorage.removeItem('token')
    store.dispatch(logout());
    navigateTo("/login");
  }
  // console.log(error)
  return error.response;
}

axiosInstance.interceptors.response.use(onAxiosSuccess, onAxiosError);

axiosInstance.interceptors.request.use((config) => {
  const state = store.getState();

  config.headers = config.headers || {};
  config.headers.authorization = state.user.token;
  // console.log("axios config: ", config)

  return config;
});

export default axiosInstance;
