import LoadingButton from "@mui/lab/LoadingButton";
import { useAppSelector } from "app/hooks";
import { selectLotOfferRequestStatus } from "features/lotOffer/lotOfferSlice";
import { RequestStatus } from "interfaces/RequestStatus";

// export default function LotOfferButton(props: any) {
//   const { label } = props;
//   const loading = useAppSelector(selectLotOfferRequestStatus);
//   return (
//     <LoadingButton
//       loading={loading === RequestStatus.loading}
//       variant="contained"
//       loadingPosition="end"
//       sx={{ borderRadius: "26px", margin: "8px", height: "51px" }}
//       {...props}
//     >
//       {label}
//     </LoadingButton>
//   );
// }
// TODO: Correct Button interface
interface LotOfferButtonProps {
  label: string;
  margin?: string;
  sx?: any;
  onClick?: any;
  disabled?: boolean;
}

export default function LotOfferButton(props: LotOfferButtonProps) {
  const { label, margin } = props;
  const loading = useAppSelector(selectLotOfferRequestStatus);
  return (
    <LoadingButton
      loading={loading === RequestStatus.loading}
      variant="contained"
      // loadingPosition="end"
      //Failed prop type: MUI: The loadingPosition="end" should be used in combination with endIcon. 
      sx={{ borderRadius: "26px", margin: "8px" }}
      {...props}

    >
      {label}
    </LoadingButton>
  );
}
