import App from "App";
import { store } from "app/store";
import {
  clear as clearLotOffer,
  clearClickedMarker,
  getLotOffer,
} from "features/lotOffer/lotOfferSlice";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import LosListe from "views/admin/losliste/LosListe";
import LotOffer from "views/admin/lotOffer/LotOffer";
import Account from "views/customer/account/Account";
import AccountConfirm from "views/customer/account/AccountConfirm";
import Login from "views/login/Login";
import ChangeLoginData from "views/customer/change-login-data/ChangeLoginData";
import Register from "views/customer/register/Register";
import Homepage from "views/homepage/Homepage";
import { Lossuche } from "views/lossuche/Lossuche";
import {
  clearResults,
  getLotLocations,
} from "features/lotLocations/lotLocationsSlice";
import { userData } from "features/user/userSlice";
import LotOfferDetails from "views/lotOffer/LotDetails";
import ShoppingCart from "views/shoppingCart/ShoppingCart";

import OrderOverview from "views/order/orderOverview/OrderOverview";
import Agb from "views/footerView/agb";
import Zahlungsarten from "views/footerView/Zahlungsarten";
import Datenschutzerklaerung from "views/footerView/Datenschutzerklaerung";
import Impressum from "views/footerView/Impressum";

import { ChangePassword } from "views/customer/change-login-data/components";
import {
  clearCustomer,
  clearPurchase,
  customerCountries,
  customerSettings,
  getCustomer,
  getCustomerPurchase,
} from "features/customer/customerSlice";
import ForgetPass from "views/login/ForgetPass";
import RegisterComplete from "views/customer/registerComplete/RegisterComplete";
import FaqSection from "views/homepage/FaqSection";
import ContactSection from "views/homepage/ContactSection";
import ResetPassword from "views/customer/change-login-data/components/ResetPassword";
import { checkShoppingCart } from "features/shoppingCart/shoppingCartSlice";
import AgentList from "views/admin/agentList/AgentList";
import SellersList from "views/admin/sellersList/SellersList";
import { clear as clearSeller, getSeller } from "features/seller/sellerSlice";
import Seller from "views/admin/seller/Seller";
import AdminAgent from "views/admin/agent/AdminAgent";
import { clear as clearAgent, clearRegisteredState, getAgent, getAgentUser,  setAgentId, } from "features/agent/agentSlice";
import CustomersList from "views/agent/customersList/CustomersList";
import Customer from "views/agent/customer/CustomerDetails";
import CheckOrder from "views/order/checkOrder/CheckOrder";
import OrderSuccessfulRoute from "views/order/succesfulOrder/OrderSuccessfulRoute";
import InvoiceOverview from "views/customer/customerInvoice/InvoiceOverview";
import OrderTaken from "views/order/orderTaken/OrderTaken";
import OrderOverflown from "views/order/orderOverflown/OrderOverflown";
import CustomerDetails from "views/agent/customer/CustomerDetails";
import { AdminWithAuth, AgentWithAuth, CustomerWithAuth, CustomerInvoicesListWithAuth } from "views/authenticateUser/Authenticate";
import { clear as clearTable } from "features/table/tableSlice";
import InviteAgent from "views/admin/agent/InviteAgent";
import AgentConfirm from "views/admin/agent/AgentConfirm";
import AgentUser from "views/admin/agent/AgentUser";
import { UserRoles } from "interfaces/UserRoles";
import Reports from "views/admin/Reports";
import KontaktErfolgreich from "views/homepage/KontaktErfolgreich";
// import { Counter } from "features/counter/Counter";
import NotFoundPage from "404";


const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "",
        element: <Homepage />,
      },
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "registrierung",
        element: <Register />,
      },
      {
        path: "registrierung_erfolgreich",
        element: <RegisterComplete />,
      },
      {
        path: "user",
        children: [
          {
            path: "confirm",
            element: <AccountConfirm />,
          },
          {
            path: "password",
            element: <ResetPassword />,
          },
          {
            path: "accept_invitation",
            element: <AgentConfirm />,
            loader: () => {
              return [store.dispatch(clearRegisteredState())];
            },
          },
        ],
      },
      {
        path: "password-vergessen",
        element: <ForgetPass />,
      },
      {
        path: "kunde",
        element: <CustomerWithAuth />,
        children: [
          {
            path: "mein_profil",
            element: <Account />,
            loader: () => {
              return [store.dispatch(userData()), store.dispatch(customerCountries())];
            },
          },
          {
            path: "meine_einkaeufe",
            element: <CustomerInvoicesListWithAuth />,
          },
          {
            path: "meine_einkaeufe/:invoice_id",
            element: <InvoiceOverview />,
            loader: ({ params }) => {
              store.dispatch(clearPurchase());
              if (params.invoice_id) {
                return store.dispatch(getCustomerPurchase({ id: params.invoice_id }));
              }
              return null;
            },
          },
          {
            path: "password",
            element: <ChangePassword />,
            loader: () => {
              return [store.dispatch(userData())];
            },
          },
          {
            path: "login_daten",
            element: <ChangeLoginData />,
          },
        ]
      },
      {
        path: "einkaufswagen",
        loader: () => {
          return store.dispatch(checkShoppingCart([]));
        },
        element: <ShoppingCart />,
      },
      {
        path: "agb",
        element: <Agb />,
      },
      {
        path: "faq",
        element: <FaqSection />,
      },
      {
        path: "contact",
        element: <ContactSection />,
      },
      {
        path: "zahlungsarten",
        element: <Zahlungsarten />,
      },

      {
        path: "bestelluebersicht",
        element: <OrderOverview />,
      },
      {
        path: "bestellung_pruefen",
        element: <CheckOrder />,
      },
      {
        path: "bestellung_erfolgreich",
        element: <OrderSuccessfulRoute />,
      },
      {
        path: "bestellung_vergeben",
        element: <OrderTaken />,
      },
      {
        path: "bestellung_zuviel",
        element: <OrderOverflown />,
      },
      {
        path: "datenschutzerklaerung",
        element: <Datenschutzerklaerung />,
      },
      {
        path: "impressum",
        element: <Impressum />,
      },
      {
        path: "nachricht-gesendet",
        element: <KontaktErfolgreich />,
      },
      // {
      //   path: "counter",
      //   element: <Counter />,
      // },


      {
        path: "lossuche",
        element: <Lossuche />,
        loader: ({ request, params }) => {
          return [store.dispatch(clearResults), store.dispatch(getLotLocations()), store.dispatch(clearClickedMarker())];
        },
      },
      {
        path: "lossuche/:lot_id",
        element: <LotOfferDetails />,
        loader: ({ request, params }) => {
          if (params.lot_id) {
            return store.dispatch(getLotOffer({ id: params.lot_id }));
          }
          return null;
        },
      },
      //einen path erstellen der zu Kontakt führt
      {
        path: "admin",
        element: <AdminWithAuth />,
        children: [
          {
            path: "losliste",
            element: <LosListe />,
          },
          {
            path: "losliste/:lot_id",
            element: <LotOffer />,
            loader: ({ request, params }) => {
              store.dispatch(clearLotOffer());
              if (params.lot_id) {
                return store.dispatch(getLotOffer({ id: params.lot_id }));
              }
              return null;
            },
          },
          {
            path: "agentenliste",
            element: <AgentList />,
          },
          {
            path: "agentenliste/:agent_id",
            element: <AdminAgent />,
            loader: ({ request, params }) => {
              if (params.agent_id) {
                return store.dispatch(getAgent({ id: params.agent_id }));
                // return [store.dispatch(getAgent({ id: params.agent_id })), store.dispatch(getAgentUsers({ agent_id: params.agent_id, page: 1, items_per_page: 10, search: undefined, role: UserRoles.Agent }))];
              } else {
                store.dispatch(clearAgent());
              }
              return null;
            },
          },
          {
            path: "agentenliste/:agent_id/agent_hinzufuegen",
            element: <InviteAgent />,
            loader: ({ params }) => {
              if (params.agent_id) {
                return store.dispatch(setAgentId(params.agent_id));
              }
              return null;
            }
          },
          {
            path: "agentenliste/:agent_id/:agent_user_id",
            element: <AgentUser />,
            loader: ({ request, params }) => {
              // console.log(params)
              store.dispatch(clearAgent());
              // store.dispatch(clearAgent());
              if (params.agent_user_id) {
                return store.dispatch(getAgentUser({ id: params.agent_user_id }));
              }
              return null;
            },
          },
          {
            path: "verkaeuferliste",
            element: <SellersList />,
          },
          {
            path: "verkaeuferliste/:seller_id",
            element: <Seller />,
            loader: ({ request, params }) => {
              //clear any info left in state from seller

              if (params.seller_id) {
                //get new seller data
                return store.dispatch(getSeller({ id: params.seller_id }));
              }
              else {
                store.dispatch(clearSeller());
              }
              return null;
            },
          },
          {
            path: "kundenliste",
            element: <CustomersList />,
          },
          {
            path: "kundenliste/:customer_id",
            element: <CustomerDetails />,
            loader: ({ request, params }) => {
              // store.dispatch(clearCustomer());
              if (params.customer_id) {
                return store.dispatch(getCustomer({ id: params.customer_id }));
              }
              else {
                store.dispatch(clearCustomer())
              }
              return null;
            },
          },
          {
            path: "reports",
            element: <Reports />,
          },
        ],
      },
      {
        path: "agent",
        element: <AgentWithAuth />,
        children: [
          {
            path: "losliste",
            element: <LosListe />,
          },
          {
            path: "losliste/:lot_id",
            element: <LotOffer />,
            loader: ({ request, params }) => {
              store.dispatch(clearLotOffer());
              if (params.lot_id) {
                return store.dispatch(getLotOffer({ id: params.lot_id }));
              }
              return null;
            },
          },
          {
            path: "verkaeuferliste",
            element: <SellersList />,
          },
          {
            path: "verkaeuferliste/:seller_id",
            element: <Seller />,
            loader: ({ request, params }) => {
              store.dispatch(clearSeller());
              if (params.seller_id) {
                return store.dispatch(getSeller({ id: params.seller_id }));
              }
              return null;
            },
          },
          {
            path: "kundenliste",
            element: <CustomersList />,
          },
          {
            path: "kundenliste/:customer_id",
            element: <Customer />,
            loader: ({ request, params }) => {
              store.dispatch(clearCustomer());
              if (params.customer_id) {
                return store.dispatch(getCustomer({ id: params.customer_id }));
              }
              return null;
            },
          },
          {
            path: "reports",
            element: <Reports />,
          },
        ],
      }
    ],
  },
  {
    path: '*',
    element: <NotFoundPage />, // Hier 404-Komponente oder eigene Fehlerseite einfügen
  },
  // {
  //     path:'*',
  //     element: <Homepage/>
  // }
]);


export default function HolzfinderBrowserRouterProvider() {
  return <RouterProvider router={router} />;
}
