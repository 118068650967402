import { Backdrop, Box, Button, Container, Modal, SvgIcon } from "@mui/material";
import { useAppDispatch, useAppSelector } from "app/hooks";
import ShoppingCartButton from "components/shoppingCart/ShoppingCartButton";
import { selectShoppingCartIds, selectShoppingCartInvalidIds, ShoppingCartInvalidStatus } from "features/shoppingCart/shoppingCartSlice";
import { selectUserIsLoggedId, selectUserRole } from "features/user/userSlice";
import { AccountCircle, MyLocation, OpenWith, Sell, Tree } from "icons";
import { LotOfferLocationInterface } from "interfaces/LotOfferLocationInterface";
import React from "react";
import { useNavigate } from "react-router-dom";
import { LargeText, MediumText, SmallText } from "../../../components/typography";
import SimpleGrow from "../OverlayCard";
import TextWithIcon from "./TextWithIcon";
import { updateAfterLoginTo } from "features/user/userSlice";
import { UserRoles } from "interfaces/UserRoles";

interface InformationenProps {
  lotInformation: LotOfferLocationInterface;
  loginNeeded: boolean;
  lotImage: string;
}

export default function Informationen(props: InformationenProps) {
  const isLoggedIn = useAppSelector(selectUserIsLoggedId);
  const { lotInformation, loginNeeded, lotImage } = props;
  const { agent, seller, county, max_wood_per_period, wood_types, distance, solid_cubic_meters_without_bark, price_per_solid_cubic_meter, price_total, vat_rate, period_duration } = lotInformation;
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const invalidShoppingCartIds = useAppSelector(selectShoppingCartInvalidIds);
  const dispatch = useAppDispatch();
  const handleShoppingCartClick = async (event: any) => {
    event.stopPropagation();
    handleOpen();
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = (event: any) => {
    event.stopPropagation();
    setOpen(false);
  };

  const shoppingCartIds = useAppSelector(selectShoppingCartIds);
  let isInShoppingCart = shoppingCartIds.includes(lotInformation.id);
  const userRole = useAppSelector(selectUserRole) as UserRoles;
  // async function navigateToLot () {
  //   navigate(`/lossuche/${lotId}`)
  // }
  // useEffect(()=> {
  //   if(container && !navigated){
  //     container.addEventListener('click', event => {
  //       const target = event.target as HTMLElement
  //       if(target){
  //         if (target.id !== 'button') {
  //           //@ts-ignore
  //           async function goToLot() {
  //             await navigateToLot();
  //             setNavigated(true)
  //           }
  //           goToLot();
  //         }
  //       }
  //     });
  //   }
  //   return () => {

  //   }
  // }, [navigated])


  function handleButtonClick(event: React.MouseEvent<HTMLButtonElement>) {
    event.stopPropagation();
  }
  const regexForComma = /\,$/

  // console.log(max_wood_per_period)
  // console.log(period_duration)

  return (
    <Container
      className="container"
      data-lot-id={lotInformation.id}
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "inherit",
        borderRadius: "0px 30px 30px 0px !important",
        padding: "0px 15px",
        pt: 2,
        gap: 2,
      }}
    >
      {/* <Box sx={{ textAlign: "start" }}>
        <SmallText >Veröffentlich am: {formated_date}</SmallText>
      </Box> */}
      <Box sx={{ display: "flex" }}>
        <Box sx={{ display: "flex", gap: 2, flexDirection: "column" }}>
          <TextWithIcon icon={<Tree />} text={wood_types.join(", ").length > 30 ? wood_types.join(", ").replace("(unbestimmt)", "").substring(0, 29).trim().replace(regexForComma, "") + "..." : wood_types.join(", ").replace("(unbestimmt)", "")} whiteSpace="pre" />
          <TextWithIcon icon={<OpenWith />} text={`${solid_cubic_meters_without_bark.toFixed(2).replace('.', ',')} fm`} />
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: 2,
            flexDirection: "column",
            whiteSpace: "pre",
            ml: "auto",
          }}
        >
          {distance ? <TextWithIcon icon={<MyLocation />} text={`${(distance / 1000).toFixed(2).replace('.', ',')} km entfernt`} /> : <></>}

          <TextWithIcon
            icon={<Sell />}
            text={`${Number(price_per_solid_cubic_meter)
              .toFixed(2)
              .replace(".", ",")
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") + " €"
              }  / fm`}
          />
        </Box>
      </Box>
      <Box sx={{ backgroundColor: "#F6F3EE", p: 1, mt: 0.5 }}>
        {loginNeeded ? (
          <Box>
            <SmallText>
              Der Verkauf erfolgt durch <b>{agent}</b>. Im Auftrag von <b>{seller}</b>.
            </SmallText>
            {!!max_wood_per_period && !!period_duration || max_wood_per_period === 0 ? (
              <SmallText>
                {agent} erlaubt den Erwerb von {max_wood_per_period} fm / {period_duration} Monate je registriertem Nutzer.
              </SmallText>


            ) : null}
            {!isLoggedIn ? (
              <SmallText>
                Sie müssen sich deshalb einloggen, um dieses Los zu kaufen.
              </SmallText>
            ) : null
            }
          </Box>
        ) : (
          <SmallText>
            Von <strong>{seller}</strong> durch <strong>{agent}</strong>.
          </SmallText>
        )}
      </Box>

      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box sx={{ my: 1, display: "flex", alignItems: "baseline", gap: 18 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "baseline",
              gap: 1,
              // maxWidth: "200px",
              // width: "100%",
            }}
          >
            <LargeText fWeight="bold" float="left" color="#072D26">
              {Number(price_total)
                .toFixed(2)
                .replace(".", ",")
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") + " €"}
            </LargeText>
            <MediumText color="#D0C2A8" float="left" whiteSpace="pre">
              Inkl. {vat_rate}% MwSt.
            </MediumText>
          </Box>
        </Box>
        {/* <Box sx={{textAlign: "right", position: "relative", bottom: "2px", right: "40px", width: "100%", top: "5px"}}> */}
        {/* <Box sx={{float: "right", justifyContent: "flex-end", display: "flex"}}> */}

        {loginNeeded && !isLoggedIn ? (
          <Box sx={{ textAlign: "right", width: "160px" }}>
            <Button
              sx={{
                borderRadius: "30px",
                backgroundColor: "#D9E868",
                color: "#000000",
                "&:hover": { backgroundColor: "#D9E868" },
                padding: "12px 24px",
                gap: 1,
                mb: 1,
              }}
              onClick={(event) => {
                dispatch(updateAfterLoginTo("/lossuche"))
                navigate("/login");
                handleButtonClick(event);

              }}
              className="button"
            >
              <MediumText fWeight="bold">Login</MediumText>
              <SvgIcon>
                <AccountCircle />
              </SvgIcon>
            </Button>
          </Box>
        ) : (
          <Box
            sx={{
              textAlign: "right",
              position: "relative",
              bottom: "2px",
              marginBottom: "12px",
              // right: "40px",
              width: "100%",
              top: "5px",
            }}
          >
            <ShoppingCartButton onClick={handleShoppingCartClick} lotInformation={lotInformation} disabled={ /*max_wood_per_period === 0 ||*/  isInShoppingCart || (userRole === "admin" || userRole === "agent")} />
          </Box>
        )}
      </Box>
      <Backdrop sx={{ backgroundColor: "#grey", zIndex: 1200 }} open={open} onClick={handleClose}>
        <Modal hideBackdrop open={open} onClose={handleClose} aria-labelledby="modal-title" sx={{ display: "flex", justifyContent: "center" }}>
          <SimpleGrow checked={open} handleClose={handleClose} imageUrl={lotImage} lotData={lotInformation} />
        </Modal>
      </Backdrop>
    </Container>
  );
}

