import { Container } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";

import BackgroundImage from "../../components/background-image/BackgroundImage";
import { StyledEngineProvider } from "@mui/material";
import styles from "./Homepage.module.css";
import background from "../../assets/images/background-main-small.jpg";
import InfoBox from "./InfoBox";
import SearchBar from "./SearchBar";
import IntroductionAndMap from "./IntroductionAndMap";
import FaqSection from "./FaqSection";
import ContactSection from "./ContactSection";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { flushSync } from "react-dom";

type HomepagePropType = any;

export default function Homepage(props: HomepagePropType): JSX.Element {
  const { state } = useLocation();
  const { targetId } = state || {};

  // TODO: @Ali bitte rückmeldung
  // siehe auch https://stackoverflow.com/questions/40280369/use-anchors-with-react-router

  // Der nachfolgende code ist `Quatsch`. Was soll das eigentlich machen? Hat es je funktioniert?
  // useEffect(() => {
  //   flushSync(() => {
  //     const el = document.getElementById(`${targetId}`);
  //     if (el) {
  //       el.scrollIntoView({
  //         behavior: "smooth",
  //         block: "start",
  //         inline: "start",
  //       });
  //     }
  //   }, [targetId]);
  // });


  return (
    <StyledEngineProvider injectFirst>
      <Container className={styles.mainBgImage}></Container>
      <BackgroundImage
        // backgroundImageHeight={"800px"}
        containerClassName={styles.mainBackgroundImage}
        backgroundImage={background}
      >
        <Container className={styles.backgroundImageHeader}>
          <Grid2 container>
            <InfoBox />
          </Grid2>
        </Container>
      </BackgroundImage>
      <SearchBar />
      <IntroductionAndMap />
      <FaqSection />
      <ContactSection id="contact-section" />
    </StyledEngineProvider>
  );
}
