import { Box, Container, Grid } from "@mui/material";
import { useAppDispatch, useAppSelector } from "app/hooks";
import Header from "components/header/Header";
import ShoppingList from "components/shoppingCart/ShoppingList";
import ShoppingSummary from "components/shoppingCart/ShoppingSummary";
import { selectUserIsLoggedId } from "features/user/userSlice";
import CustomerBillingInformation from "./CustomerBillingInformation";
import { useEffect } from "react";
import { clearShoppingCart, setFromShoppingCart } from "features/shoppingCart/shoppingCartSlice";
import { useNavigate } from "react-router-dom";
import styles from "./OrderOverview.module.css";


// const { login_and_validation_required} = clientInformation

export default function OrderOverview() {
  const navigate = useNavigate();

  const isLoggedIn = useAppSelector(selectUserIsLoggedId);
  const dispatch = useAppDispatch();
  useEffect(() => {

    dispatch(setFromShoppingCart(false))
    // if (!isLoggedIn) {
    //   dispatch(dispatch(clearShoppingCart()))
    //   navigate("/login",);
    // }
  }, [])

  return (
    <Container sx={{ pt: 4, pb: 4 }}>
      <Grid container>
        <Header header="Bitte prüfen Sie Ihre Bestellung" backButton />
        <Grid mt={3} item xs={12} md={8} lg={8}>
          <ShoppingList />
        </Grid>
        <Grid item xs={12} md={4} lg={4}>
          <Box className={styles.shoppingSummaryText} sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            {isLoggedIn ? <CustomerBillingInformation /> : <div></div>}
            <ShoppingSummary text="Jetzt Kostenpflichtig bestellen" fromShoppingCart={false} />
          </Box>
        </Grid>

      </Grid>
    </Container>
  );
}
