import { ReactNode } from "react";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import { Property } from "csstype";
import styles from "./BackgroundImage.module.css";
type BackgroundImagePropType = {
  children: ReactNode;
  backgroundImage?: string;
  containerClassName?: string;
  childrenReverse?: boolean;
  topOffset?: number;
  backgroundImageHeight?: Property.Height<(string & {}) | 0>;
};
export default function BackgroundImage({
  children,
  backgroundImage,
  containerClassName,
  childrenReverse,
  topOffset,
  backgroundImageHeight,
}: BackgroundImagePropType): JSX.Element {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));
  return (
    <div
      className={`${styles.backgroundImage} 
                  ${containerClassName ? containerClassName: ""} 
                  ${matches ? styles["backgroundImage--desktop"] : ""}
                  ${childrenReverse ? styles["reverseChildren"] : ""}`
      }
      style={backgroundImageHeight ? { "--height": backgroundImageHeight } : {}}
    >
      <img className={styles.backgroundImage__image} src={backgroundImage} alt=""/>
      <div 
        className={topOffset ? styles.backgroundImage__children_customPos: styles.backgroundImage__children}
        style={{top:topOffset}}
      >{children}</div>
    </div>
  );
}
