import { ReactNode } from "react";
import { Typography } from "@mui/material";

interface Props {
  children?: ReactNode;
  color?: String;
  fWeight?: String;
  mt?: any;
  mb?: any;
  mr?: any;
  ml?: any;
  textAlign?: String;
  display?: String;
  float?: String;
  height?: any;
  padding?: any;
  alignSelf?: string;
  whiteSpace?: string;
  textdecor?: boolean;
  // any props that come into the component
}

export default function MediumText({ children, color, fWeight, mt, mb, textAlign, display, ml, mr, float, height, padding, alignSelf, whiteSpace, textdecor }: Props) {
  return (
    <Typography 
    textAlign="center"
    sx={{
      fontFamily: 'DMSans',
      mt: mt,
      mb: mb,
      mr: mr,
      ml: ml,
      color: `${color}`,
      fontWeight: `${fWeight}`,
      fontSize: {xs: 12, md: 16},
      letterSpacing: 0,
      textAlign: `${textAlign}`,
      display: `${display}`,
      float: `${float}`,
      height: `${height}`,
      padding: `${padding}`,
      alignSelf: `${alignSelf}`,
      whiteSpace: `${whiteSpace}`,
      ...(textdecor ? {
        textDecoration: "underline",
      } : {
        textDecoration: "none"
      })
    }}
    >
      {children}
    </Typography>
  );
}
