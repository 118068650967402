import { useAppDispatch } from "app/hooks";
import { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom'
import { Button, Container } from "@mui/material";
import { LargeText, MediumText } from "components/typography";
import { userConfirm } from "features/user/userSlice";
import { clearCustomer } from "features/customer/customerSlice";
import { useNavigate } from "react-router-dom"

let lastSearch: string

export default function AccountConfirm() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const loc = useLocation();

  let [inProcces, setInProcess] = useState(true);
  let [confirmSuccess, setConfirmSuccess] = useState(false);
  let [error, setError] = useState(0);
  
  useEffect(() => {
    if (loc.search) {
      const query = new URLSearchParams(loc.search);
      const token = query.get("token");
      if (!token || lastSearch === token) return;
      dispatch(clearCustomer());

      lastSearch = token;

      setInProcess(true);
      dispatch(userConfirm(token)).then((event: any) => {
        setInProcess(false);
        if (event.type === "user/confirm/fulfilled") {
          setConfirmSuccess(true);
        } else {
          setConfirmSuccess(false);
          if (event.payload.status === 410) {
            // Gone
            setError(410);
          } else {
            setError(0);
          }
        }
      });
    }
  }, [loc.search, dispatch, navigate]);

  return (
    <Container sx={{ display: "flex", flexDirection: "column", gap: 2, mt: 5, mb: 5 }}>
      {inProcces ? (
        <LargeText fWeight="bold">Ihre Anfrage wird bearbeitet!</LargeText>
      ) : (
        <>
          {confirmSuccess ? (
            <>
              <LargeText fWeight="bold"> Vielen Dank!</LargeText>
              <MediumText> Ihre Registrierung war erfolgreich. Füllen Sie bitte ihr Profil aus.</MediumText>
              <Button
                variant="contained"
                sx={{
                  display: 'flex',
                  alignSelf: 'flex-start',
                  color: '#072D26',
                  my: 2,
                  backgroundColor: '#C4D661',
                  borderColor: '#C4D661',
                  borderRadius: 30,
                  fontSize: 'small',
                  paddingY: 1.5,
                  paddingX: 5,
                  '&:hover': {
                    backgroundColor: '#C0D904'
                  }
                }}
                onClick={() => {
                  navigate("/kunde/mein_profil", {replace: true})
                }}
                >
                  <MediumText color="#072D26" fWeight="bold">
                    ZUM PROFIL
                  </MediumText>
            </Button>
            </>
          ) : error === 410 ? (
            <>
              <LargeText fWeight="bold">Ihr Account wurde bereits angemeldet!</LargeText>
            </>
          ) : (
            <>
              <LargeText fWeight="bold">Ungültige Anmeldung!</LargeText>
              <MediumText> Ihre Registrierung ist leider abgelaufen!</MediumText>
              <MediumText> Um einen neuen Registierungscode zu bekommen, müssen Sie sich erneut registrieren und dann innerhalb von 24 Stunden auf den Ihnen zugesandten Link klicken.</MediumText>
            </>
          )}
        </>
      )}
    </Container>
  );
}