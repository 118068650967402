import Box from "@mui/material/Box";
import React from "react";
import Paper from "@mui/material/Paper";
import Grow from "@mui/material/Grow";
import { Theme } from "@mui/material/styles";
import { Button, Link, Typography, useMediaQuery } from "@mui/material";
import { LargeText, SmallText } from "components/typography";
import styles from "./Overlay.module.css";
import { Done, OpenWith, Tree } from "icons";
import { useNavigate } from "react-router-dom";
import { LotOfferLocationInterface } from "interfaces/LotOfferLocationInterface";
import { useAppSelector } from "app/hooks";
import { selectShoppingCartLots, selectShoppingCartSize } from "features/shoppingCart/shoppingCartSlice";
import { ConstructionOutlined } from "@mui/icons-material";
interface Props {
  checked: boolean;
  handleClose: Function;
  imageUrl: string;
  lotData: LotOfferLocationInterface;
}

function sumOfShoppingCart(shoppingCartData: any) {
  let value = 0;
  if (!shoppingCartData) {
    return value;
  }

  for (const element of shoppingCartData) {
    value += parseFloat(element.price_total);
  }
  return value;
}

export default React.forwardRef((props: Props, ref) => {
  const { checked, handleClose, imageUrl, lotData } = props;
  const navigate = useNavigate();
  const shoppingCartData = useAppSelector(selectShoppingCartLots);
  const shoppingCartSize = useAppSelector(selectShoppingCartSize);
  const shoppingCartValue = sumOfShoppingCart(shoppingCartData);
  const matches1200 = useMediaQuery('(min-width: 1200px)')
  const matches660 = useMediaQuery('(min-width: 660px)')
  const regexForComma = /\,$/

  return (
    <Box sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    }}
    ref={ref}>
      <Box
        sx={{
          display: "flex",
          // width: "55%",
          width: "inherit",
          alignItems: "center",
          flexDirection: matches1200 ? "row" : "column"
        }}
      >
        <Grow style={{ width: "auto", height: "auto", borderRadius: 16, padding: 2 , display: matches660 ? "block" : "none" }} in={checked}>
          <Paper sx={{ m: 1 }} elevation={4}>
            <Box display={"flex"}>
              <Box sx={{ padding: 2 }} display={"flex"} gap={5}>
                <Box component="img" src={imageUrl} alt="Holz Polter" sx={{ width: 115, height: 115, objectFit: "cover" }} />

                <Box sx={{ mt: 3, ml: -3 }}>
                  <Link color={"#005851"} fontWeight={"bold"} href="#">
                    Los-Nr.: {lotData.full_lot_number}
                  </Link>
                  <Box gap={1} sx={{ mt: 1, mb: 1 }} display={"flex"}>
                    <Tree height={16} width={16}></Tree>
                    <SmallText>{lotData.wood_types.join(", ").replace("(unbestimmt)", "").substring(0,29).trim().replace(regexForComma, "")}</SmallText>
                    <OpenWith height={16} width={16}></OpenWith>
                    <SmallText>{lotData.solid_cubic_meters_without_bark} fm</SmallText>
                    <SmallText color={"#D0C2A8"}>/ ~ {lotData.cubic_meters_without_bark.toFixed(2)} rm</SmallText>
                  </Box>
                  <Box gap={0.6} className={styles.priceTiding} sx={{ display: "flex" }}>
                    <LargeText fWeight={"bold"}>{Number(lotData.price_total).toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + ' €'} </LargeText>
                    <SmallText color={"#D0C2A8"}>Inkl. {lotData.vat_rate} % MwSt.</SmallText>
                  </Box>
                </Box>
                <Box>
                  <Box display={"flex"}>
                    <Done height={40} width={40} fill="#C0D904"></Done>
                    <LargeText fWeight={"bold"}>
                      ZUM EINKAUFSWAGEN <br /> HINZUGEFÜGT
                    </LargeText>
                  </Box>
                  <Box sx={{ mt: 2, ml: 4.5 }}>
                    <Button
                      onClick={() => {
                        handleClose();
                      }}
                      sx={{
                        borderRadius: "26px",
                        color: "#072D26",
                        fontWeight: "bold",
                        border: "2px solid #C0D904",
                        height: "51px",
                      }}
                    >
                      WEITER EINKAUFEN
                    </Button>
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{
                  fill: (theme: Theme) => theme.palette.common.white,
                  stroke: (theme) => theme.palette.divider,
                  strokeWidth: 1,
                }}
              />
            </Box>
          </Paper>
        </Grow>
        {/* Conditionally applies the timeout prop to change the entry speed. */}
        <Grow
          in={checked}
          style={{
            transformOrigin: "0 0 0",
            width: "auto",
            height: "auto",
            borderRadius: 16,
          }}
          {...(checked ? { timeout: 500 } : {})}
        >
          <Paper sx={{ m: 1 }} elevation={4}>
            <Box>
              <Box sx={{ m: matches660 ? 4 : 1 }}>
                <Typography fontSize={16} fontWeight={"bold"}>
                  Ihre Einkaufswagen-Zwischensumme
                </Typography>
                <Box gap={0.4} sx={{ mt: 1, mb: 1 }} display={"flex"}>
                  <Typography fontSize={16}>
                    ({shoppingCartSize} Artikel): {Number(shoppingCartValue).toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + ' €'}
                  </Typography>
                  <Typography fontSize={16} color="#D0C2A8">
                    inkl. MwSt.
                  </Typography>
                </Box>
                <Box gap={1} className="" sx={{ display: "flex", flexDirection: "column" }}>
                  <Button
                    variant="contained"
                    sx={{
                      borderRadius: "26px",
                      color: "#072D26",
                      fontWeight: "bold",
                      border: "2px solid #C0D904",
                      height: "51px",
                    }}
                    onClick={() => {
                      navigate(`/bestellung_pruefen`, {
                        state: {
                          fromShoppingCart: true
                        }
                      });
                    }}
                  // color="#072D26"
                  // text="ZUR KASSE GEHEN"
                  >
                    ZUR KASSE GEHEN
                  </Button>
                  <Button
                    onClick={() => {
                      navigate("/einkaufswagen");
                    }}
                    sx={{
                      borderRadius: "26px",
                      color: "#072D26",
                      fontWeight: "bold",
                      border: "2px solid #C0D904",
                      height: "51px",
                    }}
                  >
                    ZUM EINKAUFSWAGEN
                  </Button>
                </Box>
              </Box>
              <Box
                component="polygon"
                sx={{
                  fill: (theme: Theme) => theme.palette.common.white,
                  stroke: (theme) => theme.palette.divider,
                  strokeWidth: 1,
                }}
              />
            </Box>
          </Paper>
        </Grow>
      </Box>
    </Box>
  );
});
