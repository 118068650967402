import { useAppSelector } from "app/hooks";
import { StuttgartLocation, selectLotLocationsUserLocation } from "features/lotLocations/lotLocationsSlice";
import { MapContainer, TileLayer } from "react-leaflet";
import CenterMapToLocation from "./CenterMapToLocation";
import HighlightedMarker from "./HighlightedMarker";
import LotClusterMarker from "./LotClusterMarker";
import styles from "App.module.css"
import { Box } from "@mui/material";

export default function MapSection() {
  const location = useAppSelector(selectLotLocationsUserLocation);
  // console.log(location)
  return (
    <Box sx={{ position: "relative", display: { xs: "flex", md: "contents" } }}>

      <MapContainer
        center={ [StuttgartLocation.latitude, StuttgartLocation.longitude]}
        zoom={9}
        maxZoom={18}
        scrollWheelZoom={true}
        minZoom={7}
        className={styles.leaflet}
      >
        <CenterMapToLocation />
        <LotClusterMarker />
        <HighlightedMarker />

        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />

      </MapContainer>
    </Box>
  );
}
