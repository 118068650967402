import {
  Box,
  Button,
  Container,
  FormControl,
  styled,
  TextField,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import BackgroundImage from "components/background-image/BackgroundImage";
import HolzfinderInput from "components/inputs/HolzfinderInputKontakt";
import { LargeText, MediumLargeText, MediumText } from "components/typography";
import contactBackground from "../../assets/images/background-contact-small.jpg";
import styles from "./Homepage.module.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import axiosInstance from "app/axios";

const MyTextField = styled(TextField)`
  background-color: white;
  border-radius: 30px;
  min-width: 311px;
`;

export default function ContactSection(props: any) {
  const { id } = props;
  const navigate = useNavigate();

  const isEmailValid = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };



  const [formData, setFormData] = useState({
    name: "",
    firstname: "",
    email: "",
    number: "",
    message: "",
  });

  const [formErrors, setFormErrors] = useState({
    name: false,
    email: false,
    message: false,
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    let error = { ...formErrors };

    if (name === "email") {
      error = {
        ...formErrors,
        email: !isEmailValid(value.trim()),
      };
    }

    setFormData({ ...formData, [name]: value });
    setFormErrors(error);
  };

  const handleNumberInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const isValidNumber = /^\d*$/.test(value);
    if (!isValidNumber) {
      return;
    }
    setFormData({ ...formData, [name]: value });
  };

  const [backendErrorMessage, setBackendErrorMessage] = useState("");


  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const errors = {
      name: formData.name.trim() === "",
      email: !isEmailValid(formData.email.trim()),
      message: formData.message.trim() === "",
    };

    setFormErrors(errors);


    try {
      const response = await axiosInstance.post(
        "/contact/send_email/",
        formData
      );

      if (response.status >= 200 && response.status < 300) {
        console.log("Message sent successfully!", response);
        navigate("/nachricht-gesendet");
      } else
      if (response.status === 422) {
        const Error422 = response.data.data.errors.message;
        console.log('tetst', Error422);
        setBackendErrorMessage(Error422);


      }
      else {
        console.error("Error sending message:", response.data);
        // Handle errors appropriately (e.g., display error message to user)
      }
    } catch (error: any) {
      console.info("Error sending message:", error);
    }

  };
  return (
    <Box id="attyear" className={styles.contactFormOuter}>

      <BackgroundImage
        childrenReverse={true}
        // topOffset={120}
        backgroundImage={contactBackground}
        backgroundImageHeight="769px"
      >
        <Container id={id} className={styles.backgroundImageHeader}>
          <Box
            className={`${styles.leftSidedContainer} ${styles.contactForm}`}
            sx={{
              color: "#FFFFFF",
              backgroundColor: "#072D26",
              "&:hover": {
                backgroundColor: "#072D26",
              },
            }}
          >
            <div className={styles.contactFormInner}>
              <FormControl component="form" onSubmit={handleSubmit}>
                <div className={styles.contactFormHeader}>
                  <LargeText fWeight="bold">KONTAKT</LargeText>
                </div>

                <MediumLargeText>
                  Für allgemeine Fragen zu Holzfinder. Keine Bestell- und
                  Verkaufsanfragen, diese werden nicht beantwortet.
                </MediumLargeText>

                <Grid2 style={{ marginTop: "16px" }} spacing={6} container>
                  <Grid2

                    md={6}
                    xs={12}
                    spacing={2}
                    display="flex"
                    flexDirection="column"

                  >

                    <MediumText>Name*</MediumText>
                    <HolzfinderInput
                      sx={{
                        "& fieldset": { border: "none" },
                        marginBottom: "16px"
                      }}
                      placeholder="Name"
                      name={"name"}
                      required
                      onChange={handleInputChange}
                    />

                    <MediumText>Vorname</MediumText>
                    <HolzfinderInput
                      sx={{
                        "& fieldset": { border: "none" },
                        marginBottom: "16px"
                      }}
                      placeholder="Vorname"
                      name={"firstname"}
                      onChange={handleInputChange}
                    />

                    <MediumText>E-Mail*</MediumText>
                    <HolzfinderInput
                      sx={{
                        "& fieldset": { border: "none" },
                        marginBottom: "16px"
                      }}
                      placeholder="E-Mail Addresse"
                      name={"email"}
                      required
                      onChange={handleInputChange}
                    />
                    {formErrors.email && (
                      <p style={{ color: 'red' }}>Bitte geben Sie eine gültige E-Mail-Adresse ein.</p>
                    )}

                    <MediumText>Telefon</MediumText>
                    <HolzfinderInput
                      sx={{
                        "& fieldset": { border: "none" },
                        marginBottom: "16px"
                      }}
                      placeholder="Ihre Telefonnummer"
                      name={"number"}
                      onChange={handleInputChange}
                      type="tel"

                    />
                  </Grid2>

                  <Grid2 md={6} xs={12} display="flex" flexDirection="column">
                    <MediumText>Nachricht*</MediumText>


                    <MyTextField
                      multiline
                      className={`${styles.borderRadius} ${styles.contactFormInputMediaQuery} `}
                      style={{
                        minHeight: "218px",
                        maxHeight: "223px",
                      }}
                      type="text"
                      placeholder="Ihre Nachricht"
                      // className={styles.input}
                      // onChange={handleChange}
                      name={"message"}
                      sx={{
                        "& fieldset": { border: "none" },
                        marginBottom: "16px"
                      }}
                      inputProps={{ maxLength: 290 }}
                      required
                      onChange={handleInputChange}
                    />
                    {backendErrorMessage && (
                      <p style={{ color: 'red' }}>{backendErrorMessage}</p>
                    )}
                    <Button
                      className={`${styles.button} ${styles.contactFormButton} `}
                      variant="contained"
                      sx={{
                        color: "#072d26",
                        background: "#d9e868",
                        borderRadius: "26px",
                        fontWeight: "bold",
                        marginTop: "25px",
                      }}
                      type="submit"
                    >
                      NACHRICHT SENDEN
                    </Button>
                    <MediumText mt={3}>* Pflichtfeld</MediumText>
                  </Grid2>
                </Grid2>
              </FormControl>
            </div>
          </Box>
        </Container>
      </BackgroundImage>
    </Box>
  );
}
