import * as React from "react";
import { SVGProps } from "react";
const SvgManual = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 40 40"  {...props}>
    <g id="Ebene_2" data-name="Ebene 2">
      <g>
        <polygon points="30.9 37 4.4 37 4.4 7.7 7 7.7 7 34.5 30.9 34.5 30.9 37" />
        <path d="M35.6,33.6H7.8V3h27.7v30.7ZM10.4,31.1h22.6V5.5H10.4v25.5Z" />
        <g>
          <path d="M23,22.7h-2.6s0-2.1,0-2.1c0-1.4.9-2.7,2.2-3.2,1-.4,1.9-1.6,1.5-3.1-.2-.8-.9-1.5-1.7-1.7-.8-.2-1.6,0-2.2.4-.6.5-1,1.2-1,2h-2.6c0-1.6.7-3.1,2-4s2.9-1.3,4.5-.9c1.7.4,3.1,1.9,3.6,3.6.7,2.5-.7,5.2-3.1,6.1-.3.1-.5.5-.5.9v2.1Z" />
          <circle cx="21.7" cy="25" r="1.7" />
        </g>
      </g>
    </g>
  </svg>
);
export default SvgManual;