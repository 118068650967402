import { Box, Button, Container, List, ListItem, Paper } from "@mui/material";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { LargeText, MediumText } from "components/typography";
import HolzfinderInfoDetail from "components/shoppingCart/ShoppingCartInfo";
import LotOfferData from "views/lotOffer/LotOfferData";
import { selectCustomerPurchase } from "features/customer/customerSlice";
import { CustomerPurchaseInterface } from "interfaces/CustomerPurchaseInterface";
import { InvoiceInterface } from "interfaces/InvoiceInterface";
import { selectUserToken, updateSessionGone } from "features/user/userSlice";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import React from "react";


export default function InvoiceLotList() {
  const purchaseData = useAppSelector(selectCustomerPurchase) as CustomerPurchaseInterface
  const numberFormatter = new Intl.NumberFormat("de-De", { style: "currency", currency: "EUR" });
  const baseUrl = process.env.REACT_APP_API_DOMAIN ? process.env.REACT_APP_API_DOMAIN : "";
  const token = useAppSelector(selectUserToken);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);

  function logout() {
    dispatch(updateSessionGone(""));
    navigate("/login");
  }
  // uncomment next line when pdf name is sent with the invoice
  const downloadPDF = async (pdfUrl: string, pdfName: string) => {
    if (!token) {
      logout();
      return
    }
    fetch(pdfUrl, {
      headers: {
        Authorization: `${token}`,
      },
    }).then((req) => {
      return req.blob()
    }).then((blob) => {
      const href = window.URL.createObjectURL(blob);

      var link = document.createElement('a');
      // pdf is downloaded here
      link.download = pdfName;
      link.type = "pdf"
      link.href = href
      link.click();
      setLoading(false);

    }).catch((err: any) => {
      console.error(err)
      logout();
    });

  };


  return (
    <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <List
        sx={{
          width: "inherit",
          paddingRight: "17px",
          boxSizing: "content-box",
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
        key={purchaseData.id}
      >
        {purchaseData.invoices.map((invoice: InvoiceInterface) => (
          <Box>
            {invoice.lot_offers.map((lotOfferData) => (
              <Box>

                <ListItem key={lotOfferData.id} sx={{ pl: 0 }}>
                  <Paper
                    sx={{
                      overflow: "hidden",
                      display: "flex",
                      borderRadius: "16px",
                      gap: 2,
                      width: "70%"
                    }}
                  >
                    <Box
                      component="div"
                      sx={{
                        display: "flex"
                      }}
                    >
                      <Box
                        component="div"
                        sx={{
                          overflow: "hidden",
                          display: "flex",
                          maxWidth: "150px",
                          width: "100%"
                        }}
                      >
                        <Box
                          component="img"
                          sx={{
                            aspectRatio: "4/3",
                            width: "inherit",
                            height: "inherit",
                            maxWidth: "100%",
                            overflow: "hidden",
                            objectFit: "cover",
                            lineHeight: "70px",
                            textAlign: "center",
                          }}
                          alt="Bild eines Holzpolters"
                          src={baseUrl + lotOfferData.image_urls[0]}
                        />
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        width: "100%",
                        height: "inherit",
                      }}
                    >
                      <HolzfinderInfoDetail lotInformation={lotOfferData} lessInfo={true} />
                    </Box>
                  </Paper>
                </ListItem>
              </Box>
            ))}
            <LoadingButton
              loading={loading}
              onClick={() => {
                setLoading(!loading)
                downloadPDF(invoice.pdf_url, invoice.filename)

              }}
              variant="contained"
              loadingIndicator="..."
            >
              <MediumText>Zur PDF-Rechnung</MediumText>
            </LoadingButton>
          </Box>
        ))}
      </List>
      <Box
        sx={{
          display: "flex",
          gap: 2,
          justifyContent: "center",
          pl: "12%",
          alignItems: "baseline",
        }}
      >
        {/* <Box sx={{ alignItems: "baseline", display: "flex" }}>
          <MediumText>Summe ({invoices.length} Artikel)</MediumText>
        </Box> */}
        <Box sx={{ display: "flex", gap: 1, alignItems: "baseline" }}>
          <LargeText fWeight="bold">{numberFormatter.format(Number(purchaseData.price_total))}</LargeText>
          <MediumText color="#D0C2A8">Inkl. Mwst</MediumText>
        </Box>
      </Box>
    </Box>
  );
}
