import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";


export interface paginationInterface {
  pageLotList: number;
  rowsPerPageLotList: number;
  pageAgentList: number;
  rowsPerPageAgentList: number;
  pageCustomerList: number;
  rowsPerPageCustomerList: number;
  pageSellerList: number;
  rowsPerPageSellerList: number;
  pageCustomerInvoiceList: number;
  rowsPerPageCustomerInvoiceList: number;
  pageAgentUsersList: number;
  rowsPerPageAgentUsersList: number;
  lotListTab: number;
}

const initialState: paginationInterface = {
  pageLotList: 1,
  rowsPerPageLotList: 10,
  pageAgentList: 1,
  rowsPerPageAgentList: 10,
  pageCustomerList: 1,
  rowsPerPageCustomerList: 10,
  pageSellerList: 1,
  rowsPerPageSellerList: 10,
  pageCustomerInvoiceList: 1,
  rowsPerPageCustomerInvoiceList: 10,
  pageAgentUsersList: 1,
  rowsPerPageAgentUsersList: 10,
  lotListTab: 0
};

export const tableSlice = createSlice({
  name: "table",
  initialState,
  reducers: {
    clear: (state) => {
      return initialState;
    },
    setRowsPerPageLotList: (state, action: PayloadAction<number>) => {
      state.rowsPerPageLotList = action.payload
    },
    setPageLotList: (state, action: PayloadAction<number>) => {
      state.pageLotList = action.payload
    },
    setRowsPerPageAgentList: (state, action: PayloadAction<number>) => {
      state.rowsPerPageAgentList = action.payload
    },
    setPageAgentList: (state, action: PayloadAction<number>) => {
      state.pageAgentList = action.payload
    },
    setRowsPerPageCustomerList: (state, action: PayloadAction<number>) => {
      state.rowsPerPageCustomerList = action.payload
    },
    setPageCustomerList: (state, action: PayloadAction<number>) => {
      state.pageCustomerList = action.payload
    },
    setRowsPerPageSellerList: (state, action: PayloadAction<number>) => {
      state.rowsPerPageSellerList = action.payload
    },
    setPageSellerList: (state, action: PayloadAction<number>) => {
      state.pageSellerList = action.payload
    },
    setRowsPerPageCustomerInvoiceList: (state, action: PayloadAction<number>) => {
      state.rowsPerPageCustomerInvoiceList = action.payload
    },
    setPageCustomerInvoiceList: (state, action: PayloadAction<number>) => {
      state.pageCustomerInvoiceList = action.payload
    },
    setRowsPerPageAgentUsersList: (state, action: PayloadAction<number>) => {
      state.rowsPerPageAgentUsersList = action.payload
    },
    setPageAgentUsersList: (state, action: PayloadAction<number>) => {
      state.pageAgentUsersList = action.payload
    },
    setLotListTab: (state, action: PayloadAction<number>) => {
      state.lotListTab = action.payload
    },
  },
});

export const { clear, setRowsPerPageLotList, setPageLotList, setRowsPerPageAgentList, setPageAgentList, setRowsPerPageCustomerList, setPageCustomerList, setRowsPerPageSellerList, setPageSellerList, setRowsPerPageCustomerInvoiceList, setPageCustomerInvoiceList, setRowsPerPageAgentUsersList, setPageAgentUsersList,setLotListTab } = tableSlice.actions;

export const selectPageLotList = (state: RootState) => state.table.pageLotList;
export const selectRowsPerPageLotList = (state: RootState) => state.table.rowsPerPageLotList;
export const selectPageAgentList = (state: RootState) => state.table.pageAgentList;
export const selectRowsPerPageAgentList = (state: RootState) => state.table.rowsPerPageAgentList;
export const selectPageCustomer = (state: RootState) => state.table.pageCustomerList;
export const selectRowsPerPageCustomer = (state: RootState) => state.table.rowsPerPageCustomerList;
export const selectPageSeller = (state: RootState) => state.table.pageSellerList;
export const selectRowsPerPageSeller = (state: RootState) => state.table.rowsPerPageSellerList;
export const selectPageCustomerInvoiceList = (state: RootState) => state.table.pageCustomerInvoiceList;
export const selectRowsPerPageCustomerInvoiceList = (state: RootState) => state.table.rowsPerPageCustomerInvoiceList;
export const selectPageAgentUsersList = (state: RootState) => state.table.pageAgentUsersList;
export const selectRowsPerPageAgentUsersList = (state: RootState) => state.table.rowsPerPageAgentUsersList;
export const selectLotListTab = (state: RootState) => state.table.lotListTab;

export default tableSlice.reducer;
