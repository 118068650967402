import {
  createAsyncThunk,
  createSlice,
  isAnyOf,
} from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { RequestStatus } from "interfaces/RequestStatus";
import { SellerInterface } from "interfaces/Seller";
import {
  fetchSeller,
  fetchSellerArgument,
} from "./fetchSeller";

export enum LotOfferStatus {
  INACTIVE = "inactive",
  ACTIVE = "active",
}


export interface sellerState {
  status: RequestStatus;
  seller?: SellerInterface;
}

const initialState: sellerState = {
  status: RequestStatus.idle,
  seller: undefined,
};

export const getSeller = createAsyncThunk(
  "seller/fetchSeller",
  async (args: fetchSellerArgument, { getState }) => {
    const { user } = getState() as RootState;
    if (!user || !user.role) {
      throw new Error("User role is undefined.");
    }
    const response = await fetchSeller({ ...args, endpoint: user.role });
    return { headers: response.headers, data: response.data };
  }
);

export const sellerSlice = createSlice({
  name: "seller",
  initialState,
  reducers: {
    clear: (state) => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSeller.fulfilled, (state, action) => {
        state.status = RequestStatus.idle;
        // console.log(action.payload.data);
        state.seller = action.payload.data.data;
      })
      .addMatcher(isAnyOf(getSeller.pending), (state) => {
        state.status = RequestStatus.loading;
      })
      .addMatcher(isAnyOf(getSeller.rejected), (state) => {
        state.status = RequestStatus.failed;
      });
  },
});

export const { clear } = sellerSlice.actions;

export const selectSeller = (
  state: RootState
): SellerInterface | undefined => state.seller.seller;


export default sellerSlice.reducer;

