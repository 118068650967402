import { AccountCircle, Logout } from "icons";
import { Box, Button, Menu, MenuItem, SvgIcon } from "@mui/material";
import { MediumText } from "../../typography";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { logout, selectUserRole } from "features/user/userSlice";
import { useAppDispatch, useAppSelector } from "app/hooks";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { clearCustomer, clearCustomerErrors } from "features/customer/customerSlice";

export default function AccountMenu(props: any) {

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const dispatch = useAppDispatch();
  let currentlyHovering = false;
  const userRole = useAppSelector(selectUserRole);
  const handleClose = () => {
    setAnchorEl(null);
  };
  function handleHover() {
    currentlyHovering = true;
  }

  const pages = [
    {
      label: "MEIN PROFIL",
      route: "kunde/mein_profil",
    },
    {
      label: "MEINE EINKÄUFE",
      route: "kunde/meine_einkaeufe",
    },
    {
      label: "PASSWORT ÄNDERN",
      route: "kunde/password",
    },
    {
      label: "ABMELDEN",
      route: "/",
    },
  ];
  const logoutAccount = () => {
    handleClose();
    dispatch(logout());
    //Todo i dont know if is correct to clear Customer hier
    dispatch(clearCustomer())
    dispatch(clearCustomerErrors())
    localStorage.clear();
  };

  function handleCloseHover() {
    currentlyHovering = false;
    setTimeout(() => {
      if (!currentlyHovering) {
        handleClose();
      }
    }, 50);
  }

  // const hoverBox = document.querySelector("#hover-box") || undefined
  // const muiMenu = document.querySelector("#simple-menu")
  // hoverBox?.addEventListener("mouseenter", () => {
  //   handleHover();
  //   //@ts-ignore
  //   hoverBox.style.backgroundColor = "#005851"
  // })
  // console.log(hoverBox)
  // //@ts-ignore
  // hoverBox.addEventListener("click", (event: any) => {
  //   console.log(event)
  //   handleClick(event);
  // })
  // hoverBox?.addEventListener("mouseleave", () => {
  //   handleCloseHover();
  // })

  function handleClick(event: any) {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
    //@ts-ignore
    document.querySelector("#hover-box").style.backgroundColor = "#005851"
  }
  useEffect(() => {
    // console.log(hoverBox)
    // setAnchorEl(hoverBox)
    setTimeout(() => {
      if (!currentlyHovering) {
        handleClose();
      }
    }, 50);
  }, [currentlyHovering]);
  const { text } = props;
  return (
    <Box sx={{ height: "inherit" }}
    // onMouseOut={handleClose}
    >
      <Box
        id="hover-box"
        sx={{
          flexGrow: 1,
          height: { xs: "55px", lg: "88px" },
          display: { xs: "flex", md: "flex" },
          "&:hover": {
            backgroundColor: "#005851",
          },
        }}
        onMouseEnter={handleHover}
        onClick={handleClick}
      >
        <Button
          // onMouseOver={handleClick}
          size="small"
          aria-owns={anchorEl ? "simple-menu" : undefined}
          aria-haspopup="true"
          sx={{
            gap: 1,
            height: { xs: "55px", lg: "88px" },
          }}
        >
          <MediumText
            color="#FFFFFF"
            fWeight="bold"
            display="contents"
            height="inherit"
          >
            {text}
          </MediumText>
          <SvgIcon sx={{ stroke: "#D9E868", fill: "#D9E868", mr: -1 }}>
            <AccountCircle />

          </SvgIcon>
          <ExpandMoreIcon />
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          sx={{
            "& .MuiPaper-root": {
              borderRadius: "0px 0px 4px 4px",
              backgroundColor: "#005851",
              pt: 1.5
            },
            "& .popOverRoot": {
              pointerEvents: "none"
            }
          }}
          onClose={handleClose}
          MenuListProps={{
            onMouseEnter: handleHover,
            onMouseLeave: handleCloseHover,
            style: { pointerEvents: "auto" }
          }}
          anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        >
          {userRole === "customer" ? pages.map((page) => (
            <Link
              key={page.label}
              to={page.route}
              style={{
                textDecoration: "none",
                height: "inherit",
                color: "white",
              }}
            >
              {page.label !== "ABMELDEN" && (
                <MenuItem key={page.label} sx={{ height: "inherit" }} onClick={handleClose}>
                  <MediumText fWeight="bold" color="#FFFFFF" float="right">
                    {page.label}
                  </MediumText>
                </MenuItem>
              )}
              {page.label === "ABMELDEN" && (
                <MenuItem
                  key={page.label}
                  sx={{ height: "inherit" }}
                  onClick={logoutAccount}
                >
                  <MediumText fWeight="bold" color="#FFFFFF">
                    {page.label}
                  </MediumText>
                  <SvgIcon>
                    <Logout fontSize="small" />
                  </SvgIcon>
                </MenuItem>
              )}
            </Link>
          )) : pages.filter(element => element.label !== "MEINE EINKÄUFE").map((page) => (
            <Link
              key={page.label}
              to={page.route}
              style={{
                textDecoration: "none",
                height: "inherit",
                color: "white",
              }}
            >
              {page.label !== "ABMELDEN" && (
                <MenuItem key={page.label} sx={{ height: "inherit" }} onClick={handleClose}>
                  <MediumText fWeight="bold" color="#FFFFFF" float="right">
                    {page.label}
                  </MediumText>
                </MenuItem>
              )}
              {page.label === "ABMELDEN" && (
                <MenuItem
                  key={page.label}
                  sx={{ height: "inherit" }}
                  onClick={logoutAccount}
                >
                  <MediumText fWeight="bold" color="#FFFFFF">
                    {page.label}
                  </MediumText>
                  <SvgIcon>
                    <Logout fontSize="small" />
                  </SvgIcon>
                </MenuItem>
              )}
            </Link>))}
        </Menu>
      </Box>
    </Box>
  );
}
