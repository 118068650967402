import { colors } from "@mui/material";
import { styled } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
interface LotListTabProps {
  label: string;
  backgroundcolor: string | undefined;
}

// const LotListTab = styled((props: LotListTabProps) => <Tab {...props} style={{ backgroundColor: props.backgroundColor || "#F6F3EE",}} />)
//   (
//   ({ theme }) => ({
//     fontWeight: 600,
    
//     border: "none",
//     color: "#000",
//     marginRight: "1px",
//     "&.Mui-selected": {
//       backgroundColor: 
//       color: "hsl(169, 73%, 10%)",
//       border: "1px solid #D0C2A8",
//       borderBottom: "none",
//     },
//   })
// );

// export default LotListTab;

const LotListTab = ((props: LotListTabProps) => {
  return (
    < Tab {...props} sx={{ 
      backgroundColor: props.backgroundcolor || "#F6F3EE", 
      fontWeight: 600, 
      border: "none",
      color: "#000",
      marginRight: "1px",
      "&.Mui-selected": {
        color: "hsl(169, 73%, 10%)",
        border: "1px solid #D0C2A8",
        borderBottom: "none",
      },
      }} 
    />
  )
})

export default LotListTab;

