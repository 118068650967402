import { Box, Paper, useMediaQuery } from "@mui/material";
import "leaflet/dist/leaflet.css";
import SelectedLotInfoSmall from "./SelectedLotInfoSmall";

import { LotOfferLocationInterface } from "interfaces/LotOfferLocationInterface";
import holzfinderDefaultLotImage from "assets/images/Holzfinder_Los_Platzhalter.jpg"

interface Props {
  lotLocation: LotOfferLocationInterface
}
export default function LosSuche(props: Props) {
  const { lotLocation } = props
  const baseURL = process.env.REACT_APP_API_DOMAIN

  const matchesWidth = useMediaQuery('(min-width: 549px)')

  return (
    <Box sx={{position: "absolute", width: "100%", borderRadius: "13px 13px 0px 0px", backgroundColor: "white", zIndex:1101, bottom: 0, display: "flex", justifyContent: "center"}}> 
      <Box
        sx={{
          zIndex: 1100,
          position: "absolute",
          bottom: 100,
          alignSelf: "bottom",
          overflow: "hidden",
          display: {xs: "flex", lg: "none"}
        }}
      >
        <Box
          sx={{
            overflowY: "auto",
            width: "100%",
            paddingRight: "17px",
            boxSizing: "content-box",
            mt: "10px",
            padding: "15px 15px",
            maxWidth:  "100%",
            display: "flex",
            alignItems: "end"
          }}
        >
          <Paper
            sx={{
              borderRadius: 26,
              display: "flex",
              overflow: "hidden",
              maxHeight: matchesWidth ? "150px" : "100%",
              width: "100%",
              "&:hover img": {
                transform: "scale(1.25)",
                transformOrigin: "50% 50%",
                transition: "transform 0.4s",
              },
            }}
          >
            <Box
              component="div"
              sx={{
                overflow: "hidden",
                width: "30%"
              }}
            >
              <Box
                component="img"
                sx={{
                  aspectRatio: "4/3",
                  objectFit: "cover",
                  borderRadius: "30px 0px 0px 30px",
                  width: "auto",
                  height: "100%",
                  maxWidth: matchesWidth ? "200px" : "100px",
                  // maxWidth: "200px",
                  overflow: "hidden",
                }}
                alt="image"
                src={lotLocation.image_urls[0] ? baseURL + lotLocation.image_urls[0] : holzfinderDefaultLotImage}
                />
            </Box>
            <Box
              sx={{
                display: "flex",
                position: "relative",
                borderRadius: "0px 30px 30px 0px !important",
              }}
            >
              <SelectedLotInfoSmall
                lotImage={baseURL + lotLocation.image_urls[0]}
                lotInformation={lotLocation}
                loginNeeded={lotLocation.login_and_validation_required}
              />
            </Box>
          </Paper>
        </Box>
      </Box>
    </Box>
  );
}
