// import { useForm } from "../../hooks/useForm";
import { Box, Container, Grid } from "@mui/material";
// import { style } from "@mui/system";
import { Link, useNavigate } from "react-router-dom";
import { RegisterForm, ToLogin } from "./components";
import { LargeText } from "../../../components/typography";
import Header from "components/header/Header";
import { useAppDispatch } from "app/hooks";


const Register = () => {
  return (
    <Container
      maxWidth="xl"
      sx={{
        pt: 4,
        pb: 4,
        mb: 3,
        mt: 5,
      }}
    >
      <Grid container>
        <Grid item xs={12} sx={{ mb: 3 }}>
          <Header header={"Konto erstellen"} />
        </Grid>
        <Grid item xs={12} md={6} >
          <RegisterForm />
        </Grid>

        <Grid item xs={12} md={6} sx={{ marginTop: {xs: 4, md: 0 }}}>
          <LargeText color="#072D26" fWeight="bold" display="contents">
            SIE HABEN BEREITS EINEN ACCOUNT?
          </LargeText>
          <Link to="/login" style={{ textDecoration: "none" }}>
            <Box>
              <ToLogin />
            </Box>
          </Link>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Register;
