import axiosInstance from "app/axios";
import { AxiosResponse } from "axios";
import { AgentInterface } from "interfaces/Agent";
import { UserRoles } from "interfaces/UserRoles";

export interface fetchAgentListArgument {
  items_per_page: number;
  page: number;
  search: string | undefined;
  endpoint?: UserRoles;
}

export interface AgentsResponseInterface {
  data: {
    total: number;
    pages: number;
    agents: AgentInterface[];
  };
}

export function fetchAgentList({ items_per_page, page, search, endpoint = UserRoles.Admin }: fetchAgentListArgument): Promise<AxiosResponse<AgentsResponseInterface, any>> {
  return axiosInstance.get(`${endpoint}/agents`, {
    params: {
      items_per_page,
      page,
      search,
    },
  });
}
