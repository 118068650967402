import { Add } from "@mui/icons-material";
import { Alert, Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, FormControl, Link, Select, SvgIcon, TextField } from "@mui/material";
import { useAppDispatch, useAppSelector } from "app/hooks";
import Header from "components/header/Header";
import HolzfinderSelect from "components/inputs/HolzfinderSelect";
import { MediumText } from "components/typography";
import { deleteAgentUser, patchAgentUser, selectAgentUser, selectAgentUsers, selectDeleteErrors } from "features/agent/agentSlice";
import { updateAgentUser } from "features/agent/updateAgent";
import { selectErrors } from "features/customer/customerSlice";
import { Delete } from "icons";
import { AgentUserDataInterface } from "interfaces/AgentUserDataInterface";
import { UserStatus } from "interfaces/UserStatus";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import AdminAgent from "./AdminAgent";
import styles from "./InviteAgent.module.css";

const UserStatusOptions = {
  activation: {
    label: "Aktivierung",
    default: UserStatus.ACTIVE,
    options: [
      {
        value: UserStatus.ACTIVE,
        label: "Aktiv",
      },
      {
        value: UserStatus.INACTIVE,
        label: "Inaktiv",
      },
    ],
  },
};

export default function AgentUser() {
  const dispatch = useAppDispatch();

  const user = useAppSelector(selectAgentUser) as AgentUserDataInterface
  const agentUsers = useAppSelector(selectAgentUsers)
  // console.log(user)
  const { id, email } = user
  const [inviteSuccess, setInviteSuccess] = useState(false);
  const [first_name, setFirstName] = useState(user.first_name);
  const [last_name, setLastName] = useState(user.last_name);
  const [status, setStatus] = useState(user.status);
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState(false);

  const navigate = useNavigate();
  // console.log(user)

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  function handleDelete() {
    dispatch(deleteAgentUser({ id: id })).then((data: any) => {
      if (data.type === "agent/deleteAgentUser/fulfilled") {
        setOpen(false);
        navigate(-1)
      } else {
        setErrors(true)
      }
    })
  }

  return (
    // <Container sx={{ display: "flex", justifyContent: "center", alignContent: "center", pt: 2 }}>
    <>
      <Container
        maxWidth="xl"
        sx={{
          pt: 4,
          pb: 4,
          // ml: 4,
          pl: { lg: 12, md: 14, xs: 13 },
        }}
      >
        {errors ? (
          <Alert
            severity="warning"
            sx={{
              borderRadius: 5,
              paddingX: 5,
              marginTop: -4,
              marginBottom: 3
            }}
          >
            Diesen User können Sie nicht löschen.
          </Alert>
        ) : null}
        <FormControl
          sx={{
            width: { lg: "60%", md: "80%", xs: "100%" },
            gap: 0.5,
            pt: 2
          }}
        >
          {/* <Link component={AdminAgent}>tttt</Link> */}
          <Header
            header="User Einstellungen"
            backButton
            actionButton1={
              <Button sx={{ backgroundColor: "#c0d904", color: "black", borderRadius: "50px", mt: 2, mb: 2, float: "right" }} onClick={() => { handleClickOpen() }}>
                <SvgIcon>
                  <Delete />
                </SvgIcon>
              </Button>
            }
          />
          <Dialog open={open} onClose={handleClose}>
            <DialogContent>
              <DialogContentText>
                <MediumText fWeight="bold" color="#072D26">
                  Sind Sie sich sicher, dass Sie diesen User löschen wollen?
                </MediumText>
              </DialogContentText>
            </DialogContent>
            <DialogActions >
              <Button
                sx={{
                  float: "left",
                  borderRadius: "26px",
                  fontWeight: "bold",
                  color: "black",
                  backgroundColor: "#FF0000",
                  border: "2px solid #FF0000",
                }}
                onClick={handleDelete} color="secondary">
                Löschen
              </Button>
              <Button
                sx={{
                  borderRadius: "26px",
                  fontWeight: "bold",
                  color: "#072D26",
                  backgroundColor: "#D9E868",
                  border: "2px solid #D9E868",
                }}
                onClick={handleClose}>
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
          <MediumText>Vorname</MediumText>
          <TextField
            type="text"
            value={first_name}
            placeholder="Vorname"
            onChange={(event: any) => {
              setFirstName(event.target.value);
            }}
            name={"first_name"}
            InputProps={{
              className: styles.input
            }}
          />

          <MediumText>Nachname</MediumText>
          <TextField
            type="text"
            value={last_name}
            placeholder="Nachname"
            onChange={(event: any) => {
              setLastName(event.target.value);
            }}
            name={"last_name"}
            InputProps={{
              className: styles.input
            }}
          />

          <MediumText>E-Mail</MediumText>
          <TextField

            type="email"
            value={email}
            placeholder="E-Mail"
            InputProps={{
              readOnly: true,
              className: styles.input
            }}
            name={"email"}

          />
          <HolzfinderSelect
            id={"activation"}
            label={UserStatusOptions.activation.label}
            options={UserStatusOptions.activation.options}
            currentValue={status || UserStatusOptions.activation.default}
            defaultValue={UserStatusOptions.activation.default}
            onChange={(event) => {
              let newStatus: string = event.target.value as unknown as string
              setStatus(newStatus) as unknown as string
            }}
            inputProps={{ "aria-label": "Without label" }}
          />
          <div>
            <Button
              sx={{
                color: "#072D26",
                my: 2,
                mt: 2,
                backgroundColor: "#C4D661",
                borderColor: "#C4D661",
                borderRadius: 30,
                fontSize: "small",
                paddingY: 1.5,
                paddingX: 5,
                "&:hover": {
                  backgroundColor: "#C0D904",
                },
              }}
              onClick={() => {
                dispatch(
                  patchAgentUser({
                    first_name,
                    last_name,
                    id,
                    status
                  })
                ).then((data: any) => {
                  if (data.type === "agent/createAgent/fulfilled") {
                    setInviteSuccess(true);
                  }
                });
              }}
            >
              <MediumText fWeight="bold">Speichern</MediumText>
            </Button>
          </div>
        </FormControl>
      </Container>
    </>
  )

}