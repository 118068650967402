import { Edit } from "@mui/icons-material";
import { Box, Container, IconButton, Paper, SvgIcon, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@mui/material";
import { useAppDispatch, useAppSelector } from "app/hooks"
import Header from "components/header/Header";
import HolzfinderList from "components/list/HolzfinderList";
import { getCustomerPurchases, selectCustomerPurchasesList, selectCustomerPurchasesTotal } from "features/customer/customerSlice";
import { selectPageCustomer, selectPageCustomerInvoiceList, selectRowsPerPageCustomer, selectRowsPerPageCustomerInvoiceList, setPageCustomerInvoiceList, setRowsPerPageCustomerInvoiceList } from "features/table/tableSlice";
import useDate from "hooks/useDate";
import { ShowPasswordOn } from "icons";
import { CustomerPurchaseInterface } from "interfaces/CustomerPurchaseInterface";
import { LotOfferLocationInterface } from "interfaces/LotOfferLocationInterface";
import React, { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";


interface rowInterface {
  id: string;
  created_at: string;
  price_total: string;
}

const tableHeaderList: string[] = ["Gekauft am", "Preis", "Detailansicht"]
export default function CustomerPurchasesList() {
  const [rowData, setRowData] = useState<rowInterface[]>([]);
  const purchasesList = useAppSelector(selectCustomerPurchasesList);
  const purchasesListTotal = useAppSelector(selectCustomerPurchasesTotal) as number;
  const page = useAppSelector(selectPageCustomerInvoiceList)
  const rowsPerPage = useAppSelector(selectRowsPerPageCustomerInvoiceList)
  const dispatch = useAppDispatch();
  const { format } = useDate();


  let Euro = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
  });

  useEffect(() => {

    dispatch(setPageCustomerInvoiceList(0));

  }, []);


  useEffect(() => {

    dispatch(
      getCustomerPurchases({
        items_per_page: rowsPerPage,
        page: page + 1,
      })
    );
  }, [page, rowsPerPage, dispatch]);

  useEffect(() => {
    if (purchasesList) {
      const mappedData = purchasesList.map(
        (purchase) => ({
          id: purchase.id,
          created_at: format(purchase.created_at),
          price_total: Euro.format(parseInt(purchase.price_total))

        })

      );
      setRowData(mappedData);
    }
  }, [purchasesList])

  return (
    <Container sx={{ pl: { lg: 12, md: 12, xs: 1 }, pt: 5, width: "fit-content", minWidth: { xs: "100%", md: 500, lg: 600 } }} maxWidth="md">
      <Header header="Meine Käufe" />
      {/* // TODO: readd search on a later occasion */}
      {/* <Select className={styles.inputs} sx={{ my: 2 }}></Select> */}
      <HolzfinderList
        listLengthTotal={purchasesListTotal}
        rowData={rowData}
        switchActive={false}
        link={`/kunde/meine_einkaeufe/`}
        tableHeaderList={tableHeaderList}
        setPage={setPageCustomerInvoiceList}
        setRowsPerPage={setRowsPerPageCustomerInvoiceList}
        page={page}
        rowsPerPage={rowsPerPage}
        isLotList={false}
      />

    </Container>
  )
}