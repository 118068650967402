import { ReactNode } from "react";
import { Typography } from "@mui/material";

interface Props {
  children?: ReactNode;
  color?: String;
  fWeight?: String;
  display?: String;
  float?: String;
  mdml?: any;
  xsml?: any;
  mr?: any;
  whiteSpace?: any;
  my?: any;
  padding?: any;
  // any props that come into the component
}

export default function LargeText({
  children,
  color,
  fWeight,
  display,
  float,
  mdml,
  xsml,
  mr,
  padding,
  whiteSpace,
  my,
}: Props) {
  return (
    <Typography
      variant="h4"
      noWrap
      sx={{
        fontFamily: "DMSans",
        mr: mr,
        display: { md: `${display}` },
        fontWeight: `${fWeight}`,
        color: `${color}`,
        textDecoration: "none",
        ml: { xs: xsml, md: mdml },
        fontSize: { xs: 16, md: 24 },
        float: `${float}`,
        padding: `${padding}`,
        whiteSpace: whiteSpace,
        my: my,
      }}
    >
      {children}
    </Typography>
  );
}
