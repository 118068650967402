import { Grid } from "@mui/material";
import { Container } from "@mui/system";
import useDate from "hooks/useDate";
import LotOfferImages from "./LotOfferImages";
import HolzpolterInfoDetail from "./HolzpolterInfoDetail";
import { useParams } from "react-router-dom";
import { getLotLocation, selectLotLocationsFiltered } from "features/lotLocations/lotLocationsSlice";
import Header from "components/header/Header";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { LotOfferLocationInterface } from "interfaces/LotOfferLocationInterface";
import { useEffect, useState } from "react";
import { AxiosResponseHeaders } from "axios";
import styles from "./Carousel.module.css"

export default function LotOfferDetails() {
  let { lot_id } = useParams<{ lot_id?: string }>();
  let lotLocs = useAppSelector(selectLotLocationsFiltered);
  const dispatch = useAppDispatch();
  const [lotOfferData, setLotOfferData] = useState<LotOfferLocationInterface | undefined>(lotLocs.find((el) => el.id === lot_id) as LotOfferLocationInterface);
  useEffect(() => {
    if (!lotOfferData && lot_id) {
      dispatch(getLotLocation(lot_id)).then((response) => {
        // console.log(response);
        if (response.meta.requestStatus === "fulfilled") {
          const payload = response.payload as { headers: AxiosResponseHeaders; data: LotOfferLocationInterface };
          setLotOfferData(payload.data);
        }
      });
    }
  }, [lotOfferData, lot_id, dispatch]);

  const { format } = useDate();
  if (!lotOfferData) {
    return (
      <Container sx={{ pt: 4, pb: 4 }}>
        <Header header={`Los-Nr.: N/A`} subHeader={`Veröffentlicht am: N/A`} backButton />
      </Container>
    );
  }
  let formated_date = format(lotOfferData?.published_at);

  return (
    <Container className={styles.losDetailContainer} sx={{ pt: 4, pb: 4 }}>
      <Header header={`Los-Nr.: ${lotOfferData.full_lot_number}`} subHeader={`Veröffentlicht am: ${formated_date}`} backButton />
      <Grid container sx={{ flexDirection: "row", pt: 2, gap: 1 }}>
        <LotOfferImages lotInformation={lotOfferData} />
        <HolzpolterInfoDetail lotInformation={lotOfferData} />
      </Grid>
    </Container>
  );
}
