import { Button } from "@mui/material";
import { MediumText } from "../../../../components/typography";

export default function ToLogin() {
  return (
    <Button
      variant="contained"
      sx={{
        display: "flex",
        alignSelf: "flex-start",
        height: "51px",
        color: "#072D26",
        my: 2,
        backgroundColor: "#C4D661",
        borderColor: "#C4D661",
        borderRadius: 30,
        fontSize: "small",
        paddingY: 1.5,
        paddingX: 5,
        "&:hover": {
          backgroundColor: "#C0D904",
        },
      }}
    >
      <MediumText color="#072D26" fWeight="bold">
        zum login
      </MediumText>
    </Button>
  );
}
