import * as React from "react";
import { SVGProps } from "react";
const SvgAgent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36.02 36.22" {...props}>
    <path d="m8.96 36.22-.34-.08c-2.52-.58-4.74-2.03-6.24-4.08C.86 30.13 0 27.66 0 25.16v-6.19l8.96-3.11 8.96 3.11v6.19c0 2.49-.85 4.96-2.42 6.95-1.47 2.01-3.68 3.45-6.21 4.03l-.34.08ZM3 21.1v4.06c0 1.83.63 3.63 1.78 5.09a7.661 7.661 0 0 0 4.18 2.88c1.68-.46 3.15-1.46 4.15-2.84 1.18-1.5 1.81-3.31 1.81-5.13V21.1l-5.96-2.06L3 21.1Z" />
    <circle cx={9.02} cy={9.8} r={1.5} />
    <path d="M27.82 24.7h-7.9v.46c0 .85-.09 1.7-.26 2.54h8.16v-3ZM27.82 16.5H16.9l1.68.58 1.34.47v1.95h7.9v-3z" />
    <path d="M35.12.9c-.6-.6-1.3-.9-2.1-.9h-30c-.8 0-1.5.3-2.1.9-.6.6-.9 1.3-.9 2.1v13.85l3-1.04V3h30v30H17.33c-.08.11-.15.22-.23.32-.76 1.04-1.69 1.93-2.73 2.68h18.65c.8 0 1.5-.3 2.1-.9.6-.6.9-1.3.9-2.1V3c0-.8-.3-1.5-.9-2.1Z" />
    <path d="M15.62 8.3h12.2v3h-12.2z" />
  </svg>
);
export default SvgAgent;
