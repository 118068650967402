import { Container } from "@mui/system";
import Grid2 from "@mui/material/Unstable_Grid2";
import styles from "./LotOffer.module.css";
import { Box } from "@mui/material/";

import Header from "components/header/Header";
import CustomerMasterData from "./CustomerMasterData";
import CustomerInformation from "./CustomerInformation";
import CustomerActive from "./CustomerActive";
// import CustomerShoppingList from "./CustomerShoppingList";

export default function CustomerDetails() {
  return (
    <>
      <Container
        maxWidth="xl"
        sx={{
          pt: 4,
          pb: 4,
          // ml: 4,
          pl: { lg: 12, md: 14, xs: 13 },
        }}
      >
        <Header backButton header="Kunde" />
        <Box
          sx={{
            mt: 3,
          }}
        >
          <div className={styles.LotOfferContainer}>
            <Grid2 container className={` ${styles.sdsd}`}>
              <CustomerMasterData className={styles.LotOfferMasterData} />
              <CustomerInformation className={styles.LotOfferMasterData} />
              <CustomerActive className={styles.LotOfferMasterData} />
            </Grid2>
          </div>
        </Box>
        {/* <CustomerShoppingList></CustomerShoppingList> */}
      </Container>
    </>
  );
}
