import { useAppSelector } from "app/hooks";
import { selectLotLocationsWoodPileLocation } from "features/lotLocations/lotLocationsSlice";
import { useEffect } from "react";
import { Marker, useMap } from "react-leaflet";
import polterHighlight from "assets/icons/PolterHighlight.svg"
import { Icon } from "leaflet";
interface Props{

}
export default function HighlightedMarker() {
  const map = useMap();
  const location = useAppSelector(selectLotLocationsWoodPileLocation);
  useEffect(() => {
    // console.log(location)
    if (location) {
      map.setView(
        { lat: location.latitude, lng: location.longitude },
        map.getZoom(),
        { animate: true }
      );
    }
  }, [location, map]);

  let highlight = new Icon({
    iconUrl: polterHighlight,
    iconRetinaUrl: polterHighlight,
    iconSize:     [38, 95], // size of the icon
    shadowSize:   [50, 64], // size of the shadow
    iconAnchor:   [22, 94], // point of the icon which will correspond to marker's location
    shadowAnchor: [4, 62],  // the same for the shadow
    popupAnchor:  [-3, -76] // point from which the popup should open relative to the iconAnchor
});
  return location ? <Marker position={{ lat: location.latitude, lng: location.longitude }} icon={highlight}/>  : null
    
}
