import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { MediumText } from "../typography/index";
import { Link } from "react-router-dom";
import Information from "./components/Information";
import styles from "./FooterNavbar.module.css";
import { Container } from "@mui/material";

const services = [
  { name: "Konto erstellen", url: "registrierung" },
  { name: "Kontakt", url: "contact" },
  { name: "Hilfe/FAQ", url: "faq" },
];
const information = [
  "Zahlungsarten",
  "AGB",
  "Datenschutzerklaerung",
  "Impressum",
];

export function FooterNavbar() {
  return (
    // <Container ></Container> // TODO ali

    <AppBar
      position="relative"
      sx={{
        backgroundColor: "#072C25",
        zIndex: "1100",
        // position: "fixed",
        bottom: 0,
        // top: 0,
        // minHeight: "70%",
        // maxHeight: 230,
      }}
    >
      <Container
        sx={
          {
            // width: "100%",
            // height: "100%",
            // paddingBottom: "min(75px, 17%)",
            // paddingTop: "min(75px, 17%)",
            // paddingBottom: "60px",
          }
        }
      >
        <Toolbar
          disableGutters
          sx={{
            marginTop: "auto",
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            alignItems: "flex-start",
            marginBottom: "40px",
            mt: 3,
            gap: { xs: 4, md: 10, lg: 13, xl: 20 },
          }}
        >
          <Link to="/" style={{ textDecoration: "none" }}>
            <Information />
          </Link>

          <Box className={styles.links}>
            <MediumText fWeight="bold" color="#FFFFFF" mb={2}>
              SERVICE
            </MediumText>
            {services.map((service) => (
              <Link
                key={service.name}
                to={`${service.url.toLocaleLowerCase()}`}
                style={{ textDecoration: "none", marginBottom: 5 }}
              >
                <MediumText fWeight="bold" color="#D9E868">
                  {service.name}
                </MediumText>
              </Link>
            ))}
          </Box>

          <Box className={styles.links}>
            <MediumText fWeight="bold" color="#FFFFFF" mb={2}>
              INFORMATION
            </MediumText>
            {
              information.map((info) => (

                <Link
                  key={info}
                  to={`${info.toLocaleLowerCase()}`}
                  style={{ textDecoration: "none", marginBottom: 5 }}
                >
                  <MediumText fWeight="bold" color="#D9E868">
                    {info.length == 21 && info === "Datenschutzerklaerung" ? "Datenschutzerklärung" : info}
                  </MediumText>
                </Link>
              ))
            }
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
