import * as React from "react";
import { SVGProps } from "react";
const SvgNav = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="2 6 28 20"
    xmlSpace="preserve"
    {...props}
  >
    <path
      fill="#FFF"
      d="M4 10h24a2 2 0 0 0 0-4H4a2 2 0 0 0 0 4zm24 4H4a2 2 0 0 0 0 4h24a2 2 0 0 0 0-4zm0 8H4a2 2 0 0 0 0 4h24a2 2 0 0 0 0-4z"
    />
  </svg>
);
export default SvgNav;
