import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { RequestStatus } from "interfaces/RequestStatus";
import { fetchLotList, fetchLotListArgument } from "./fetchLotList";
import { LotOfferInterface } from "../../interfaces/LotOfferInterface";

export interface lotListState {
  status: RequestStatus;
  total: number;
  pages: number;
  itemsPerPage: number;
  lotOffers: LotOfferInterface[];
}

const initialState: lotListState = {
  status: RequestStatus.idle,
  total: 0,
  pages: 0,
  itemsPerPage: 10,
  lotOffers: [],
};

export const getLotList = createAsyncThunk("lotList/fetchLotList", async (args: fetchLotListArgument, { getState }) => {
  const { user } = getState() as RootState;
  const response = await fetchLotList({ ...args, endpoint: user.role });
  return { headers: response.headers, data: response.data };
});

export const lotListSlice = createSlice({
  name: "lotList",
  initialState,
  reducers: {
    clear: (state) => {
      return initialState;
    },
    setStatus: (state, action: PayloadAction<string>) => {
      
    } 
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLotList.pending, (state) => {
        state.status = RequestStatus.loading;
      })
      .addCase(getLotList.fulfilled, (state, action) => {
        state.status = RequestStatus.idle;
        state.lotOffers = action.payload.data.data.lot_offers;
        state.pages = action.payload.data.data.pages;
        state.total = action.payload.data.data.total;
      })
      .addCase(getLotList.rejected, (state) => {
        state.status = RequestStatus.failed;
      });
  },
});

export const { clear } = lotListSlice.actions;

export const selectLotList = (state: RootState) => state.lotList.lotOffers;
export const selectLotListTotal = (state: RootState) => state.lotList.total;
export const selectLotListPages = (state: RootState) => state.lotList.pages;

export default lotListSlice.reducer;
