import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { AgentInterface } from "interfaces/Agent";
import { RequestStatus } from "interfaces/RequestStatus";
import { fetchAgentList, fetchAgentListArgument } from "./fetchAgentList";

export interface agentListState {
  status: RequestStatus;
  total: number;
  pages: number;
  itemsPerPage: number;
  agents: AgentInterface[];
}

const initialState: agentListState = {
  status: RequestStatus.idle,
  total: 0,
  pages: 0,
  itemsPerPage: 10,
  agents: [],
};

export const getAgentList = createAsyncThunk("agentList/fetchAgentList", async (args: fetchAgentListArgument, { getState }) => {
  const { user } = getState() as RootState;
  const response = await fetchAgentList({ ...args, endpoint: user.role });
  return { headers: response.headers, data: response.data };
});

export const agentListSlice = createSlice({
  name: "agentList",
  initialState,
  reducers: {
    clear: (state) => {
      return initialState;
    },
    setStatus: (state, action: PayloadAction<string>) => {
      
    } 
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAgentList.pending, (state) => {
        state.status = RequestStatus.loading;
      })
      .addCase(getAgentList.fulfilled, (state, action) => {
        state.status = RequestStatus.idle;
        state.agents = action.payload.data.data.agents;
        state.pages = action.payload.data.data.pages;
        state.total = action.payload.data.data.total;
      })
      .addCase(getAgentList.rejected, (state) => {
        state.status = RequestStatus.failed;
      });
  },
});

export const { clear } = agentListSlice.actions;

export const selectAgentList = (state: RootState) => state.agentList.agents;
export const selectAgentListTotal = (state: RootState) => state.agentList.total;
export const selectAgentListPages = (state: RootState) => state.agentList.pages;

export default agentListSlice.reducer;
