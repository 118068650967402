import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { LotOfferLocationInterface } from "interfaces/LotOfferLocationInterface";
import { fetchValidate, postPurchase } from "./fetchValidate";

export enum ShoppingCartInvalidStatus {
  TAKEN = "taken_lot_offer_ids",
  OVERFLOW = "overflown_lot_offer_ids",
}

export interface shoppingCartState {
  lotLocations: LotOfferLocationInterface[];
  ids: string[];
  invalid: {
    [key: string]: ShoppingCartInvalidStatus;
  };
  fromShoppingCart: any,
}

const initialState: shoppingCartState = {
  ids: [],
  lotLocations: [],
  invalid: {},
  fromShoppingCart: false,
};

export const checkShoppingCart = createAsyncThunk("shoppingCart/validate", async (ids: string[], { getState }) => {
  const { shoppingCart } = getState() as RootState;
  const response = await fetchValidate({ ids: ids.concat(shoppingCart.ids) });
  return { headers: response.headers, data: response.data };
});

export const purchaseShoppingCart = createAsyncThunk("shoppingCart/purchase", async (ids: string[], { getState }) => {
  const { shoppingCart } = getState() as RootState;
  const response = await postPurchase({ ids: ids.concat(shoppingCart.ids) });
  return { headers: response.headers, data: response.data };
});

export const shoppingCartSlice = createSlice({
  name: "shoppingCart",
  initialState,
  reducers: {
    clear: (state) => {
      return initialState;
    },
    clearShoppingCart: () => {
      return initialState;
    },
    deleteItemFromShoppingCart: (state, action: PayloadAction<LotOfferLocationInterface[]>) => {
      state.lotLocations = action.payload || [];
    },
    deleteFromShoppingIds: (state, action: PayloadAction<string>) => {
      state.ids = state.ids.filter((id) => id !== action.payload);
    },
    setShoppingCart: (state, action: PayloadAction<LotOfferLocationInterface>) => {
      if (state.lotLocations.findIndex((lotLocation) => lotLocation.id === action.payload.id) === -1) {
        state.ids = [...state.ids].concat(action.payload.id)
        state.lotLocations = [...state.lotLocations].concat(action.payload)
      }
    },
    setFromShoppingCart: (state, action: PayloadAction<boolean>) => {
      state.fromShoppingCart = action.payload;
    },
  
  },
  extraReducers: (builder) => {
    builder.addCase(checkShoppingCart.fulfilled, (state, action) => {
      if (action.payload.data.data) {
        action.payload.data.data.overflown_lot_offer_ids?.forEach((id) => {
          state.invalid = {...state.invalid, id: ShoppingCartInvalidStatus.OVERFLOW}
        });
        action.payload.data.data.taken_lot_offer_ids?.forEach((id) => {
          state.invalid = {...state.invalid, id: ShoppingCartInvalidStatus.TAKEN}
        });
      } else {
        state.invalid = {};
      }
    });
    builder.addCase(purchaseShoppingCart.fulfilled, (state, action) => {
      if (action.payload.data.data) {
        action.payload.data.data.overflown_lot_offer_ids?.forEach((id) => {
          state.invalid = {...state.invalid, id: ShoppingCartInvalidStatus.OVERFLOW}
        });
        action.payload.data.data.taken_lot_offer_ids?.forEach((id) => {
          state.invalid = {...state.invalid, id: ShoppingCartInvalidStatus.TAKEN}
        });
      } else {
        state.invalid = {};
      }
    });
  },
});

export const { clear, clearShoppingCart, setShoppingCart, deleteItemFromShoppingCart, deleteFromShoppingIds, setFromShoppingCart  } = shoppingCartSlice.actions;

export const selectShoppingCartIds = (state: RootState) => state.shoppingCart.ids;

export const selectShoppingCartLots = (state: RootState) => state.shoppingCart.lotLocations;
export const selectShoppingCartSize = (state: RootState) => state.shoppingCart.lotLocations.length;
export const selectShoppingCartInvalidIds = (state: RootState) => state.shoppingCart.invalid;
export const fromShoppingCart  = (state: RootState) =>  state.shoppingCart.fromShoppingCart as any;


export default shoppingCartSlice.reducer;
