import { ReactNode } from "react";
import { Typography } from "@mui/material";

interface Props {
  children?: ReactNode;
  color?: String;
  fWeight?: String;
  mt?: any;
  mb?: any;
  textAlign?: String;
  display?: String;
  float?: String;
  // any props that come into the component
}

export default function SmallText({
  children,
  color,
  fWeight,
  mt,
  mb,
  textAlign,
  display,
  float,
}: Props) {
  return (
    <Typography
      textAlign="center"
      sx={{
        fontFamily: "DMSans",
        marginTop: mt,
        marginBottom: mb,
        color: `${color}`,
        fontWeight: `${fWeight}`,
        textDecoration: "none",
        fontSize: 11,
        letterSpacing: 0,
        alignSelf: "flex-start",
        textAlign: `${textAlign}`,
        display: `${display}`,
        float: `${float}`,
      }}
    >
      {children}
    </Typography>
  );
}
