import { Box, SvgIcon, Typography } from "@mui/material";

interface TextWithIconProps {
  icon: JSX.Element;
  text: string;
}

export default function TextWithIcon(props: TextWithIconProps) {
  const { icon, text } = props;
  return (
    <Box sx={{ display: "flex", alignItems: "center", my: 0.5, padding: 0.5, gap: "5px" }}>
      <SvgIcon inheritViewBox>{icon}</SvgIcon> <Typography sx={{fontSize: {xs: "12px", md: "14px", lg: "16px"}}}>{text}</Typography>
    </Box>
  );
}
