import { IconButton, SvgIcon, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import { useAppSelector } from "app/hooks";
import pdf from "assets/documents/240705-Holzfinder-Handbuch.pdf"
import { selectUserRole } from "features/user/userSlice";
import { Agent, Buyer, List, Manual, Report, Seller } from "icons";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import styles from "./SideBarNavigation.module.css";

function getOutletHeight() {
  let el = document.getElementById("reactOutlet");
  if (el) {
    return el.getBoundingClientRect().height + 300;
  } else {
    return 0;
  }
}

export default function SideBarNavigation() {
  const [height, setHeight] = useState(getOutletHeight());
  let location = useLocation();
  const userRole = useAppSelector(selectUserRole);
  function handleResize() {
    let currentHeight = getOutletHeight();
    if (currentHeight !== height) setHeight(currentHeight);
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize);
  });
  // eslint-disable-next-line
  useEffect(handleResize, [location]);

  return (
    <Box>
      <Box
        // position="fixed"

        id="sidebarNavigation"
        sx={{
          // right: "auto",
          // left: 0,
          textAlign: "center",
          top: 0,
          width: "80px",
          backgroundColor: "#D0C2A8",
          position: "fixed",
          zIndex: 999,
          height: height + "px",
          paddingTop: { xs: 9, md: 13 },
        }}
      >
        {/* TODO: This still needst to be linked */}
        {/* <IconButton>
          <SvgIcon sx={{ height: 36, width: 36 }}>
            <Dashboard color="#000000" />
          </SvgIcon>
        </IconButton> */}
        <Link to={`/${userRole}/losliste`}>
          <Tooltip title="Losliste" placement="right">
            <IconButton>
              <SvgIcon sx={{ height: 44, width: 44 }}>
                <List color="#000000" />
              </SvgIcon>
            </IconButton>
          </Tooltip>
        </Link>
        <Link to={`/${userRole}/kundenliste`}>
          <Tooltip title="Kundenliste" placement="right">
            <IconButton>
              <SvgIcon sx={{ height: 36, width: 36 }}>
                <Buyer color="#000000" />
              </SvgIcon>
            </IconButton>
          </Tooltip>
        </Link>
        {userRole == "admin" ? (
          <Link to="/admin/agentenliste">
            <Tooltip title="Agentenliste" placement="right">
              <IconButton>
                <SvgIcon sx={{ height: 34, width: 34 }}>
                  <Agent color="#000000" />
                </SvgIcon>
              </IconButton>
            </Tooltip>
          </Link>
        ) : (
          <></>
        )}
        <Link to={`/${userRole}/verkaeuferliste`}>
          <Tooltip title="Verkäuferliste" placement="right">
            <IconButton>
              <SvgIcon sx={{ height: 34, width: 34 }}>
                <Seller color="#000000" />
              </SvgIcon>
            </IconButton>
          </Tooltip>
        </Link>
        <Link to={`/${userRole}/reports`}>
          <Tooltip title="Reports" placement="right">
            <IconButton>
              <SvgIcon sx={{ height: 34, width: 34, fill: "#000000" }}>
                <Report color="#000000" />
              </SvgIcon>
            </IconButton>
          </Tooltip>
        </Link>
        <a href={pdf} target="_blank" className={styles.linkManual}>
          <Tooltip title="Handbuch" placement="right">
            <IconButton>
              <SvgIcon sx={{ height: 34, width: 34, fill: "#000000" }}>
                <Manual color="#000000" />
              </SvgIcon>
            </IconButton>
          </Tooltip>
        </a>
      </Box>
    </Box>
  );
}