import { Button, MenuItem, Select, Typography } from "@mui/material";
import { useAppSelector, useAppDispatch } from "app/hooks";
import { selectCustomer, selectCustomerStatus, selectCustomersList, updateStatus } from "features/customer/customerSlice";

import SellerData from "../../admin/seller/SellerData";
import SellerGrid from "../../admin/seller/SellerGrid";
import { useParams, useNavigate } from "react-router-dom";
// import HolzfinderSelect from '../../../components/inputs/HolzfinderSelect';
// import HolzfinderSelect from 'components/inputs/HolzfinderSelect';
import { useEffect, useState } from "react";
import { selectUserRole } from "features/user/userSlice";
import axiosInstance from "app/axios";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


const ContactDataLabels = {
  status: "status",
};
export default function CustomerActive(props: any) {
  const customer = useAppSelector(selectCustomer);
  const userId = useAppSelector((state) => state.customerSettings.customer?.id);
  const userRole = useAppSelector(selectUserRole);
  const { customer_id } = useParams<{ customer_id: string }>();
  const selectedCustomer = useAppSelector((state) => state.customerSettings.customer);
  const selectedCustomerStatus = useAppSelector(state => state.customerSettings.customer?.status);

  const [status, setStatus] = useState(selectedCustomerStatus);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const handleDeleteClick = () => {
    setDeleteDialogOpen(true);
  };

  const handleConfirmDelete = () => {
    deactivateCustomer();
    setDeleteDialogOpen(false);
  };

  const handleCancelDelete = () => {
    setDeleteDialogOpen(false);
  };

  const handleChange = async (event: any) => {
    const newStatus = event.target.value;
    setStatus(newStatus);
    dispatch(updateStatus(newStatus));

    if (selectedCustomer) {
      try {
        await axiosInstance.patch(`${userRole}/customers/${customer_id}`, {
          status: newStatus,
        });
      } catch (error) {
        console.log('Failed to update status:', error);
      }
    }
  };

  const deactivateCustomer = async () => {
    try {
      await axiosInstance.delete(`admin/customers/${customer_id}`);
      navigate("/admin/kundenliste");
    } catch (error) {
      console.error("Error in deactivation:", error);
    }
  };

  const isButtonDisabled = status === "active" || selectedCustomerStatus === "active";
  const isUserRoleAdmin = userRole === "admin";

  return (
    <SellerGrid>
      <Typography
        fontWeight={"bold"}
        variant="h5"
        sx={{
          mt: 2,
          mb: 1,
          color: "#072D26",
        }}
      >
        Kundenstatus
      </Typography>
      <Select
        value={selectedCustomerStatus}
        // value={customerStatus}
        onChange={handleChange}
        // displayEmpty
        inputProps={{ 'aria-label': 'Without label' }}
        sx={{ borderRadius: "26px" }}
      >
        <MenuItem value={'inactive'}>gesperrt</MenuItem>
        <MenuItem value={'active'}>entsperrt</MenuItem>
      </Select>

      <div>
        {isUserRoleAdmin && (
          <div>
            <Button
              onClick={handleDeleteClick}
              disabled={isButtonDisabled}
              variant="outlined"
              // sx={{ borderRadius: "26px", float: 'right', mt: -25, position: 'absolute', right: 0 }}
              style={{
                borderRadius: "26px",
                fontWeight: "bold",
                color: "#072D26",
                border: isButtonDisabled ? "1px solid #d3d3d3" : "2px solid #FF6A7D",

              }}
              sx={{
                mt: -25,
                bottom: '78%',
                left: "70%",
                position: 'absolute',
                color: "#072D26",
                height: "50px",
                ":hover": {
                  backgroundColor: "#FF6A7D",
                  color: "white",
                  fontWeight: "bold",
                  border: "2px solid #FF6A7D",
                }

              }}
            >
              KUNDEN LÖSCHEN
            </Button>
          </div>
        )}

        <Dialog open={isDeleteDialogOpen} onClose={handleCancelDelete}>
          <DialogTitle>Soll der Kunde wirklich gelöscht werden?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Dies führt zu einer endgültigen Löschung sämtlicher Kunden- und Kaufdaten des Kunden und ist nicht umkehrbar.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancelDelete} color="primary">
              Nein
            </Button>
            <Button onClick={handleConfirmDelete} color="primary">
              Ja
            </Button>
          </DialogActions>
        </Dialog>
      </div>

    </SellerGrid>
  );
}
