import axiosInstance from "app/axios";
import { AxiosResponse } from "axios";
import { CustomerDataInterface } from "interfaces/CustomerDataInterface";
import { CustomerCountriesInterface } from "interfaces/CustomerCountriesInterface";
import { UserDataInterface } from "interfaces/UserDataInterface";

export interface CustomerCountriesResponseInterface {
  data: CustomerCountriesInterface;
}

export function getCustomerCountries(): Promise<
  AxiosResponse<CustomerCountriesResponseInterface, any>
> {
  const payload: any = {};  
  payload["items_per_page"] = -1
  // return axiosInstance.get("customer/countries");
  return axiosInstance.get("customer/countries", { params: 
    {
      //Get all countries here 
      "items_per_page": "unlimited"
    }
  }
  );
}

