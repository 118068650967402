import { Box, Container } from "@mui/material";
import Header from "components/header/Header";
import { MediumLargeText } from "components/typography";

export default function SuccesfulOrder() {
  return(
    <Container sx={{ pl: { lg: 12, md: 12, xs: 1 }, pt: 5 }}>
      <Header header="Bestellung erfolgreich" />
      <Box sx={{display: "flex", flexDirection:"column", pt: 5, gap: 1}}>
        <MediumLargeText>Ihre Bestellung ist eingegangen.</MediumLargeText>
        <MediumLargeText>Sie erhalten im Nachgang für jeden Anbieter, von dem Sie Holz gekauft haben, eine separate Rechnung per E-Mail.</MediumLargeText>
      </Box>
    </Container>
  )
}