import { Box, Container } from "@mui/material";
import Header from "components/header/Header";
import {
  LargeText,
  MediumLargeText,
  MediumText,
  SmallText,
} from "components/typography";

export default function Zahlungsarten() {
  return (
    <Container sx={{ p: 1, mt: 5 }}>
      <Header
        header="Zahlungsarten
"
      />
      <Box sx={{ mt: 3, mb: 4 }}>
        <LargeText my={2}>Kauf auf Rechnung</LargeText>
        <Box sx={{ marginBottom: 2 }}>
          <MediumText>
            Der Kauf auf Rechnung stellt für den Käufer im Onlinehandel eine sehr sichere Variante dar.
            Nach einem Kauf bei Holzfinder bekommst Du eine Rechnung und hast 14 Tage Zeit, diese zu bezahlen. Der Betrag ist auf einmal zu zahlen, eine Ratenzahlung ist nicht möglich.
            Die entsprechende Bankverbindung des Verkäufers findest Du oben rechts auf der Rechnung neben Deiner Adresse.
          </MediumText>
          <MediumText>
            Bitte gib bei der Überweisung das auf der Rechnung angegebene Kassenzeichen als Verwendungszweck an. Das Kassenzeichen steht unter der Bankverbindung des Verkäufers.
          </MediumText>
          <MediumText>
            Die Rechnung gilt in Verbindung mit einem Zahlungsbeleg als Abfuhrfreigabe. Beides hast Du dabei, wenn Du in den Wald fährst und Dein Holz holst.           </MediumText>

        </Box>
      </Box>
    </Container>
  );
}
