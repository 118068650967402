import { Divider, MenuItem, Paper, Select, TextField } from "@mui/material";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  getLotLocations,
  selectLotLocationDistance,
  selectLotLocationLocationString,
  setDistance,
  setLocation,
} from "features/lotLocations/lotLocationsSlice";
import { useEffect } from "react";

export default function LocationFilterSection() {
  const dispatch = useAppDispatch();
  const distance = useAppSelector(selectLotLocationDistance);
  const location = useAppSelector(selectLotLocationLocationString);
  // const handleDistanceChange = (event: any) => {
  //   console.log(event.target.value)
  //   if (event.target.value === 0) {
  //     dispatch(setDistance());

  //   }
  //   else {
  //     dispatch(setDistance(event.target.value));
  //   }
  // };
  const handleDistanceChange = (event: any) => {
    // console.log(event.target.value)
    if (event.target.value == 0) {
      dispatch(setDistance(undefined));

    }
    else {
      dispatch(setDistance(event.target.value));
    }
  };

  const handleLocationChange = (event: any) => {
    dispatch(setLocation(event.target.value));
  };

  const updateLotLocations = () => {
    dispatch(getLotLocations());
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      updateLotLocations()
    }
  }
  /*
.extendableShow{
  display: flex;
  padding: 2px 4px;
  align-items: center;
  border-radius: 30px !important;
  margin: auto 16px;
}
  */
  // console.log(distance)
  // useEffect(() => {
  //   dispatch(setDistance(0));
  // }, []);
  return (
    <Paper
      sx={{
        display: "flex",
        alignItems: "center",
        padding: "2px 4px",
        borderRadius: "30px",
        width: { xs: "200px", lg: "400px" },
        height: "50px",
        gap: 2,
      }}
    >
      <TextField
        placeholder="Ort oder Postleitzahl"
        variant="standard"
        InputProps={{ disableUnderline: true }}
        value={location}
        // disableUnderline
        onChange={handleLocationChange}
        onBlur={updateLotLocations}
        onKeyDown={handleKeyDown}
        sx={{
          pl: 2,
          fontSize: "15px",
          width: { xs: "150px", lg: "335px" },
          "&::before": { borderBottom: "none" },
        }}
      />
      <Divider
        sx={{ height: 28, m: 1, color: "#1F2A40", border: 1 }}
        orientation="vertical"
      />

      <Select
        variant="standard"
        value={distance == undefined ? 0 : distance}
        label="Km"
        onChange={handleDistanceChange}
        placeholder="+25"
        onBlur={updateLotLocations}
        disableUnderline
        sx={{
          width: { xs: "80px", lg: "100px" },
          "&::before": { borderBottom: "none" },
        }}
      >
        <MenuItem value={0}> alle</MenuItem>
        <MenuItem value={2}>+ 2km</MenuItem>
        <MenuItem value={5}>+ 5km</MenuItem>
        <MenuItem value={10}>+ 10km</MenuItem>
        <MenuItem value={25}>+ 25km</MenuItem>
        <MenuItem value={50}>+ 50km</MenuItem>
      </Select>
    </Paper>
  );
}
