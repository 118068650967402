import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { AgentInterface } from "interfaces/Agent";
import { RequestStatus } from "interfaces/RequestStatus";
import { SellerInterface } from "interfaces/Seller";
import { LotOfferInterface } from "../../interfaces/LotOfferInterface";
import { fetchSellersList, fetchSellersListArgument } from "./fetchSellersList";

export interface SellersListState {
  status: RequestStatus;
  total: number;
  pages: number;
  itemsPerPage: number;
  sellers: SellerInterface[];
}

const initialState: SellersListState = {
  status: RequestStatus.idle,
  total: 0,
  pages: 0,
  itemsPerPage: 10,
  sellers: [],
};

export const getSellersList = createAsyncThunk("agentList/fetchSellersList", async (args: fetchSellersListArgument, { getState }) => {
  const { user } = getState() as RootState;
  const response = await fetchSellersList({ ...args, endpoint: user.role });
  return { headers: response.headers, data: response.data };
});

export const sellersListSlice = createSlice({
  name: "sellersList",
  initialState,
  reducers: {
    clear: (state) => {
      return initialState;
    },
    setStatus: (state, action: PayloadAction<string>) => {
      
    } 
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSellersList.pending, (state) => {
        state.status = RequestStatus.loading;
      })
      .addCase(getSellersList.fulfilled, (state, action) => {
        state.status = RequestStatus.idle;
        state.sellers = action.payload.data.data.sellers;
        state.pages = action.payload.data.data.pages;
        state.total = action.payload.data.data.total;
      })
      .addCase(getSellersList.rejected, (state) => {
        state.status = RequestStatus.failed;
      });
  },
});

export const { clear } = sellersListSlice.actions;

export const selectSellersList = (state: RootState) => state.sellersList.sellers;
export const selectSellersListTotal = (state: RootState) => state.sellersList.total;
export const selectSellersListPages = (state: RootState) => state.sellersList.pages;

export default sellersListSlice.reducer;
