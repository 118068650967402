import { Typography } from "@mui/material";
import { useAppSelector } from "app/hooks";
import {
  selectLotOffer,
  selectLotOfferWoodTypes,
} from "features/lotOffer/lotOfferSlice";
import LotOfferData from "./LotOfferData";
import LotOfferGrid from "./LotOfferGrid";

const MasterDataLabels = {
  lot_number: "Los-Nr.",
  wood_type: "Holzart",
  wood_category: "Holzsorte",
  solid_cubic_meters_without_bark: "Festmeter in Fm o. R.",
  average_length_value: "Ø-Länge",
  diameter: "Ø-Durchm.",
  // taxes: "Steuersatz", //TODO: Where are taxes stored?
};
export default function LotOfferMasterData(props: any) {
  const lotOfferData = useAppSelector(selectLotOffer);
  const lotOfferWoodTypes = useAppSelector(selectLotOfferWoodTypes);

  function formatValue(value: string | undefined): string {
    if (value !== undefined) {
      const numericValue = parseFloat(value);
      if (!isNaN(numericValue)) {
        return numericValue.toFixed(2).replace('.', ',');
      }
    }
    return "";
  }
  return (
    <LotOfferGrid>
      <Typography
        fontWeight={"bold"}
        variant="h5"
        sx={{
          mt: 2,
          mb: 0.5,
          color: "#072D26",
        }}
      >
        STAMMDATEN
      </Typography>
      <LotOfferData
        label={MasterDataLabels.lot_number}
        data={lotOfferData?.lot_number}
      />
      <LotOfferData
        label={MasterDataLabels.wood_type}
        data={lotOfferWoodTypes?.join(", ")}
      />
      <LotOfferData
        label={MasterDataLabels.wood_category}
        data={lotOfferData?.wood_category.short_name}
      />
      <LotOfferData
        label={MasterDataLabels.solid_cubic_meters_without_bark}
        data={formatValue(lotOfferData?.solid_cubic_meters_without_bark)}
      />
      <LotOfferData
        label={MasterDataLabels.average_length_value}
        data={formatValue(lotOfferData?.average_length_value)}
      />
      <LotOfferData
        label={MasterDataLabels.diameter}
        data={lotOfferData?.diameter}
      />
      {/* <LotOfferData label={MasterDataLabels.taxes} data={"N/A"} /> */}
    </LotOfferGrid>
  );
}

