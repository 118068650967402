import { FormControl, TextField, Box } from '@mui/material';
import styles from "../ChangeLoginData.module.css";
import { MediumText } from "../../../../components/typography";
import { ShowPassword } from "../../../../components/buttons"
import { useState } from 'react';
import { useAppDispatch } from 'app/hooks';
import { updateNewEmail, updatePasswordForEmail } from 'features/user/userSlice';

export default function ChangeEmail(){

  const [showNewPassword, setShowNewPassword] = useState(false);
  const dispatch = useAppDispatch();
  return(
    <div>

      <Box sx={{display: 'flex', flexDirection: 'column'}}>
        <FormControl sx={{ width: '25ch', pt: 4, pb: 9, pr: 4}} className={styles.form}>
          <MediumText>Neue Email</MediumText>
          <TextField type="email" placeholder="Neue Email" className={styles.input} onChange={(event: any) => {dispatch(updateNewEmail(event?.target.value))}} name={"email"} />

          <MediumText>Passwort</MediumText>
          <TextField type={showNewPassword ? "text" : "password"} placeholder="Passwort" className={styles.input} onChange={(event: any) => {dispatch(updatePasswordForEmail(event?.target.value))}} name={"password"} />

          <ShowPassword onClick={setShowNewPassword(!showNewPassword)}/>

        </FormControl>
      </Box>
    </div>
  )
}