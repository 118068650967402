import {
  FormControl,
  MenuItem,
  Select,
  SelectProps,
  // SelectVariants,
} from "@mui/material";

interface HolzfinderSelectOptions {
  label: string;
  value: string | number;
}

type HolzfinderSelectProps = Omit<SelectProps, 'variant'> &
{
  id: string;
  label: string;
  options: HolzfinderSelectOptions[];
  currentValue: string | number;
  disabled?: boolean;
}

export default function HolzfinderSelect(props: HolzfinderSelectProps) {
  const { id, label, options, currentValue, onChange, disabled, ...rest } = props;
  return (
    <FormControl>
      <label
        className={`${id}_class`}
        style={{ whiteSpace: "pre", marginBottom: "8px" }}
      >
        {label}
      </label>
      <Select
        // defaultValue={false}
        labelId={id}
        id={id}
        {...rest}
        disabled={disabled}
        value={currentValue}
        onChange={onChange}
        // label="label"
        sx={{
          borderRadius: 26,
          height: 51,
        }}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
