import axiosInstance from "app/axios";
import { AxiosResponse } from "axios";
import { AgentInterface } from "interfaces/Agent";
import { AgentUserDataInterface } from "interfaces/AgentUserDataInterface";
import { UserDataInterface } from "interfaces/UserDataInterface";
import { UserRoles } from "interfaces/UserRoles";

export interface fetchAgentArgument {
  id: string | undefined;
  endpoint?: UserRoles;
}
export interface fetchAgentUserListArgument {
  agent_id: string;
  items_per_page: number;
  page: number;
  search: string | undefined;
  endpoint?: UserRoles;
  role: UserRoles;
}
export interface postNewAgentArgument {
  first_name: string;
  last_name: string;
  email: string;
  role?: string;
  endpoint?: UserRoles;
  agent_id: string;
}
export interface confirmAgentArgument {
  token: string;
  password: string;
  password_confirmation: string;
}

export interface AgentResponseInterface {
  data: AgentInterface;
}
export interface AgentUsersResponseInterface {
  data: {
    total: number;
    pages: number;
    users: AgentUserDataInterface[];
  }
}
export interface AgentUserResponseInterface {
  data: AgentUserDataInterface;
}
export interface InviteAgentResponseInterface {
  data: {
    errors: {
      email: string[];
    }
  };
}
export interface ConfirmAgentResponseInterface {
  data: {
    errors: {
      password: string[];
      password_confirmation: string[];
    }
  };
}

export interface DeleteAgentUserResponseInterface {
  data: {
    errors: {
      id: string[];
    }
  };
}

export function fetchAgent({ id, endpoint = UserRoles.Admin }: fetchAgentArgument): Promise<AxiosResponse<AgentResponseInterface, any>> {
  return axiosInstance.get(`${endpoint}/agents/${id}`);
}

export function archiveUser({ id, endpoint = UserRoles.Admin }: fetchAgentArgument): Promise<AxiosResponse<DeleteAgentUserResponseInterface, any>> {
  return axiosInstance.delete(`${endpoint}/users/${id}`);
}

export function fetchAgentUsers({ agent_id, items_per_page, page, search, role, endpoint = UserRoles.Admin }: fetchAgentUserListArgument): Promise<AxiosResponse<AgentUsersResponseInterface, any>> {
  return axiosInstance.get(`${endpoint}/users`, {
    params: {
      agent_id,
      items_per_page,
      page,
      search,
      role, 
    },
  });
}

export function fetchAgentUser({ endpoint = UserRoles.Admin, id }: fetchAgentArgument): Promise<AxiosResponse<AgentUserResponseInterface, any>> {
  return axiosInstance.get(`${endpoint}/users/${id}`);
}

export function postAgent({ first_name, last_name, email, agent_id, endpoint = UserRoles.Admin, role = "agent" }: postNewAgentArgument): Promise<AxiosResponse<InviteAgentResponseInterface, any>> {
  return axiosInstance.post(`${endpoint}/users`, {
    first_name,
    last_name,
    email,
    role, 
    agent_id
  });
}

export function patchAgentLogin({ token, password, password_confirmation }: confirmAgentArgument): Promise<AxiosResponse<InviteAgentResponseInterface, any>> {
  return axiosInstance.patch(`/user/accept_invitation`, {
    token,
    password,
    password_confirmation
  });
}

export interface saveLotOfferArgument {
  id: string;
  agent: AgentInterface;
  changedValues: (keyof AgentInterface)[];
  endpoint?: UserRoles;
}

export function saveAgent({ id, agent, changedValues, endpoint = UserRoles.Admin }: saveLotOfferArgument) {
  // Create payload
  const payload: any = {};
  changedValues.forEach((changedValue) => {
    // TODO: Need a way to access deeper things maybe
    payload[changedValue] = agent[changedValue];
    
  });
  return axiosInstance.patch(`${endpoint}/agents/${id}`, payload);
}