import { Checkbox, MenuItem, Select } from "@mui/material";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { FilterLotSearchText } from "components/typography";
import {
  selectLotLocationFilterSettings,
  selectLotLocationFilter,
  setCounties,
} from "features/lotLocations/lotLocationsSlice";

export default function CountyFilterSection() {
  const dispatch = useAppDispatch();
  const countiesSettings = useAppSelector(selectLotLocationFilterSettings).counties;
  const counties = useAppSelector(selectLotLocationFilter).counties;

  const handleCounty = (event: any) => {
    dispatch(setCounties(event.target.value));
  };

  if (countiesSettings.length <= 1) return <div></div>;
  //landkreis
  
  return (
    <Select
      variant="standard"
      value={counties}
      // multiple
      onChange={handleCounty}
      disableUnderline
      displayEmpty
      renderValue={
        counties.length > 0
          ? () => (
              <FilterLotSearchText>
                {counties.length} ausgewählt
              </FilterLotSearchText>
            )
          : //       <FilterLotSearchText>{selected.join(",")}</FilterLotSearchText>
            //     );
            () => (
              <FilterLotSearchText color={"gray"}>
                Landkreis
              </FilterLotSearchText>
            )
      }
      sx={{
        backgroundColor: "white",
        minWidth: { xs: "200px", lg: "300px" },
        borderRadius: "26px",
        display: "flex",
        padding: "2px 4px",
        alignItems: "center",
        height: "50px",
        "&::before": {
          borderBottom: "none",
        },
        "&::hover": {
          borderBottom: "none",
        },
      }}
    >
      {countiesSettings.map((county: string) => (
        <MenuItem key={county} value={county}>
          <Checkbox checked={counties.includes(county)} />
          {county}
        </MenuItem>
      ))}
    </Select>
  );
}
