import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
} from "@mui/material";

import { LargeText, MediumLargeText, MediumText } from "components/typography";
import styles from "./Homepage.module.css";
import { ExpandMore } from "@mui/icons-material";

export default function FaqSection() {
  const AccordionStyle = {
    "&:before": {
      backgroundColor: "transparent !important",
    },
  };
  const AccordionDetailsStyle = {
    borderBottomLeftRadius: "18px !important",
    borderBottomRightRadius: "18px",
  };

  function buildFAQAccordionTag(question: string, answer: string) {
    return <Accordion
      style={{ borderRadius: "16px" }}
      className={styles.accordion}
      sx={AccordionStyle}>
      <AccordionSummary
        expandIcon={<ExpandMore style={{ color: "#005851" }} />}
        aria-controls="panel2a-content"
        id="panel2a-header"
      >
        <LargeText>
          {question}
        </LargeText>
      </AccordionSummary>
      <AccordionDetails
        className={styles.accordionDetails}
        sx={AccordionDetailsStyle}
      >
        <MediumLargeText>
          {answer}
        </MediumLargeText>
      </AccordionDetails>
    </Accordion>
  }

  return (
    <div className={styles.accordionColorContainer}>
      <Container
        className={styles.blockContainer}

      >
        <div className={styles.accordionContainer}>
          <LargeText fWeight={"bold"}>FAQ / HÄUFIG GESTELLTE FRAGEN</LargeText>

          <Accordion
            className={styles.accordion}
            style={{
              marginTop: "38px",
              borderRadius: "16px",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMore style={{ color: "#005851" }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <LargeText>
                Warum muß ich mich als Käufer registrieren?
              </LargeText>
            </AccordionSummary>
            <AccordionDetails
              className={styles.accordionDetails}
              sx={AccordionDetailsStyle}
            >
              <MediumLargeText>
                Zur Sicherstellung eines transparenten, schnellen und sicheren Brennholzverkaufs ist die Registrierung bei Holzfinder Voraussetzung zur Nutzung.
              </MediumLargeText>
            </AccordionDetails>
          </Accordion>
          {/* {buildFAQAccordionTag("Warum muß ich mich als Käufer registrieren?", "Zur Sicherstellung eines transparenten, schnellen und sicheren Brennholzverkaufs ist die Registrierung bei Holzfinder Voraussetzung zur Nutzung.")} */}
          {buildFAQAccordionTag("Wird ofenfertiges Holz angeboten?", "Holzfinder bietet kein ofenfertiges Holz an. Es wird Brennholz, welches in langer Form am Waldweg gelagert ist, verkauft. Der Käufer muss dieses nach dem Kauf eigenständig aufarbeiten, abtransportieren und lagern.")}
          {buildFAQAccordionTag("Wo ist der Unterschied zwischen Festmeter und Raummeter?", "Ein Festmeter entspricht einem Kubikmeter, also dem Rauminhalt eines Würfels mit einer Kantenlänge von einem Meter ohne Luftinhalt. Festmeter ist die Verkaufseinheit bei Holzfinder. Ein Raummeter (umgangssprachlich auch ‘Ster’) entspricht 0,7 Festmetern. Hierbei handelt es sich um lose gestapeltes Holz mit dem Rauminhaltes eines Würfels mit einem Meter Kantenlänge. Im Gegensatz zum Festmeter beinhaltet der Raummeter leere Zwischenräume und besitzt daher weniger Masseninhalt als der Festmeter. Für viele ist die umgekehrte Rechnung eingängiger: Aus 1,0 Festmeter Holz erhält man rund 1,3 Raummeter / Ster gespaltenes und gestapeltes Holz.")}
          {buildFAQAccordionTag("Was bedeutet ‘Lieferung - Frei Wald’?", "Bei „Lieferung – frei Wald“  wird das in langer Form am Waldweg gelagerte Holz verkauft. Der Käufer  muss sich selbst um Aufarbeitung und Abtransport kümmern.")}
          {buildFAQAccordionTag("Was ist ein Holzlos?", "Holzlose dienen bei Holzfinder als Verkaufseinheit. Ein Holzlos ist eine Zusammenfassung von Holzstämmen mit ähnlicher Ausprägung zu einer Verkaufseinheit. Ein Holzlos kann aus mehreren Haufen (Poltern) zusammengesetzt sein und demnach auch verschiedene Lagerorte besitzen.")}
          {buildFAQAccordionTag("Was ist ein Polter?", "Ein Polter ist ein Haufen zusammen gelagertes Holz entlang eines Weges. Jedes Polter ist einem Holzlos zugehörig. Ein Holzlos kann aus mehreren Poltern / Haufen bestehen.")}
          {buildFAQAccordionTag("Wann bekomme ich die genauen Koordinaten zu dem von mir gekauften Holz?", "Die Koordinaten werden auf der Rechnung nach Kauf mit ausgegeben.")}
          {buildFAQAccordionTag("Darf ich mir aus Holzfinder angebotenes Holz anschauen und dafür mit meinem Kraftfahrzeug in den Wald fahren?", "Nein. Das Befahren von Waldwegen mit Kraftfahrzeugen ist erst nach Bezahlung des Holzes gestattet. Die Fahrerlaubnis wird durch eine Abfuhrfreigabe gestattet. Als Abfuhrfreigabe gilt die Kaufrechnung in Kombination mit einem Zahlungsnachweis.")}
          {buildFAQAccordionTag("Warum gibt es eine Mengenbeschränkung beim Einkauf?", "Teilweise kann es vorkommen, dass eine zu hohe Nachfrage nach Brennholz auf eine beschränkt verfügbare Menge Brennholz trifft. Daher behalten sich manche Anbieter eine Mengenbeschränkung vor.")}
          {buildFAQAccordionTag("Welche Anbieter verkaufen ihr Brennholz bei Holzfinder?", "Bei Holzfinder bieten sowohl private als auch kommunale Waldbesitzer aus Baden-Württemberg ihr Brennholz zum Verkauf an.")}

          <div className={styles.accordionBottomText}>
            <MediumText fWeight={"bold"}>
              Sie konnten Ihre Frage nicht finden? Schreiben Sie uns gerne eine
              Nachricht.
            </MediumText>
          </div>
        </div>
      </Container>
    </div>
  );
}
