import { Box, Typography } from "@mui/material";

interface LotOfferDataProps {
  label: string;
  data?: string | number;
  unit?: string;
}

export default function SellerData(props: LotOfferDataProps) {
  let { label, data, unit } = props;
  return (
    <Box>
      <Typography variant="body1">{label}</Typography>
      <Typography variant="body2">
        {data}
        {unit ? unit : ""}
      </Typography>
    </Box>
  );
}
