import * as React from "react";
import { SVGProps } from "react";
const SvgHolzAbholen = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80" {...props}>
    <g data-name="Gruppe 756">
      <path data-name="Rechteck 1520" fill="#fff" d="M0 0h80v80H0z" />
      <g data-name="Gruppe 750" transform="translate(-320 -1828)">
        <g data-name="003-pickup-truck">
          <g data-name="Gruppe 55">
            <g data-name="Gruppe 54">
              <path
                data-name="Pfad 89"
                d="M341.401 1884.525a1.2 1.2 0 0 0-1.2 1.2 2.656 2.656 0 1 1-2.656-2.656 1.204 1.204 0 1 0 0-2.408 5.064 5.064 0 1 0 5.064 5.064 1.2 1.2 0 0 0-1.208-1.2Z"
                fill="#005851"
              />
            </g>
          </g>
          <g data-name="Gruppe 57">
            <g data-name="Gruppe 56">
              <path
                data-name="Pfad 90"
                d="M389.789 1884.525a1.2 1.2 0 0 0-1.2 1.2 2.656 2.656 0 1 1-2.656-2.656 1.204 1.204 0 0 0 0-2.408 5.064 5.064 0 1 0 5.064 5.064 1.2 1.2 0 0 0-1.208-1.2Z"
                fill="#005851"
              />
            </g>
          </g>
          <g data-name="Gruppe 59">
            <g data-name="Gruppe 58">
              <path
                data-name="Pfad 91"
                d="m396.927 1864.543-12.276-2.04-5.41-14.027h.8a1.169 1.169 0 1 0 0-2.337H362.5a3.676 3.676 0 0 0-3.677 3.667v13.821h-37.651a1.17 1.17 0 0 0-1.172 1.166v17.487a3.676 3.676 0 0 0 3.677 3.663h3.84v.08a9.94 9.94 0 0 0 19.879 0v-.08h28.969v.08a9.94 9.94 0 0 0 19.879 0v-.08h1.335a2.424 2.424 0 0 0 2.421-2.414v-15.371a3.658 3.658 0 0 0-3.073-3.615Zm-14.836-2.166h-13.324a1.333 1.333 0 0 1-1.333-1.329v-7.575h11.223Zm-44.636 31.226a7.575 7.575 0 1 1 7.6-7.575 7.594 7.594 0 0 1-7.6 7.575Zm48.848 0a7.575 7.575 0 1 1 7.6-7.575 7.594 7.594 0 0 1-7.603 7.575Zm11.353-10.073a.081.081 0 0 1-.081.08h-1.633a9.946 9.946 0 0 0-19.278 0h-29.57a9.946 9.946 0 0 0-19.278 0h-4.139a1.333 1.333 0 0 1-1.333-1.329v-16.319h36.484v13.82a1.172 1.172 0 0 0 2.344 0v-29.978a1.333 1.333 0 0 1 1.328-1.33h14.23l1.025 2.659h-11.493a1.17 1.17 0 0 0-1.172 1.169v8.741a3.676 3.676 0 0 0 3.677 3.667H383.7l12.842 2.133a1.326 1.326 0 0 1 1.114 1.311v.462h-3.838a1.17 1.17 0 0 0-1.172 1.169v3.747a3.676 3.676 0 0 0 3.677 3.667h1.333Zm0-8.663h-1.333a1.333 1.333 0 0 1-1.333-1.329v-2.579h2.666Z"
                fill="#005851"
              />
            </g>
          </g>
          <g data-name="Gruppe 61">
            <g data-name="Gruppe 60">
              <path
                data-name="Pfad 92"
                d="M380.782 1868.587h-15.44a1.204 1.204 0 1 0 0 2.408h15.44a1.204 1.204 0 0 0 0-2.408Z"
                fill="#005851"
              />
            </g>
            <g data-name="Gruppe 141">
              <path
                data-name="Pfad 92"
                d="M394.782 1868.587h-15.44a1.204 1.204 0 1 0 0 2.408h15.44a1.204 1.204 0 0 0 0-2.408Z"
                fill="#005851"
              />
            </g>
          </g>
          <g data-name="Gruppe 65">
            <g data-name="Gruppe 64">
              <path
                data-name="Pfad 94"
                d="M355.945 1868.587h-29.594a1.204 1.204 0 1 0 0 2.408h29.594a1.204 1.204 0 0 0 0-2.408Z"
                fill="#005851"
              />
            </g>
          </g>
        </g>
        <circle
          data-name="Ellipse 47"
          cx={6.501}
          cy={6.501}
          transform="translate(327.063 1850.848)"
          fill="none"
          stroke="#005851"
          strokeWidth={2.5}
          r={6.501}
        />
        <circle
          data-name="Ellipse 48"
          cx={6.501}
          cy={6.501}
          transform="translate(340.064 1850.848)"
          fill="none"
          stroke="#005851"
          strokeWidth={2.5}
          r={6.501}
        />
        <circle
          data-name="Ellipse 49"
          cx={6.501}
          cy={6.501}
          transform="translate(333.213 1839)"
          fill="none"
          stroke="#005851"
          strokeWidth={2.5}
          r={6.501}
        />
      </g>
    </g>
  </svg>
);
export default SvgHolzAbholen;
