import axiosInstance from "app/axios";
import { AxiosResponse } from "axios";
import { CustomerPurchaseInterface } from "interfaces/CustomerPurchaseInterface";
import { UserRoles } from "interfaces/UserRoles";

export interface fetchCustomerPurchasesListArgument {
  items_per_page: number;
  page: number;
}

export interface CustomersResponseInterface {
  data: {
    total: number;
    pages: number;
    purchases: CustomerPurchaseInterface[];
  };
}

export function fetchCustomerPurchasesList({ items_per_page, page }: fetchCustomerPurchasesListArgument): Promise<AxiosResponse<CustomersResponseInterface, any>> {
  return axiosInstance.get(`customer/purchases`, {
    params: {
      items_per_page,
      page,
    },
  });
}
export interface fetchCustomerPurchaseArgument {
  id: string;
}

export interface CustomerPurchaseResponseInterface {
  data: CustomerPurchaseInterface;
}

export function fetchCustomerPurchase({ id }: fetchCustomerPurchaseArgument): Promise<AxiosResponse<CustomerPurchaseResponseInterface, any>> {
  return axiosInstance.get(`customer/purchases/${id}`);
}