import { Box, Container } from "@mui/material";
import Header from "components/header/Header";
import {
  LargeText,
  MediumLargeText,
  MediumText,
  SmallText,
} from "components/typography";

export default function Impressum() {
  return (
    <Container sx={{ p: 1, mt: 5 }}>
      <Header
        header="Impressum
"
      />
      <Box sx={{ mt: 3, mb: 4 }}>

        <MediumText>Angaben gemäß § 5 Telemediengesetz (TMG)</MediumText>
        <Box sx={{ marginBottom: 2 }}></Box>
        <MediumText>
          Diensteanbieter
        </MediumText>
        <MediumText>
          Landkreis Karlsruhe
        </MediumText>
        <MediumText>
          Kreisverwaltung Landratsamt Karlsruhe
        </MediumText>
        <MediumText>
          Kriegsstraße 100
        </MediumText>
        <MediumText>
          D - 76133 Karlsruhe
        </MediumText>
        <Box sx={{ marginBottom: 2 }}></Box>

        <MediumText>
          Umsatzsteuer-Identifikationsnummer: DE 813 539 808
        </MediumText>
        <Box sx={{ marginBottom: 2 }}></Box>
        <MediumText>
          Telefon: +49 721 936 - 50 (Zentrale)
        </MediumText>
        <MediumText>
          E-Mail: info@holzfinder.de
        </MediumText>
        <Box sx={{ marginBottom: 2 }}></Box>
        <MediumText>
          Der Landkreis Karlsruhe ist eine Körperschaft des öffentlichen Rechts. Der Landkreis Karlsruhe wird vertreten durch den Landrat Dr. Christoph Schnaudigel Aufsichtsbehörde des Landkreises Karlsruhe ist das Regierungspräsidium Karlsruhe.

        </MediumText>
        <Box sx={{ marginBottom: 2 }}></Box>
        <MediumText>
          Hinweis: Der vorgenannte Anbieter ist nicht immer identisch mit dem Verkäufer. Für den Inhalt der in diesem Online-Shop dargestellten Angebote ist der (jeweilige) Verkäufer verantwortlich. Kaufverträge, die ggfs. über diesen Online-Shop abgeschlosssen werden, kommen ausschließlich mit dem (jeweiligen) Verkäufer zustande.
          Die Identität des (jeweiligen) Verkäufers ergibt sich aus dem jeweiligen Angebot.

        </MediumText>
        <Box sx={{ marginBottom: 2 }}></Box>
        <MediumText>
          Verantwortliche Person im Sinne von § 18 Abs. 2 MStV : Landrat Dr. Christoph
          Schnaudigel, Kreisverwaltung Landratsamt Karlsruhe, Kriegsstraße 100, D-76133
          Karlsruhe

        </MediumText>
        <Box sx={{ marginBottom: 2 }}></Box>
        <MediumText>
          Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit, die Sie unter http://ec.europa.eu/consumers/odr finden. Zur Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle sind wir nicht verpflichtet und nicht bereit.

        </MediumText>
        <Box sx={{ marginBottom: 2 }}></Box>



      </Box>
    </Container>
  );
}
