import { Box, List, ListItem, Paper } from "@mui/material";
import { useAppSelector } from "app/hooks";
import { LargeText, MediumText } from "components/typography";
import { useNavigate } from "react-router-dom";
import { selectShoppingCartLots, selectShoppingCartSize } from "features/shoppingCart/shoppingCartSlice";
import HolzpolterInfo from "./ShoppingCartInfo";
import styles from "./ShoppingList.module.css";
import holzfinderDefaultLotImage from "assets/images/Holzfinder_Los_Platzhalter.jpg"
import ShoppingCartDelete from "./ShoppingCartDelete";
import { LotOfferLocationInterface } from "interfaces/LotOfferLocationInterface";

function sumOfShoppingCart(shoppingCartData: any) {
  let value = 0;
  for (const element of shoppingCartData) {
    value += parseFloat(element.price_total);
  }
  return value;
}

export default function ShoppingList() {
  const numberFormatter = new Intl.NumberFormat("de-De", { style: "currency", currency: "EUR" });
  const shoppingCartData = useAppSelector(selectShoppingCartLots);

  function groupBy(objectArray: any, property: any) {
    return objectArray.reduce((acc: any, obj: any) => {
      const key = obj[property];
      if (!acc[key]) {
        acc[key] = [];
      }
      // Add object to list for given key's value
      acc[key].push(obj);
      return acc;
    }, {});
  }
  const groupedCategorySeller = groupBy(shoppingCartData, 'seller');
  const mappedCategorySellers = Object.entries(groupedCategorySeller)
  const shoppingCartSize = useAppSelector(selectShoppingCartSize);
  const shoppingCartValue = sumOfShoppingCart(shoppingCartData);
  const baseUrl = process.env.REACT_APP_API_DOMAIN;
  const navigate = useNavigate();
  function handleContainerClick(lotDataId: string) {
    navigate(`/lossuche/${lotDataId}`);
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <List
        sx={{
          width: "inherit",
          paddingRight: "17px",
          boxSizing: "content-box",
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        {shoppingCartData.length > 0 ? (
          mappedCategorySellers.map((item: any) => {
            return <div key={item[0]}>
              <LargeText padding="0px -5px 0px 0px" fWeight="bold" >{item[0]}:</LargeText>

              {item[1].map((lotData: LotOfferLocationInterface) => 
                (
                  <ListItem key={lotData.id} sx={{ pl: 0 }}>

                    <Paper className={styles.shoppingCartListItem}
                      sx={{
                        overflow: "hidden",
                        display: "flex",
                        borderRadius: "16px",
                        width: "100%",
                        gap: 2
                      }}
                    >
                      <Box
                        component="div"
                        sx={{
                          display: "flex"
                        }}
                      >
                        <Box
                          component="div"
                          sx={{
                            overflow: "hidden",
                            display: "flex",
                            maxWidth: "150px",
                            width: "100%"
                          }}
                        >
                          <Box
                            component="img"
                            sx={{
                              aspectRatio: "4/3",
                              width: "inherit",
                              height: "inherit",
                              maxWidth: "100%",
                              overflow: "hidden",
                              objectFit: "cover",
                            }}
                            alt="Bild eines Holzpolters"
                            src={lotData.image_urls[0] ? baseUrl + lotData.image_urls[0] : holzfinderDefaultLotImage}

                          />
                        </Box>
                      </Box>
                      <Box
                        onClick={() => {
                          handleContainerClick(lotData.id);
                        }}
                        sx={{
                          cursor: "pointer",
                          display: "flex",
                          width: "100%",
                          height: "inherit",
                        }}
                      >
                        <HolzpolterInfo lotInformation={lotData} />
                      </Box>
                    </Paper>
                  </ListItem>
                )
              )}
            </div>
          })) : (
          <Box sx={{ display: "flex" }}>
            <LargeText fWeight="bold">Ihr Einkaufswagen ist leer</LargeText>
          </Box>
        )}
      </List>
      {shoppingCartData.length > 0 ? (
        <Box
          sx={{
            display: "flex",
            gap: 2,
            justifyContent: "center",
            pl: "12%",
            alignItems: "baseline",
          }}
        >
          <Box sx={{ alignItems: "baseline", display: "flex" }}>
            <MediumText>Summe ({shoppingCartSize} Artikel)</MediumText>
          </Box>
          <Box sx={{ display: "flex", gap: 1, alignItems: "baseline" }}>
            <LargeText fWeight="bold">{numberFormatter.format(Number(shoppingCartValue))}</LargeText>
            <MediumText color="#D0C2A8">Inkl. Mwst</MediumText>
          </Box>
        </Box>
      ) : null}
    </Box>
  );
}
