import { Backdrop, Box, Button, Grow, Modal, Paper, Theme } from "@mui/material";
import { MediumText } from "components/typography";
import { LotOfferLocationInterface } from "interfaces/LotOfferLocationInterface";
import styles from "./QuantityRestriction.module.css";

interface Props {
  checked: boolean;
  handleClose: Function;
  text: string;
  onClickEvent: Function;
}


export default function QuantityRestriction(props: Props) {
  const { checked, handleClose, text, onClickEvent } = props;
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          // width: "55%",
          width: "inherit",
          top: "40%",
          position: "fixed",
          left: "50%",
          marginLeft: "-37.5%",
        }}
      >
        <Grow style={{ width: "80%", borderRadius: 16 }} in={checked}>
          <Paper sx={{ m: 1 }} elevation={4}>
            <Box className={styles.modalBox}  display={"flex"} gap={4} sx={{ m: 6 }}>
              <Box >
                <MediumText fWeight="bold">
                  {text}
                </MediumText>
                {/* <Box
                  component="polygon"
                  sx={{
                    fill: (theme: Theme) => theme.palette.common.white,
                    stroke: (theme) => theme.palette.divider,
                    strokeWidth: 1,
                  }}
                /> */}
              </Box>
              <Button onClick={() => { onClickEvent() }}>
                Ok
              </Button>
            </Box>
          </Paper>
        </Grow>
      </Box>
    </Box>

  )

}