import { Box } from "@mui/material";
import { LotImagesInterface, LotImagesType } from "interfaces/LotImagesInterface";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import styles from "./LotOfferImages.module.css";
import { useAppDispatch } from "app/hooks";
import { setIdsToDelete } from "features/lotOffer/lotOfferSlice";


interface LotOfferImageProps {
  lotOfferImage: LotImagesType;
}

export default function LotOfferImage(props: LotOfferImageProps) {
  const dispatch = useAppDispatch();
  const deleteImage = (id: string) => {
    dispatch(setIdsToDelete(id))
  }
  const baseURL = process.env.REACT_APP_API_DOMAIN
  const url = typeof props.lotOfferImage === 'string' ? props.lotOfferImage : baseURL + props.lotOfferImage.url;
  const id = typeof props.lotOfferImage === "string" ? url : props.lotOfferImage.id;
  return (
    <Box>
      <Box
        component="img"
        src={url}
        sx={{
          width: "100%",
          aspectRatio: "16/9",
          objectFit: "cover",
        }}
      />
        <div className={styles.imageIcons}>
          <DeleteOutlineOutlinedIcon
            onClick={() => {deleteImage(id)}}
          />
        </div>
    </Box>

  );
}
