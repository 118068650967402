import axiosInstance from "app/axios";
import { AxiosResponse } from "axios";
import { SellerInterface } from "interfaces/Seller";
import { UserRoles } from "interfaces/UserRoles";

export interface fetchSellerArgument {
  id: string;
  endpoint?: UserRoles;
  isFetchingCustomers?: any
}

export interface SellerResponseInterface {
  data: SellerInterface;
}


export function fetchSeller({ id, endpoint}: fetchSellerArgument): Promise<AxiosResponse<SellerResponseInterface, any>> {
  return axiosInstance.get(`${endpoint}/sellers/${id}`);
}

// export function fetchSeller({ id, endpoint  }: fetchSellerArgument): Promise<AxiosResponse<SellerResponseInterface, any>> {
//   return axiosInstance.get(`${endpoint}/sellers/${id}`);
// }



// export function fetchSeller({ id, endpoint, isFetchingCustomers }: fetchSellerArgument): Promise<AxiosResponse<SellerResponseInterface, any>> {
//   let apiUrl;

//   if (endpoint === UserRoles.Agent) {
//     const currentURL = window.location.href;
// const isFetchingCustomers = currentURL.includes("agent/kundenliste");
//     if (isFetchingCustomers) {
//       apiUrl = `https://api.holzfinder.cortona.de/agent/customers/${id}`;
      
//     } else {
//       apiUrl = `https://api.holzfinder.cortona.de/agent/sellers/${id}`;
      
//     }
//   } else if (endpoint === UserRoles.Admin) {
//     apiUrl = `${endpoint}/sellers/${id}`;
//   } else {
//     // Handle the case when 'endpoint' is neither 'Agent' nor 'Admin'
//     return Promise.reject(new Error('Invalid endpoint'));
//   }

//   return axiosInstance.get(apiUrl);
// }
