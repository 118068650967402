import Grid2 from "@mui/material/Unstable_Grid2";
import ColoredBox from "components/colored-box/ColoredBox";
import { LargeText, MediumLargeText, XXLargeText } from "components/typography";
import styles from "./Homepage.module.css";
import infoBoxStyles from "./InfoBox.module.css";


export default function InfoBox() {
  return (
    <Grid2 className={`${infoBoxStyles.infoBox} `} xs={12} lg={6}>
      <ColoredBox backgroundColor="#072D26" coloredBoxPadding="32px">
        <div className={`${infoBoxStyles.coloredBoxBanner} ${styles.smartphoneContainer}`}>
          <XXLargeText whiteSpace={"pre-line"} color="#FFFFFF" fWeight={"bold"}>
            Holz direkt aus der Region
          </XXLargeText>
          <MediumLargeText color="#FFFFFF">Waldbesitzende aus Baden-Württemberg bieten dir hier Brennholz aus nachhaltig bewirtschafteten Wäldern zum Kauf an. Wähle frei aus diesem Angebot aus und stelle deine Brennholzmenge selbst zusammen.</MediumLargeText>
        </div>
      </ColoredBox>
      <div className={`${infoBoxStyles.coloredBoxBannerLightGreen} ${styles.smartphoneContainerLightGreen}`}>
        <ColoredBox backgroundColor="#C0D904" coloredBoxPadding="32px">
          <LargeText padding="0 0 0 40px" whiteSpace={"pre-line"} color="#072D26" fWeight={"bold"}>
            ZUR ZEIT GILT FÜR KÄUFER EINE MENGENBESCHRÄNKUNG BEI EINIGEN ANBIETERN
          </LargeText>
        </ColoredBox>
      </div>
    </Grid2>
  );
}
