import { Navigate } from "react-router-dom";
import { useAppSelector } from "app/hooks";
import SuccesfulOrder from "./SuccesfulOrder"
import { selectUserIsLoggedId } from "features/user/userSlice";

const OrderSuccessfulRoute = () => {
  const isLoggedIn = useAppSelector(selectUserIsLoggedId);

  return isLoggedIn ? <SuccesfulOrder /> : <Navigate to="/login" replace />;
};

export default OrderSuccessfulRoute;
