import axiosInstance from "app/axios";
import { AxiosResponse } from "axios";
import { LotOfferInterface } from "interfaces/LotOfferInterface";
import { UserRoles } from "interfaces/UserRoles";

export interface fetchLotOfferArgument {
  id: string;
  endpoint?: UserRoles;
}

export interface LotOfferResponseInterface {
  data: LotOfferInterface;
}


export function fetchLotOffer({ id,  endpoint = UserRoles.Admin }: fetchLotOfferArgument): Promise<AxiosResponse<LotOfferResponseInterface, any>> {
  return axiosInstance.get(`${endpoint}/lot_offers/${id}`);
}
// export function fetchLotOffer({ id, endpoint = UserRoles.Admin }: fetchLotOfferArgument): Promise<AxiosResponse<LotOfferResponseInterface, any>> {
//   return axiosInstance.get(`${endpoint}/lot_offers/${id}`);
// }

// export function fetchLotOffer({ id, endpoint }: fetchLotOfferArgument): Promise<AxiosResponse<LotOfferResponseInterface, any>> { 
//   let path;
//   if (endpoint !== undefined) {
//     if (endpoint === UserRoles.Admin) {
//       path = `${endpoint}/lot_offers/${id}`;
//     } else if (endpoint === UserRoles.Agent) {
//       path = `agent/lot_offers/${id}`;
//     } 
//       else if (endpoint === UserRoles.Customer) {
//       path = `lot_offers/${id}`;
//     } 
//     else {
//       throw new Error('Ungültig Endpoint');
//     }
//   } else {
//     throw new Error('Endpoint ist nicht definiert');
//   }
  
//   return axiosInstance.get(path);
// }



// return axiosInstance.get(`/lot_offers/${id}`);

export interface saveLotOfferArgument {
  id: string;
  lotOffer: LotOfferInterface;
  changedValues: (keyof LotOfferInterface)[];
  endpoint?: UserRoles;
}
export interface patchImages {
  imageId: string;
  endpoint?: UserRoles;
}

export function saveLotOffer({ id, lotOffer, changedValues, endpoint = UserRoles.Admin }: saveLotOfferArgument) {
  // Create payload
  const payload: any = {};
  changedValues.forEach((changedValue) => {
    // TODO: Need a way to access deeper things maybe

    if (changedValue === "images") {
      payload[changedValue] = lotOffer.images.filter((image) => typeof image === "string");
    } else {
      payload[changedValue] = lotOffer[changedValue];
    }
  });
  return axiosInstance.patch(`${endpoint}/lot_offers/${id}`, payload);
}

export function deleteImagesFromLotOffer({ imageId, endpoint = UserRoles.Agent }: patchImages) {
  // Create payload
  const payload: any = {};
  if (imageId) {
    payload["id"] = imageId;
    return axiosInstance.delete(`${endpoint}/attachments/${imageId}`, payload);
  }
}

export function deleteLotOffer({ id, userRole }: any) {
  const endpoint = userRole === 'admin' ? UserRoles.Admin : UserRoles.Agent;
  return axiosInstance.delete(`${endpoint}/lot_offers/${id}`);
}
