import { Typography } from "@mui/material";
import { useAppSelector } from "app/hooks";
import { selectLotOfferWoodPiles } from "features/lotOffer/lotOfferSlice";
import LotOfferData from "./LotOfferData";
import { WoodPileInterface } from "interfaces/WoodPile";
import LotOfferGrid from "./LotOfferGrid";

export function generateWoodPileLocationString(woodPile: WoodPileInterface) {
  return `${woodPile.latitude}, ${woodPile.longitude}`;
}

export default function LotOfferLocations() {
  const woodPiles = useAppSelector(selectLotOfferWoodPiles);
  return (
    <LotOfferGrid>
      <Typography
        fontWeight={"bold"}
        variant="h5"
        sx={{
          mt: 2,
          mb: 0.5,
          color: "#072D26",
        }}
      >
        LAGERORT
      </Typography>
      {woodPiles.map((woodPile, index) => (
        <LotOfferData
          key={woodPile.id}
          label={`Polter ${index + 1}`}
          data={generateWoodPileLocationString(woodPile)}
        />
      ))}
    </LotOfferGrid>
  );
}
