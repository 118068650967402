import { TextField, BaseTextFieldProps } from "@mui/material";
import { styled } from "@mui/material/styles";

// export interface HolzfinderInputProps extends BaseTextFieldProps {
//   onChange: any;
// }
interface BaseTextFieldPropsWithInputProps extends BaseTextFieldProps {
  InputProps?: any;
  onChange?: any;
  pattern?: any;
}

const CustomInput = styled(TextField)<BaseTextFieldPropsWithInputProps>(({ theme }) => ({
  borderRadius: "26px",
  backgroundColor: "white",
  ".MuiInputBase-root": {
    borderRadius: "26px",
  },
  "&:hover": {
    borderColor: "1px none transparent",
  },
}));

export default function HolzfinderInputKontakt(props: BaseTextFieldPropsWithInputProps) {
  return <CustomInput {...props} />;
}
