import { Checkbox, CheckboxProps, FormControlLabel, Tooltip } from "@mui/material";
import React from "react";

interface Props {
    checked: boolean;
    handleCheckBoxChange: (event: any) => void;
    disabled: boolean;
    isHeaderCheckbox: boolean;
}



export default function HolzfinderLotListCheckbox(props: Props) {
    const { checked, handleCheckBoxChange, disabled, isHeaderCheckbox } = props;

    return (
        <>
            {isHeaderCheckbox ? (
                <Tooltip title="Alle Lose auswählen" placement="bottom">
                    <FormControlLabel
                        control={
                            <Checkbox
                                inputProps={{ 'aria-label': 'controlled' }}
                                checked={checked}
                                disabled={disabled}
                                sx={{
                                    color: checked ? "#c0d904" : "#ccc", // adjust color of gray
                                    '&.Mui-checked': {
                                        color: "#c0d904",
                                    },
                                }}
                                onClick={handleCheckBoxChange}
                            />
                        }
                        label={undefined}
                    />
                </Tooltip>
            ) : (
                <>
                    {disabled ? (
                        <Tooltip title="Bereits verkauft" placement="bottom">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        inputProps={{ 'aria-label': 'controlled' }}
                                        disabled={true}
                                    />
                                }
                                label={undefined}
                            />
                        </Tooltip>
                    ) : (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    checked={checked}
                                    disabled={disabled}
                                    sx={{
                                        color: checked ? "#c0d904" : "#ccc", // Adjust the color based on checkbox state
                                        '&.Mui-checked': {
                                            color: "#c0d904",
                                        },
                                    }}
                                    onClick={handleCheckBoxChange}
                                />
                            }
                            label={undefined}
                        />
                    )}
                </>
            )}
        </>
    );
}
