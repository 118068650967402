// import { useForm } from "../../hooks/useForm";
import { Container, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import { ForgetPassword, LoginForm, ToRegister } from "./components";
import { LargeText } from "../../components/typography";
import Header from "components/header/Header";

// import { style } from "@mui/system";

export default function ForgetPass() {
  return (
    <Container
      maxWidth="xl"
      sx={{
        pt: 4,
        pb: 4,
        mb: 3,
        // ml: 4,
        mt: 5,
      }}
    >
      <Grid container>
        <Grid item xs={12} sx={{ mb: 3 }}>
          <Header header={"Passwort vergessen"} />
        </Grid>
        <Grid item xs={12} md={6}>
          <ForgetPassword />
        </Grid>
        <Grid item xs={12} md={6}>
          <LargeText
            color="#072D26"
            fWeight="bold"
            display="contents"
            mdml={10}
            mr={7}
          >
            SIE HABEN NOCH KEIN KONTO?
          </LargeText>
          <Link to="/registrierung" style={{ textDecoration: "none" }}>
            <ToRegister />
          </Link>
        </Grid>
      </Grid>
    </Container>
  );
}
