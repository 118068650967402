import { Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { getCustomer, selectCustomer, selectCustomersList } from "features/customer/customerSlice";
import { selectSeller } from "features/seller/sellerSlice";
import SellerData from "./CustomerData";
import SellerGrid from "./CustomerGrid";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
interface RouteParams {
  userId: string;
  customer_id?: string; // Include customer_id if it's part of your route
}
const MasterDataLabels = {
  // company: "Firma",
  last_name: "Nachname",
  first_name: "Vorname",
  street: "Straße",
  street_number: "Hausnummer",
  zip_code: "Postleitzahl",
  po_box: "Postbox",
  city: "Ort",
  country_name: "Land",
};


export default function CustomerMasterData(props: any) {
  // const customerData = useAppSelector(selectCustomer);
  const selectCustomersLists = useAppSelector(selectCustomersList);
  const { customer_id } = useParams<{ customer_id: string }>();
  const customerDatas = useAppSelector((state) => state.customerSettings);
  const userIdFromRedux = useAppSelector((state) => state.customerSettings.customer?.id);

  // console.log(customerDatas.customer?.id)
  // console.log(customerDatas.customers)
  // const dispatch = useAppDispatch();
  // useEffect(() => {
  //   // Check if the user's role is "agent" before dispatching the action.
  //   if (userRole === 'agent' && userId) {
  //     dispatch(getCustomer({ id: userId }));
  //   }
  // }, [userRole, userId]);

  const customersData = selectCustomersLists?.find((c: any) => c.id === customer_id || c.id === userIdFromRedux);
  return (
    <SellerGrid>
      <Typography
        fontWeight={"bold"}
        variant="h5"
        sx={{
          mt: 2,
          mb: 0.5,
          color: "#072D26",
        }}
      >
        Kunden Stammdaten
      </Typography>
      {/* {
        customerDatas?.company ? (
          <SellerData
            label={MasterDataLabels.company}
            data={customerDatas?.company}
          />
        ) : (
          <></>
        )
      } */}
      {
        customersData?.last_name ? (
          <SellerData
            label={MasterDataLabels.last_name}
            data={customersData?.last_name}
          />
        ) : (
          <></>
        )
      }
      {
        customersData?.first_name ? (
          <SellerData
            label={MasterDataLabels.first_name}
            data={customersData?.first_name}
          />
        ) : (
          <></>
        )
      }
      {
        customersData?.street ? (
          <SellerData
            label={MasterDataLabels.street}
            data={customersData?.street}
          />
        ) : (
          <></>
        )
      }
      {
        customersData?.street_number ? (
          <SellerData
            label={MasterDataLabels.street_number}
            data={customersData?.street_number}
          />
        ) : (
          <></>
        )
      }
      {
        customersData?.zip_code ? (
          <SellerData
            label={MasterDataLabels.zip_code}
            data={customersData?.zip_code}
          />
        ) : (
          <></>
        )
      }
      {
        customersData?.city ? (
          <SellerData
            label={MasterDataLabels.city}
            data={customersData?.city}
          />
        ) : (
          <></>
        )
      }
      {
        customersData?.country_name ? (
          <SellerData
            label={MasterDataLabels.country_name}
            data={customersData?.country_name}
          />
        ) : (
          <></>
        )
      }

      {
        customersData?.po_box ? (
          <SellerData
            label={MasterDataLabels.po_box}
            data={customersData?.po_box}
          />
        ) : (
          <></>
        )
      }
    </SellerGrid>
  );
}
