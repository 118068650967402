import { Container, Grid, Alert } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { LoginForm, ToRegister } from "./components";
import { LargeText } from "../../components/typography";
import Header from "components/header/Header";
import { selectPermissionsMissing, selectSessionGone, selectUserIsLoggedId } from "features/user/userSlice";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { useEffect } from "react";
import { clearShoppingCart } from "features/shoppingCart/shoppingCartSlice";


export default function Login() {
  const sessionGone = useAppSelector(selectSessionGone);
  const permissionsMissing = useAppSelector(selectPermissionsMissing);
  const { state } = useLocation();
  const { successMessage } = state || {};

  const dispatch = useAppDispatch();
  const isLoggedIn = useAppSelector(selectUserIsLoggedId);
  // useEffect(() => {
  //   if (!isLoggedIn) {
  //     dispatch(clearShoppingCart());
  //   }
  // }, []);

  // console.log(permissionsMissing)
  return (
    <Container
      maxWidth="xl"
      sx={{
        pt: 4,
        pb: 4,
        mb: 3,
        mt: 5,
      }}
    >
      {sessionGone ? <Alert
        severity="warning"
        sx={{
          borderRadius: 5,
          paddingX: 5,
          marginTop: -4,
          marginBottom: 3
        }}
      >
        Ihre Session ist abgelaufen!
      </Alert> : null}
      {permissionsMissing ? <Alert
        severity="warning"
        sx={{
          borderRadius: 5,
          paddingX: 5,
          marginTop: -4,
          marginBottom: 3
        }}
      >
        Bitte loggen Sie sich ein.
      </Alert> : null}
      {successMessage?.length > 0 ? <Alert severity="success" sx={{
        borderRadius: 5,
        paddingX: 5,
        marginTop: -4,
        marginBottom: 3,
      }}>{successMessage}</Alert> : null}
      <Grid container>
        <Grid item xs={12} sx={{ mb: 3 }}>
          <Header header={"Login"} />
        </Grid>
        <Grid item xs={12} md={6} >
          <LoginForm />
        </Grid>
        <Grid item xs={12} md={6}>
          <LargeText
            color="#072D26"
            fWeight="bold"
            display="contents"
            mdml={10}
            mr={7}
          >
            SIE HABEN NOCH KEIN KONTO?
          </LargeText>
          <Link to="/registrierung" style={{ textDecoration: "none", borderRadius: "100%" }}>
            <ToRegister />
          </Link>
        </Grid>
      </Grid>

    </Container>
  );
}
