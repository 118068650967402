import * as React from "react";
import { SVGProps } from "react";
const SvgDeleteSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.73 12.73" {...props}>
    <path
      data-name="Vereinigungsmenge 47"
      d="M2.475 8.84 4.95 6.363 2.475 3.89l1.414-1.415L6.364 4.95l2.475-2.475 1.414 1.415-2.475 2.474 2.475 2.475-1.414 1.415-2.475-2.475-2.475 2.475Z"
    />
  </svg>
);
export default SvgDeleteSmall;
