import React, { useEffect, useState } from "react";
import HolzfinderInput from "./HolzfinderInput";
import { DesktopDateTimePicker } from "@mui/x-date-pickers";
import { deDE } from '@mui/x-date-pickers/locales';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DateTime } from "luxon";
import { Alert } from "@mui/material";

export default function HolzfinderDateInput({ value, onChange, disablePast }: { value: string; onChange: (value: string) => void; disablePast: boolean }) {
  const [editingValue, setEditingValue] = useState<DateTime | null>(
    value ? DateTime.fromISO(value) : DateTime.now()
  );
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const formattedValue = editingValue?.toFormat("dd-MM-yyyy HH:mm") ?? "";
    onChange(formattedValue);
  }, [editingValue, onChange]);

  const handleTimePickerInput = (newValue: DateTime | null) => {
    if (newValue) {
      const formattedValue = newValue.toFormat("dd-MM-yyyy HH:mm");
      if (!newValue.isValid) {
        setError("Bitte formatieren Sie das Datum wie folgt: dd/mm/yyyy hh/mm");
        return

      }
      if (newValue < DateTime.now() && disablePast) {
        setError("Das Datum darf nicht in der Vergangenheit liegen.");
        return
      }
      else {
        setError(null);
        onChange(formattedValue);
      }
      setEditingValue(newValue);

    }
  };

  const handleBlur = (newValue: DateTime | null) => {
    if (newValue) {
      const formattedValue = newValue.toFormat("dd-MM-yyyy HH:mm");
      onChange(formattedValue);
    }
  };

  return (
    <LocalizationProvider localeText={deDE.components.MuiLocalizationProvider.defaultProps.localeText} dateAdapter={AdapterLuxon} adapterLocale="de-DE">
      {error && <Alert severity="error">{error}</Alert>}
      <DesktopDateTimePicker

        inputFormat="dd-MM-yyyy HH:mm"
        ampm={false}
        disablePast={disablePast}
        onChange={handleTimePickerInput}
        value={editingValue}
        onAccept={handleBlur}
        renderInput={(params) => <HolzfinderInput {...params} />}
      />
    </LocalizationProvider>
  );
}
