import { FormControlLabel, IconButton } from '@mui/material';
import {ShowPasswordOff } from 'icons';

export default function ShowPassword(props?:any){
  const { onClick } = props
  return(
    <FormControlLabel 
      control={
        <IconButton onClick={onClick}>
          <ShowPasswordOff />
        </IconButton>
      }
      label="Passwort anzeigen"
    />
  )
}