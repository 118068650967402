import React from "react";
import { Tabs } from "@mui/material";
import LotListTab from "./LotListTab";
type TabInfo = {
  name: string;
  color: string;
}


type LotListTabsProps = {
  activeTab: number;
  handleTabChange: (event: React.SyntheticEvent, newValue: number) => void;
  tabInfo: TabInfo[];
};

function LotListTabs({
  activeTab,
  handleTabChange,
  tabInfo
}: LotListTabsProps) {
  return (
    <Tabs
      onChange={handleTabChange}
      value={activeTab}
      //@ts-expect-error indicatorColor={''}
      indicatorColor={""}
      sx={{ 
        border: "none",

      }}
    >
      {tabInfo.map((tabInfo) => (
        <LotListTab key={tabInfo.name} label={tabInfo.name} backgroundcolor={tabInfo.color}/>
      ))}
    </Tabs>
  );
}

export default LotListTabs;
