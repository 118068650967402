import axiosInstance from "app/axios";
import { AxiosResponse } from "axios";
import { UpdateEmailInformation } from "./userSlice";


export interface NewPasswordResponseInterface {
  data?: {
    errors: {
      password?: string[],
      password_confirmation?: string[]
    }
  };
}

export interface updateEmailInterface {
  updateEmailData: UpdateEmailInformation;
}

export function saveNewEmail({updateEmailData}: updateEmailInterface): Promise<AxiosResponse<NewPasswordResponseInterface, any>> {
  return axiosInstance.patch("user/change_email", updateEmailData);
}
