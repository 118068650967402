import { Paper, Box, IconButton, SvgIcon, Button, Backdrop, Modal } from "@mui/material";
import { LargeText, MediumText, XXLargeText } from "components/typography";
import { Tree, OpenWith, Sell, MyLocation, AddShoppingCart, AccountCircle } from "icons";
import TextWithIcon from "./TextWithIcon";
import { LotOfferLocationInterface } from "interfaces/LotOfferLocationInterface";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { selectUserIsLoggedId, updateAfterLoginTo } from "features/user/userSlice";
import { setShoppingCart, selectShoppingCartIds, checkShoppingCart } from "features/shoppingCart/shoppingCartSlice";
import { useState } from "react";
import OverlayCard from "views/lossuche/OverlayCard";

interface InformationenProps {
  lotInformation: LotOfferLocationInterface;
}
export default function HolzfinderInfoDetail(props: InformationenProps) {
  const { lotInformation } = props;
  const isLoggedIn = useAppSelector(selectUserIsLoggedId);
  const dispatch = useAppDispatch();
  const { seller, agent, max_wood_per_period, period_duration, wood_types, distance, login_and_validation_required, solid_cubic_meters_without_bark, cubic_meters_without_bark, price_per_solid_cubic_meter, price_total, vat_rate } = lotInformation;
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const addToCart = (event: any, lotData: LotOfferLocationInterface) => {
    dispatch(checkShoppingCart([lotData.id])).then(() => {
      dispatch(setShoppingCart(lotData));
    });
    event?.preventDefault();
    event?.stopPropagation();
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const shoppingCartIds = useAppSelector(selectShoppingCartIds);
  let isInShoppingCart = shoppingCartIds.includes(lotInformation.id);

  const baseURL = process.env.REACT_APP_API_DOMAIN;
  function clickPaper() {
    navigate(`${lotInformation.id}`);
  }

  return (
    <Paper onClick={clickPaper} sx={{ boxShadow: "none", pl: { xs: 0, md: 2 }, width: "inherit", maxWidth: "100%" }}>
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          borderRadius: "0px",
          boxShadow: "none",
          justifyContent: "space-between",
          padding: 1,
          "&::after": {
            height: "3px",
            border: "1px solid",
          },
        }}
      >
        <Box sx={{ display: "flex" }}>
          <Box sx={{ mt: "3%", display: "flex", gap: 1, flexDirection: "column", width: "50%" }}>
            <TextWithIcon icon={<Tree />} text={wood_types.join(",")} />
            {distance ? <TextWithIcon icon={<MyLocation />} text={`${(distance / 1000).toFixed(2).replace('.', ',')} km entfernt`} /> : <></>}
          </Box>
          <Box sx={{ mt: "3%", display: "flex", gap: 1, flexDirection: "column", width: "50%" }}>
            <Box>
              <TextWithIcon icon={<OpenWith />} text={`${solid_cubic_meters_without_bark.toFixed(2).replace('.', ',')} Fm `} text2={`/ ~ ${cubic_meters_without_bark.toFixed(2)} rm `} />
            </Box>
            <TextWithIcon icon={<Sell />} text={`${price_per_solid_cubic_meter} € / Fm`} />
          </Box>
        </Box>
      </Paper>
      <Paper sx={{ boxShadow: "none" }}>
        <Box sx={{ backgroundColor: "#F6F3EE", padding: "16px 16px 16px 16px" }}>
          {login_and_validation_required && !isLoggedIn ? (
            <Box>
              <MediumText>
                Der Verkauf erfolgt durch <b>{agent}</b>. Im Auftrag von <b>{seller}</b>.
              </MediumText>
              <MediumText>
                {agent} erlaubt den Erwerb von {max_wood_per_period} fm / {period_duration}
                je registriertem Nutzer. Sie müssen sich deshalb einloggen, um dieses Los zu kaufen.
              </MediumText>
            </Box>
          ) : (
            <MediumText>
              Von <b>{seller}</b> durch <b>{agent}</b>.
            </MediumText>
          )}
        </Box>
      </Paper>
      <Box sx={{ "&::after": { content: '" "', display: "block", maxWidth: "inherit", width: "100%", height: "1px", borderBottom: "1px solid #403521", margin: "auto" } }}>
        <Box sx={{ display: "flex", width: "100%", gap: 1, flexDirection: "column", my: 2, p: 1 }}>
          <Box sx={{ my: 1, display: "flex", alignItems: "baseline", width: "inherit", justifyContent: "space-between" }}>
            <Box sx={{ display: "flex", alignItems: "baseline", width: "inherit", gap: 1 }}>
              <XXLargeText fWeight="bold" color="#072D26">
                {Number(price_total)
                  .toFixed(2)
                  .replace(".", ",")
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") + " €"}
              </XXLargeText>
              <LargeText color="#D0C2A8" float="right" whiteSpace="pre">
                Inkl. {vat_rate}% MwSt.
              </LargeText>
            </Box>
          </Box>
          <Box sx={{ position: "relative", bottom: "2px", width: "100%", top: "5px", display: "flex", justifyContent: "center" }}>
            {login_and_validation_required && !isLoggedIn ? (
              <Button
                sx={{
                  borderRadius: "30px",
                  backgroundColor: "#D9E868",
                  color: "#000000",
                  "&:hover": { backgroundColor: "#D9E868" },
                  padding: "12px 24px",
                  gap: 1, width: "100%"
                }}
                onClick={(event) => {
                  dispatch(updateAfterLoginTo("/lossuche"))
                  navigate("/login");
                  event.stopPropagation();
                }}
              >

                <MediumText fWeight="bold">Login</MediumText>
                <SvgIcon>
                  <AccountCircle />
                </SvgIcon>
              </Button>
            ) : isInShoppingCart ? (
              <></>
            ) : (
              <IconButton
                sx={{ float: "right", backgroundColor: "#D9E868", borderRadius: "26px", color: "black", width: "100%" }}
                onClick={(event) => {
                  handleOpen();
                  addToCart(event, lotInformation);
                  event.stopPropagation();
                  event.preventDefault();
                }}
              >
                <MediumText color="#072D26" fWeight="bold">
                  In den Einkaufswagen
                </MediumText>
                {/* <Link to={`/lossuche/${lotInformation.id}`}> */}
                <SvgIcon>
                  <AddShoppingCart />
                </SvgIcon>
                {/* </Link> */}
              </IconButton>
              //   <Button
              //   sx={{borderRadius: "50%", backgroundColor: "#D9E868", color: "#000000", "&:hover":{backgroundColor: "#D9E868"}, minWidth: "20px", padding: 1}}
              //   onClick={(event) => {
              //     handleOpen();
              //     addIdsToCart(event, lotInformation.id)
              //     addToCart(event, lotInformation)
              //     event.stopPropagation();
              //     event.preventDefault();
              //   }}
              //   >
              //   <SvgIcon>
              //     <AddShoppingCart />
              //   </SvgIcon>
              // </Button>
            )}
          </Box>
        </Box>
      </Box>
      <Backdrop sx={{ backgroundColor: "#grey", zIndex: 1200 }} open={open} onClick={handleClose}>
        <Modal hideBackdrop open={open} onClose={handleClose} aria-labelledby="modal-title">
          <OverlayCard checked={open} handleClose={handleClose} imageUrl={baseURL + lotInformation.image_urls[0]} lotData={lotInformation} />
        </Modal>
      </Backdrop>
    </Paper>
  );
}
