
import { useAppDispatch, useAppSelector } from "app/hooks";
import { logout, selectUserIsLoggedId, selectUserRole, updatePermissionsMissing } from "features/user/userSlice";
import React from "react";
import { Navigate } from "react-router-dom";
import { UserRoles } from "interfaces/UserRoles";
import Agent from "views/agent/Agent";
import Admin from "views/admin/Admin";
import Customer from "views/customer/Customer";
import CustomerInvoicesList from "views/customer/customerInvoices/CustomerInvoicesList";


const withAuth = (Component: React.FC,  route: string ) => {
  return () => {
    const userRole = useAppSelector(selectUserRole) as UserRoles;
    const dispatch = useAppDispatch();
    const isLoggedIn = useAppSelector(selectUserIsLoggedId)

    const permissionsMissing = () => {
      if(isLoggedIn) dispatch(logout)
      dispatch(updatePermissionsMissing(true))
      return <Navigate to="/login" />;
    }
    const permissionsCorrect = () => {
      dispatch(updatePermissionsMissing(false))
      return <Component />;
    }
    switch(route){
      case "admin":
        if (userRole !== "admin"){
          return permissionsMissing();
        }
        return permissionsCorrect();
      case "agent":
        if (userRole !== "admin" && userRole !== "agent"){
          return permissionsMissing();
        }
        return permissionsCorrect();
      case "customer":
        if (userRole !== "admin" && userRole !== "agent" && userRole !== "customer"){
          return permissionsMissing();
        }
        return permissionsCorrect();
      case "invoices":
        if (userRole === "customer"){
          return permissionsCorrect();
        }
        return <Navigate to="/" />;
      default:
        return permissionsMissing();
    }
  };
};



export const AdminWithAuth = withAuth(Admin, "admin");
export const AgentWithAuth = withAuth(Agent, "agent");
export const CustomerWithAuth = withAuth(Customer, "customer");
export const CustomerInvoicesListWithAuth = withAuth(CustomerInvoicesList, "invoices");