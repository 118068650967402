import { Box, ListItem, Paper, TextField } from "@mui/material";
import styles from "../Lossuche.module.css";
import HolzpolterInfo from "./HolzpolterInfo";
import "leaflet/dist/leaflet.css";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  selectLotLocations,
  setHighlightedMarkerLocation,
  clearHighlightMarkerLocation,
  selectLotLocationsFiltered,
} from "features/lotLocations/lotLocationsSlice";
import { selectUserIsLoggedId } from "features/user/userSlice";
import { Height, InsertChartOutlinedRounded } from "@mui/icons-material";
import { clearClickedMarker, selectClickedMarker } from "features/lotOffer/lotOfferSlice";
import holzfinderDefaultLotImage from "assets/images/Holzfinder_Los_Platzhalter.jpg"
import { useNavigate } from "react-router-dom";
import { useEffect, useRef } from "react";

export default function LosSuche() {

  const lotLocationOffers = useAppSelector(selectLotLocationsFiltered);
  const clickMarker = useAppSelector(selectClickedMarker);
  const selectedIndex = clickMarker ? lotLocationOffers.findIndex(el => el.id == clickMarker.id) : -1;
  const navigate = useNavigate();
  const baseURL = process.env.REACT_APP_API_DOMAIN;
  const isLoggedIn = useAppSelector(selectUserIsLoggedId);
  const dispatch = useAppDispatch();
  const highlightMarker = (data: any) => {
    dispatch(setHighlightedMarkerLocation(data[0]));
    // console.log(data[0])
  };

  const clearHighlightMarker = () => {
    dispatch(clearClickedMarker());
    dispatch(clearHighlightMarkerLocation());
  };

  function handleContainerClick(lotId: string) {
    navigate(`/lossuche/${lotId}`);
  }
  const scrollToRef = useRef<HTMLElement | null>(null);

  // console.log(lotLocationOffers)

  const inputElement = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputElement.current) {
      inputElement.current.focus();
    }
  }, []);
  useEffect(() => {
    if (selectedIndex >= 0 && scrollToRef.current) {
      scrollToRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",

      });
    }
  }, [selectedIndex]);


  useEffect(() => {
    if (inputElement.current) {
      inputElement.current.focus();
    }
  }, []);

  return (
    <Box
      sx={{
        alignSelf: "start",
        maxHeight: "100%",
        height: "100vh",
        overflow: "hidden",
        width: "50%",
        display: { xs: "none", md: "block" },
      }}
    >
      <Box
        className={styles.scrollStyle}
        sx={{
          overflowY: "auto",
          height: "100%",
        }}
      >
        {lotLocationOffers.map((lotLocationOffer, index) => {
          const isCentered = selectedIndex === index;

          return (
            <Box
              key={index}
              ref={isCentered ? scrollToRef : null}
              sx={{
                mt: "10px",
                padding: "15px 15px",
                cursor: "pointer",
              }}
              onMouseEnter={() => {
                highlightMarker(lotLocationOffer.wood_piles);
              }}
              onMouseLeave={() => {
                clearHighlightMarker();
              }}
              onClick={() => {
                handleContainerClick(lotLocationOffer.id);
                // console.log('info')
              }}
            >
              {(isCentered && selectedIndex === index) && (
                <Box sx={{ height: 0, width: 0, overflow: "hidden" }}>
                  <TextField autoFocus={true} className={styles.display} />
                </Box>
              )}

              <Paper
                className={styles.lossucheContainer}
                sx={{
                  overflow: "hidden",
                  borderColor: "#072C25",
                  borderStyle: "solid",
                  borderWidth: isCentered ? 3 : 0,
                }}
              >
                <Box component="div" className={styles.lossucheImage}>
                  <Box
                    component="img"
                    sx={{
                      aspectRatio: "4/3",
                      objectFit: "cover",
                      borderRadius: "30px 0px 0px 30px",
                      width: "100%",
                      overflow: "hidden",
                      transition: "transform 0.4s",
                      transformOrigin: "50% 50%",
                      "&:hover": {
                        transform: "scale(1.05)",
                      },
                    }}
                    alt="image"
                    src={lotLocationOffer.image_urls[0] ? baseURL + lotLocationOffer.image_urls[0] : holzfinderDefaultLotImage}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flex: "2 1 0px",
                    width: "auto",
                    borderRadius: "0px 30px 30px 0px !important",
                  }}
                >
                  <HolzpolterInfo
                    lotImage={lotLocationOffer.image_urls[0] ? baseURL + lotLocationOffer.image_urls[0] : holzfinderDefaultLotImage}
                    lotInformation={lotLocationOffer}
                    loginNeeded={lotLocationOffer.login_and_validation_required}
                  />
                </Box>
              </Paper>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}