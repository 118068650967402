import { ThemeProvider, StyledEngineProvider } from "@mui/material";
import createTheme from "@mui/material/styles/createTheme";
import "assets/fonts/DMSans-Regular.ttf";
const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: `
      @font-face {
        font-family: 'DMSans';
        src: url("./assets/fonts/DMSans-Regular.ttf") format('truetype');
        font-style: normal;
        font-weight: 400;
      }
      `,
    },
  },
  typography: {
    allVariants: {
      fontFamily: "DMSans",
    },
  },
  palette: {
    primary: {
      main: "#c0d904",
    },
    secondary: {
      main: "#072d26",
    },
    text: {
      primary: "#000000",
      secondary: "#00000",
    },
  },
  tabs: {
    inactive: "#f6f3ee",
    active: "#ffffff",
  },
});

export default function HolzfinderThemeProvider({
  children,
}: {
  children: any;
}) {
  return (
    <ThemeProvider theme={theme}>
      <StyledEngineProvider>{children}</StyledEngineProvider>
    </ThemeProvider>
  );
}
