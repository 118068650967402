import { ReactNode } from "react";
import { Typography } from "@mui/material";

interface Props {
  children?: ReactNode,
  color?: String,
  fWeight?: String
  mx?: any,
  my?: any;
  whiteSpace?: string,
  // any props that come into the component
}

export default function MediumLargeText({ children, color, fWeight, mx, my, whiteSpace }: Props) {
  return (
    <Typography 
    textAlign="center"
    sx={{
      fontFamily: 'DMSans',
      my: my,
      mx: mx,
      color: `${color}`,
      fontWeight: `${fWeight}`,
      textDecoration: "none",
      fontSize: {xs: 15, md: 18},
      textAlign: 'start',
      whiteSpace: `${whiteSpace}`
    }}
    >
      {children}
    </Typography>
  );
}
