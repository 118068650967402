import {
  FormControl,
  TextField,
  Box,
  Link,
  Button,
  Alert,
} from "@mui/material";
import styles from "../Login.module.css";
import { MediumText } from "../../../components/typography";
import { useAppDispatch } from "app/hooks";
import { resetPassword, updateRequestEmail } from "features/user/userSlice";
import { useState } from "react";


export default function ForgetPassword() {
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState("");
  const [sendEmail, setSendEmail] = useState(false);

  return (
    <Box>
      <FormControl
        sx={{
          width: { lg: "60%", md: "80%", xs: "100%" },
          marginRight: { xs: 1, md: 6 },
        }}
        className={styles.form}
      >
        <MediumText>E-Mail</MediumText>
        <TextField
          value={email}
          type="email"
          placeholder="E-Mail"
          className={styles.input}
          onChange={(event: any) => { setEmail(event.target.value) }}
          name={"email"}
        />

        <div>
          <Button
            sx={{
              color: "#072D26",
              my: 2,
              backgroundColor: "#C4D661",
              borderColor: "#C4D661",
              borderRadius: 30,
              fontSize: "small",
              paddingY: 1.5,
              paddingX: 5,
              "&:hover": {
                backgroundColor: "#C0D904",
              },
            }}
            className={`${styles.roundInputs} ${styles.sizeInputs}`}
            variant="outlined"
            onClick={() => {
              dispatch(resetPassword(email)).then((ev: any) => {
                setEmail("")
                setSendEmail(true)
              })
            }}
          >
            PASSWORT ÄNDERN
          </Button>
        </div>
        {sendEmail ? <Alert severity="success" sx={{ overflow: "auto" }}> <Box sx={{ padding: 1 }}> Falls diese Email im System hinterlegt ist, erhalten Sie in wenigen Minuten eine Nachricht mit den Anweisungen zum Einrichten des neuen Passworts.</Box> </Alert> : null}
      </FormControl>
    </Box>
  );
}
