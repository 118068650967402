import * as React from "react";
const SVGReport = (props: any) => (
  <svg
    id="Ebene_1"
    data-name="Ebene 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 532 532"
    {...props}
  >
    <defs>
      <style>{"\n      .cls-1 {\n        fill: #fff;\n      }\n    "}</style>
    </defs>
    <path d="m487.01,0H44.99C20.14,0,0,20.14,0,44.99v442.01c0,24.85,20.14,44.99,44.99,44.99h442.01c24.85,0,44.99-20.14,44.99-44.99V44.99c0-24.85-20.14-44.99-44.99-44.99Zm.77,487.77H44.23V44.23h443.55v443.55Z" />
    <rect x={169.94} y={39.3} width={29.56} height={453.19} />
    <rect x={332.5} y={39.3} width={29.56} height={453.19} />
    <rect
      x={251.22}
      y={-41.98}
      width={29.56}
      height={453.19}
      transform="translate(450.62 -81.38) rotate(90)"
    />
    <rect
      x={251.22}
      y={120.58}
      width={29.56}
      height={453.19}
      transform="translate(613.17 81.17) rotate(90)"
    />
    <circle cx={347.28} cy={347.17} r={125.61} />
    <g>
      <rect
        className="cls-1"
        x={250.04}
        y={332.4}
        width={156.64}
        height={29.56}
      />
      <polygon
        className="cls-1"
        points="376.3 416.26 355.4 395.37 403.49 347.28 355.18 298.98 376.08 278.08 445.28 347.28 376.3 416.26"
      />
    </g>
  </svg>
);
export default SVGReport;
