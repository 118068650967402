import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
} from "@reduxjs/toolkit";
import counterReducer from "../features/counter/counterSlice";
import userReducer from "../features/user/userSlice";
import lotListReducer from "features/lotlist/lotListSlice";
import lotOfferReducer from "features/lotOffer/lotOfferSlice";
import lotLocationsReducer from "features/lotLocations/lotLocationsSlice";
import shoppingCartReducer from "features/shoppingCart/shoppingCartSlice";
import customerReducer from "features/customer/customerSlice";
import agentListReducer from "features/agentList/agentListSlice";
import sellersListReducer from "features/sellersList/sellersListSlice";
import sellerReducer from "features/seller/sellerSlice";
import agentReducer from "features/agent/agentSlice";
import tableReducer from "features/table/tableSlice";
import filterAndSortReducer from 'features/lotlist/filterAndSortSlice'
// Redux Persist
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";

import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  blacklist: ['agent']
};
const agentPersistConfig = {
  key: "agent",
  version: 1,
  storage,
  blacklist: ['registered']
}

const reducers = combineReducers({
  counter: counterReducer,
  user: userReducer,
  lotList: lotListReducer,
  lotOffer: lotOfferReducer,
  lotLocations: lotLocationsReducer,
  shoppingCart: shoppingCartReducer,
  customerSettings: customerReducer,
  agentList: agentListReducer,
  sellersList: sellersListReducer,
  seller: sellerReducer,
  agent: persistReducer(agentPersistConfig, agentReducer),
  table: tableReducer,
  filterAndSort: filterAndSortReducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});
export const persistore = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
