import Grid2 from "@mui/material/Unstable_Grid2";
import SideBarNavigation from "components/side-bar-navigation/SideBarNavigation";
import { Outlet } from "react-router-dom";

export default function Admin() {
  return (
    <Grid2>
      <SideBarNavigation />
      <Outlet />
    </Grid2>
  );
}
