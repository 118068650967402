import { IconButton, InputAdornment, SvgIcon, TextField } from "@mui/material";
import { useAppDispatch } from "app/hooks";
import { DeleteSmall, Search } from "icons";
import { useEffect, useState } from "react";

interface Props {
  page: number;
  rowsPerPage: number;
  tab?: number;
  getListType: Function
  status?: string | undefined;
  setSearchQuery?: Function;
  searchQuery?: string;
}



export default function SearchBar(props: Props) {
  let { page, rowsPerPage, tab, status, getListType, setSearchQuery, searchQuery } = props
  const dispatch = useAppDispatch();
  const [searchQueryCache, setSearchQueryCache] = useState("")

  const handleClearSearchQuery = () => {
    setSearchQuery?.("")
    setSearchQueryCache("")
  }

  return (
    <>
      <TextField
        name="search"
        sx={{ my: 2, width: "40%" }}
        placeholder="Suche"
        value={searchQueryCache ? searchQueryCache : ""}
        onChange={(event) => {
          setSearchQueryCache(event.target.value)
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            setSearchQuery?.(searchQueryCache)
          }
        }}
        InputProps={{
          endAdornment: (
            <>
              <InputAdornment position="end">
                <IconButton
                  aria-label="filter lot list by search"
                  onClick={() => setSearchQuery?.(searchQueryCache)}
                >
                  <SvgIcon>
                    <Search />
                  </SvgIcon>
                </IconButton>
              </InputAdornment>
              <InputAdornment position="end">
                <IconButton
                  aria-label="reset filter"
                  onClick={handleClearSearchQuery}
                >
                  <SvgIcon>
                    <DeleteSmall />
                  </SvgIcon>
                </IconButton>
              </InputAdornment>
            </>
          ),
        }}
      />
    </>
  )
}