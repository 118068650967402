import axiosInstance from "app/axios";
import { AxiosResponse } from "axios";
import { UpdatePasswordInformation } from "./userSlice";

export interface NewPasswordResponseInterface {
  data?: {
    errors: {
      password?: string[],
      password_confirmation?: string[]
    }
  };
}

export interface saveNewPasswordArgument {
  passwordData: UpdatePasswordInformation;
}

export function saveNewPassword({passwordData}: saveNewPasswordArgument): Promise<AxiosResponse<NewPasswordResponseInterface, any>> {
  return axiosInstance.patch("user/password", passwordData);
}
