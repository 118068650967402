import { Edit } from "@mui/icons-material";
import { Backdrop, Box, Button, Container, Grow, IconButton, InputAdornment, Modal, Select, SvgIcon, Switch, TextField, Tooltip, Typography } from "@mui/material";
import AskForConfirmation from "components/shoppingCart/AskForConfirmation";
import { useState } from "react";
import BulkEdit from "./BulkEdit";
import { func } from "prop-types";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { bulkEdit } from "features/lotlist/bulkEditSlice";
import { selectUserRole } from "features/user/userSlice";
import { getLotList } from "features/lotlist/lotListSlice";
import { XLargeText } from 'components/typography';

interface Props {
    checkedBoxes: string[]
    rowsPerPage: number
    page: number
    searchQuery?: string
    lotListTabNames: string[]
    sort: string
    tab: number
}

export default function BulkEditModal(props: Props) {

    const { checkedBoxes, rowsPerPage, page, searchQuery, lotListTabNames, sort, tab } = props
    const [open, setOpen] = useState(false)
    const [anyCheckboxSelected, setAnyCheckboxSelected] = useState(false);

    const dispatch = useAppDispatch();
    const userRole = useAppSelector(selectUserRole);

    function handleOpen() {
        setOpen(true)
    }
    function handleClose() {
        setOpen(false)
    }
    const bulkEditConfirmed = (type: string, price: number) => {
        dispatch(
            bulkEdit({
                ids: checkedBoxes,
                type: type,
                price: price,
                endpoint: userRole,
            })
        ).then(() => {
            dispatch(
                getLotList({
                    items_per_page: rowsPerPage,
                    page: page + 1,
                    search: searchQuery,
                    status: lotListTabNames[tab],
                    sort: sort,
                })
            );
            handleClose()
        });
    }


    return (
        <>
            <Box >
                <Tooltip title="Alle makierten Lose bearbeiten" placement="bottom">
                    <IconButton
                        sx={{
                            borderRadius: "26px",
                            width: "135px",
                            height: "48px",
                            justifyContent: "center",
                            backgroundColor: "#c0d904",
                            "&:hover": {
                                backgroundColor: "#869702",
                            },
                        }}
                        onClick={handleOpen}
                    >
                        <XLargeText>€</XLargeText>
                        <SvgIcon sx={{ mr: 4, ml: -3 }}>
                            <Edit />
                        </SvgIcon>
                    </IconButton>
                </Tooltip>
            </Box>
            {checkedBoxes.length > 0 ? (
                <Backdrop sx={{ backgroundColor: "#grey", zIndex: 1200 }} open={open}>
                    <Modal hideBackdrop open={open} onClose={handleClose} aria-labelledby="modal-title">
                        <BulkEdit canceled={handleClose} confirmed={bulkEditConfirmed} checkedBoxes={checkedBoxes.length} />
                    </Modal>
                </Backdrop>
            ) : (
                <Backdrop sx={{ backgroundColor: "#grey", zIndex: 1200 }} open={open} onClick={handleClose}>
                    <Modal hideBackdrop open={open} onClose={handleClose} aria-labelledby="modal-title">
                        <Box sx={{
                            width: "30%",
                            minWidth: "300px",
                            maxWidth: "90%",
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            backgroundColor: "white",
                            transform: "translate(-50%, -50%)",
                            borderRadius: "16px",
                            display: "flex",
                            flexDirection: "column",
                            gap: 2,
                            padding: 4,
                            textAlign: "center",
                        }}>
                            <Typography fontSize={20} fontWeight={"bold"}>
                                Sie müssen erst Lose auswählen um sie zu bearbeiten.
                            </Typography>
                            <Button
                                onClick={() => {
                                    handleClose()
                                }}
                                sx={{
                                    flexGrow: 1,
                                    borderRadius: "26px",
                                    color: "#072D26",
                                    fontWeight: "bold",
                                    border: "2px solid #C0D904",
                                    height: "51px",
                                    paddingLeft: "50px",
                                    paddingRight: "50px",
                                    "&:hover": { background: "#c0d904" }
                                }}>
                                Fortfahren
                            </Button>
                        </Box>
                    </Modal>
                </Backdrop>
            )}
        </>

    )
}
