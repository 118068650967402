import { Slider, SliderTypeMap } from "@mui/material";
import { DefaultComponentProps } from "@mui/material/OverridableComponent";
import React from "react";

export default function HolzfinderSlider(props: DefaultComponentProps<SliderTypeMap>) {
  return (
    <Slider
      {...props}
      sx={{
        color: "#005851",

        "& .MuiSlider-track": {
          color: "#C0D904",
        },
      }}
    />
  );
}
