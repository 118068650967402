import { Typography } from "@mui/material";
import { useAppSelector } from "app/hooks";
import { selectAgent } from "features/agent/agentSlice";
import AgentData from "./AgentData";
import AgentGrid from "./AgentGrid";

const MasterDataLabels = {
  company: "Firma",
  contact: "Kontakt",
  max_wood_per_period: "Holzmengenbeschränkung",
  period_duration: "Periode für Holzmengenbeschränkung",
  street: "Straße",
  street_number: "Hausnummer",
  zip_code: "Postleitzahl",
  po_box: "Postbox", 
  city: "Ort"
};

export default function AgentMasterData(props: any) {
  const agentData = useAppSelector(selectAgent);
  return (
    <AgentGrid>
      <Typography
        fontWeight={"bold"}
        variant="h5"
        sx={{
          mt: 2,
          mb: 0.5,
          color: "#072D26",
        }}
      >
        Agenten Stammdaten
      </Typography>
      {
        agentData?.company ? (
          <AgentData
            label={MasterDataLabels.company}
            data={agentData?.company}
          />
        ) : (
          <></>
        )
      }
      {
        agentData?.contact ? (
          <AgentData
            label={MasterDataLabels.contact}
            data={agentData?.contact}
          />
        ) : (
          <></>
        )
      }
      {
        agentData?.street ? (
          <AgentData
            label={MasterDataLabels.street}
            data={agentData?.street}
          />
        ) : (
          <></>
        )
      }
      {
        agentData?.street_number ? (
          <AgentData
            label={MasterDataLabels.street_number}
            data={agentData?.street_number}
          />
        ) : (
          <></>
        )
      }
      {
        agentData?.zip_code ? (
          <AgentData
            label={MasterDataLabels.zip_code}
            data={agentData?.zip_code}
          />
        ) : (
          <></>
        )
      }
      {
        agentData?.city ? (
          <AgentData
            label={MasterDataLabels.city}
            data={agentData?.city}
          />
        ) : (
          <></>
        )
      }
      {
        agentData?.po_box ? (
          <AgentData
            label={MasterDataLabels.po_box}
            data={agentData?.po_box}
          />
        ) : (
          <></>
        )
      }
    </AgentGrid>
  );
}
