import {
  FormControl,
  Box,
  Container,
  Typography,
  TextField,
} from "@mui/material";
import styles from "../Account.module.css";
import { MediumText } from "../../../../components/typography";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  selectUserData,
  selectUserRole,
  updateFirstName,
  updateLastName,
} from "features/user/userSlice";
import { selectCustomer, updateCompany } from "features/customer/customerSlice";
import { set } from "husky";

interface Props {
  firstAndLastName: { first_name: string; last_name: string };
  setFirstAndLastName: (value: { first_name: string; last_name: string }) => void;
}

export default function AccountSettingsForm(props: Props) {
  const { firstAndLastName, setFirstAndLastName } = props;
  const userRole = useAppSelector(selectUserRole);

  const dispatch = useAppDispatch();
  const { company } = useAppSelector(selectCustomer);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, first: boolean) => {
    setFirstAndLastName({ ...firstAndLastName, [event.target.name]: event.target.value })
  }


  return (
    <Box>
      <Box>
        <Typography
          fontWeight={"bold"}
          variant="h5"
          sx={{
            mt: 2,

            color: "#072D26",
          }}
        >
          PERSÖNLICHE ANGABEN
        </Typography>
      </Box>

      <FormControl
        sx={{
          width: { lg: "60%", md: "80%", xs: "100%" },
          pt: 2,
          gap: 0.5
        }}
        className={styles.form}
      >
        <MediumText>Vorname*</MediumText>
        <TextField
          required
          type="text"
          placeholder="Vorname"
          className={styles.input}
          onChange={(event) => handleChange(event, true)}
          name={"first_name"}
          value={firstAndLastName.first_name}
          InputProps={{
            className: styles.input
          }}
        />

        <MediumText>Nachname*</MediumText>
        <TextField
          required
          type="text"
          placeholder="Name"
          className={styles.input}
          onChange={(event) => handleChange(event, false)}
          name={"last_name"}
          value={firstAndLastName.last_name}
          InputProps={{
            className: styles.input
          }}
        />

        {/* <MediumText>Geburtsdatum*</MediumText>
        <TextField
          required
          type="text"
          placeholder="Name"
          className={styles.input}
          onChange={(event) => dispatch(updateDateOfBirth(event.target.value))}
          name={"last_name"}
          value={last_name}
          InputProps={{
            className: styles.input
          }}
        /> */}
        {/* Not yet relevant */}
        {/* { userRole == "customer" ? <div>
            <MediumText>Firma</MediumText>
            <TextField
              placeholder="Firma"
              className={styles.input}
              onChange={(event) => dispatch(updateCompany(event.target.value))}
              value={company}
              name={"firm"}
              InputProps={{
                className: styles.input
              }}
              />

            </div>: null
          } */}
      </FormControl>
    </Box>
  );
}
