import axiosInstance from "app/axios";
import { AxiosResponse } from "axios";
import { GeoLocationInterface } from "interfaces/GeoLocationInterface";
import { LotOfferLocationInterface } from "interfaces/LotOfferLocationInterface";

export interface fetchLotLocationsArgument {
  location: string;
  distance: number | undefined ;
}

export interface LotLocationsResponseInterface {
  data: {
    user: GeoLocationInterface;
    lot_offers: LotOfferLocationInterface[];
  };
}

export function fetchLotLocations({
  location,
  distance,
}: fetchLotLocationsArgument): Promise<
  AxiosResponse<LotLocationsResponseInterface, any>
> {
  return axiosInstance.get("lot_offers", {
    params: {
      location,
      distance,
    },
  });
}

export function fetchAllLotLocations(): Promise<
  AxiosResponse<LotLocationsResponseInterface, any>
> {
  return axiosInstance.get("lot_offers");
}

export interface fetchLotLocationArgument {
  location: string;
  id: string;
}

export interface LotLocationResponseInterface {
  data: LotOfferLocationInterface;
}

export function fetchLotLocation({ location, id }: fetchLotLocationArgument): Promise<AxiosResponse<LotLocationResponseInterface, any>> {
  return axiosInstance.get(`lot_offers/${id}`, {
    params: {
      location,
    },
  });
}
