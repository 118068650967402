import { Dialog, DialogActions, DialogContent, DialogContentText } from "@mui/material";
import { Container } from "@mui/system";
import Grid2 from "@mui/material/Unstable_Grid2";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { MediumText } from "components/typography";
import { patchLotOffer, patchImages, selectImagesToDeleteLength, deleteLotOfferSlice, selectLotOffer } from "features/lotOffer/lotOfferSlice";
import LotOfferButton from "./LotOfferButton";
import LotOfferImages from "./LotOfferImages";
import LotOfferLocations from "./LotOfferLocations";
import LotOfferMasterData from "./LotOfferMasterData";
import LotOfferOptions from "./LotOfferOptions";
import styles from "./LotOffer.module.css";
import Button from "@mui/material/Button";
import { Box } from "@mui/material/";

import Header from "components/header/Header";
import { useState } from "react";
import { useNavigate } from "react-router";
import { selectUserRole } from "features/user/userSlice";
import { LotOfferInterface } from "interfaces/LotOfferInterface";

export default function LotOffer() {
  const lotOfferData = useAppSelector(selectLotOffer) as LotOfferInterface;

  // console.log(lotOfferData.price_per_solid_cubic_meter)
  const [open, setOpen] = useState(false);
  const [changesSaved, setChangesSaved] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userRole = useAppSelector(selectUserRole);
  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  function handleDelete() {
    dispatch(deleteLotOfferSlice())
    setOpen(false);
    navigate(`/${userRole}/losliste`)
  }
  const deletedImagesIds = useAppSelector(selectImagesToDeleteLength)
  const saveChanges = () => {
    const promises = [];
    const pricePerSolidCubicMeter = lotOfferData.price_per_solid_cubic_meter;
    const regex = /^\d+((\.|,)\d+)?$/;
    if (regex.test(pricePerSolidCubicMeter)) {
      promises.push(dispatch(patchLotOffer()));
      if (deletedImagesIds > 0) {
        promises.push(dispatch(patchImages()));
      }
      Promise.all(promises).then((result) => {
        setChangesSaved(true);
      });
    }
  };
  return (
    <>
      <Container
        maxWidth="xl"
        sx={{
          pt: 4,
          pb: 4,
          pl: { lg: 12, md: 14, xs: 13 },
        }}
      >
        <Header backButton header="Los bearbeiten" actionButton2={
          <div>
            <Button
              onClick={handleClickOpen}
              style={{
                borderRadius: "26px",
                fontWeight: "bold",
                color: "#072D26",
                border: "2px solid #C0D904",
              }}
              className={` ${styles.sizeInputs}`}
              variant="outlined"
            >
              LOS LöSCHEN
            </Button>
            <Dialog open={open} onClose={handleClose}>
              <DialogContent>
                <DialogContentText>
                  <MediumText fWeight="bold" color="#072D26">
                    Sind Sie sich sicher, dass Sie dieses Los löschen wollen? Diese Aktion ist nicht widerrufbar.
                  </MediumText>
                </DialogContentText>
              </DialogContent>
              <DialogActions >
                <Button
                  sx={{
                    float: "left",
                    borderRadius: "26px",
                    fontWeight: "bold",
                    color: "black",
                    backgroundColor: "#FF0000",
                    border: "2px solid #FF0000",
                  }}
                  onClick={handleDelete} color="secondary">
                  Löschen
                </Button>
                <Button
                  sx={{
                    borderRadius: "26px",
                    fontWeight: "bold",
                    color: "#072D26",
                    backgroundColor: "#D9E868",
                    border: "2px solid #D9E868",
                  }}
                  onClick={handleClose}>
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        } />
        <Box
          sx={{
            mt: 3,
          }}
        >
          <div className={styles.LotOfferContainer}>
            <Grid2 container className={` ${styles.sdsd}`}>
              <LotOfferMasterData className={styles.LotOfferMasterData} />
              <LotOfferLocations />

              <LotOfferOptions />

              <LotOfferImages />
            </Grid2>
          </div>
        </Box>

        {changesSaved && (
          <Box sx={{ mt: 2 }}>
            <MediumText fWeight="bold" color="#072D26">
              Änderungen wurden gespeichert.
            </MediumText>
          </Box>
        )}

        <Box
          sx={{
            display: "flex",
            mt: 3,
            gap: 2,
          }}
        >
          <LotOfferButton
            label="Änderungen speichern"
            sx={{ borderRadius: "26px", height: "51px", fontWeight: "bold" }}
            onClick={saveChanges}
          />

          <Button
            style={{
              borderRadius: "26px",
              color: "#072D26",
              fontWeight: "bold",
              border: "2px solid #C0D904",
            }}
            className={`${styles.roundInputs} ${styles.sizeInputs}`}
            variant="outlined"
            onClick={() => { navigate(`/${userRole}/losliste`) }}
          >
            Änderungen VERWERFEN
          </Button>
        </Box>
      </Container>
    </>
  );
}
