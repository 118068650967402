import { IconButton } from "@mui/material";
import { AccountCircle } from "icons";
import { MediumText } from "../../typography";

export default function ToLogin(props: any) {
  return (
    <IconButton
      size="small"
      color="inherit"
      sx={{
        "&:active": {
          color: "#C4D661",
          //height /width :0% eliminated
        },
        gap: 1,
      }}
    >
      <MediumText color="#FFFFFF" fWeight="bold" alignSelf="center">
        {props.text}
      </MediumText>
      <AccountCircle
        style={{
          width: "32px",
          height: "32px",
          fill: "#D9E868"

        }}
        fill={"#D9E868"}
        stroke={"#D9E868"}
      />
    </IconButton>
  );
}
