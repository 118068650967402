import {
  createAsyncThunk,
  createSlice,
  isAnyOf,
  PayloadAction,
} from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { RegisterErrors } from "features/user/userSlice";
import { AgentInterface } from "interfaces/Agent";
import { AgentUserDataInterface } from "interfaces/AgentUserDataInterface";
import { RequestStatus } from "interfaces/RequestStatus";
import {
  archiveUser,
  confirmAgentArgument,
  fetchAgent,
  fetchAgentArgument,
  fetchAgentUser,
  fetchAgentUserListArgument,
  fetchAgentUsers,
  patchAgentLogin,
  postAgent,
  postNewAgentArgument,
  saveAgent,
} from "./fetchAgent";
import { updateAgentUser, patchAgentUserInterface } from "./updateAgent";

export enum LotOfferStatus {
  INACTIVE = "inactive",
  ACTIVE = "active",
}


const emptyInvitationErrors: RegisterErrors = {}
export interface agentState {
  status: RequestStatus;
  agent?: AgentInterface;
  changedValues: (keyof AgentInterface)[];
  agentUsers: AgentUserDataInterface[];
  total: number;
  pages: number;
  itemsPerPage: number;
  invitationErrors?: RegisterErrors;
  confirmationErrors?: RegisterErrors;
  deleteErrors?: RegisterErrors;
  currentAgentId: string;
  user?: AgentUserDataInterface;
  //neue Nachricht
  message: string;
  registered: boolean;
}

const initialState: agentState = {
  status: RequestStatus.idle,
  agent: undefined,
  changedValues: [],
  total: 0,
  pages: 0,
  itemsPerPage: 10,
  agentUsers: [],
  currentAgentId: "",
  user: undefined,
  message: "",
  registered: false,
};


const emptyAgentUsers: any={
  id:"",
  first_name:"",
  last_name:"",
  email:"string",
  role:"",
  status:"",
  created_at:"",
  updated_at:"",
  email_changeable:false,
  status_changeable:true,
  inviteable:false,
  translated_status:"",
  translated_role:"",
  deletable:true,
  editable:true
}

export const getAgent = createAsyncThunk("agent/fetchAgent", async (args: fetchAgentArgument, { getState }) => {
  const { user } = getState() as RootState;
  const response = await fetchAgent({ ...args, endpoint: user.role });
  return { headers: response.headers, data: response.data };
});

export const getAgentUsers = createAsyncThunk("agent/fetchAgentUsers", async (args: fetchAgentUserListArgument, { getState }) => {
  const { user } = getState() as RootState;
  const response = await fetchAgentUsers({ ...args, endpoint: user.role });
  return { headers: response.headers, data: response.data };
});

export const getAgentUser = createAsyncThunk("agent/fetchAgentUser", async (args: fetchAgentArgument, { getState }) => {
  const { user } = getState() as RootState;
  const response = await fetchAgentUser({ ...args, endpoint: user.role });
  return { headers: response.headers, data: response.data };
});

export const patchAgentUser = createAsyncThunk("agent/updateAgentUser", async (args: patchAgentUserInterface, { getState, rejectWithValue }) => {
  const { user } = getState() as RootState;
  const response = await updateAgentUser({ ...args, endpoint: user.role });
  if (response.status === 200) {
    return { headers: response.headers, data: response.data  };
  }
  else {
    return rejectWithValue(response.data.data.errors);
  }
});

export const createAgent = createAsyncThunk("agent/createAgent", async (args: postNewAgentArgument, { getState, rejectWithValue }) => {
  const { user } = getState() as RootState;
  const response = await postAgent({ ...args, endpoint: user.role });
  if (response.status === 200) {
    return { headers: response.headers, data: response.data  };
  }
  else {
    return rejectWithValue(response.data.data.errors);
  }
});

export const confirmAgent = createAsyncThunk("agent/confirmAgent", async (args: confirmAgentArgument, { rejectWithValue }) => {
  const response = await patchAgentLogin({ ...args });
  if (response.status === 200) {
    return { headers: response.headers, data: response.data  };
  }
  else {
    return rejectWithValue(response.data.data.errors);
  }
});

export const patchAgent = createAsyncThunk("agent/saveAgent", async (args, { getState }) => {
  const { agent, user } = getState() as RootState;
  if (!agent.agent) {
    return { headers: {}, data: {} };
  }
  const response = await saveAgent({
    changedValues: agent.changedValues,
    id: agent.agent.id,
    agent: agent.agent,
    endpoint: user.role,
  });
  return { headers: response.headers, data: response.data };
});

export const deleteAgentUser = createAsyncThunk("agent/deleteAgentUser", async (args: fetchAgentArgument, { getState, rejectWithValue }) => {
  const { user } = getState() as RootState;
  const response = await archiveUser({ ...args, endpoint: user.role });
  if (response.status === 200) {
    return { headers: response.headers, data: response.data  };
  }
  else {
    return rejectWithValue(response.data.data.errors);
  }
});

export const agentSlice = createSlice({
  name: "agent",
  initialState,
  reducers: {
    clear: (state) => {
      return {
        ...initialState,
        currentAgentId: "",
        user: undefined,
      };
    },
    setMaxWoodPerPeriod: (state, action: PayloadAction<number | null | string >) => {
      if (state.agent) {
        state.agent.max_wood_per_period = action.payload;
        state.changedValues.indexOf("max_wood_per_period") === -1 && state.changedValues.push("max_wood_per_period");
      }
    },


    setPeriodDuration: (state, action: PayloadAction<number>) => {
      if (state.agent) {
        state.agent.period_duration = action.payload;
        state.changedValues.indexOf("period_duration") === -1 && state.changedValues.push("period_duration");
      }
    },
    clearConfirmationErrors: (state) => {
      state.confirmationErrors = emptyInvitationErrors;
    },
    clearRegisteredState: (state) => { 
      state.registered = false;
    },
    // clearAgents: (state) => {
    //   state.user = emptyAgentUsers;
    // },
    clearDeleteErrors: (state) => {
      state.deleteErrors = emptyInvitationErrors;
    },
    setAgentId: (state, action: PayloadAction<string>) => {
      state.currentAgentId = action.payload
    },
    showMessage: (state) => {
      state.message = "Die Eingabe ist nicht korrekt";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAgent.fulfilled, (state, action) => {
        state.status = RequestStatus.idle;
        state.agent = action.payload.data.data;
      })
      .addCase(getAgentUsers.fulfilled, (state, action) => {
        state.status = RequestStatus.idle;
        state.total = action.payload.data.data.total;
        state.pages = action.payload.data.data.pages;
        state.agentUsers = action.payload.data.data.users;
      })
      .addCase(getAgentUser.fulfilled, (state, action) => {
        state.status = RequestStatus.idle;
        if (!state.user) {
          state.user = action.payload.data.data;
        }
      })
      .addCase(createAgent.fulfilled, (state, action) => {
        state.status = RequestStatus.idle;
        state.invitationErrors = emptyInvitationErrors;
      })
      .addCase(createAgent.rejected, (state, action) => {
        state.status = RequestStatus.failed;
        state.invitationErrors = action.payload as RegisterErrors;
      })
      .addCase(confirmAgent.pending, (state) => {
        state.status = RequestStatus.loading;
        state.confirmationErrors = emptyInvitationErrors;
        state.registered = false;
      })
      .addCase(confirmAgent.fulfilled, (state, action) => {
        state.status = RequestStatus.idle;
        state.confirmationErrors = emptyInvitationErrors;
        state.registered = true;
      })
      .addCase(confirmAgent.rejected, (state, action) => {
        state.status = RequestStatus.failed;
        state.confirmationErrors = action.payload as RegisterErrors;
        state.registered = false;
      })
      .addCase(deleteAgentUser.fulfilled, (state, action) => {
        state.status = RequestStatus.idle;
        state.deleteErrors = emptyInvitationErrors;
      })
      .addCase(deleteAgentUser.rejected, (state, action) => {
        state.status = RequestStatus.failed;
        state.deleteErrors = {} as RegisterErrors;
      })
      .addMatcher(isAnyOf(getAgent.pending), (state) => {
        state.status = RequestStatus.loading;
      })
      .addMatcher(isAnyOf(getAgent.rejected), (state) => {
        state.status = RequestStatus.failed;
      });
  },
});

export const { clear, setMaxWoodPerPeriod, setPeriodDuration, clearConfirmationErrors,clearRegisteredState, setAgentId, clearDeleteErrors, showMessage, } = agentSlice.actions; //clearAgents

export const selectAgent = (
  state: RootState
): AgentInterface | undefined => state.agent.agent;
export const selectAgentUsers = (state: RootState): AgentUserDataInterface[] => state.agent.agentUsers;
export const selectAgentUsersListTotal = (state: RootState) => state.agent.total;
export const selectAgentUsersListPages = (state: RootState) => state.agent.pages;
export const selectInvitationErrors = (state: RootState) => state.agent.invitationErrors;
export const selectConfirmationErrors = (state: RootState) => state.agent.confirmationErrors;
export const selectAgentRegisterStatus = (state: RootState) => state.agent.registered;
export const selectDeleteErrors = (state: RootState) => state.agent.deleteErrors;
export const selectCurrentAgentId = (state: RootState) => state.agent.currentAgentId;
export const selectAgentUser = (state: RootState) => state.agent.user;
export const selectAgents = (state: RootState) => state.agent;

export default agentSlice.reducer;
