import { Checkbox, CheckboxProps } from "@mui/material";
import React from "react";

export default function HolzfinderCheckbox(props: CheckboxProps) {
  return (
    <Checkbox
      sx={{
        color: "#D0C2A8",
      }}
      {...props}
    />
  );
}
