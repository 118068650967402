import { Edit } from "@mui/icons-material";
import { Box, Container, IconButton, InputAdornment, Paper, SvgIcon, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField } from "@mui/material";
import { useAppDispatch, useAppSelector } from "app/hooks"
import Header from "components/header/Header";
import { getAgentList, selectAgentList, selectAgentListTotal } from "features/agentList/agentListSlice"
import { getLotList } from "features/lotlist/lotListSlice";
import useDate from "hooks/useDate";
import { DeleteSmall, Search } from "icons";
import React, { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import SearchBar from "components/inputs/HolzfinderSearchBar";
import HolzfinderList from "components/list/HolzfinderList";
import { selectLotListTab, selectPageAgentList, selectRowsPerPageAgentList, setPageAgentList, setRowsPerPageAgentList } from "features/table/tableSlice";
interface rowInterface {
  id: string;
  company: string;
  eldat_reference_id: number;
  street: string;
  street_number: string;
  zip_code: string;
  city: string;
}

const tableHeaderList: string[] = ["Eldat Referenz ID", "Firma", "Straße", "Hausnummer", "Postleitzahl", "Stadt", "Bearbeiten"]

export default function AgentList() {

  const [rowData, setRowData] = useState<rowInterface[]>([]);
  const [searchQuery, setSearchQuery] = React.useState("");
  const agentList = useAppSelector(selectAgentList);
  const agentListTotal = useAppSelector(selectAgentListTotal);
  const page = useAppSelector(selectPageAgentList)
  const rowsPerPage = useAppSelector(selectRowsPerPageAgentList)
  const [pageCache, setPageCache] = React.useState(page);
  const [rowsPerPageCache, setRowsPerPageCache] = React.useState(rowsPerPage);
  const dispatch = useAppDispatch();

  const receiveAgentList = (resetSearch: boolean) => {
    if (resetSearch) setSearchQuery("")
    dispatch(
      getAgentList({
        items_per_page: rowsPerPage,
        page: page + 1,
        search: resetSearch ? undefined : searchQuery,
      })
    );
  };

  useEffect(() => {
    receiveAgentList(false)
  }, [page, rowsPerPage, searchQuery])

  useEffect(() => {
    const mappedData = agentList.map(
      (agent) => ({
        id: agent.id,
        eldat_reference_id: agent.county.eldat_reference_id,
        company: agent.company,
        street: agent.street,
        street_number: agent.street_number,
        zip_code: agent.zip_code,
        city: agent.city
      })
    );
    setRowData(mappedData);
  }, [agentList])
  useEffect(() => {
    dispatch(setPageAgentList(0));
  }, [searchQuery]);
  return (
    <Container sx={{ pl: { lg: 12, md: 12, xs: 12 }, pt: 5 }} maxWidth="xl">
      <Header header="Agentenliste" />
      <SearchBar page={page} rowsPerPage={rowsPerPage} getListType={getAgentList} setSearchQuery={setSearchQuery} />

      <HolzfinderList
        listLengthTotal={agentListTotal}
        rowData={rowData}
        switchActive={false}
        tableHeaderList={tableHeaderList}
        setPage={setPageAgentList}
        setRowsPerPage={setRowsPerPageAgentList}
        page={page}
        rowsPerPage={rowsPerPage === undefined ? 10 : rowsPerPage}
        setAgent={true}
        isLotList={false}
      />
    </Container>
  )
}