import { Checkbox, MenuItem, Select } from "@mui/material";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { FilterLotSearchText } from "components/typography";
import { getLotLocations, selectLotLocationFilter, selectLotLocationFilterSettings, setSellers } from "features/lotLocations/lotLocationsSlice";

export default function SellerSection(props: any) {
  const dispatch = useAppDispatch();
  const valueSettings = useAppSelector(selectLotLocationFilterSettings).sellers;
  const value = useAppSelector(selectLotLocationFilter).sellers;

  const handleSeller = (event: any) => {
    dispatch(setSellers(event.target.value));
  };

  // const updateLotLocations = () => {
  //   dispatch(getLotLocations());
  // };
  // console.log({lotOfferData?.solid_cubic_meters_without_bark});

  if (valueSettings.length <= 1) return <div></div>;

  return (
    <Select
      value={value}
      multiple
      label="Verkäufer"
      onChange={handleSeller}
      // onBlur={updateLotLocations}
      variant="standard"
      disableUnderline
      displayEmpty
      sx={{
        backgroundColor: "white",
        minWidth: { xs: "200px", lg: "300px" },
        borderRadius: "26px",
        display: "flex",
        padding: "2px 4px",
        alignItems: "center",
        height: "50px",
      }}
      renderValue={
        value.length > 0
          ? () => <FilterLotSearchText>{value.length} ausgewählt</FilterLotSearchText>
          : //       <FilterLotSearchText>{selected.join(",")}</FilterLotSearchText>
            //     );
            () => <FilterLotSearchText color={"gray"}>Anbieter</FilterLotSearchText>
      }
    >
      {valueSettings.map((seller: string) => (
        <MenuItem key={seller} value={seller}>
          <Checkbox checked={value.includes(seller)} />
          {seller}
        </MenuItem>
      ))}
    </Select>
  );
}
