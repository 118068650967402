import { Paper, Box, IconButton, SvgIcon, Button } from "@mui/material";
import { LargeText, MediumText, SmallText } from "../../components/typography";
import { Tree, OpenWith, Sell, MyLocation, AddShoppingCart, TrustedSeller, Circumference, AccountCircle, Length } from "icons";
import TextWithIcon from "./TextWithIcon";
import { LotOfferLocationInterface } from "interfaces/LotOfferLocationInterface";
import { Link, useNavigate } from "react-router-dom";
import useDate from "hooks/useDate";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { selectUserIsLoggedId, selectUserRole, updateAfterLoginTo } from "features/user/userSlice";
import { setShoppingCart, selectShoppingCartIds, checkShoppingCart } from "features/shoppingCart/shoppingCartSlice";
import { useState } from "react";
import ShoppingCartButton from "components/shoppingCart/ShoppingCartButton";
import { UserRoles } from "interfaces/UserRoles";
// const lotExample: LotOfferLocationInterface = {
//   id: "1b6f1034-60c5-4eb1-b27c-9f1b4850c88e",
//   full_lot_number: "2023/123/ARS/111",
//   wood_types: ["Küstentanne"],
//   wood_category: "Brennholz lang",
//   average_length_value: "7.5",
//   diameter: "42.11943849338047",
//   solid_cubic_meters_without_bark: "12.54",
//   cubic_meters_without_bark: "19.292307692307692307692307692",
//   number_of_logs: 12,
//   image_urls: [
//     "/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaEpJaWswTkRsbFptRXdOUzAzWmpOa0xUUm1aRFF0T0RFME1DMWhZVEExTkdRNE9UazJOekVHT2daRlZBPT0iLCJleHAiOm51bGwsInB1ciI6ImJsb2JfaWQifX0=--03c3f9345ef1bb81a4f2d1a56c0a991113f203bc/sample.jpg",
//   ],
//   wood_piles: [
//     {
//       longitude: 8.6717458,
//       latitude: 49.5462349,
//     },
//   ],
//   seller: "Waibel GmbH & Co. KG",
//   agent: "Rosbach-Hamann",
//   county: "Landkreis Karlsruhe",
//   max_wood_per_period: 100,
//   period_duration: 3,
//   login_and_validation_required: true,
//   vat_rate: 0,
//   price_per_solid_cubic_meter: "0.0",
//   price_total: "0.0",
//   distance: 0,
//   buyable: true,
//   created_at: "",
//   updated_at: "",
// };
interface InformationenProps {
  lotInformation: LotOfferLocationInterface;
}
export default function HolzfinderInfoDetail(props: InformationenProps) {
  const { lotInformation } = props;
  const isLoggedIn = useAppSelector(selectUserIsLoggedId);
  const diameter = Number(lotInformation.diameter);
  const { seller, agent, max_wood_per_period, wood_types, distance, average_length_value, login_and_validation_required, solid_cubic_meters_without_bark, price_per_solid_cubic_meter, price_total, vat_rate, published_at, period_duration } = lotInformation;

  const shoppingCartIds = useAppSelector(selectShoppingCartIds);
  let isInShoppingCart = shoppingCartIds.includes(lotInformation.id);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userRole = useAppSelector(selectUserRole) as UserRoles;

  return (
    <Paper sx={{ boxShadow: "none", maxWidth: "500px", width: "100%", pl: { xs: 0, md: 2 } }}>
      <Paper sx={{ display: "flex", boxShadow: "none" }}>
        <Box sx={{ backgroundColor: "#F6F3EE" }}>
          <Box>
            <Box sx={{ display: "flex", padding: 2 }}>
              <SvgIcon sx={{ mr: 1, padding: 0.5 }}>
                <TrustedSeller />
              </SvgIcon>
              <Box>
                <SmallText>
                  <span>
                    Der Verkauf erfolgt durch <strong>{agent}</strong>.
                  </span>
                  <br />
                  <span>
                    Im Auftrag von <strong>{seller}</strong>.
                  </span>
                </SmallText>
              </Box>
            </Box>
            <MediumText padding="16px">
              {max_wood_per_period !== null && max_wood_per_period >= 0 && `${agent} erlaubt den Erwerb von ${max_wood_per_period} fm / ${period_duration} Monate je registriertem Nutzer.`}
              {!isLoggedIn && max_wood_per_period !== null && "Bitte loggen Sie sich ein."}

            </MediumText>

          </Box>
        </Box>
      </Paper>
      <Paper
        sx={{
          display: "flex",
          flexDirection: "row",
          borderRadius: "0px",
          boxShadow: "none",
          justifyContent: "space-between",
          padding: 1,
          "&::after": {
            height: "3px",
            border: "1px solid",
          },
        }}
      >
        <Box sx={{ mt: "3%", display: "flex", flexBasis: "min-content", flexGrow: "0.6" }}>
          <TextWithIcon icon={<Tree />} text={wood_types.join(", ").trim().replace(" (unbestimmt)", "")} />
          {distance ? <TextWithIcon icon={<MyLocation />} text={`${(distance / 1000).toFixed(2).replace('.', ',')} km entfernt`} /> : <></>}
        </Box>
        <Box sx={{ mt: "3%" }}>
          <TextWithIcon icon={<Length />} text={`${average_length_value} m`} />
          <TextWithIcon icon={<OpenWith />} text={`${solid_cubic_meters_without_bark.toFixed(2).replace('.', ',')} fm`} />
        </Box>
        <Box sx={{ mt: "3%" }}>
          {/* text={`${Number(solid_cubic_meters_without_bark).toFixed(
            2
          )} € / fm`} */}
          <TextWithIcon icon={<Circumference />} text={`${Math.round(diameter)} cm`} />
          <TextWithIcon icon={<Sell />} text={`${price_per_solid_cubic_meter} € / Fm`} />
        </Box>
      </Paper>
      <Box
        sx={{
          "&::before": {
            content: '" "',
            display: "block",
            maxWidth: "inherit",
            width: "100%",
            height: "1px",
            borderBottom: "1px solid #403521",
            margin: "auto",
          },
        }}
      >
        <Box sx={{ my: 2, padding: 1 }}>
          <Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ my: 1, display: "flex", alignItems: "baseline", gap: 18 }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "baseline",
                    gap: 1,
                    // maxWidth: "200px",
                    width: "100%",
                  }}
                >
                  <LargeText fWeight="bold" float="left" color="#072D26">
                    {/* price change */}
                    {Number(price_total)
                      .toFixed(2)
                      .replace(".", ",")
                      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") + " €"}
                  </LargeText>
                  <MediumText color="#D0C2A8" float="left" whiteSpace="pre">
                    Inkl. {vat_rate}% MwSt.
                  </MediumText>
                </Box>
              </Box>
              <Box
                sx={{
                  textAlign: "right",
                  position: "relative",
                  bottom: "2px",
                  // width: "100%",
                  top: "5px",
                }}
              >
                {login_and_validation_required && !isLoggedIn ? (
                  <Button
                    onClick={(event) => {
                      dispatch(updateAfterLoginTo(`/lossuche/${lotInformation.id}`))
                      navigate("/login");
                    }}
                    sx={{
                      borderRadius: "30px",
                      backgroundColor: "#D9E868",
                      color: "#000000",
                      "&:hover": { backgroundColor: "#D9E868" },
                      padding: "12px 24px",
                      gap: 1,
                    }}
                  >
                    <MediumText fWeight="bold">Login</MediumText>
                    <SvgIcon>
                      <AccountCircle />
                    </SvgIcon>
                  </Button>
                ) : (
                  <ShoppingCartButton lotInformation={lotInformation} withLabel disabled={isInShoppingCart || (userRole === "admin" || userRole === "agent")} />
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
}
