import React from "react";
import { Link } from "react-router-dom";
import styles from "./LinkWithHoverLine.module.css";
// TODO: add correct Types
export default function LinkWithHoverLine({ children, to }: any) {
  return (
    <Link className={styles.hoverLine} to={to}>
      {children}
    </Link>
  );
}
