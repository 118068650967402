import { Typography } from "@mui/material";
import { useAppSelector } from "app/hooks";
import { selectSeller } from "features/seller/sellerSlice";
import SellerData from "./SellerData";
import SellerGrid from "./SellerGrid";
import { useEffect } from "react";

const MasterDataLabels = {
  eldat_reference_id: "Betriebsnr.",
  company: "Betriebsname",
  last_name: "Nachname",
  first_name: "Vorname",
  street: "Straße",
  street_number: "Hausnummer",
  zip_code: "Postleitzahl",
  po_box: "Postbox",
  city: "Ort",
};

export default function SellerMasterData(props: any) {
  const sellerData = useAppSelector(selectSeller);
  useEffect(() => {

  }, [sellerData]);
  return (
    <SellerGrid>

      {
        sellerData?.eldat_reference_id ? (
          <SellerData
            label={MasterDataLabels.eldat_reference_id}
            data={sellerData?.eldat_reference_id}
          />
        ) : (
          <></>
        )
      }
      {
        sellerData?.company ? (
          <SellerData
            label={MasterDataLabels.company}
            data={sellerData?.company}
          />
        ) : (
          <></>
        )
      }
      {
        sellerData?.last_name ? (
          <SellerData
            label={MasterDataLabels.last_name}
            data={sellerData?.last_name}
          />
        ) : (
          <></>
        )
      }
      {
        sellerData?.first_name ? (
          <SellerData
            label={MasterDataLabels.first_name}
            data={sellerData?.first_name}
          />
        ) : (
          <></>
        )
      }
      {
        sellerData?.street ? (
          <SellerData
            label={MasterDataLabels.street}
            data={sellerData?.street}
          />
        ) : (
          <></>
        )
      }
      {
        sellerData?.street_number ? (
          <SellerData
            label={MasterDataLabels.street_number}
            data={sellerData?.street_number}
          />
        ) : (
          <></>
        )
      }
      {
        sellerData?.zip_code ? (
          <SellerData
            label={MasterDataLabels.zip_code}
            data={sellerData?.zip_code}
          />
        ) : (
          <></>
        )
      }
      {
        sellerData?.city ? (
          <SellerData
            label={MasterDataLabels.city}
            data={sellerData?.city}
          />
        ) : (
          <></>
        )
      }
      {
        sellerData?.po_box ? (
          <SellerData
            label={MasterDataLabels.po_box}
            data={sellerData?.po_box}
          />
        ) : (
          <></>
        )
      }
    </SellerGrid>
  );
}
