import { Divider, Paper } from "@mui/material";
import { useAppDispatch, useAppSelector } from "app/hooks";
import HolzfinderSlider from "components/inputs/HolzfinderSlider";
import { FilterLotSearchText } from "components/typography";
import { selectLotLocationFilter, selectLotLocationFilterSettings, setDiameter } from "features/lotLocations/lotLocationsSlice";

export default function DiameterFilterSection(props: any) {
  const dispatch = useAppDispatch();
  const diameterSettings = useAppSelector(selectLotLocationFilterSettings).diameter;
  const diameter = useAppSelector(selectLotLocationFilter).diameter;

  const handleDiameter = (event: any) => {
    if (Array.isArray(event.target.value)) dispatch(setDiameter(event.target.value));
    else dispatch(setDiameter([event.target.value, event.target.value]));
  };

  if (diameterSettings[0] === diameterSettings[1]) return <div></div>;

  return (
    <Paper
      sx={{
        backgroundColor: "white",
        minWidth: { xs: "320px", md: "400px", lg: "400px" },
        borderRadius: "26px",
        display: "flex",
        padding: "2px 4px",
        alignItems: "center",
        height: "50px",
      }}
    >
      <FilterLotSearchText whiteSpace="nowrap">Durchmesser in cm</FilterLotSearchText>
      <Divider sx={{ height: 28, m: 1, color: "#1F2A40", border: 1 }} orientation="vertical" />
      <FilterLotSearchText mx={1}>{diameterSettings[0]}</FilterLotSearchText>

      <HolzfinderSlider value={diameter} onChange={handleDiameter} min={diameterSettings[0]} max={diameterSettings[1]} valueLabelDisplay="auto" size="small" />
      <FilterLotSearchText mx={1}>{diameterSettings[1]}</FilterLotSearchText>
    </Paper>
  );
}
