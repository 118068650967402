import { Container } from "@mui/material";
import axiosInstance from "app/axios";
import { useAppDispatch, useAppSelector } from "app/hooks";
import Header from "components/header/Header";
import SearchBar from "components/inputs/HolzfinderSearchBar";
import HolzfinderList from "components/list/HolzfinderList";
import {
  getCustomers,
  selectCustomersList,
  selectCustomersListTotal,
  updateCustomerStatus,
  updateStatus,
} from "features/customer/customerSlice";
import {
  selectPageCustomer,
  selectRowsPerPageCustomer,
  setPageCustomerList,
  setRowsPerPageCustomerList,
} from "features/table/tableSlice";
import { selectUserRole } from "features/user/userSlice";
import React, { useState, useEffect } from "react";

interface rowInterface {
  id: string;
  email: string;
  street: string;
  street_number: string;
  zip_code: string;
  city: string;
  first_name: string;
  last_name: string;
  status?: string;
  checked?: boolean;
}

const tableHeaderList: string[] = [
  "Vorname",
  "Nachname",
  "E-Mail",
  "Straße",
  "Hausnummer",
  "Postleitzahl",
  "Stadt",
  "Status",
  "Bearbeiten",
];

export default function CustomersList() {
  const [rowData, setRowData] = useState<rowInterface[]>([]);
  const customersList = useAppSelector(selectCustomersList);
  const customersListTotal = useAppSelector(selectCustomersListTotal) as number;
  const page = useAppSelector(selectPageCustomer);
  const rowsPerPage = useAppSelector(selectRowsPerPageCustomer);
  const dispatch = useAppDispatch();
  const userRole = useAppSelector(selectUserRole);
  const [searchQuery, setSearchQuery] = React.useState("");

  const receiveCustomersList = (resetSearch: boolean) => {
    if (resetSearch) setSearchQuery("");
    dispatch(
      getCustomers({
        items_per_page: rowsPerPage,
        page: page + 1,
        search: resetSearch ? undefined : searchQuery,
      })
    );
  };

  useEffect(() => {
    receiveCustomersList(false);
  }, [page, rowsPerPage, searchQuery]);

  useEffect(() => {
    const mappedData = customersList?.map((customer) => ({
      id: customer.id,
      first_name: customer.first_name,
      last_name: customer.last_name,
      email: customer.email,
      street: customer.street,
      street_number: customer.street_number,
      zip_code: customer.zip_code,
      city: customer.city,
      status: customer.status,
      checked: customer.status === "active" ? true : false,
    }));
    if (mappedData) setRowData(mappedData);
  }, [customersList]);

  const switchStatus = async (id: string, newChecked: boolean) => {
    const newItems = rowData.map(async (row: any) => {
      if (row.id === id) {
        const payload: any = {
          status: row.status === "active" ? "inactive" : "active",
        };
        try {
          await axiosInstance.patch(`${userRole}/customers/${row.id}`, payload);
          dispatch(updateStatus(payload.status));
          return {
            ...row,
            checked: newChecked,
            status: payload.status,
          };
        } catch (error) {
          console.error("Error in Axios:", error);
          return row;
        }
      } else {
        return row;
      }
    });
    const updatedItems = await Promise.all(newItems);
    setRowData(updatedItems);
  };

  const handleCustomerSwitch = (id: string, isChecked: boolean) => {
    const newRowData = rowData.map((row) => {
      if (row.id === id) {
        return row;
      } else {
        return row;
      }
    });
    setRowData(newRowData);
  };

  useEffect(() => {
    dispatch(setPageCustomerList(0));
  }, [searchQuery]);

  return (
    <Container sx={{ pl: { lg: 12, md: 12, xs: 12 }, pt: 5 }} maxWidth="xl">
      <Header header="Kundenliste" />
      <SearchBar
        page={page}
        rowsPerPage={rowsPerPage}
        getListType={getCustomers}
        setSearchQuery={setSearchQuery}
      />
      <HolzfinderList
        listLengthTotal={customersListTotal}
        rowData={rowData}
        switchActive={true}
        switchStatus={switchStatus}
        link={`/${userRole}/kundenliste/`}
        tableHeaderList={tableHeaderList}
        setPage={setPageCustomerList}
        setRowsPerPage={setRowsPerPageCustomerList}
        page={page}
        rowsPerPage={rowsPerPage === undefined ? 10 : rowsPerPage}
        isLotList={false}
      />
    </Container>
  );
}
