import { useAppSelector } from "app/hooks";
import { StuttgartLocation, selectLotLocationsUserLocation } from "features/lotLocations/lotLocationsSlice";
import { MapContainer, TileLayer } from "react-leaflet";
import CenterMapToLocation from "./CenterMapToLocation";
import HighlightedMarker from "./HighlightedMarker";
import LotClusterMarker from "./LotClusterMarker";
import styles from "App.module.css";
import { selectClickedMarker } from "features/lotOffer/lotOfferSlice";
import SelectedLot from "./SelectedLot";
import { Box } from "@mui/material";

export default function MapSection() {
  const location = useAppSelector(selectLotLocationsUserLocation);
  const clickedMarker = useAppSelector(selectClickedMarker);

  return (
    <Box sx={{ position: "relative", display: { xs: "flex", md: "contents" } }}>
      <MapContainer center={ [StuttgartLocation.latitude, StuttgartLocation.longitude]} zoom={11} maxZoom={18} scrollWheelZoom={false} minZoom={7} className={styles.leaflet}>
        <CenterMapToLocation />
        <LotClusterMarker />
        <HighlightedMarker />

        <TileLayer attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        {typeof clickedMarker === "object" ? <SelectedLot lotLocation={clickedMarker} /> : <></>}
      </MapContainer>
    </Box>
  );
}
