import axiosInstance from "app/axios";
import { AxiosResponse } from "axios";

export interface fetchValidateArguments {
  ids: string[];
}

export interface fetchValidateResponse {
  data?: {
    taken_lot_offer_ids?: string[];
    overflown_lot_offer_ids?: string[];
  };
}

export function fetchValidate({ ids }: fetchValidateArguments): Promise<AxiosResponse<fetchValidateResponse, any>> {
  const payload = {
    ids,
  };
  return axiosInstance.post(`customer/purchases/validate`, payload);
}

export interface postPurchaseArguments {
  ids: string[];
}

export interface postPurchaseResponse {
  data?: {
    taken_lot_offer_ids?: string[];
    overflown_lot_offer_ids?: string[];
  };
}

export function postPurchase({ ids }: postPurchaseArguments): Promise<AxiosResponse<postPurchaseResponse, any>> {
  const payload = {
    ids,
  };
  return axiosInstance.post(`customer/purchases`, payload);
}
