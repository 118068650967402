import { Box, Container, IconButton, SvgIcon, Tooltip, styled } from "@mui/material";
import FilterSection from "./components/filterSection/FilterSection";
import LosSection from "./components/LosSection";
import LosSectionSmall from "./components/LosSectionSmall";
import MapSection from "./components/MapSection";
import MapSectionSmall from "./components/MapSectionSmall";
import { useState } from "react";
import { useAppDispatch } from "app/hooks";
import { clearFilters, getLotLocations } from "features/lotLocations/lotLocationsSlice";
import Grid2 from "@mui/material/Unstable_Grid2";
import SortFilterSection from "./components/filterSection/SortFilterSection";
import LocationFilterSection from "./components/filterSection/LocationFilterSection";
import styles from "./Lossuche.module.css";
import { Add, Close, Delete, MoreVertical, Search } from "icons";
import SolidCubicMeterFilterSection from "./components/filterSection/SolidCubicMeterFilterSection";
import PricePerFeetMeterFilter from "./components/filterSection/PricePerFeetMeterFilter";
import WoodTypeFilterSelection from "./components/filterSection/WoodTypeFilterSection";
import DiameterFilterSection from "./components/filterSection/DiameterFilterSection";
import LengthFilterSection from "./components/filterSection/LengthFilterSection";
import CountyFilterSection from "./components/filterSection/CountyFilterSection";
import SellerFilterSection from "./components/filterSection/SellerFilterSection";

export function Lossuche() {
  window.scrollTo(0, 0);
  const [hide, setHide] = useState(true);
  const dispatch = useAppDispatch();
  const CustomContainer = styled(Container)`
    background-color: #d0c2a8;
    max-width: 100% !important;
    margin-bottom: auto;
  `;

  const toggleHide = () => {
    setHide(!hide);
  };

  const toggleFilters = (event: any) => {
    dispatch(clearFilters());
  };

  const updateLotLocations = () => {
    dispatch(getLotLocations());
  };

  return (
    <>
      <CustomContainer
        className={`CustomContainer ${hide ? "" : "hidden"}`}
        sx={{
          mb: "auto",
          height: "auto",
          p: { xs: "10px 8px 0px", md: "10px 12px 0px", lg: "10px 20px 0px" },
          zIndex: 1001,
          position: "fixed"
        }}
      >
        <Grid2
          container
          sx={{
            height: "inherit",
            paddingBottom: 2,
            gap: 2,
            display: "flex",
          }}
        >
          <Box sx={{ display: { xs: "none", md: "flex", gap: 16 } }}>
            <SortFilterSection />
            <LocationFilterSection />

            <Tooltip title="Suchen" placement="bottom">
              <IconButton
                className={styles.extendableShow + " " + styles.button}
                sx={{
                  width: { xs: "50px", lg: "50px" },
                  height: { xs: "50px", lg: "50px" },
                  justifyContent: "center",
                  backgroundColor: "white",
                  "&:hover": {
                    backgroundColor: "white",
                  },
                }}
                onClick={updateLotLocations}
              >
                <SvgIcon>
                  <Search />
                </SvgIcon>
              </IconButton>
            </Tooltip>

            <Tooltip title="Weitere Filter anzeigen" placement="bottom">
              <IconButton
                className={styles.extendableShow + " " + styles.button}
                sx={{
                  width: { xs: "50px", lg: "50px" },
                  height: { xs: "50px", lg: "50px" },
                  justifyContent: "center",
                  backgroundColor: "white",
                  "&:hover": {
                    backgroundColor: "white",
                  },
                }}
                onClick={toggleHide}
              >
                <SvgIcon>{hide ? <Add /> : <Close />}</SvgIcon>
              </IconButton>
            </Tooltip>

            <Tooltip title="Filter zurücksetzen" placement="bottom">
              <IconButton
                className={styles.extendableShow + " " + styles.button}
                sx={{
                  width: { xs: "50px", lg: "50px" },
                  height: { xs: "50px", lg: "50px" },
                  justifyContent: "center",
                  backgroundColor: "white",
                  "&:hover": {
                    backgroundColor: "white",
                  },
                }}
                onClick={toggleFilters}
              >
                <SvgIcon>
                  <Delete />
                </SvgIcon>
              </IconButton>
            </Tooltip>
          </Box>

          <Box
            sx={{ display: { xs: "flex", md: "none" }, width: "100%", gap: 1 }}
          >
            {/* <Box
              sx={{ width: { xs: "70%", } }}
            > */}

            <LocationFilterSection />
            {/* </Box> */}

            <Tooltip title="Suchen" placement="bottom">
              <IconButton
                className={styles.extendableShow + " " + styles.button}
                sx={{
                  width: { xs: "50px", lg: "50px" },
                  height: { xs: "50px", lg: "50px" },
                  justifyContent: "center",
                  backgroundColor: "white",
                  "&:hover": {
                    backgroundColor: "white",
                  },
                }}
                onClick={updateLotLocations}
              >
                <SvgIcon>
                  <Search />
                </SvgIcon>
              </IconButton>
            </Tooltip>


            <Tooltip title="Weitere Filter anzeigen" placement="bottom">
              <IconButton
                sx={{
                  width: '50px',
                  borderRadius: '50%',
                  justifyContent: "center",
                  backgroundColor: "white",
                  "&:hover": {
                    backgroundColor: "white",
                  },
                }}
                onClick={toggleHide}
                aria-label={hide ? "Mehr Filter ausblenden" : "Mehr Filter anzeigen"}
              >
                <SvgIcon>{hide ? <MoreVertical /> : <Close />}</SvgIcon>
              </IconButton>
            </Tooltip>
          </Box>
        </Grid2>
        {!hide ? (
          <Grid2
            container
            sx={{
              height: "inherit",
              paddingBottom: 1.5,
              gap: 2,
            }}
          >
            <SolidCubicMeterFilterSection />
            <PricePerFeetMeterFilter />
            <WoodTypeFilterSelection />
            <Box sx={{ display: { xs: "60%" } }}>
              <DiameterFilterSection />
            </Box>
            <LengthFilterSection />
            <CountyFilterSection />
            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              <SellerFilterSection />
            </Box>
            <Box sx={{ display: { xs: "flex", md: "none" }, gap: 1 }}>
              <SellerFilterSection />
              <Tooltip title="Filter zurücksetzen" placement="bottom">
                <IconButton
                  className={styles.extendableShow + " " + styles.button}
                  sx={{
                    width: { xs: "50px", lg: "50px" },
                    height: { xs: "50px", lg: "50px" },
                    justifyContent: "center",
                    backgroundColor: "white",
                    "&:hover": {
                      backgroundColor: "white",
                    },
                  }}
                  onClick={toggleFilters}
                >
                  <SvgIcon>
                    <Delete />
                  </SvgIcon>
                </IconButton>
              </Tooltip>
            </Box>
          </Grid2>
        ) : (
          <></>
        )}
      </CustomContainer>
      <Box
        sx={{
          display: { xs: "none", lg: "flex" },
          width: "100%",
          flexDirection: "row",
          pt: hide ? 10 : 27
        }}
      >
        {/* <Box sx={{ display: { md: "flex" }, width: "100%" }}> */}
        <LosSection />
        <MapSection />
      </Box>
      <Box
        sx={{
          display: {
            xs: "flex",
            lg: "none",
          },
          position: "relative",
          flexDirection: "column",
        }}
      >
        <MapSectionSmall />
        <LosSectionSmall />
      </Box>
    </>
  );
}
