import axiosInstance from "app/axios";
import { AxiosResponse } from "axios";
import { RegisterInformation } from "./userSlice";

export interface RegisterResponseInterface {
  data: {
    errors: {
      email: string[];
      password: string[];
      password_confirmation: string[];
    }
  };
}

export function postRegister(registerData: RegisterInformation): Promise<AxiosResponse<RegisterResponseInterface, any>> {
  return axiosInstance.post("user/register", registerData);
}
