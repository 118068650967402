import { Container } from "@mui/system";
import Grid2 from "@mui/material/Unstable_Grid2";
import styles from "./LotOffer.module.css";
import { Box, Button } from "@mui/material/";
import { MediumText } from "components/typography";
import Header from "components/header/Header";
import AgentMasterData from "./AgentMasterData";
import AgentInformation from "./AgentInformation";
import CountyInformation from "./CountyInformation";
import AgentOptions from "./AgentOptions";
import LotOfferButton from "../lotOffer/LotOfferButton";
import { patchAgent, selectAgent, selectAgents } from "features/agent/agentSlice";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "app/hooks";
import AgentUsers from "./AgentUsers";
import { getAgentUsers, selectAgentUsers, selectAgentUsersListTotal, selectCurrentAgentId } from "features/agent/agentSlice"
import { selectPageAgentUsersList, selectRowsPerPageAgentUsersList, setPageAgentUsersList, setRowsPerPageAgentUsersList } from "features/table/tableSlice";
import { current } from "@reduxjs/toolkit";
import { useState } from "react";

export default function AdminAgent() {

  const [error, setError] = useState("");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const saveChanges = () => {

    const promises = [];
    promises.push(dispatch(patchAgent()));
    Promise.all(promises).then((result) => {
      // navigate("/admin/agentenliste");
      setChangesSaved(true);
    });
  };
  const [changesSaved, setChangesSaved] = useState(false);

  const agentUsers = useAppSelector(selectAgentUsers)
  const agentListTotal = useAppSelector(selectAgentUsersListTotal);
  const page = useAppSelector(selectPageAgentUsersList)
  const rowsPerPage = useAppSelector(selectRowsPerPageAgentUsersList)
  const currentAgentId = useAppSelector(selectCurrentAgentId)
  const allagent = useAppSelector(selectAgents)
  // console.log(allagent.agent?.id)
  const currentAgentId2 = allagent.agent?.id
  // debugger

  return (
    <>
      <Container
        maxWidth="xl"
        sx={{
          pt: 4,
          pb: 4,
          // ml: 4,
          pl: { lg: 12, md: 14, xs: 13 },
        }}
      >
        <Header backButton header="Agent" />
        <Box
          sx={{
            mt: 3,
          }}
        >
          <div className={styles.LotOfferContainer}>
            <Grid2 container className={` ${styles.sdsd}`}>
              <AgentMasterData className={styles.LotOfferMasterData} />
              <AgentInformation className={styles.LotOfferMasterData} />
              <CountyInformation className={styles.LotOfferMasterData} />
              <AgentOptions error={error} setError={setError} className={styles.LotOfferMasterData} />
            </Grid2>
          </div>
        </Box>

        {changesSaved && (
          <Box sx={{ mt: 2 }}>
            <MediumText fWeight="bold" color="#072D26">
              Änderungen wurden gespeichert.
            </MediumText>
          </Box>
        )}
        <Box sx={{ mt: 2, display: "flex", gap: 1 }}>
          <LotOfferButton
            label="Änderungen speichern"
            sx={{ borderRadius: "26px", height: "51px", fontWeight: "bold" }}
            onClick={saveChanges}
            disabled={error.length > 0}
          />

          <Button
            style={{
              borderRadius: "26px",
              color: "#072D26",
              fontWeight: "bold",
              border: "2px solid #C0D904",
            }}
            className={`${styles.roundInputs} ${styles.sizeInputs}`}
            variant="outlined"
            onClick={() => { navigate("/admin/agentenliste") }}
          >
            Änderungen VERWERFEN
          </Button>
        </Box>
        <AgentUsers page={page} rowsPerPage={rowsPerPage} agentId={currentAgentId2 ? currentAgentId2 : ""} agentListTotal={agentListTotal} />
      </Container>
    </>
  );
}
