import axiosInstance from "app/axios";
import { AxiosResponse } from "axios";
import { LotOfferInterface } from "interfaces/LotOfferInterface";
import { UserRoles } from "interfaces/UserRoles";

export interface BulkEditLotOffersInterface {
    ids: string[];
    type: string;
    price: number;
    endpoint?: UserRoles;
}

export interface BulkUpdateLotOffersResponseInterface {
  data: {
    
  };
}

export function fetchBulkEditLotOffers({ ids, type, price, endpoint = UserRoles.Admin }: BulkEditLotOffersInterface): Promise<AxiosResponse<BulkUpdateLotOffersResponseInterface, any>> {   
    return axiosInstance.patch(`${endpoint}/lot_offers/bulk_update`, {ids, type, price});
}
