import {
  FormControl,
  MenuItem,
  Select,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { FilterLotSearchText } from "components/typography";
import { SortKinds, setSort, selectLotLocationsSort } from "features/lotLocations/lotLocationsSlice";


export default function SortFilterSection() {
  const dispatch = useAppDispatch();
  const sortIndex = useAppSelector(selectLotLocationsSort);

  const handleSort = (event: any) => {
    dispatch(setSort(event.target.value));
  };

  return (
    <FormControl>
      <Select
        value={sortIndex || 0}
        displayEmpty
        onChange={handleSort}
        variant="standard"
        disableUnderline
        renderValue={(selected) => {
          return (
            <FilterLotSearchText color={"gray"}>
              {SortKinds[selected || 0].label}
            </FilterLotSearchText>
          );
        }}
        sx={{
          backgroundColor: "white",
          minWidth: { xs: "100px", lg: "176px" },
          borderRadius: "26px",
          display: "flex",
          alignItems: "center",
          height: "50px",
          "&::before": { borderBottom: "none" },
        }}
      >
        {SortKinds?.map((sortKind, index) => 
          <MenuItem key={index} value={index}>
            {sortKind.label}
          </MenuItem>
        )}
      </Select>
    </FormControl>
  );
}
