import { Box } from "@mui/material";
import { LotImagesType } from "interfaces/LotImagesInterface";

interface LotOfferImageProps {
  lotOfferImage: LotImagesType;
}

export default function LotOfferSmallImage(props: LotOfferImageProps) {
  const baseURL = process.env.REACT_APP_API_DOMAIN;
  const url =
    typeof props.lotOfferImage === "string"
      ? baseURL + props.lotOfferImage
      : baseURL + props.lotOfferImage.url;

  return (
    <Box
      component="img"
      src={url}
      sx={{
        maxWidth: "100%",
        width: "100px",
        height: "70px",
      }}
    />
  );
}
