import { Grow, Box, Typography, Button, Radio } from "@mui/material"
import HolzfinderNumberInput from "components/inputs/HolzfinderNumberInput";
import { formatNumberInput, validateNumberInput } from "functions/lotPrice";
import { useState } from "react";

interface Props {
    canceled: Function;
    confirmed: Function;
    checkedBoxes: Number;
}

export default function BulkEdit(props: Props) {
    const validPriceRegex = /^\d+((\.|,)\d+)?$/;
    const { canceled, confirmed, checkedBoxes } = props

    const [netPriceActive, setNetPriceActive] = useState(true)
    const [netPrice, setNetPrice] = useState<string>("0")
    const [grossPrice, setGrossPrice] = useState<string>("0")
    const [nonValidNumber, setNonValidNumber] = useState(false)
    const [alreadyConfirmed, setAlreadyConfirmed] = useState(false)

    const handleNetInput = (value: string) => {
        const isValidPrice = validPriceRegex.test(value);
        if (isValidPrice) {
            setNetPrice(Number(value).toFixed(2))
            setNonValidNumber(false)
        } else {
            setNetPrice("0");
            setNonValidNumber(true)
        }
    }
    const handleNGrossInput = (value: string) => {
        const isValidPrice = validPriceRegex.test(value);
        if (isValidPrice) {
            setGrossPrice(Number(value).toFixed(2))
            setNonValidNumber(false)
        } else {
            setNetPrice("0");
        }
    }
    const checkIfvalid = (value: string) => {
        return validPriceRegex.test(value)
    }

    const handleCheckboxChange = () => {
        setNetPriceActive(!netPriceActive)
    }
    const handleConfirmed = () => {
        if (netPriceActive) {
            if (checkIfvalid(netPrice)) {
                confirmed("net", netPrice)
                setAlreadyConfirmed(true)
            } else {
                setNonValidNumber(true)
            }
        } else {
            if (checkIfvalid(grossPrice)) {
                setAlreadyConfirmed(true)
                confirmed("gross", grossPrice)
            } else {
                setNonValidNumber(true)
            }

        }
    }

    return (
        <Grow in={true} style={{ transform: "translate(-50%, -50%)" }}>
            <Box sx={{
                width: "30%",
                minWidth: "300px",
                maxWidth: "90%",
                position: "absolute",
                top: "50%",
                left: "50%",
                backgroundColor: "white",
                transform: "translate(-50%, -50%)",
                borderRadius: "16px",
                display: "flex",
                flexDirection: "column",
                gap: 2,
                padding: 4,
                textAlign: "center",
            }}>
                <Typography fontSize={20} fontWeight={"bold"}>
                    {`Geben Sie den gewünschten Preis für die ausgewählten Lose (${checkedBoxes}) an. `}
                </Typography>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 2, alignContent: "center" }}>
                    <Box sx={{ flexDirection: "row" }}>
                        <Radio
                            checked={netPriceActive ? true : false}
                            onChange={handleCheckboxChange}
                            name="radio-buttons"
                            sx={{ color: "#00000061" }}
                        />
                        <HolzfinderNumberInput
                            unit="Netto Preis €/Fm"
                            value={netPrice}
                            disabled={netPriceActive ? false : true}
                            onChange={handleNetInput}
                            validateInput={(s) => validateNumberInput(s, 3)}
                            formatInput={formatNumberInput}
                            style={{ width: '75%' }}
                        />
                    </Box>
                    <Box sx={{ flexDirection: "row" }}>
                        <Radio
                            checked={netPriceActive ? false : true}
                            onChange={handleCheckboxChange}
                            name="radio-buttons"
                            sx={{ color: "#00000061" }}
                        />
                        <HolzfinderNumberInput
                            unit="Brutto Preis €/Fm"
                            value={grossPrice}
                            disabled={netPriceActive ? true : false}
                            onChange={handleNGrossInput}
                            validateInput={(s) => validateNumberInput(s, 3)}
                            formatInput={formatNumberInput}
                            style={{ width: '75%' }}

                        />
                    </Box>
                </Box>
                {nonValidNumber ? (
                    <Typography fontSize={17.5} fontWeight={"bold"} sx={{ color: "rgb(211, 47, 47)", alignSelf: "normal" }}>
                        Bitte geben Sie einen Wert größer als 0 ein.
                    </Typography>
                ) : null}
                <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap", gap: 1, mt: 2 }}>
                    <Button
                        onClick={() => {
                            canceled()
                        }}
                        sx={{
                            flexGrow: 1,
                            borderRadius: "26px",
                            color: "#072D26",
                            fontWeight: "bold",
                            border: "2px solid #C0D904",
                            height: "51px",
                            paddingLeft: "50px",
                            paddingRight: "50px",
                            "&:hover": { background: "#c0d904" }
                        }}>
                        Abbrechen
                    </Button>
                    <Button
                        onClick={() => {
                            handleConfirmed()
                        }}
                        disabled={alreadyConfirmed}
                        sx={{
                            flexGrow: 1,
                            borderRadius: "26px",
                            color: "#072D26",
                            fontWeight: "bold",
                            border: "2px solid #C0D904",
                            height: "51px",
                            paddingLeft: "50px",
                            paddingRight: "50px",
                            "&:hover": { background: "#c0d904" }
                        }}
                    >
                        Bestätigen
                    </Button>
                </Box>
            </Box>
        </Grow>
    )
}