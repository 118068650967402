import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RequestStatus } from "interfaces/RequestStatus";
import { getLotList } from "./lotListSlice";
import { RootState } from "app/store";


export interface FilterAndSortState {
    filter: string;
    sort: string;
  }
const initialState: FilterAndSortState = {
    filter: "",
    sort: "",
};

export const FilterAndSort = createSlice({
    name: "filterAndSort",
    initialState,
    reducers: {
      clear: (state) => {
        return initialState;
      },
      setFilter: (state, action: PayloadAction<string>) => {
        state.filter = action.payload
      },
      setSorting: (state, action: PayloadAction<string>) => {
        state.sort = action.payload
      } 
    },
  });

  export const { clear, setFilter, setSorting } = FilterAndSort.actions;

  export const selectFilter = (state: RootState) => state.filterAndSort.filter;
  export const selectSorting = (state: RootState) => state.filterAndSort.sort;

  export default FilterAndSort.reducer;