import { Divider, Paper } from "@mui/material";
import { useAppDispatch, useAppSelector } from "app/hooks";
import HolzfinderSlider from "components/inputs/HolzfinderSlider";
import { FilterLotSearchText } from "components/typography";
import { selectLotLocationFilter, selectLotLocationFilterSettings, setPrice } from "features/lotLocations/lotLocationsSlice";

export default function PricePerFeetMeterFilter() {
  const dispatch = useAppDispatch();
  const valueSettings = useAppSelector(selectLotLocationFilterSettings).price_per_solid_cubic_meter;
  const value = useAppSelector(selectLotLocationFilter).price_per_solid_cubic_meter;

  const handlePrice = (event: any) => {
    dispatch(setPrice(event.target.value));
  };

  if (valueSettings[0] === valueSettings[1]) return <div></div>;

  return (
    <Paper
      sx={{
        backgroundColor: "white",
        minWidth: { xs: "300px", lg: "360px" },
        borderRadius: "26px",
        display: "flex",
        padding: "2px 4px",
        alignItems: "center",
        height: "50px",
      }}
    >
      <FilterLotSearchText>Preis/fm</FilterLotSearchText>
      <Divider sx={{ height: 28, m: 1, color: "#1F2A40", border: 1 }} orientation="vertical" />
      <FilterLotSearchText mx={1}>{valueSettings[0]}</FilterLotSearchText>

      <HolzfinderSlider value={value} onChange={handlePrice} min={valueSettings[0]} max={valueSettings[1]} valueLabelDisplay="auto" size="small" />
      <FilterLotSearchText mx={1}>{valueSettings[1]}</FilterLotSearchText>
    </Paper>
  );
}
