import * as React from "react";
import { SVGProps } from "react";
const SvgBuyer = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 37.69 36.17" {...props}>
    <path d="M10.76 25.51h-.12c-1.38 0-2.72-.56-3.68-1.55-2.05-2.07-2.03-5.39.02-7.42 2.05-2.04 5.4-2.04 7.45 0 1.02 1.03 1.56 2.34 1.55 3.74 0 1.4-.56 2.71-1.55 3.7-.95.97-2.28 1.53-3.66 1.53Zm-.06-3h.03c.6 0 1.16-.22 1.57-.65.89-.89.9-2.31.03-3.18-.9-.89-2.35-.9-3.24-.01-.42.42-.66.98-.66 1.58s.23 1.16.65 1.58c.44.45 1.01.68 1.6.68h.03ZM21.4 36.17H0l.71-2c1.54-4.35 5.56-7.27 9.99-7.27s8.45 2.92 9.99 7.27l.71 2Zm-16.83-3h12.25c-1.42-2.02-3.69-3.27-6.13-3.27s-4.7 1.25-6.13 3.27Z" />
    <path d="M36.79.9c-.6-.6-1.3-.9-2.1-.9h-30c-.8 0-1.5.3-2.1.9-.6.6-.9 1.3-.9 2.1v25.83c.88-.93 1.9-1.71 3-2.33v-2.17a7.176 7.176 0 0 1-1.26-4.11c0-1.47.45-2.87 1.26-4.06V3h30v30H22.36c.07.17.15.33.21.5l.71 2 .18.5h11.23c.8 0 1.5-.3 2.1-.9.6-.6.9-1.3.9-2.1V3c0-.8-.3-1.5-.9-2.1Z" />
    <path d="M29.49 24.7h-12.2v2.16c.4.26.79.54 1.17.84h11.03v-3ZM17.29 8.3h12.2v3h-12.2zM29.49 16.5h-12.2v.7c.34.72.56 1.5.64 2.3h11.56v-3Z" />
    <circle cx={10.69} cy={9.8} r={1.5} />
  </svg>
);
export default SvgBuyer;
