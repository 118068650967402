import { Box, Paper } from "@mui/material";
import { useAppSelector } from "app/hooks";
import { MediumText } from "components/typography";
import { selectCustomer } from "features/customer/customerSlice";
import { mapCustomerCountries } from "features/customer/mapCustomerCountries";
import { selectUserData } from "features/user/userSlice";
import { useEffect, useState } from "react";

export default function OrderOverview() {
  const [countryName, setCountryName] = useState("");
  const { city, street, street_number, zip_code, country_id } =
    useAppSelector(selectCustomer);
  const { first_name, last_name } = useAppSelector(selectUserData);
  const mappedCountries = mapCustomerCountries();
  const countryData = mappedCountries.get(country_id);
  useEffect(() => {
    if (countryData) {
      setCountryName(countryData.name);
    }
  }, [countryData]);
  return (
    <Paper
      sx={{
        borderRadius: 3,
        height: "fit-content",
        mt: 5,
      }}
    >
      <Box sx={{ padding: 4.5, display: "flex", flexDirection: "column" }}>
        {/* der Zahlungsart */}
        <MediumText fWeight="bold" whiteSpace="pre">
          Adresse
        </MediumText>

        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <MediumText>
            {first_name} {last_name}
          </MediumText>
          <MediumText>
            {street} {street_number}
          </MediumText>
          <MediumText>
            {zip_code} {city}
          </MediumText>
          <MediumText>{countryName}</MediumText>
        </Box>
      </Box>
    </Paper>
  );
}
