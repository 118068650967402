import React from 'react';
import { Box, Container } from "@mui/material";
import Header from "components/header/Header";
import {
  LargeText,
  MediumLargeText,
  MediumText,
  SmallText,
} from "components/typography";

const NotFoundPage = () => {
  return (
    <div>
      <Container sx={{ p: 1, mt: 5 }}>
        <Header
          header="Error 404"
        />
        <Box sx={{ mt: 3, mb: 4 }}>
          <LargeText my={0.5}>Seite nicht gefunden!</LargeText>
          <Box sx={{ marginBottom: 6 }}>
            <MediumText mt={3}>
              Entschuldigung, die von Ihnen angeforderte Seite existiert nicht.
            </MediumText>
            <MediumText>
            </MediumText>
          </Box>
        </Box>
      </Container>
    </div>
  );
};

export default NotFoundPage;
