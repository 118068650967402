import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { Alert, Box, Button, Container, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, MenuItem, Select, styled, TextField } from "@mui/material";
import { useAppDispatch, useAppSelector } from "app/hooks";
import axios from "axios";
import { capitalizeFirstLetter } from "components/header-navbar/HeaderNavbar";
import Header from "components/header/Header";
import HolzfinderCheckbox from "components/inputs/HolzfinderCheckbox";
import { FilterLotSearchText, MediumLargeText, MediumText } from "components/typography";
import { createAgent, selectCurrentAgentId, selectInvitationErrors } from "features/agent/agentSlice";
import { clearRegisterErrors, register, selectRegisterErrors } from "features/user/userSlice";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "./InviteAgent.module.css";


export default function InviteAgent() {
  const dispatch = useAppDispatch();
  const errors: any = useAppSelector(selectInvitationErrors) || {};

  const [inviteSuccess, setInviteSuccess] = useState(false);
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const agent_id = useAppSelector(selectCurrentAgentId)

  const BrownBorderTextField = styled(TextField)`
    & label.Mui-focused {
      color: #d0c2a8;
    }
    & .MuiOutlinedInput-root {
      &.Mui-focused fieldset {
        border-color: #d0c2a8;
      }
    }
  `;

  function errorExists(name: string): boolean {
    return !!errors[name] as boolean;
  }
  function extractError(name: string): string {
    return !!errors[name] ? (errors[name].join(", ") as string) : "";
  }

  useEffect(() => {
    if (errors && errors.length > 0) {
      setTimeout(() => {
        dispatch(clearRegisterErrors());
      }, 10000);
    }
  }, [errors]);

  return (
    <>
      <Container
        maxWidth="xl"
        sx={{
          pt: 4,
          pb: 4,
          // ml: 4,
          pl: { lg: 12, md: 14, xs: 13 },
        }}
      >
        <Header header="Nutzer zum Agent einladen" backButton />
        <FormControl
          sx={{
            width: { lg: "60%", md: "80%", xs: "100%" },
            gap: 0.5,
            pt: 2,
          }}
        >

          <MediumText>Vorname</MediumText>
          <TextField
            error={errorExists("first_name")}
            helperText={extractError("first_name")}
            type="text"
            value={first_name}
            placeholder="Vorname"
            onChange={(event: any) => {
              setFirstName(event.target.value);
            }}
            name={"first_name"}
            InputProps={{
              className: styles.input
            }}
          />

          <MediumText>Nachname</MediumText>
          <TextField

            error={errorExists("last_name")}
            helperText={extractError("last_name")}
            type="text"
            value={last_name}
            placeholder="Nachname"
            onChange={(event: any) => {
              setLastName(event.target.value);
            }}
            name={"last_name"}
            InputProps={{
              className: styles.input
            }}
          />

          <MediumText>E-Mail*</MediumText>
          <TextField
            error={errorExists("email")}
            helperText={extractError("email")}
            type="email"
            value={email}
            placeholder="E-Mail"
            onChange={(event: any) => {
              setEmail(event.target.value);
            }}
            name={"email"}
            InputProps={{
              className: styles.input
            }}
          />
          <div>
            <Button
              sx={{
                color: "#072D26",
                my: 2,
                mt: 2,
                backgroundColor: "#C4D661",
                borderColor: "#C4D661",
                borderRadius: 30,
                fontSize: "small",
                paddingY: 1.5,
                paddingX: 5,
                "&:hover": {
                  backgroundColor: "#C0D904",
                },
              }}
              onClick={() => {
                dispatch(
                  createAgent({
                    first_name,
                    last_name,
                    email,
                    agent_id
                  })
                ).then((data: any) => {
                  if (data.type === "agent/createAgent/fulfilled") {
                    setInviteSuccess(true);
                  }
                });
              }}
            >
              <MediumText fWeight="bold">Einladung senden</MediumText>
            </Button>
          </div>
        </FormControl>
        {inviteSuccess ? <Alert severity="success">Die Einladung war erfolgreich und eine Email wurde verschickt.</Alert> : null}
      </Container>
    </>

  );
}
