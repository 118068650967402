import { Typography } from "@mui/material";
import { useAppSelector } from "app/hooks";
import { selectSeller } from "features/seller/sellerSlice";
import SellerData from "./SellerData";
import SellerGrid from "./SellerGrid";

const ContactDataLabels = {
  phone: "Telefon",
  mobile: "Mobil",
  fax: "Fax",
  email: "E-Mail",
  tax_number: "Steuernummer",
  vat_identification_number: "Mehrwertsteuer-Identifikationsnummer",
  payment_provider_reference: "Referenz des Zahlungsanbieters", 
  bank_iban: "IBAN",
  bank_bic: "BIC"
};

export default function SellerInformation(props: any) {
  const sellerData = useAppSelector(selectSeller);
  return (
    <SellerGrid>
      {
        sellerData?.phone ? (
          <SellerData
            label={ContactDataLabels.phone}
            data={sellerData?.phone}
          />
        ) : (
          <></>
        )
      }
      {
        sellerData?.mobile ? (
          <SellerData
            label={ContactDataLabels.mobile}
            data={sellerData?.mobile}
          />
        ) : (
          <></>
        )
      }
      {
        sellerData?.fax ? (
          <SellerData
            label={ContactDataLabels.fax}
            data={sellerData?.fax}
          />
        ) : (
          <></>
        )
      }
      {
        sellerData?.email ? (
          <SellerData
            label={ContactDataLabels.email}
            data={sellerData?.email}
          />
        ) : (
          <></>
        )
      }
      {
        sellerData?.tax_number ? (
          <SellerData
            label={ContactDataLabels.tax_number}
            data={sellerData?.tax_number}
          />
        ) : (
          <></>
        )
      }
      {
        sellerData?.vat_identification_number ? (
          <SellerData
            label={ContactDataLabels.vat_identification_number}
            data={sellerData?.vat_identification_number}
          />
        ) : (
          <></>
        )
      }
      {
        sellerData?.payment_provider_reference ? (
          <SellerData
            label={ContactDataLabels.payment_provider_reference}
            data={sellerData?.payment_provider_reference}
          />
        ) : (
          <></>
        )
      }
            {
        sellerData?.bank_iban ? (
          <SellerData
            label={ContactDataLabels.bank_iban}
            data={sellerData?.bank_iban}
          />
        ) : (
          <></>
        )
      }
      {
        sellerData?.bank_bic ? (
          <SellerData
            label={ContactDataLabels.bank_bic}
            data={sellerData?.bank_bic}
          />
        ) : (
          <></>
        )
      }
    </SellerGrid>
  );
}
