import { useAppSelector } from "app/hooks";
import { selectLotLocationsUserLocation } from "features/lotLocations/lotLocationsSlice";
import L from "leaflet";
import { useEffect } from "react";
import { Marker, useMap } from "react-leaflet";
import user from "assets/icons/User.svg";
export default function CenterMapToLocation() {
  const map = useMap();
  const location = useAppSelector(selectLotLocationsUserLocation);
  useEffect(() => {
    map.getContainer().focus = () => { }; //to disable map jump
    if (location.latitude && location.longitude) {
      map.setView(
        { lat: location.latitude, lng: location.longitude },
        map.getZoom(),
        { animate: true }
      );
    }
  }, [location, map]);
  if (!location.latitude || !location.longitude) return null;
  let UserIcon = L.icon({
    iconUrl: user,
    iconRetinaUrl: user,
    shadowUrl: undefined,
    iconSize: [38, 95], // size of the icon
    shadowSize: [50, 64], // size of the shadow
    iconAnchor: [22, 94], // point of the icon which will correspond to marker's location
    popupAnchor: [-3, -76], // point from which the popup should open relative to the iconAnchor
  });
  return (
    <Marker
      icon={UserIcon}
      position={{ lat: location.latitude, lng: location.longitude }}
    />
  );
}
