import { Box, Button, SvgIcon } from "@mui/material";
import { MediumText } from "components/typography";
import { useNavigate } from "react-router-dom"
interface TextWithIconProps {
  icon: JSX.Element;
}

export default function BackButton(props: TextWithIconProps) {
  const navigate = useNavigate();
  const { icon } = props;
  return (
    <Box sx={{ display: "flex", alignItems: "center", my: 0.5 }}>
      <Button onClick={() => navigate(-1)} sx={{padding: 0, color:"#000000", "&:hover": {backgroundColor: "white"}}}>
        <SvgIcon inheritViewBox>{icon}</SvgIcon> <MediumText fWeight="bold">Zurück</MediumText>
      </Button>
    </Box>
  );
}
