import Grid from "@mui/system/Unstable_Grid";
import React from "react";

export default function SellerGrid({
  children,
}: React.PropsWithChildren) {
  return (
    <Grid
      xs={12}
      md={6}
      lg={3}
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        paddingRight: "22px",
      }}
    >
      {children}
    </Grid>
  );
}
