import { useAppSelector } from "app/hooks";
import { selectUserData, selectUserIsLoggedId } from "features/user/userSlice";
import React from "react";
import { LoginButton } from ".";
import AccountMenu from "./AccountMenu";
import LinkWithHoverLine from "./LinkWithHoverLine";
export default function NavigationProfileButton() {
  const isLoggedIn = useAppSelector(selectUserIsLoggedId);
  const { first_name, last_name } = useAppSelector(selectUserData);

  if (isLoggedIn) {
    const userName = first_name && last_name ? `${first_name} ${last_name}` : "PROFIL";
    return <AccountMenu text={userName} />;
  }
  return (
    <LinkWithHoverLine to="/login">
      <LoginButton text="LOGIN" />
    </LinkWithHoverLine>
  );
}
