import { Box } from "@mui/material";
import { LargeText } from "../../typography/index";
import styles from "../FooterNavbar.module.css";

export default function Information() {
  return (
    <Box className={styles.footerTitleFontSize}>
      <LargeText color="#FFFFFF" fWeight="bold">
        HOLZFINDER
      </LargeText>
    </Box>
  );
}
