import { Box, Button, Checkbox, Container, FormControl, FormControlLabel, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import axiosInstance from "app/axios";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { AxiosResponse } from "axios";
import Header from "components/header/Header";
import HolzfinderDateInputWithoutTime from "components/inputs/HolzfinderDateInputWithoutTime";
import { getAgentList, selectAgentList } from "features/agentList/agentListSlice";
import { selectUserRole } from "features/user/userSlice";
import { set } from "husky";
import { UserRoles } from "interfaces/UserRoles";
import { string } from "prop-types";
import { useEffect, useState } from "react";

interface fetchReportsArgument {
    start_time: string;
    end_time: string;
    agent_id?: string;
    endpoint?: UserRoles;
}

interface ReportsResponseInterface {
    data: {

    };
}

export default function Reports() {
    const userRole = useAppSelector(selectUserRole);
    const [dateFrom, setDateFrom] = useState("")
    const [dateTo, setDateTo] = useState("")
    const [selectedMenuItem, setSelectedMenuItem] = useState("")
    const dispatch = useAppDispatch();
    const agentList = useAppSelector(selectAgentList);
    const [agents, setAgents] = useState(Array)
    const [showNoAgentError, setShowNoAgentError] = useState(false)
    const [alreadyReported, setAlreadyReported] = useState(false)


    // useEffect(() => {
    //     if(alreadyReported){
    //         setAlreadyReported(false)
    //     }
    // }, [dateTo, dateFrom, selectedMenuItem])

    const handlePlannedDateFrom = (value: string) => {
        setDateFrom(value)
    }
    const handlePlannedDateTo = (value: string) => {
        setDateTo(value)
    }
    const handleGenerateReport = () => {
        if (userRole === "admin" && selectedMenuItem === "") {
            setShowNoAgentError(true)
        } else {
            setAlreadyReported(true)
            setShowNoAgentError(false)
            const formattedDateFrom = formatDate(dateFrom)
            const formattedDateTo = formatDate(dateTo)
            receiveReports(formattedDateFrom, formattedDateTo)
        }
    }

    // 02-08-2023 08:49
    // 2023-08-08:49:00.000+01:00
    const formatDate = (inputDate: string) => {
        console.log(inputDate)
        const dateParts = inputDate.split(' ')[0].split('-');
        console.log(dateParts)
        // const timeParts = inputDate.split(' ')[1].split(':');
        const year = parseInt(dateParts[2]);
        const month = parseInt(dateParts[1]) - 1;
        const day = parseInt(dateParts[0]);

        const jsDate = new Date(year, month, day);
        const jsDateUTCString = jsDate.toUTCString();
        console.log("___", jsDateUTCString)

        return jsDateUTCString;
    }


    const getReports = async (args: fetchReportsArgument) => {
        const response = await fetchReports({ ...args, endpoint: userRole })
        return { headers: response.headers, data: response.data };
    }
    const receiveReports = (start_time: string, end_time: string) => {
        if (userRole === "admin") {
            getReports({
                start_time: start_time,
                end_time: end_time,
                agent_id: selectedMenuItem,
                endpoint: userRole
            })
                .then((event: any) => {
                    if (event.data) {
                        handleDownload(event)
                    }
                });
        } else {
            getReports({
                start_time: start_time,
                end_time: end_time,
                endpoint: userRole
            })
                .then((event: any) => {
                    if (event.data) {
                        handleDownload(event)
                    }
                });
        }
        const handleDownload = async (res: any) => {
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(res.data);
            var contentDisposition = res.headers["content-disposition"].split(';')
            var filename = contentDisposition[2].slice(18)
            link.download = `report_${filename}`;
            link.click();
            setTimeout(() => {
                setAlreadyReported(false)
            }, 3000)
        };

    }

    function fetchReports({ start_time, end_time, agent_id, endpoint = UserRoles.Admin }: fetchReportsArgument): Promise<AxiosResponse<ReportsResponseInterface, any>> {
        return axiosInstance.get(`${endpoint}/reports`, {
            params: {
                start_time,
                end_time,
                agent_id,
            },
            responseType: "blob"
        });
    }

    const handleSelectMenu = (event: SelectChangeEvent) => {
        setSelectedMenuItem(event.target.value as string)
    }

    const receiveAgentList = () => {
        dispatch(
            getAgentList({
                items_per_page: 50,
                page: 1,
                search: "",
            })
        ).then((res: any) => {
            const mappedData = res.payload.data.data.agents.map(
                (agent: any) => ({
                    id: agent.id,
                    company: agent.company,
                })
            );
            const allAgents = [{ id: "all", company: "Alle" }, ...mappedData];

            setAgents(allAgents)
        })
    };
    useEffect(() => {
        if (userRole === "admin") {
            receiveAgentList();
        }
    }, []);
    return (
        <Container sx={{ pl: { lg: 12, md: 12, xs: 12 }, pt: 5 }} maxWidth="xl">
            <Header header="Reports" />
            {userRole === "admin" ? (
                <Box sx={{ mt: 5 }}>
                    <FormControl sx={{ minWidth: "30%" }}>
                        <InputLabel id="demo-simple-select-autowidth-label">Bitte auswählen</InputLabel>
                        <Select
                            labelId="demo-simple-select-autowidth-label"
                            id="demo-simple-select-autowidth"
                            value={selectedMenuItem}
                            label="Bitte auswählen"
                            onChange={handleSelectMenu}
                        >
                            {agents.map((agent: any) => (
                                <MenuItem key={agent.id} value={agent.id}>{agent.company}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
            ) : null}
            {showNoAgentError ? (
                <Typography sx={{ color: "rgb(211, 47, 47)", ml: 0.5, mt: 1 }} fontSize={19} fontWeight={"bold"}>
                    Bitte wählen Sie einen Agenten aus.
                </Typography>
            ) : null}
            <Box sx={{ mt: 5, display: "flex", flexDirection: "row" }} >
                <Typography sx={{ mt: 1.5, mr: 1 }} fontSize={20} fontWeight={"bold"}>Von</Typography>
                <HolzfinderDateInputWithoutTime value="" onChange={handlePlannedDateFrom} disablePast={false} />
                <Typography sx={{ mt: 1.5, ml: 3, mr: 1 }} fontSize={20} fontWeight={"bold"}>Bis</Typography>
                <HolzfinderDateInputWithoutTime value="" onChange={handlePlannedDateTo} disablePast={false} />
                <Button
                    onClick={() => {
                        handleGenerateReport()
                    }}
                    disabled={alreadyReported}
                    sx={{
                        ml: 8,
                        flexGrow: 0.2,
                        borderRadius: "26px",
                        color: "#072D26",
                        fontWeight: "bold",
                        border: "2px solid #C0D904",
                        height: "56px",
                        paddingLeft: "50px",
                        paddingRight: "50px",
                        "&:hover": { background: "#c0d904" }
                    }}>
                    Report generieren
                </Button>
            </Box>
        </Container>
    )
}