import { Box, Container, Grid } from "@mui/material";
import { useAppSelector } from "app/hooks";
import Header from "components/header/Header";
import ShoppingList from "components/shoppingCart/ShoppingList";
import ShoppingSummary from "components/shoppingCart/ShoppingSummary";
import { selectCustomerPurchase } from "features/customer/customerSlice";
import { selectUserIsLoggedId } from "features/user/userSlice";
import useDate from "hooks/useDate";
import { CustomerPurchaseInterface } from "interfaces/CustomerPurchaseInterface";
import { useParams } from "react-router-dom";
import InvoiceLotList from "./InvoiceLotList";

// const dispatch = useAppDispatch();
// const { login_and_validation_required} = clientInformation

export default function InvoiceOverview() {
  const purchaseData = useAppSelector(selectCustomerPurchase) as CustomerPurchaseInterface
  const {format} = useDate();
  return (
    <Container sx={{ pt: 4, pb: 4 }}>
      <Grid container>
        <Header header={`Rechnung vom ${format(purchaseData.created_at)}`} backButton />
        <InvoiceLotList />
      </Grid>
    </Container>
  );
}
