import * as React from "react";
import { SVGProps } from "react";
const SvgPayOnline = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80" {...props}>
    <g data-name="Gruppe 755">
      <path data-name="Rechteck 1519" fill="#fff" d="M0 0h80v80H0z" />
      <g data-name="Gruppe 751" fill="#005851">
        <path
          data-name="Pfad 144"
          d="M60.275 17h-.754a1.204 1.204 0 0 0 0 2.408h.754a3.682 3.682 0 0 1 3.677 3.679v26.971a3.681 3.681 0 0 1-3.677 3.677h-11.07a3.5 3.5 0 0 0-1.842.524l-11.348 7.025a.128.128 0 0 1-.2-.109v-5.3a2.141 2.141 0 0 0-2.138-2.138h-13.6A3.681 3.681 0 0 1 16.4 50.06V23.087a3.682 3.682 0 0 1 3.677-3.679h39.821a1.204 1.204 0 0 0 0-2.408H20.085A6.093 6.093 0 0 0 14 23.087v26.971a6.092 6.092 0 0 0 6.085 6.085h13.326v5.032a2.537 2.537 0 0 0 3.871 2.157l11.348-7.026a1.09 1.09 0 0 1 .575-.163h11.069a6.092 6.092 0 0 0 6.085-6.085V23.087A6.093 6.093 0 0 0 60.275 17Z"
        />
        <path
          data-name="Pfad 145"
          d="M27.681 28.537h-4.2a1.2 1.2 0 0 0-1.2 1.2v14.464a1.2 1.2 0 0 0 1.2 1.2h.005c.039 0 3.877-.017 4.927-.017a5.093 5.093 0 0 0 2.726-9.4 4.527 4.527 0 0 0-3.46-7.451Zm0 2.408a2.123 2.123 0 1 1 0 4.245h-3v-4.245Zm.734 12.032c-.677 0-2.5.007-3.729.012v-5.381l3.729-.006a2.688 2.688 0 1 1 0 5.375Z"
        />
        <path
          data-name="Pfad 146"
          d="M50.293 29.083a1.204 1.204 0 0 0-2.016 1.316l4.421 6.771-.017 7.025a1.2 1.2 0 0 0 1.2 1.207 1.2 1.2 0 0 0 1.2-1.2l.017-7.029 4.386-6.737a1.204 1.204 0 1 0-2.018-1.313l-3.573 5.488Z"
        />
        <path
          data-name="Pfad 147"
          d="M35.773 28.537a1.2 1.2 0 0 0-1.2 1.2v9.948a5.4 5.4 0 0 0 2.824 4.761 6.064 6.064 0 0 0 3.311.952h.01a6.431 6.431 0 0 0 2.922-.675 5.464 5.464 0 0 0 3.387-5.048v-9.934a1.204 1.204 0 0 0-2.408 0v9.937c0 .8-.214 1.967-2.062 2.9a4.047 4.047 0 0 1-1.839.418h-.006a3.694 3.694 0 0 1-2.018-.573 3.058 3.058 0 0 1-1.712-2.732v-9.95a1.2 1.2 0 0 0-1.209-1.204Z"
        />
      </g>
    </g>
  </svg>
);
export default SvgPayOnline;
