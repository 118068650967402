import { Box, Grid } from "@mui/material";
import { MediumLargeText, XXLargeText } from "components/typography";
import BackButton from "components/buttons/BackButton";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

interface HeaderProps {
  backButton?: boolean;
  header: string;
  actionButton1?: JSX.Element;
  actionButton2?: JSX.Element;
  subHeader?: string;
}

export default function Header(props: HeaderProps) {
  const { backButton, header, actionButton1, actionButton2, subHeader } = props
  return (
    <Grid container spacing={2} columns={12}>
      {backButton ?
        <Grid item xs={8}>
          <BackButton icon={<ArrowBackIcon />} />
        </Grid>
        : <></>
      }
      <Grid item xs={8}>
        <XXLargeText color="#1F2A40" fWeight="bold">
          {header}
        </XXLargeText>
      </Grid>
      <Grid item xs={4}>
        <Box sx={{ float: "right", gap: 2, display: "flex" }}>
          {actionButton1}
          {actionButton2}
        </Box>
      </Grid>
      {subHeader ?
        <Grid item xs={12}>
          <MediumLargeText color="#1F2A40">
            {subHeader}
          </MediumLargeText>
        </Grid>
        : <></>
      }
    </Grid>
  )
}