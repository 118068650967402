import { Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { selectLotOffer, selectLotOfferImages, setImages } from "features/lotOffer/lotOfferSlice";
import LotOfferGrid from "./LotOfferGrid";
import LotOfferImage from "./LotOfferImage";
import React, { useState, useEffect } from "react";
import styles from "./LotOfferImages.module.css";
import { LotOfferInterface } from "interfaces/LotOfferInterface";

export default function LotOfferImages() {
  const lotOfferImages = useAppSelector(selectLotOfferImages);
  const lotOfferData = useAppSelector(selectLotOffer) as LotOfferInterface;
  const lotOfferSold = lotOfferData?.status === "sold"
  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState("");

  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!selectedFile) {
      setPreview("");
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);



  function getBase64(file: File) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (reader.result) {
          let encoded = reader.result.toString();
          resolve(encoded);
        }
      };
      reader.onerror = (error) => reject(error);
    });
  }
  // ------------
  const dispatch = useAppDispatch();
  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    getBase64(file).then((data) => {
      //@ts-ignore
      dispatch(setImages(data));
    });
  };



  return (
    <LotOfferGrid>
      <Typography
        fontWeight={"bold"}
        variant="h5"
        sx={{
          mt: 2,
          mb: 0.5,
          color: "#072D26",
        }}
      >
        BILDER
      </Typography>
      {lotOfferImages.map((image, index) => (
        <LotOfferImage key={index} lotOfferImage={image} />
      ))}

      <div>
        {/* --------- */}
        <form>
          <div className="form-group">
            <input
              type="file"
              // disabled={files.length === 500}
              className={styles.multiUpload}
              onChange={handleFileChange}
              accept="image/png, image/jpeg"
              id="labelPreview"
              disabled={lotOfferSold}
            />
            <div className={styles.imageIcons}>
              {/* <div className={styles.insideIconsRight}>
            
            
              </div> */}
            </div>
            <label className={styles.imagePreviewLabel} htmlFor="labelPreview">
              {" "}
              + BILD HINZUFÜGEN
            </label>
          </div>
        </form>
        {/* --------- */}
      </div>
    </LotOfferGrid>
  );
}
