import { Backdrop, IconButton, Modal, SvgIcon } from "@mui/material";
import { AddShoppingCart } from "icons";
import { LotOfferLocationInterface } from "interfaces/LotOfferLocationInterface";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { checkShoppingCart, setShoppingCart, selectShoppingCartInvalidIds, ShoppingCartInvalidStatus } from "features/shoppingCart/shoppingCartSlice";
import { MediumText } from "components/typography";
import { fetchValidateResponse } from "features/shoppingCart/fetchValidate";
import { AxiosResponseHeaders } from "axios";
import React from "react";
import QuantityRestriction from "components/modals/QuantityRestriciton";
import { selectUserIsLoggedId } from "features/user/userSlice";

export interface ShoppingCartButtonInterface {
  lotInformation: LotOfferLocationInterface;
  onClick?: (event?: any) => void;
  withLabel?: boolean;
  disabled?: boolean;
}

export default function ShoppingCartButton({ lotInformation, onClick = undefined, withLabel = false, disabled = false }: ShoppingCartButtonInterface) {
  const [open, setOpen] = React.useState(false);
  const dispatch = useAppDispatch();
  const isLoggedIn = useAppSelector(selectUserIsLoggedId);
  const invalidShoppingCartIds = useAppSelector(selectShoppingCartInvalidIds);
  const handleAddToCartClick = (event: any) => {
    event.stopPropagation();
    // if (!isLoggedIn) {

    //   return;
    // }
    if (isLoggedIn) {

      dispatch(checkShoppingCart([lotInformation.id])).then((response) => {
        if (response.meta.requestStatus === "fulfilled") {
          const payload = response.payload as { headers: AxiosResponseHeaders; data: fetchValidateResponse };
          const overflownStatus = payload.data.data?.overflown_lot_offer_ids?.includes(lotInformation.id);
          const takenStatus = payload.data.data?.taken_lot_offer_ids?.includes(lotInformation.id);
          if (overflownStatus) {
            handleOpen();
            return false;
          }
          if (takenStatus) {
            handleOpen();
            return false;
          }
          return true
        }
        return false;
      }).then((result) => {
        if (result) {
          onClick?.(event);
          dispatch(setShoppingCart(lotInformation));
        }
      });
    } else {
      onClick?.(event);
      dispatch(setShoppingCart(lotInformation));

    }

  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = (event: any) => {
    event.stopPropagation();
    setOpen(false);
  };
  return (
    <>
      <IconButton
        sx={{
          float: "right",
          backgroundColor: "#D9E868",
          borderRadius: withLabel ? "26px" : "50%",
          color: "#000000",
          "&:hover": { backgroundColor: "#D9E868" },
          padding: 1,
          // TODO: This Comes from Selected LotInfoSmall Styling
          // padding: "4px 6px"
          minWidth: "20px",
          gap: 1,
        }}
        onClick={handleAddToCartClick}
        disabled={disabled}
      >
        {withLabel ? (
          <MediumText color="#072D26" fWeight="bold">
            In den Einkaufswagen
          </MediumText>
        ) : null}
        <SvgIcon>
          <AddShoppingCart />
        </SvgIcon>
      </IconButton>
      <Backdrop sx={{ backgroundColor: "#grey", zIndex: 1200 }} open={open} onClick={handleClose}>
        <Modal hideBackdrop open={open} onClose={handleClose} aria-labelledby="modal-title">
          <QuantityRestriction
            onClickEvent={handleClose}
            checked={open}
            handleClose={handleClose}
            text={`Das Los ${lotInformation?.full_lot_number} kann leider nicht in Ihren Einkaufswagen hinzugefügt werden. ${lotInformation?.agent} erlaubt den Erwerb von ${lotInformation?.max_wood_per_period} Fm / ${lotInformation?.period_duration} Monate je registriertem Nutzer.`}
          />
        </Modal>
      </Backdrop>
    </>
  );
}
