import React from 'react'
import style from './ColoredBox.module.css';
import { Property } from "csstype";
type Props = {
    children: React.ReactNode
    backgroundColor: Property.Color | undefined;
    coloredBoxPadding?: Property.Padding
}

function ColoredBox({children, backgroundColor, coloredBoxPadding = '1.25rem'}: Props) {
  return (
    <div className={style.coloredBox} style={{'--backgroundColor': backgroundColor, '--padding': coloredBoxPadding}}>
        {children}
    </div>
  )
}

export default ColoredBox