import { IconButton, Box } from "@mui/material";
import { useAppSelector } from "app/hooks";
import { selectShoppingCartSize } from "features/shoppingCart/shoppingCartSlice";
import { selectUserRole } from "features/user/userSlice";
import { ShoppingCart } from "icons";
import { UserRoles } from "interfaces/UserRoles";
import { MediumText } from "../../typography";
import LinkWithHoverLine from "./LinkWithHoverLine";

export default function ShoppingCartButton(props?: any) {
  const userRole = useAppSelector(selectUserRole);
  const shoppingCartSize = useAppSelector(selectShoppingCartSize);
  if (userRole !== UserRoles.Admin && userRole !== UserRoles.Agent) {
    return (
      <LinkWithHoverLine to="/einkaufswagen">
        <Box sx={{ display: "flex" }}>
          <IconButton
            size="small"
            color="inherit"
            sx={{
              "&:active": {
                color: "#C4D661",
              },
              gap: 1,
            }}
          >
            <MediumText
              color="#FFFFFF"
              fWeight="bold"
              height="inherit"
              // display="contents"
            >
              {props.text}
            </MediumText>
            <ShoppingCart
              style={{
                maxWidth: "32px",
                height: "32px",
              }}
            />
          </IconButton>
          <MediumText fWeight="bold" color="#FFFFFF" alignSelf="center">
            {shoppingCartSize > 0 ? shoppingCartSize : ""}
          </MediumText>
        </Box>
      </LinkWithHoverLine>
    );
  }
  return null;
}
