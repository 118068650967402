import { ReactNode } from "react";
import { Typography } from "@mui/material";

interface Props {
  children?: ReactNode,
  color?: String,
  fWeight?: String,
  mx?: any,
  my?: any,
  border?: any,
  // any props that come into the component
}

export default function XLargeText({ children, color, fWeight, mx, my, border }: Props) {
  return (
    <Typography
      variant="h2"
      noWrap
      sx={{
        fontFamily: 'DMSans',
        mx: {xs:"auto", md:mx},
        // mx: {xs:"auto", md:`${mx}`},
        my:my,
        display: { xs: "inline-flex", md: "inline-block" },
        fontWeight: `${fWeight}`,
        color: `${color}`,
        textDecoration: "none",
        fontSize: {xs: 12, md: 32},
        border: {xs: `${border}`, md: "none"},
        padding: {xs: 0.5, md: 0}
      }}
    >
      {children}
    </Typography>
  );
}
