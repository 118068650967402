export default function formatDate() {
    function format(inputDate: any){
    let date, month, year;
    let input = new Date(inputDate)
    date = input.getDate();
    month = input.getMonth() + 1;
    year = input.getFullYear();

      date = date
          .toString()
          .padStart(2, '0');

      month = month
          .toString()
          .padStart(2, '0');

    return `${date}.${month}.${year}`;
  }
  return{
    format
  }
}