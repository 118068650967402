import {
  createAsyncThunk,
  createSlice,
  isAnyOf,
  PayloadAction,
  Update,
} from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { postLogin } from "./login";
import { patchConfirm } from "./confirm";
import { patchResetPassword, postResetPassword, ResetPasswordSetPasswordArgument } from "./resetPass";
import { RequestStatus } from "interfaces/RequestStatus";
import { UserRoles } from "../../interfaces/UserRoles";
import { UserDataInterface } from "interfaces/UserDataInterface";
// import { UserData } from "interfaces/UserDataInterface";
import { getUserData, saveUserData } from "./fetchUserData";
import { saveNewPassword } from "./fetchUserPassword";
import { postRegister } from "./register";
import { saveNewEmail } from "./resetEmail";

export interface LoginInformation {
  email: string;
  password: string;
}
export interface ResetPasswordInformation {
  email: string;
}

export interface UpdateEmailInformation {
  email: string;
  password: string;
}
export interface UpdatePasswordInformation {
  current_password: string;
  password: string;
  password_confirmation: string;
}

export interface RegisterInformation {
  email: string;
  password: string;
  password_confirmation: string;
}
export interface ResetPasswordErrors {
  string?: string[]
}

export interface UserErrors {
  string: string[]
}
export interface RegisterErrors {
  string?: string[]
}

export interface UserState {
  // current_password: any;
  // passwordx: any;
  password?: any;
  role: UserRoles | undefined;
  customer_required: boolean;
  token: string;
  sessionGone: boolean;
  permissionsMissing: boolean;
  status: RequestStatus;
  userErrors?: UserErrors;
  registerErrors?: RegisterErrors;
  resetPasswordErrors?: ResetPasswordErrors;
  changePasswordErrors?: ResetPasswordErrors;
  user?: UserDataInterface;
  lastRequestSuccessfull: boolean;
  updateEmail?: UpdateEmailInformation;
  resetPassword?: ResetPasswordInformation;
  afterLoginTo?: string;
}

const initialState: UserState = {
  role: undefined,
  customer_required: true,
  token: "",
  sessionGone: false,
  permissionsMissing: false,
  status: RequestStatus.idle,
  lastRequestSuccessfull: true,
  afterLoginTo: "/",
};

const emptyUser: UserDataInterface = {
  first_name: "",
  last_name: "",
  email: "",
  created_at: "",
  updated_at: "",
};

const emptyUpdateEmail: UpdateEmailInformation = {
  email: "",
  password: "",
};


const emptyRegisterError: RegisterErrors = {}
const emptyResetPasswordError: ResetPasswordErrors = {}
const changePasswordErrors: ResetPasswordErrors = {}

const emptyResetPassword: ResetPasswordInformation = {
  email: ""
}

export interface ResetInformation {
  email: string;
}

export const login = createAsyncThunk(
  "user/login",
  async (args: LoginInformation, {getState, rejectWithValue}) => {
    const response = await postLogin({loginData: args});
    if (response.status === 200) {
      return { headers: response.headers, data: response.data.data };
    }
    else {
      return rejectWithValue(response.status);
    }
  }
);

export const register = createAsyncThunk(
  "user/register",
  async (params: RegisterInformation, {rejectWithValue}) => {
    const response = await postRegister(params);
    if (response.status === 200) {
      return { headers: response.headers, data: params };
    }
    else {
      return rejectWithValue(response.data.data.errors);
    }
  }
);

export const userData = createAsyncThunk("user/data", async () => {
  const response = await getUserData();
  return response.data.data;
});

export const userConfirm = createAsyncThunk(
    "user/confirm", 
    async (token: string, {rejectWithValue}) => {
  const response = await patchConfirm(token);
  if (response.status === 200) {
    return { headers: response.headers, status: response.status };
  }
  else {
    return rejectWithValue({status: response.status});
  }
});

export const patchUserData = createAsyncThunk(
  "user/saveUserData",
  async (args, { getState, rejectWithValue }) => {
    const state = getState() as RootState;
    if (!state.user.user) {
      return { data: {} };
    }
    try{
      const response = await saveUserData({ userData: state.user.user });
      return { data: response.data };
    } catch(err: any){
      // console.log(err.response.data)
      return rejectWithValue(err.response.data)
    }
  }
);

export const patchUserPassword = createAsyncThunk("user/patch_password",
  async (args: UpdatePasswordInformation, {rejectWithValue}) => {
    try{
      const response = await saveNewPassword({passwordData: args});
      return { headers: response.headers, data: response.data.data };
    } catch (err: any) {
      return rejectWithValue(err.response.data.errors)
    }
  }
)

export const patchUserEmail = createAsyncThunk("user/change_email",
  async (args, {getState, rejectWithValue}) => {
    const state = getState() as RootState;
    if (!state.user.updateEmail){
      return {data: {errors: {}}}
    }
    const response = await saveNewEmail({updateEmailData: state.user.updateEmail});
    return { headers: response.headers, data: response.data.data };
  }
)

export const resetPassword = createAsyncThunk(
  "user/reset_password",
  async (email: string, {}) => {
    const response = await postResetPassword({email});
    return { headers: response.headers, data: response.data.data };
  }
);

export const resetPasswordSetPassword = createAsyncThunk(
  "user/reset_password_set_password",
  async (data: ResetPasswordSetPasswordArgument, {rejectWithValue}) => {
    try{
      const response = await patchResetPassword(data);
      return { headers: response.headers, data: response.data.data };
    } catch (err:any) {
      return rejectWithValue(err.response.data)
    }
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logout: (state) => {
      localStorage.removeItem('token')
      return initialState;
    },
    clearRegisterErrors: (state) => {
      state.registerErrors = emptyRegisterError;
    },
    updateToken: (state, action: PayloadAction<string>) => {
      state.sessionGone = false;
      state.token = action.payload;
    },
    updateSessionGone: (state, action: PayloadAction<string>) => {
      state.token = "";
      state.sessionGone = true;
    },
    updatePermissionsMissing: (state, action: PayloadAction<boolean>) => {
      state.permissionsMissing = action.payload;
    },
    updateUserName: (state, action: PayloadAction<any>) => {
      if (!state.user) {
        state.user = emptyUser;
      }
      state.user = {...state.user, ...action.payload}
    },
    updateFirstName: (state, action: PayloadAction<string>) => {
      if (!state.user) {
        state.user = emptyUser;
      }
      state.user = { ...state.user, first_name: action.payload};
    },
    updateLastName: (state, action: PayloadAction<string>) => {
      if (!state.user) {
        state.user = emptyUser;
      }
      state.user = { ...state.user, last_name: action.payload};
    },
    updateNewEmail: (state, action: PayloadAction<string>) => {
      if (!state.updateEmail){
        state.updateEmail = emptyUpdateEmail;
      }
      state.updateEmail = { ...state.updateEmail, email: action.payload}
    },
    updatePasswordForEmail: (state, action: PayloadAction<string>) => {
      if (!state.updateEmail){
        state.updateEmail = emptyUpdateEmail;
      }
      state.updateEmail = {...state.updateEmail, password: action.payload }
    },
    updateRequestEmail: (state, action: PayloadAction<string>) => {
      if (!state.resetPassword){
        state.resetPassword = emptyResetPassword;
      }
      state.resetPassword = {...state.resetPassword, email: action.payload }
    },
    updateAfterLoginTo: (state, action: PayloadAction<string>) => {
      state.afterLoginTo = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.fulfilled, (state, action) => {
        if (action.payload.data?.errors) {
        }
        state.status = RequestStatus.idle;
        state.customer_required = action.payload.data.customer_required;
        state.role = action.payload.data.role;
        state.permissionsMissing = false;
        getUserData()
      })
      .addCase(userData.fulfilled, (state, action) => {
        state.user = action.payload;
      })
      .addCase(register.fulfilled, (state, action) => {
        state.status = RequestStatus.idle;
        state.registerErrors = emptyRegisterError;
      })
      .addCase(resetPasswordSetPassword.fulfilled, (state, action) => {
        state.status = RequestStatus.idle;
        state.resetPasswordErrors = emptyResetPasswordError;
      })
      .addCase(patchUserPassword.fulfilled, (state, action) => {
        state.status = RequestStatus.idle;
        state.changePasswordErrors = emptyResetPasswordError;
      })
      .addCase(login.rejected, (state, action) => {
        state.status = RequestStatus.failed;
      })
      .addCase(register.rejected, (state, action) => {
        state.status = RequestStatus.failed;
        state.registerErrors = action.payload as RegisterErrors;
      })
      .addCase(resetPasswordSetPassword.rejected, (state, action) => {
        state.status = RequestStatus.failed;
        state.resetPasswordErrors = action.payload as ResetPasswordErrors;
      })
      .addCase(patchUserPassword.rejected, (state, action) => {
        state.status = RequestStatus.failed;
        state.changePasswordErrors = action.payload as ResetPasswordErrors;
      })
      .addMatcher(isAnyOf(login.pending, userData.pending), (state) => {
        state.status = RequestStatus.loading;
        state.userErrors = {} as UserErrors
      })
      .addMatcher(isAnyOf(userData.rejected), (state, { payload }) => {
        state.status = RequestStatus.failed;
      });
  },
});

// , current_password ,password,password_confirmation
export const { logout, updateToken, updateSessionGone, updateFirstName, updateLastName, updateUserName,  clearRegisterErrors, updateNewEmail, updatePasswordForEmail, updateRequestEmail, updatePermissionsMissing, updateAfterLoginTo } = userSlice.actions;

export const selectUser = (state: RootState) => state.user;
export const selectUserStatus = (state: RootState) => state.user.status;
export const selectUserRole = (state: RootState): UserRoles | undefined => state.user.role;
export const selectUserData = (state: RootState) =>
  state.user.user || emptyUser;
export const selectUserCustomerRequired = (state: RootState) =>
state.user.customer_required;
export const selectUserToken = (state: RootState) => state.user.token;
export const selectSessionGone = (state: RootState) => state.user.sessionGone;
export const selectUserIsLoggedId = (state: RootState) => !!state.user.token && state.user.token !== "";
  
export const selectUserErrors = (state: RootState) => state.user.userErrors;
export const selectRegisterErrors = (state: RootState) => state.user.registerErrors;
export const selectResetPasswordErrors = (state: RootState) => state.user.resetPasswordErrors;
export const selectChangePasswordErrors = (state: RootState) => state.user.changePasswordErrors;
export const selectPermissionsMissing = (state: RootState) => state.user.permissionsMissing;

export const selectAfterLoginTo = (state: RootState) => state.user.afterLoginTo;

  // export const selectUserCurrentPassword = (state: RootState) => state.user.current_password;
// export const selectUserNewPassword = (state: RootState) => state.user.password;
  
  // export const selectUserPass = (state: RootState) =>
  //   state.user.user || emptyPassword;
export default userSlice.reducer;





