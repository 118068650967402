import { Box, Button, Container, SvgIcon } from "@mui/material";
import { current } from "@reduxjs/toolkit";
import axiosInstance from "app/axios";
import { useAppDispatch, useAppSelector } from "app/hooks"
import Header from "components/header/Header";
import HolzfinderList from "components/list/HolzfinderList";
import { getAgentUsers, selectAgentUsers, selectAgentUsersListTotal, selectCurrentAgentId } from "features/agent/agentSlice"
import { selectPageAgentUsersList, selectRowsPerPageAgentUsersList, setPageAgentUsersList, setRowsPerPageAgentUsersList } from "features/table/tableSlice";
import { selectUserRole } from "features/user/userSlice";
import { Add, Delete, Edit } from "icons";
import { UserRoles } from "interfaces/UserRoles";
import { NumberingSystem } from "luxon";
import React from "react";
import { useEffect, useState } from "react";
import { Navigate, Route, Router, Routes, useLocation, useNavigate } from "react-router-dom";
import AdminAgent from "./AdminAgent";

interface rowInterface {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  status: string;
  checked: boolean;
}
interface Props {
  page: number;
  rowsPerPage: number;
  agentListTotal: number;
  agentId: string
}
// console.log(setRowsPerPageAgentUsersList)

const tableHeaderList: string[] = ["Vorname", "Nachname", "E-Mail", "Aktiv", "Bearbeiten"]


export default function AgentUsers(props: Props) {
  const { page = 0, rowsPerPage = 10, agentListTotal = 0, agentId = "1" } = props
  // const page = useAppSelector(selectPageAgentUsersList)

  //test
  // const [shouldRedirect, setShouldRedirect] = useState(false);
  // const navigated = useNavigate();
  //test
  // useEffect(() => {
  //   if (shouldRedirect) navigate(`agent_hinzufuegen`);
  // }, [shouldRedirect]);
  //test


  const currentAgentId = useAppSelector(selectCurrentAgentId)
  // console.log(currentAgentId)

  const [rowData, setRowData] = useState<rowInterface[]>([]);
  const [searchQuery, setSearchQuery] = React.useState("");
  const agentUsers = useAppSelector(selectAgentUsers)
  const userRole = useAppSelector(selectUserRole)
  const dispatch = useAppDispatch();
  let navigate = useNavigate();
  const receiveAgentUsersList = (resetSearch: boolean) => {
    if (resetSearch) setSearchQuery("")

    dispatch(
      getAgentUsers({
        items_per_page: rowsPerPage,
        page: page + 1,
        search: resetSearch ? undefined : searchQuery,
        agent_id: agentId,
        role: UserRoles.Agent
      })
    );
  };

  const switchStatus = (id: string, newChecked: boolean) => {
    const newItems = rowData.map((row) => {
      if (row.id === id) {
        const payload: any = {};
        payload["status"] = row.status === "active" ? "inactive" : "active";
        axiosInstance.patch(`admin/users/${row.id}`, payload);

        return {
          ...row,
          checked: newChecked,
          status: row.status === "active" ? "inactive" : "active",
        };
      } else {
        return row;
      }
    });
    setRowData(newItems);
  };

  useEffect(() => {
    // console.log(page, rowsPerPage, agentId, rowsPerPage)
    receiveAgentUsersList(false)

  }, [page, rowsPerPage, dispatch, agentId]);




  useEffect(() => {
    // console.log(agentUsers)
    if (agentUsers) {
      const mappedData = agentUsers.map(

        (user) => ({
          id: user.id,
          first_name: user.first_name,
          last_name: user.last_name,
          email: user.email,
          status: user.status,
          checked: user.status === "active" ? true : false,
        })
      );
      setRowData(mappedData);
    }
  }, [agentUsers])

  //solve list pagination at first load to zero
  useEffect(() => {
    dispatch(setPageAgentUsersList(0));
  }, [setPageAgentUsersList]);

  return (
    <Container sx={{ pt: 7 }} maxWidth="xl">
      <Box>
        <Header
          header="Userliste"
          actionButton1={
            <Button sx={{ backgroundColor: "#c0d904", color: "black", borderRadius: "50px", mt: 2, mb: 2, float: "right" }} onClick={() => { navigate('agent_hinzufuegen') }}>
              <SvgIcon>
                <Add />
              </SvgIcon>
            </Button>

          }
        />
      </Box>
      {/* <div>
        <button onClick={() => setShouldRedirect(true)}>Redirect</button>
      </div> */}

      <HolzfinderList
        listLengthTotal={agentListTotal}
        rowData={rowData}
        switchActive={true}
        switchStatus={switchStatus}
        tableHeaderList={tableHeaderList}
        setPage={setPageAgentUsersList}
        setRowsPerPage={setRowsPerPageAgentUsersList}
        page={page}
        rowsPerPage={rowsPerPage}
        link={`/${userRole}/agentenliste/${agentId}/`}
        isLotList={false}
      />
    </Container>
  )
}
