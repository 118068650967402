import {
  Box,
  Button,
  Container,
} from "@mui/material";

import { LargeText, MediumText } from "components/typography";
import styles from "./Homepage.module.css";
import {
  FindLot,
  HolzAbholen,
  Locations,
  PayOnline,
  LandkarteStartseite,
} from "icons";
import { useNavigate } from "react-router-dom";

export default function IntroductionAndMap() {
  const navigate = useNavigate();
  return (
    <Container className={styles.blockContainer}>
      <Box
        className={`${styles.mapGrid}`}
        sx={{
          display: "grid",
          gridTemplateColumns: "4fr 6fr",
          gap: 1,
          gridTemplateRows: "auto",
          gridTemplateAreas: `"header map"
                            "submit map"`,
        }}
      >
        <Box sx={{ gridArea: "header" }}>
          <div className={styles.mapHeader}>
            <LargeText fWeight={"bold"}>
              WIE FUNKIONIERT HOLZFINDER?
            </LargeText>
          </div>
          <div>
            <MediumText>
              Holzfinder bietet dir eine große Auswahl von Brennholz. Befülle
              deinen Einkaufswagen selbst aus einem vielfältigen Angebot mit
              unterschiedlichsten Mengen. Nach dem Kauf erhältst Du online
              eine Rechnung je Anbieter und kannst Dein Holz nach Bezahlung
              aus dem Wald abholen.
            </MediumText>
          </div>
        </Box>
        <Box sx={{ gridArea: "map" }}>
          <LandkarteStartseite />
        </Box>

        <Box className={styles.mapIconsContainer} sx={{ gridArea: "submit" }}>
          <div>
            <FindLot
              style={{
                maxWidth: "80px",
                height: "80px",
              }}
            />
            <div>
              <LargeText>Brennholz in der Umgebung suchen</LargeText>
            </div>
          </div>
          <div>
            <Locations
              style={{
                maxWidth: "80px",
                height: "80px",
              }}
            />
            <div>
              <LargeText>Alle Angebote finden</LargeText>
            </div>
          </div>
          <div>
            <PayOnline
              style={{
                maxWidth: "80px",
                height: "80px",
              }}
            />
            <div>
              <LargeText>Einfach & schnell online kaufen</LargeText>
            </div>
          </div>
          <div>
            <HolzAbholen
              style={{
                maxWidth: "80px",
                height: "80px",
              }}
            />
            <div>
              <LargeText>Gekauftes Holz abholen</LargeText>
            </div>
          </div>
          <div>
            <Button
              className={`${styles.button} ${styles.mapIconsSubmit}`}
              variant="contained"
              onClick={() => { navigate("/lossuche") }}
            >
              JETZT BRENNHOLZ FINDEN
            </Button>
          </div>
        </Box>
      </Box>
    </Container>
  );
}
