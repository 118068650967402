import axiosInstance from "app/axios";
import { AxiosResponse } from "axios";
import { LotOfferInterface } from "interfaces/LotOfferInterface";
import { UserRoles } from "interfaces/UserRoles";

export interface fetchLotListArgument {
  items_per_page: number;
  page: number;
  search: string | undefined;
  status: string | undefined ;
  endpoint?: UserRoles;
  sort?: string | undefined
}

export interface LotOffersResponseInterface {
  data: {
    total: number;
    pages: number;
    lot_offers: LotOfferInterface[];
  };
}

export function fetchLotList({ items_per_page, page, search, status , sort, endpoint }: fetchLotListArgument): Promise<AxiosResponse<LotOffersResponseInterface, any>> {
  return axiosInstance.get(`${endpoint}/lot_offers`, {
    params: {
      items_per_page,
      page,
      search,
      status, 
      sort
    },
  });
}
