import { Box, SvgIcon, Container, Button, Modal, Backdrop, Divider, Paper, useMediaQuery } from "@mui/material";
import { LargeText, MediumText, SmallText } from "../../../components/typography";
import { Tree, OpenWith, MyLocation, AccountCircle, Close } from "icons";
import TextWithIcon from "./TextWithIcon";
import { LotOfferLocationInterface } from "interfaces/LotOfferLocationInterface";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { selectUserIsLoggedId } from "features/user/userSlice";
import { selectShoppingCartIds } from "features/shoppingCart/shoppingCartSlice";
import SimpleGrow from "../OverlayCard";
import React from "react";
import { clearClickedMarker } from "features/lotOffer/lotOfferSlice";
import ShoppingCartButton from "components/shoppingCart/ShoppingCartButton";
import { sign } from "crypto";
import { style } from '@mui/system';
import styles from "./LotInfoSmall.module.css";

interface InformationenProps {
  lotInformation: LotOfferLocationInterface;
  loginNeeded: boolean;
  lotImage: string;
}
export default function InformationSmall(props: InformationenProps) {
  const isLoggedIn = useAppSelector(selectUserIsLoggedId);
  const dispatch = useAppDispatch();
  const { lotInformation, loginNeeded, lotImage } = props;
  const { seller, agent, max_wood_per_period, period_duration, wood_types, distance, solid_cubic_meters_without_bark, price_total, vat_rate, login_and_validation_required } = lotInformation;
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);

  const handleShoppingCartClick = (event: any) => {
    event.stopPropagation();
    handleOpen();
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = (event: any) => {
    event.stopPropagation();
    setOpen(false);
  };
  const shoppingCartIds = useAppSelector(selectShoppingCartIds);
  let isInShoppingCart = shoppingCartIds.includes(lotInformation.id);

  function handleContainerClick() {
    navigate(`/lossuche/${lotInformation.id}`);
  }
  function handleButtonClick(event: React.MouseEvent<HTMLButtonElement>) {
    event.stopPropagation();
  }
  const matches = useMediaQuery("(min-width: 820px)");
  const smallView = useMediaQuery("(max-width: 420px)");
  return (
    <Container
      className="container"
      onClick={() => {
        handleContainerClick();
      }}
      data-lot-id={lotInformation.id}
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        borderRadius: "0px 30px 30px 0px !important",
        pt: "4px",
        mx: "initial",
        px: 0,
        p: matches ? "16px" : "8px",
        justifyContent: "center",

        "@media (min-width: 550px) and (max-width: 1200px)": { padding: 2, margin: 2, paddingTop: 8, paddingBottom: 10 },
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "row", gap: smallView ? 2 : 4 }}>
        <Box sx={{ display: "flex", flexDirection: "column", width: "80%" }}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
            <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
              <Box sx={{ display: "flex", flexDirection: "column", gap: matches ? 2 : 1 }}>
                <TextWithIcon icon={<MyLocation width={18} height={18} />} text={`${(distance / 1000).toFixed(2).replace('.', ',')} km entfernt`} />
                <Box sx={{ display: "flex", gap: 1, flexDirection: "row", whiteSpace: "pre" }}>
                  <TextWithIcon icon={<Tree width={18} height={18} />} text={wood_types[0]} whiteSpace="pre" />
                  <TextWithIcon icon={<OpenWith width={18} height={18} />} text={`${solid_cubic_meters_without_bark.toFixed(2).replace('.', ',')} Fm`} />
                </Box>
                {matches ? (
                  <Box sx={{ display: "flex" }}>
                    <Box sx={{ my: 1, display: "flex", alignItems: "baseline", gap: 2 }}>
                      <Box sx={{ display: "flex", alignItems: "baseline", gap: 1, width: "100%" }}>
                        <LargeText fWeight="bold" color="#072D26">
                          {Number(price_total)
                            .toFixed(2)
                            .replace(".", ",")
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") + " €"}
                        </LargeText>
                        <SmallText color="#D0C2A8" float="left">
                          Inkl. {vat_rate}% MwSt.
                        </SmallText>
                      </Box>
                    </Box>
                  </Box>
                ) : (
                  <></>
                )}
              </Box>
              {matches ? (
                <Paper sx={{ boxShadow: "none", display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <Box sx={{ backgroundColor: "#F6F3EE", padding: "8px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    {login_and_validation_required && !isLoggedIn ? (
                      <Box>
                        <MediumText >
                          Der Verkauf erfolgt durch <b>{agent}</b>. Im Auftrag von <b>{seller}</b>.
                        </MediumText>
                        <MediumText>
                          {agent} erlaubt den Erwerb von {max_wood_per_period} fm / {period_duration}
                          je registriertem Nutzer. Sie müssen sich deshalb einloggen, um dieses Los zu kaufen.
                        </MediumText>
                      </Box>
                    ) : (
                      <Box className={styles.tableHeader}>

                        <MediumText>
                          Von <b>{seller}</b> durch <b>{agent}</b>.
                        </MediumText>
                      </Box>
                    )}
                  </Box>
                </Paper>
              ) : (
                <></>
              )}
            </Box>
          </Box>
          {!matches ? (
            <>
              <Divider></Divider>
              <Box sx={{ display: "flex" }}>
                <Box sx={{ my: 1, display: "flex", alignItems: "baseline", gap: 2 }}>
                  <Box sx={{ display: "flex", alignItems: "baseline", gap: 1, width: "100%" }}>
                    <LargeText fWeight="bold" color="#072D26">
                      {Number(price_total)
                        .toFixed(2)
                        .replace(".", ",")
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") + " €"}
                    </LargeText>
                    <SmallText color="#D0C2A8" float="left">
                      Inkl. {vat_rate}% MwSt.
                    </SmallText>
                  </Box>
                </Box>
              </Box>
            </>
          ) : (
            <></>
          )}
        </Box>
        {loginNeeded && !isLoggedIn ? (
          // <Box sx={{textAlign: "right", width: "100%"}}>
          <Box sx={{ textAlign: "right", display: "flex", justifyContent: "center", alignContent: "center", flexWrap: "wrap" }}>
            <Button
              sx={{ borderRadius: matches ? "26px" : "50%", backgroundColor: "#D9E868", color: "#000000", "&:hover": { backgroundColor: "#D9E868" }, padding: "4px 6x", gap: 1, minWidth: 0 }}
              onClick={(event) => {
                navigate("/login");
                handleButtonClick(event);
              }}
              className="button"
            >
              {matches ? <MediumText>Login</MediumText> : <></>}
              <SvgIcon>
                <AccountCircle />
              </SvgIcon>
            </Button>
          </Box>
        ) : (
          <Box sx={{ textAlign: "right", display: "flex", justifyContent: "center", alignContent: "center", flexWrap: "wrap" }}>
            <ShoppingCartButton lotInformation={lotInformation} onClick={handleShoppingCartClick} withLabel={matches} disabled={isInShoppingCart} />
            {smallView ? (
              <Button
                sx={{ color: "black", justifyContent: "end", minWidth: 0 }}
                onClick={(event) => {
                  dispatch(clearClickedMarker());
                  event.stopPropagation();
                }}
              >
                <SvgIcon>
                  <Close />
                </SvgIcon>
              </Button>
            ) : (
              <></>
            )}
          </Box>
        )}
        {smallView ? (
          <></>
        ) : (
          <Button
            sx={{ color: "black", justifyContent: "end", minWidth: 0 }}
            onClick={(event) => {
              dispatch(clearClickedMarker());
              event.stopPropagation();
            }}
          >
            <SvgIcon>
              <Close />
            </SvgIcon>
          </Button>
        )}
      </Box>
      <Backdrop sx={{ backgroundColor: "#grey", zIndex: 1200 }} open={open} onClick={handleClose}>
        <Modal hideBackdrop open={open} onClose={handleClose} aria-labelledby="modal-title">
          <SimpleGrow checked={open} handleClose={handleClose} imageUrl={lotImage} lotData={lotInformation} />
        </Modal>
      </Backdrop>
    </Container>
  );
}
