import { Box, SvgIcon } from "@mui/material";
import { MediumText } from "components/typography";

interface TextWithIconProps {
  icon: JSX.Element;
  text: string;
  text2?: string;
  whiteSpace?: string;
}

export default function TextWithIcon(props: TextWithIconProps) {
  const { icon, text, whiteSpace, text2 } = props;
  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: {xs: "2px", md:"5px" }}}>
      <SvgIcon inheritViewBox>{icon}</SvgIcon> 
      <MediumText whiteSpace={`${whiteSpace}`}>{text}</MediumText>
      <MediumText whiteSpace={`${whiteSpace}`} color="#D0C2A8">{text2}</MediumText>
    </Box>
  );
}
