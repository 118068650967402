import { Box, Container, Grid } from "@mui/material";
import { useAppSelector } from "app/hooks";
import Header from "components/header/Header";
import ShoppingCartDelete from "components/shoppingCart/ShoppingCartDelete";
import ShoppingList from "components/shoppingCart/ShoppingList";
import ShoppingSummary from "components/shoppingCart/ShoppingSummary";
import { clearShoppingCart, setFromShoppingCart } from "features/shoppingCart/shoppingCartSlice";
import { selectUserIsLoggedId } from "features/user/userSlice";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";


export default function ShoppingCart() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isLoggedIn = useAppSelector(selectUserIsLoggedId);

  useEffect(() => {

    dispatch(setFromShoppingCart(true))
    if (!isLoggedIn) {
      // dispatch(dispatch(clearShoppingCart()))
      navigate("/login",);
    }
  }, [])
  return (
    <Container sx={{ pt: 4, pb: 4 }}>
      <Grid container>
        <Grid item xs={12} sx={{ mb: 3 }}>
          <Header header="Ihr Einkaufswagen" backButton />
        </Grid>
        {/* <ShoppingCartDelete></ShoppingCartDelete> */}
        <Box sx={{ width: "100%", display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
          <Grid item xs={12} md={7}>
            <Box>
              <ShoppingList />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <ShoppingSummary
              text="Weiter zum Kauf"
              fromShoppingCart={true}
            />
          </Grid>
        </Box>
      </Grid>
    </Container>
  );
}
