import { Paper, Box, SvgIcon, Button } from "@mui/material";
import { LargeText, MediumText } from "../typography";
import { Tree, OpenWith, DeleteSmall, Sell, Circumference } from "icons";
import TextWithIcon from "../typography/TextWithIcon";
import { LotOfferLocationInterface } from "interfaces/LotOfferLocationInterface";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { deleteItemFromShoppingCart, selectShoppingCartLots, deleteFromShoppingIds } from "features/shoppingCart/shoppingCartSlice";
import InvalidShoppingCartMarker from "./InvalidShoppingCartMarker";
import { LotOfferInterface } from "interfaces/LotOfferInterface";

interface InformationenProps {
  lotInformation?: LotOfferLocationInterface;
  lessInfo?: boolean;
}
export default function HolzfinderInfoDetail(props: InformationenProps) {
  const { lotInformation, lessInfo = false } = props;
  const numberFormatter = new Intl.NumberFormat("de-De", { style: "currency", currency: "EUR" });
  const shoppingCartLots = useAppSelector(selectShoppingCartLots);
  const dispatch = useAppDispatch();
  if (!lotInformation) {
    return <Paper sx={{ boxShadow: "none", pl: { xs: 0, md: 2 }, display: "flex", gap: 5, justifyContent: "space-between", width: "inherit" }}></Paper>;
  }
  const { wood_types, solid_cubic_meters_without_bark, price_total, vat_rate, diameter, price_per_solid_cubic_meter } = lotInformation;
  const clearLotFromShoppingCart = () => {
    let filteredCartLots = shoppingCartLots.filter(function (lotLocation: LotOfferLocationInterface) {
      return lotLocation.id !== lotInformation.id;
    });
    dispatch(deleteItemFromShoppingCart(filteredCartLots));
  };
  const clearFromShoppingIds = (id: string) => {
    dispatch(deleteFromShoppingIds(id));
  };
  const regexForComma = /\,$/
  return (
    <Paper sx={{ boxShadow: "none", pl: { xs: 0, md: 2 }, display: "flex", justifyContent: "space-between", width: "inherit" }}>
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          borderRadius: "0px",
          boxShadow: "none",
          justifyContent: "space-between",
          pt: 2,
          width: "100%",
          "&::after": {
            height: "3px",
            border: "1px solid",
          },
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Box>
            <MediumText padding={"0px 4px"} color="#005851" fWeight="500" textdecor>
              Los-Nr.: {lotInformation.full_lot_number}
            </MediumText>
            <TextWithIcon icon={<Tree />} text={wood_types.join(", ").replace("(unbestimmt)", "").substring(0, 29).trim().replace(regexForComma, "")} />
            <TextWithIcon icon={<OpenWith />} text={`${solid_cubic_meters_without_bark.toFixed(2).replace('.', ',')} Fm`} />
          </Box>
          {lessInfo ? (
            <Box sx={{ p: "30px 10px" }}>
              <TextWithIcon icon={<Circumference />} text={`${Math.round(diameter)} cm`} />
              <TextWithIcon icon={<Sell />} text={`${price_per_solid_cubic_meter} € / Fm`} />
            </Box>
          ) : (
            <></>
          )

          }
        </Box>

      </Paper>
      <Box sx={{ padding: lessInfo ? "30px 10px 10px 0px" : "20px 10px", display: "flex", flexDirection: "column", justifyContent: "center", width: "12rem" }}>
        <LargeText fWeight="bold" display="inline" float="left" mr={1} color="#072D26">
          {numberFormatter.format(Number(price_total))}
        </LargeText>
        <MediumText display="inline" color="#D0C2A8" float="left">
          Inkl. {vat_rate}% MwSt.
        </MediumText>
      </Box>
      {!lessInfo ? (
        <Box sx={{ display: "flex" }}>
          <Button
            sx={{ color: "#000000", "&:hover": { background: "white" } }}
            onClick={(event: any) => {
              event.stopPropagation();
              event.preventDefault();
              clearFromShoppingIds(lotInformation.id);
              clearLotFromShoppingCart();
            }}
          >
            <SvgIcon>
              <DeleteSmall />
            </SvgIcon>
            <MediumText fWeight="bold">Löschen</MediumText>
          </Button>
        </Box>
      ) : (<></>)}
    </Paper>
  );
}
