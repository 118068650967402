import { Container } from "@mui/material";
import { LargeText, MediumText } from "components/typography";

export default function RegisterComplete() {
  return(
    <Container sx={{display: "flex", flexDirection: "column", gap: 2, mt: 5, mb: 5}}>
      <LargeText fWeight="bold"> Vielen Dank!</LargeText>
      <MediumText> Ihre Registrierung war erfolgreich!</MediumText>
      <MediumText> Bestätigen Sie die Registrierung, indem Sie auf den Link in der Ihnen zugesandten E-Mail klicken. Füllen sie bitte anschließend ihr Profil fertig aus.</MediumText>
      <MediumText> Falls Sie innerhalb der nächsten Minuten keine Mail erhalten, schauen Sie bitte in ihrem Spam Postfach nach.</MediumText>
    </Container>
  )
}
