import { Box } from "@mui/material";
import { LotImagesType } from "interfaces/LotImagesInterface";
import holzfinderDefaultLotImage from "assets/images/Holzfinder_Los_Platzhalter.jpg"

interface LotOfferImageProps {
  lotOfferImage: LotImagesType;
}

export default function LotOfferBigImage(props: LotOfferImageProps) {
  const baseURL = process.env.REACT_APP_API_DOMAIN as string;
  // const url =
  //   typeof props.lotOfferImage === "string"
  //     ? baseURL + props.lotOfferImage
  //     : baseURL + props.lotOfferImage.url;
  const url = props.lotOfferImage ? baseURL + props.lotOfferImage : holzfinderDefaultLotImage
  return (
    <Box
      component="div"
      sx={{
        display: "flex",
        overflow: "hidden",
        maxWidth: "500px",
        width: "auto",
        justifyContent: "center",
        height: "auto",
      }}
    >
      <Box
        component="img"
        src={url}
        alt="Bild eines HolzPolters"
        sx={{
          aspectRatio: "16/9",
          maxWidth: "500px",
          maxHeight: "400px",
          width: "auto",
          height: "auto",
          objectFit: "cover",
          objectPosition: "left",
        }}
      />
    </Box>
  );
}
