import { Box, Container } from "@mui/material";
import Header from "components/header/Header";
import {
  LargeText,
  MediumLargeText,
  MediumText,
  SmallText,
} from "components/typography";

export default function KontaktErfolgreich() {
  return (
    <Container sx={{ p: 1, mt: 5 }}>
      <Header
        header=""
      />
      <Box sx={{ mt: 3, mb: 4 }}>
        <LargeText my={0.5}>Danke für Ihre Nachricht!</LargeText>
        <Box sx={{ marginBottom: 6 }}>
          <MediumText mt={3}>
            Ihre Nachricht wurde erfolgreich versendet. Wir werden uns so schnell wie möglich bei Ihnen melden.
          </MediumText>

          <MediumText>

          </MediumText>
        </Box>
      </Box>
    </Container>
  );
}
