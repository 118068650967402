import { ReactNode } from "react";
import { Typography } from "@mui/material";

interface Props {
  children?: ReactNode;
  color?: String;
  fWeight?: String;
  my?: any;
  mx?: any;
  whiteSpace?: any;
  // any props that come into the component
}

export default function XXLargeText({
  children,
  color,
  fWeight,
  my,
  mx,
  whiteSpace,
}: Props) {
  return (
    <Typography
      variant="h3"
      noWrap
      sx={{
        fontFamily: "DMSans",
        my: my,
        mx: mx,
        display: { md: "flex" },
        fontWeight: `${fWeight}`,
        color: `${color}`,
        textDecoration: "none",
        fontSize: { xs: 24, md: 40 },
        whiteSpace: whiteSpace,
      }}
    >
      {children}
    </Typography>
  );
}
