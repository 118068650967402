import {
  FormControl,
  TextField,
  Box,
  InputAdornment,
  IconButton,
  Link,
  Button,
} from "@mui/material";
import styles from "../Login.module.css";
import { MediumText } from "../../../components/typography";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { login, selectAfterLoginTo } from "features/user/userSlice";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { clearShoppingCart } from "features/shoppingCart/shoppingCartSlice";
import { customerSettings } from "features/customer/customerSlice";

export default function LoginForm() {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  // const [success, setSuccess] = useState("")
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const afterLoginTo = useAppSelector(selectAfterLoginTo) as string;

  const handleLogin = () => {
    dispatch(login({ email, password })).then(function (ev) {
      // console.log(ev);
      if (ev.type === "user/login/fulfilled") {
        dispatch(customerSettings());
        navigate(afterLoginTo)
      } else if (ev.payload == 401) {
        // TODO Error
        setError("Email und/oder Passwort falsch.")
      }
      else if (ev.payload == 423) {
        setError("Nutzer gesperrt. Bitte kontaktieren Sie info@holzfinder.de")
        dispatch(clearShoppingCart())
      }
    });
  }
  return (
    <Box>
      <FormControl
        sx={{
          width: { lg: "60%", md: "80%", xs: "100%" },
          marginRight: { xs: 1, md: 6 },
        }}

        className={styles.form}
      >
        <MediumText>E-Mail</MediumText>
        <TextField
          type="email"
          placeholder="E-Mail"
          className={styles.input}
          value={email}
          onChange={(event) => {
            setError("");
            setEmail(event.target.value);
          }}
          name={"email"}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              handleLogin();
            }
          }}
        />

        <MediumText>Passwort</MediumText>
        <TextField
          type={showPassword ? "text" : "password"}
          placeholder="Passwort wählen"
          className={styles.input}
          value={password}
          onChange={(event) => {
            setError("");
            setPassword(event.target.value);
          }}
          name={"password"}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              handleLogin();
            }
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => {
                    setShowPassword(!showPassword);
                  }}
                >
                  {" "}
                  <VisibilityOffOutlinedIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {error.length > 0 ? <div style={{ color: "#d32f2f", marginTop: "6px" }}>{error}</div> : null}
        {/* {success.length > 0 ? <div style={{ color: "green", marginTop: "6px" }}>{success}</div> : null} */}
        <div>
          <Button
            variant="contained"
            onClick={() => {
              handleLogin();
            }}
            sx={{
              color: "#072D26",
              my: 2,
              backgroundColor: "#C4D661",
              borderColor: "#C4D661",
              borderRadius: 30,
              fontSize: "small",
              paddingY: 1.5,
              paddingX: 5,
              "&:hover": {
                backgroundColor: "#C0D904",
              },
            }}
          >
            <MediumText color="#072D26" fWeight="bold">
              Login
            </MediumText>
          </Button>

        </div>

        <Link
          href="/password-vergessen"
          underline="always"
          color={"#000000"}
          sx={{ mt: 2 }}
        >
          Passwort vergessen
        </Link>
      </FormControl>
    </Box>
  );
}
