import { ReactNode } from "react";
import { Typography } from "@mui/material";

interface Props {
  children?: ReactNode;
  color?: String;
  fWeight?: String;
  mt?: any;
  mb?: any;
  mr?: any;
  ml?: any;
  mx?: any;
  pl?: any;
  pr?: any;
  textAlign?: String;
  display?: String;
  float?: String;
  height?: any;
  padding?: any;
  alignSelf?: string;
  whiteSpace?: string;
  textdecor?: boolean;
  noPadding?: boolean
  // any props that come into the component
}

export default function FilterLotSearchText({
  children,
  color,
  fWeight,
  mt,
  mb,
  textAlign,
  display,
  ml,
  mr,
  mx,
  pl,
  pr,
  float,
  height,
  padding,
  alignSelf,
  whiteSpace,
  textdecor,
  noPadding = false
}: Props) {
  return (
    <Typography
      textAlign="center"
      sx={{
        fontFamily: "DMSans",
        mt: mt,
        mb: mb,
        mr: mr,
        ml: ml,
        mx: mx,
        pl: noPadding ? "0px" : "15px",
        pr: noPadding ? "0px" : "15px",
        color: `${color}`,
        fontWeight: `${fWeight}`,
        fontSize: 15,
        letterSpacing: 0,
        textAlign: `${textAlign}`,
        display: `${display}`,
        float: `${float}`,
        height: `${height}`,
        padding: `${padding}`,
        alignSelf: `${alignSelf}`,
        whiteSpace: `${whiteSpace}`,
        ...(textdecor
          ? {
              textDecoration: "underline",
            }
          : {
              textDecoration: "none",
            }),
      }}
    >
      {children}
    </Typography>
  );
}
