import { Divider, Paper } from "@mui/material";
import { useAppDispatch, useAppSelector } from "app/hooks";
import HolzfinderSlider from "components/inputs/HolzfinderSlider";
import { FilterLotSearchText } from "components/typography";
import { selectLotLocationFilter, selectLotLocationFilterSettings, setFm } from "features/lotLocations/lotLocationsSlice";

export default function SolidCubicMeterFilterSection() {
  const dispatch = useAppDispatch();
  const valueSettings = useAppSelector(selectLotLocationFilterSettings).solid_cubic_meters_without_bark;
  const value = useAppSelector(selectLotLocationFilter).solid_cubic_meters_without_bark;

  const handleFm = (event: any) => {
    dispatch(setFm(event.target.value));
  };

  return (
    <Paper
      sx={{
        backgroundColor: "white",
        minWidth: { xs: "300px", lg: "360px" },
        borderRadius: "26px",
        display: "flex",
        padding: "2px 4px",
        alignItems: "center",
        height: "50px",
      }}
    >
      <FilterLotSearchText>Festmeter</FilterLotSearchText>
      <Divider sx={{ height: 28, m: 1, color: "#1F2A40", border: 1 }} orientation="vertical" />
      <FilterLotSearchText mx={1}>{valueSettings[0]}fm</FilterLotSearchText>

      <HolzfinderSlider
        // @ts-ignore: Unreachable code error
        value={value}
        onChange={handleFm}
        min={valueSettings[0]}
        max={valueSettings[1]}
        valueLabelDisplay="auto"
        size="small"
      />
      <FilterLotSearchText mx={1}>{valueSettings[1]}fm</FilterLotSearchText>
    </Paper>
  );
}
