import { Typography } from "@mui/material";
import { useAppSelector } from "app/hooks";
import { selectAgent } from "features/agent/agentSlice";
import AgentData from "./AgentData";
import AgentGrid from "./AgentGrid";

const ContactDataLabels = {
  phone: "Telefon",
  mobile: "Mobil",
  fax: "Fax",
  email: "E-Mail",
  tax_number: "Steuernummer",
  vat_identification_number: "Mehrwertsteuer-Identifikationsnummer",
};

export default function AgentInformation(props: any) {
  const agentData = useAppSelector(selectAgent);
  return (
    <AgentGrid>
      <Typography
        fontWeight={"bold"}
        variant="h5"
        sx={{
          mt: 2,
          mb: 1,
          color: "#072D26",
        }}
      >
        Agenten Informationen
      </Typography>
      {
        agentData?.phone ? (
          <AgentData
            label={ContactDataLabels.phone}
            data={agentData?.phone}
          />
        ) : (
          <></>
        )
      }
      {
        agentData?.mobile ? (
          <AgentData
            label={ContactDataLabels.mobile}
            data={agentData?.mobile}
          />
        ) : (
          <></>
        )
      }
      {
        agentData?.fax ? (
          <AgentData
            label={ContactDataLabels.fax}
            data={agentData?.fax}
          />
        ) : (
          <></>
        )
      }
      {
        agentData?.email ? (
          <AgentData
            label={ContactDataLabels.email}
            data={agentData?.email}
          />
        ) : (
          <></>
        )
      }
      {
        agentData?.tax_number ? (
          <AgentData
            label={ContactDataLabels.tax_number}
            data={agentData?.tax_number}
          />
        ) : (
          <></>
        )
      }
      {
        agentData?.vat_identification_number ? (
          <AgentData
            label={ContactDataLabels.vat_identification_number}
            data={agentData?.vat_identification_number}
          />
        ) : (
          <></>
        )
      }
    </AgentGrid>
  );
}
