import { Box, Container } from "@mui/material";
import Header from "components/header/Header";
import XLargeText from '../../components/typography/XLargeText';
import {
  LargeText,
  MediumLargeText,
  MediumText,
  SmallText,
} from "components/typography";

export default function Agb() {
  return (
    <Container sx={{ p: 1, mt: 5 }}>
      <Header
        header="Datenschutzerklärung"
      />
      {/* <XLargeText>AGB</XLargeText> */}
      <Box sx={{ mt: 3, mb: 4 }}>
        <XLargeText my={0.5}>1. Datenschutz auf einen Blick</XLargeText>
        <LargeText my={0.5}>Allgemeine Hinweise</LargeText>
        <Box sx={{ marginBottom: 2 }}>
          <MediumText>
            Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten passiert, wenn Sie diese Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie persönlich identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten Datenschutzerklärung.
          </MediumText>
          <Box sx={{ marginBottom: 2 }}></Box>
        </Box>

        <LargeText my={0.5}>Datenerfassung auf dieser Website</LargeText>
        <Box sx={{ marginBottom: 2 }}></Box>
        <Box sx={{ marginBottom: 2 }}>
          <MediumText>
            Wer ist verantwortlich für die Datenerfassung auf dieser Website?
          </MediumText>
          <MediumText>
            Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen Kontaktdaten können Sie dem Abschnitt „Hinweis zur Verantwortlichen Stelle“ in dieser Datenschutzerklärung entnehmen.
          </MediumText>
          <Box sx={{ marginBottom: 2 }}></Box>
        </Box>

        <Box sx={{ marginBottom: 2 }}>
          <MediumText>
            Wie erfassen wir Ihre Daten?
          </MediumText>
          <MediumText>
            Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z. B. um Daten handeln, die Sie in ein Kontaktformular eingeben.
          </MediumText>
          <Box sx={{ marginBottom: 2 }}></Box>
          <MediumText>
            Andere Daten werden automatisch oder nach Ihrer Einwilligung beim Besuch der Website durch unsere IT- Systeme erfasst. Das sind vor allem technische Daten (z. B. Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald Sie diese Website betreten.
          </MediumText>
        </Box>
        <Box sx={{ marginBottom: 2 }}>
          <MediumText>
            Wofür nutzen wir Ihre Daten?
          </MediumText>
          <MediumText>
            Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu gewährleisten. Andere Daten können zur Analyse Ihres Nutzerverhaltens verwendet werden.
          </MediumText>
        </Box>
        <Box sx={{ marginBottom: 2 }}>
          <MediumText>
            Welche Rechte haben Sie bezüglich Ihrer Daten?
          </MediumText>
          <MediumText>
            Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu erhalten. Sie haben außerdem ein Recht, die Berichtigung oder Löschung dieser Daten zu verlangen. Wenn Sie eine Einwilligung zur Datenverarbeitung erteilt haben, können Sie diese Einwilligung jederzeit für die Zukunft widerrufen. Außerdem haben Sie das Recht, unter bestimmten Umständen die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
          </MediumText>
          <MediumText>
            Des Weiteren steht Ihnen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
          </MediumText>
          <Box sx={{ marginBottom: 2 }}></Box>
          <MediumText>
            Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich jederzeit an uns wenden.
          </MediumText>
        </Box>
        <XLargeText my={0.5}>2. Hosting</XLargeText>
        <MediumText>
          Wir hosten die Inhalte unserer Website bei folgendem Anbieter:
        </MediumText>
        <Box sx={{ marginBottom: 2 }}></Box>
        <MediumText fWeight={'700'}>
          Hetzner
        </MediumText>
        <Box sx={{ marginBottom: 2 }}></Box>
        <MediumText>
          Anbieter ist die Hetzner Online GmbH, Industriestr. 25, 91710 Gunzenhausen (nachfolgend Hetzner).
        </MediumText>
        <Box sx={{ marginBottom: 2 }}></Box>
        <MediumText>
          Details entnehmen Sie der Datenschutzerklärung von Hetzner:
        </MediumText>
        <Box sx={{ marginBottom: 2 }}></Box>
        <a target="_blank" href="https://www.hetzner.com/de/rechtliches/datenschutz" >
          https://www.hetzner.com/de/rechtliches/datenschutz .
        </a>
        <Box sx={{ marginBottom: 2 }}></Box>
        <MediumText>
          Die Verwendung von Hetzner erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Wir haben ein berechtigtes Interesse an einer möglichst zuverlässigen Darstellung unserer Website. Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG, soweit die Einwilligung die Speicherung von Cookies oder den Zugriff auf Informationen im Endgerät des Nutzers (z. B. Device-Fingerprinting) im Sinne des TTDSG umfasst. Die Einwilligung ist jederzeit widerrufbar.
        </MediumText>
        <Box sx={{ marginBottom: 2 }}></Box>

        <LargeText my={0.5}>Auftragsverarbeitung</LargeText>
        <MediumText>
          Wir haben einen Vertrag über Auftragsverarbeitung (AVV) zur Nutzung des oben genannten Dienstes geschlossen. Hierbei handelt es sich um einen datenschutzrechtlich vorgeschriebenen Vertrag, der gewährleistet, dass dieser die personenbezogenen Daten unserer Websitebesucher nur nach unseren Weisungen und unter Einhaltung der DSGVO verarbeitet.
        </MediumText>
        <Box sx={{ marginBottom: 2 }}></Box>

        <XLargeText my={0.5}>3. Allgemeine Hinweise und Pflichtinformationen</XLargeText>

        <LargeText my={0.5}>Datenschutz</LargeText>
        <MediumText>
          Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und entsprechend den gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung.
        </MediumText>
        <Box sx={{ marginBottom: 2 }}></Box>
        <MediumText>
          Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie persönlich identifiziert werden können. Die vorliegende Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie erläutert auch, wie und zu welchem Zweck das geschieht.
        </MediumText>
        <Box sx={{ marginBottom: 2 }}></Box>

        <MediumText>
          Wir weisen darauf hin, dass die Datenübertragung im Internet (z. B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.
        </MediumText>
        <Box sx={{ marginBottom: 2 }}></Box>
        <LargeText my={0.5}>Hinweis zur verantwortlichen Stelle</LargeText>

        <MediumText>
          Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:
        </MediumText>
        <Box sx={{ marginBottom: 2 }}></Box>

        <MediumText>
          Landratsamt Karlsruhe

        </MediumText>
        <MediumText>
          Kriegsstraße 100

        </MediumText>
        <MediumText>
          76133 Karlsruhe

        </MediumText>
        <Box sx={{ marginBottom: 2 }}></Box>

        <MediumText>
          Telefon: 0721/936-50


        </MediumText>
        <MediumText>

          E-Mail: posteingang@landratsamt-karlsruhe.de

        </MediumText>
        <Box sx={{ marginBottom: 2 }}></Box>

        <MediumText>
          Verantwortliche Stelle ist die natürliche oder juristische Person, die allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z. B. Namen, E-Mail-Adressen o. Ä.) entscheidet.
        </MediumText>
        <Box sx={{ marginBottom: 2 }}></Box>

        <LargeText my={0.5}>Speicherdauer</LargeText>


        <MediumText>
          Soweit innerhalb dieser Datenschutzerklärung keine speziellere Speicherdauer genannt wurde, verbleiben Ihre personenbezogenen Daten bei uns, bis der Zweck für die Datenverarbeitung entfällt. Wenn Sie ein berechtigtes Löschersuchen geltend machen oder eine Einwilligung zur Datenverarbeitung widerrufen, werden Ihre Daten gelöscht, sofern wir keine anderen rechtlich zulässigen Gründe für die Speicherung Ihrer personenbezogenen Daten haben (z. B. steuer- oder handelsrechtliche Aufbewahrungsfristen); im letztgenannten Fall erfolgt die Löschung nach Fortfall dieser Gründe.
        </MediumText>
        <Box sx={{ marginBottom: 2 }}></Box>
        <LargeText my={0.5}>Allgemeine Hinweise zu den Rechtsgrundlagen der Datenverarbeitung auf dieser Website</LargeText>

        <MediumText>
          Sofern Sie in die Datenverarbeitung eingewilligt haben, verarbeiten wir Ihre personenbezogenen Daten auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO. Sofern Sie in die Speicherung von Cookies oder in den Zugriff auf Informationen in Ihr Endgerät (z. B. via Device-Fingerprinting) eingewilligt haben, erfolgt die Datenverarbeitung zusätzlich auf Grundlage von § 25 Abs. 1 TTDSG. Die Einwilligung ist jederzeit widerrufbar. Sind Ihre Daten zur Vertragserfüllung oder zur Durchführung vorvertraglicher Maßnahmen erforderlich, verarbeiten wir Ihre Daten auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO. Des Weiteren verarbeiten wir Ihre Daten, sofern diese zur Erfüllung einer rechtlichen Verpflichtung erforderlich sind auf Grundlage von Art. 6 Abs. 1 lit. c DSGVO. Die Datenverarbeitung kann ferner auf Grundlage unseres berechtigten Interesses nach Art. 6 Abs. 1 lit. F DSGVO erfolgen. Über die jeweils im Einzelfall einschlägigen Rechtsgrundlagen wird in den folgenden Absätzen dieser Datenschutzerklärung informiert.
        </MediumText>
        <Box sx={{ marginBottom: 2 }}></Box>


        <LargeText my={0.5}>Empfänger von personenbezogenen Daten</LargeText>

        <MediumText>
          Im Rahmen unserer Geschäftstätigkeit arbeiten wir mit verschiedenen externen Stellen zusammen. Dabei ist teilweise auch eine Übermittlung von personenbezogenen Daten an diese externen Stellen erforderlich. Wir geben personenbezogene Daten nur dann an externe Stellen weiter, wenn dies im Rahmen einer Vertragserfüllung erforderlich ist, wenn wir gesetzlich hierzu verpflichtet sind (z. B. Weitergabe von Daten an Steuerbehörden), wenn wir ein berechtigtes Interesse nach Art. 6 Abs. 1 lit. f DSGVO an der Weitergabe haben oder wenn eine sonstige Rechtsgrundlage die Datenweitergabe erlaubt. Beim Einsatz von Auftragsverarbeitern geben wir personenbezogene Daten unserer Kunden nur auf Grundlage eines gültigen Vertrags über Auftragsverarbeitung weiter. Im Falle einer gemeinsamen Verarbeitung wird ein Vertrag über gemeinsame Verarbeitung geschlossen.
        </MediumText>



        <Box sx={{ marginBottom: 2 }}></Box>


        <LargeText my={0.5}>Verarbeiten von Kunden- und Vertragsdaten</LargeText>

        <MediumText>
          Im Rahmen Ihrer Bestellung auf holzfinder.de ist es üblich, dass Sie einen Artikel erwerben, der nicht durch Holzfinder selbst bereitgestellt wird, sondern durch den jeweiligen Waldbesitzer. Eine Datenübermittlung an entsprechende Waldbesitzer erfolgt auf Basis Ihres Kaufs ausschließlich zum Zweck der Übermittlung der Rechnungsdaten an den Waldbesitzer, um den Zahlungseingang feststellen zu können.
        </MediumText>



        <Box sx={{ marginBottom: 2 }}></Box>
        <LargeText my={0.5}>Widerruf Ihrer Einwilligung zur Datenverarbeitung</LargeText>

        <MediumText>
          Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie können eine bereits erteilte Einwilligung jederzeit widerrufen. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
        </MediumText>



        <Box sx={{ marginBottom: 2 }}></Box>

        <LargeText my={0.5}>Widerspruchsrecht gegen die Datenverarbeitung</LargeText>

        <MediumText>
          Wenn die Datenverarbeitung auf Grundlage von Art. 6 Abs. 1 lit. e oder f DSGVO erfolgt, haben Sie jederzeit das Recht, aus Gründen, die sich aus ihrer besonderen Situation ergeben, gegen die Verarbeitung ihrer personenbezogenen Daten Widerspruch einzulegen.
        </MediumText>



        <Box sx={{ marginBottom: 2 }}></Box>

        <LargeText my={0.5}>Beschwerderecht bei der zuständigen Aufsichtsbehörde</LargeText>

        <MediumText>
          Soweit Sie sich durch die Verarbeitung personenbezogener Daten in Ihren Rechten verletzt fühlen, steht Ihnen ein Recht zur Beschwerde beim Landesbeauftragten für den Datenschutz und die Informationsfreiheit, Lautenschlagerstraße 20, 70173 Stuttgart, Postfach 102932, 70025 Stuttgart, Tel.: 0711 / 615541-0, Fax: 0711 / 615541-15, poststelle@lfdi.bwl.de zu
        </MediumText>



        <Box sx={{ marginBottom: 2 }}></Box>


        <LargeText my={0.5}>Recht auf Datenübertragbarkeit</LargeText>

        <MediumText>
          Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder an einen Dritten in einem gängigen, maschinenlesbaren Format aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar ist.
        </MediumText>



        <Box sx={{ marginBottom: 2 }}></Box>


        <LargeText my={0.5}>Auskunft, Berichtigung und Löschung</LargeText>

        <MediumText>
          Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung oder Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit an uns wenden.
        </MediumText>



        <Box sx={{ marginBottom: 2 }}></Box>

        <LargeText my={0.5}>Recht auf Einschränkung der Verarbeitung</LargeText>

        <MediumText>
          Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Hierzu können Sie sich jederzeit an uns wenden. Das Recht auf Einschränkung der Verarbeitung besteht in folgenden Fällen:
        </MediumText>

        <Box ml={2}>


          <MediumText>
            •	Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten bestreiten, benötigen wir in der Regel Zeit, um dies zu überprüfen. Für die Dauer der Prüfung haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.

          </MediumText>
          <MediumText>

            •	Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig geschah/geschieht, können Sie statt der Löschung die Einschränkung der Datenverarbeitung verlangen.
          </MediumText>

          <MediumText>
            •	Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie jedoch zur Ausübung, Verteidigung oder Geltendmachung von Rechtsansprüchen benötigen, haben Sie das Recht, statt der Löschung die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.

          </MediumText>
          <MediumText>
            •	Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine Abwägung zwischen Ihren und unseren Interessen vorgenommen werden. Solange noch nicht feststeht, wessen Interessen überwiegen, haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.

          </MediumText>
        </Box>
        <Box sx={{ marginBottom: 2 }}></Box>
        <MediumText>
          Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt haben, dürfen diese Daten – von ihrer Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder juristischen Person oder aus Gründen eines wichtigen öffentlichen Interesses der Europäischen Union oder eines Mitgliedstaats verarbeitet werden.
        </MediumText>
        <Box sx={{ marginBottom: 2 }}></Box>

        <LargeText my={0.5}>SSL- bzw. TLS-Verschlüsselung</LargeText>

        <MediumText>
          Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL- bzw. TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von„http://“ auf „https://“ wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.
        </MediumText>

        <Box sx={{ marginBottom: 2 }}></Box>

        <MediumText>
          Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen werden.
        </MediumText>

        <Box sx={{ marginBottom: 2 }}></Box>


        <XLargeText my={0.5}>4. Datenerfassung auf dieser Website</XLargeText>

        <LargeText my={0.5}>Cookies</LargeText>

        <MediumText>
          Unsere Internetseiten verwenden so genannte „Cookies“. Cookies sind kleine Datenpakete und richten auf Ihrem Endgerät keinen Schaden an. Sie werden entweder vorübergehend für die Dauer einer Sitzung (Session-Cookies) oder dauerhaft (permanente Cookies) auf Ihrem Endgerät gespeichert. Session-Cookies werden nach Ende Ihres Besuchs automatisch gelöscht. Permanente Cookies bleiben auf Ihrem Endgerät gespeichert, bis Sie diese selbst löschen oder eine automatische Löschung durch Ihren Webbrowser erfolgt.
        </MediumText>
        <Box sx={{ marginBottom: 2 }}></Box>
        <MediumText>
          Cookies können von uns (First-Party-Cookies) oder von Drittunternehmen stammen (sog. Third-Party- Cookies). Third-Party-Cookies ermöglichen die Einbindung bestimmter Dienstleistungen von Drittunternehmen innerhalb von Webseiten (z. B. Cookies zur Abwicklung von Zahlungsdienstleistungen).
        </MediumText>
        <Box sx={{ marginBottom: 2 }}></Box>
        <MediumText>
          Cookies haben verschiedene Funktionen. Zahlreiche Cookies sind technisch notwendig, da bestimmte Webseitenfunktionen ohne diese nicht funktionieren würden (z. B. die Warenkorbfunktion oder die Anzeige von Videos). Andere Cookies können zur Auswertung des Nutzerverhaltens oder zu Werbezwecken verwendet werden.
        </MediumText>
        <Box sx={{ marginBottom: 2 }}></Box>
        <MediumText>
          Cookies, die zur Durchführung des elektronischen Kommunikationsvorgangs, zur Bereitstellung bestimmter, von Ihnen erwünschter Funktionen (z. B. für die Warenkorbfunktion) oder zur Optimierung der Website (z. B. Cookies zur Messung des Webpublikums) erforderlich sind (notwendige Cookies), werden auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO gespeichert, sofern keine andere Rechtsgrundlage angegeben wird. Der Websitebetreiber hat ein berechtigtes Interesse an der Speicherung von notwendigen Cookies zur technisch fehlerfreien und optimierten Bereitstellung seiner Dienste. Sofern eine Einwilligung zur Speicherung von Cookies und vergleichbaren Wiedererkennungstechnologien abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage dieser Einwilligung (Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG); die Einwilligung ist jederzeit widerrufbar.
        </MediumText>
        <Box sx={{ marginBottom: 2 }}></Box>
        <MediumText>
          Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und Cookies nur im Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder generell ausschließen sowie das automatische Löschen der Cookies beim Schließen des Browsers aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität dieser Website eingeschränkt sein.
        </MediumText>
        <Box sx={{ marginBottom: 2 }}></Box>
        <MediumText>
          Welche Cookies und Dienste auf dieser Website eingesetzt werden, können Sie dieser Datenschutzerklärung entnehmen.
        </MediumText>
        <Box sx={{ marginBottom: 2 }}></Box>


        <LargeText my={0.5}>Kontaktformular</LargeText>

        <MediumText>
          Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre Angaben aus dem Anfrageformular inklusive der von Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für den Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.
        </MediumText>
        <Box sx={{ marginBottom: 2 }}></Box>

        <MediumText>
          Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf unserem berechtigten Interesse an der effektiven Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde; die Einwilligung ist jederzeit widerrufbar.
        </MediumText>
        <Box sx={{ marginBottom: 2 }}></Box>

        <MediumText>
          Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt (z. B. nach abgeschlossener Bearbeitung Ihrer Anfrage). Zwingende gesetzliche Bestimmungen – insbesondere Aufbewahrungsfristen – bleiben unberührt.
        </MediumText>
        <Box sx={{ marginBottom: 2 }}></Box>


        <LargeText my={0.5}>Registrierung bei der Webseite</LargeText>

        <MediumText>
          Sie können sich auf unserer Website registrieren, um zusätzliche Funktionen auf der Seite zu nutzen. Die dazu eingegebenen Daten verwenden wir nur zum Zwecke der Nutzung des jeweiligen Angebotes oder Dienstes, für den Sie sich registriert haben. Die bei der Registrierung abgefragten Pflichtangaben müssen vollständig angegeben werden. Anderenfalls werden wir die Registrierung ablehnen.
        </MediumText>
        <Box sx={{ marginBottom: 2 }}></Box>

        <MediumText>
          Für wichtige Änderungen etwa beim Angebotsumfang oder bei technisch notwendigen Änderungen nutzen wir die bei der Registrierung angegebene E-Mail-Adresse, um Sie auf diesem Wege zu informieren.
        </MediumText>
        <Box sx={{ marginBottom: 2 }}></Box>

        <MediumText>
          Die Verarbeitung der bei der Registrierung eingegebenen Daten erfolgt auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Sie können eine von Ihnen erteilte Einwilligung jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an uns. Die Rechtmäßigkeit der bereits erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
        </MediumText>
        <Box sx={{ marginBottom: 2 }}></Box>

        <MediumText>
          Die bei der Registrierung erfassten Daten werden von uns gespeichert, solange Sie auf unserer Website registriert sind und werden anschließend gelöscht. Gesetzliche Aufbewahrungsfristen bleiben unberührt.
        </MediumText>
        <Box sx={{ marginBottom: 2 }}></Box>



        <LargeText my={0.5}>Datenübermittlung bei Vertragsschluss für Online-Shops, Händler und Warenversand</LargeText>

        <MediumText>
          Wir übermitteln personenbezogene Daten an Dritte nur dann, wenn dies im Rahmen der Vertragsabwicklung notwendig ist, etwa an die mit der Lieferung der Ware betrauten Unternehmen oder das mit der Zahlungsabwicklung beauftragte Kreditinstitut. Eine weitergehende Übermittlung der Daten erfolgt nicht bzw. nur dann, wenn Sie der Übermittlung ausdrücklich zugestimmt haben. Eine Weitergabe Ihrer Daten an Dritte ohne ausdrückliche Einwilligung, etwa zu Zwecken der Werbung, erfolgt nicht.
        </MediumText>
        <Box sx={{ marginBottom: 2 }}></Box>
        <MediumText>
          Grundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. b DSGVO, der die Verarbeitung von Daten zur Erfüllung eines Vertrags oder vorvertraglicher Maßnahmen gestattet.
        </MediumText>
        <Box sx={{ marginBottom: 2 }}></Box>



        <LargeText my={0.5}>Anfrage per E-Mail, Telefon oder Telefax</LargeText>

        <MediumText>
          Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird Ihre Anfrage inklusive aller daraus hervorgehenden personenbezogenen Daten (Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei uns gespeichert und verarbeitet. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.
        </MediumText>

        <Box sx={{ marginBottom: 2 }}></Box>
        <MediumText>

          Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf unserem berechtigten Interesse an der effektiven Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde; die Einwilligung ist jederzeit widerrufbar.

        </MediumText>

        <Box sx={{ marginBottom: 2 }}></Box>
        <MediumText>
          Die von Ihnen an uns per Kontaktanfragen übersandten Daten verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt (z. B. nach abgeschlossener Bearbeitung Ihres Anliegens). Zwingende gesetzliche Bestimmungen – insbesondere gesetzliche Aufbewahrungsfristen – bleiben unberührt.
        </MediumText>

        <Box sx={{ marginBottom: 2 }}></Box>




        <XLargeText my={0.5}>5. Plugins und Tools</XLargeText>

        <LargeText my={0.5}>OpenStreetMap</LargeText>

        <MediumText>
          Wir nutzen den Kartendienst von OpenStreetMap (OSM).
        </MediumText>

        <Box sx={{ marginBottom: 2 }}></Box>
        <MediumText>
          Wir binden das Kartenmaterial von OpenStreetMap auf dem Server der OpenStreetMap Foundation, St John’s Innovation Centre, Cowley Road, Cambridge, CB4 0WS, Großbritannien, ein. Großbritannien gilt als datenschutzrechtlich sicherer Drittstaat. Das bedeutet, dass Großbritannien ein Datenschutzniveau aufweist, das dem Datenschutzniveau in der Europäischen Union entspricht. Bei der Nutzung der OpenStreetMap-Karten wird eine Verbindung zu den Servern der OpenStreetMap-Foundation hergestellt. Dabei können u. a. Ihre IP-Adresse und weitere Informationen über Ihr Verhalten auf dieser Website an die OSMF weitergeleitet werden. OpenStreetMap speichert hierzu unter Umständen Cookies in Ihrem Browser oder setzt vergleichbare Wiedererkennungstechnologien ein.
        </MediumText>

        <Box sx={{ marginBottom: 2 }}></Box>
        <MediumText>
          Die Nutzung von OpenStreetMap erfolgt im Interesse einer ansprechenden Darstellung unserer Online-Angebote und einer leichten Auffindbarkeit der von uns auf der Website angegebenen Orte. Dies stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar. Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG, soweit die Einwilligung die Speicherung von Cookies oder den Zugriff auf Informationen im Endgerät des Nutzers (z. B. Device-Fingerprinting) im Sinne des TTDSG umfasst. Die Einwilligung ist jederzeit widerrufbar.
        </MediumText>

        <Box sx={{ marginBottom: 2 }}></Box>



        <XLargeText my={0.5}>6. Datenschutzbeauftragter</XLargeText>


        <MediumText>
          Sie erreichen unseren Datenschutzbeauftragten unter:
        </MediumText>
        <MediumText>
          datenschutzbeauftragter@landratsamt-karlsruhe.de
        </MediumText>

        <Box sx={{ marginBottom: 2 }}></Box>


        <Box></Box>
      </Box>
    </Container >
  );
}
