import { Box, Container } from "@mui/material";
import Header from "components/header/Header";
import XLargeText from '../../components/typography/XLargeText';
import {
  LargeText,
  MediumLargeText,
  MediumText,
  SmallText,
} from "components/typography";

export default function Agb() {
  return (
    <Container sx={{ p: 1, mt: 5 }}>
      <Header
        header="Allgemeine Geschäftsbedingungen"
      />
      {/* <XLargeText>AGB</XLargeText> */}
      <Box sx={{ mt: 3, mb: 4 }}>
        <LargeText my={0.5}>Geltungsbereich</LargeText>
        <Box sx={{ marginBottom: 2 }}>
          <MediumText>
            Die Website www.holzfinder.de ist ein Marktplatz für unverarbeitetes Rundholz, der von den teilnehmenden holzverkaufenden Stellen der Landkreise in Baden-Württemberg (im nachfolgenden “Holzfinder” genannt), unterhalten wird. Dabei tritt Holzfinder nur als Vermittler auf. Der Vertrag kommt mit dem/den jeweiligen Forstbetrieb(en) zustande, den/die der Kunde im Rahmen des Kaufvorgangs auswählt hat (im nachfolgenden “Verkäufer” genannt). Sämtlichen Verträgen auf dieser Website liegen die nachfolgenden Allgemeinen Geschäftsbedingungen zugrunde. Diese AGB dient bei deren Anwendung als Grundlage für Brennholzverkäufe an Verbraucher/-innen im Sinne des § 13 BGB. Sie sind Bestandteil der Kaufverträge.
          </MediumText>
          <Box sx={{ marginBottom: 2 }}></Box>
          <MediumText>Hinweise auf die Geltung gesetzlicher Vorschriften haben nur klarstellende Bedeutung. Auch ohne eine derartige Klarstellung gelten daher die gesetzlichen Vorschriften, soweit sie in diesen AGB nicht unmittelbar abgeändert oder ausdrücklich ausgeschlossen werden.</MediumText>
          <Box sx={{ marginBottom: 2 }}></Box>

          <LargeText my={0.5}>Vertragsschluss, Leistung und Streitbeilegung</LargeText>

          <MediumText>
            Der Verkauf von Rundholz auf dieser Plattform erfolgt ausdrücklich nicht durch Holzfinder. Verkäufer des Brennholzes und somit Vertragspartner mit allen Rechten und Pflichten gegenüber dem Kunden ist derjenige Forstbetrieb oder andere Verkäufer, der im Verkaufsangebot als Anbieter genannt ist. Holzfinder stellt lediglich die Plattform zum Vermitteln dieser Verträge bereit sowie ggfs. weitere Dienstleistungen, soweit diese in diesen AGB ausdrücklich genannt sind. Holzfinder übernimmt keine Haftung und keine Gewähr über die Durchführung der vermittelten Verträge und wird diese auch nicht überprüfen. Das Rechtsverhältnis bzgl. des Verkaufs und der Abholung der Ware betrifft allein die beteiligten Kunden und Anbieter.
          </MediumText>
          <Box sx={{ marginBottom: 2 }}></Box>

          <MediumText>
            Die Darstellung der Ware auf der Website www.holzfinder.de stellt ein rechtlich bindendes Angebot durch den Anbieter dar, das nur auf dem nachstehend beschriebenen Weg vom Kunden über die Website www.holzfinder.de angenommen werden kann. Mit Bestätigung des “Jetzt kostenpflichtig bestellen”-Buttons und Zustimmung zu den AGB nimmt der Kunde das Kaufangebot an und der Vertrag gilt als abgeschlossen. Im Anschluss erhält der Kunde eine E-Mail als Kaufbestätigung mit Rechnung und Lagerortkarte.
          </MediumText>
          <Box sx={{ marginBottom: 2 }}></Box>


          <MediumText>
            Eingabefehler bei der Bestellung kann der Kunde dadurch korrigieren, dass er den “Jetzt kostenpflichtig bestellen”-Button nicht betätigt, und stattdessen in seinem Browserfenster auf die vorherigen Seiten klickt und im jeweiligen Eingabefenster die Angaben zur Bestellung wie Bestellmenge, Rechnungsadresse, usw. korrigiert. Durch einen Klick auf das Symbol “Einkaufswagen” hat der Kunde außerdem jederzeit die Möglichkeit, einzelne Artikel aus der Bestellung zu entfernen.
          </MediumText>
          <Box sx={{ marginBottom: 2 }}></Box>


          <MediumText>
            Der Vertragstext wird nicht gespeichert. Der Kunde hat jedoch die Möglichkeit, sich am Ende des Bestellvorgangs die Bestellinformationen, die ihm nach Abschluss der Bestellung auch noch einmal per E-Mail übersandt werden, sowie ggf. die Annahmeerklärung über die Druckfunktion im verwendeten Browser oder E-Mail-Client auszudrucken.
          </MediumText>
          <Box sx={{ marginBottom: 2 }}></Box>


          <MediumText>
            Ist keine andere Form der Übergabe vereinbart, erhält der Kunde nach Abschluss des Kaufs die entsprechende Rechnung. Diese gilt in Kombination mit einem Zahlungsnachweis als Zufahrtsberechtigung, um das Brennholz im Wald des Verkäufers abzuholen. Die Gültigkeitsdauer der Zufahrtsberechtigung ist auf der Rechnung angezeigt.
          </MediumText>
          <Box sx={{ marginBottom: 2 }}></Box>


          <MediumText>
            Holzfinder behält sich vor, sein Leistungsangebot insoweit zu erweitern oder zu verbessern, wie dies zur Anpassung an die aktuellen technischen Gegebenheiten notwendig ist, um insbesondere die Sicherheit und Stabilität des Services zu sichern oder geänderten gesetzlichen Anforderungen gerecht zu werden. Holzfinder verpflichtet sich dazu, solche Änderungen nur in einem für den Kunden zumutbaren Rahmen und unter Berücksichtigung der Interessen aller Vertragsparteien vorzunehmen.
          </MediumText>
          <Box sx={{ marginBottom: 2 }}></Box>


          <MediumText>
            Holzfinder ist bemüht, den Zugang zur Plattform rund um die Uhr zur Verfügung zu stellen. Es besteht jedoch kein Anspruch des Kunden auf ununterbrochene Verfügbarkeit des Services. Der Zugriff auf die Plattform kann gelegentlich unterbrochen oder beschränkt sein, um Instandsetzungen, Wartungen, systemimmanenten Störungen des Internets bei fremden Netzbetreibern, die Einführung von neuen Einrichtungen oder Services zu ermöglichen sowie im Falle höherer Gewalt. Es besteht kein Anspruch auf Leistung oder Schadensersatz des Kunden, wenn aus den vorstehenden Gründen oder aufgrund höherer Gewalt nicht auf die Plattform zugegriffen werden kann.
          </MediumText>
          <Box sx={{ marginBottom: 2 }}></Box>


          <MediumText>
            Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit, die unter ec.europa.eu/consumers/odr/ zu finden ist. Im Übrigen ist Holzfinder zur Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle weder bereit noch verpflichtet.
          </MediumText>
          <Box sx={{ marginBottom: 2 }}></Box>

          <LargeText my={0.5}>
            Anmeldung und Mitgliedskonto
          </LargeText>


          <MediumText>
            Die Nutzung der Holzfinder-Website zum Erwerb der angebotenen Holzlose setzt die Registrierung voraus. Die Registrierung ist kostenlos. Sie erfolgt durch Eröffnung eines Kundenkontos unter Zustimmung zu diesen AGB.
          </MediumText>
          <Box sx={{ marginBottom: 2 }}></Box>

          <MediumText>
            Die Anmeldung ist nur volljährig natürlichen Personen erlaubt. Eine Nutzung durch Minderjährige oder durch juristische Personen ist ausgeschlossen. Holzfinder behält sich vor, Minderjährige oder juristische Personen bei Bekanntwerden unverzüglich von der Nutzung auszuschließen.
          </MediumText>
          <Box sx={{ marginBottom: 2 }}></Box>

          <MediumText>
            Die von Holzfinder bei der Anmeldung abgefragten Daten sind vollständig und korrekt anzugeben: Vor- und Nachname, die aktuelle Adresse (kein Postfach) und Telefonnummer (keine Mehrwertdienste-Rufnummer), eine gültige E-Mail-Adresse.
            Bei der Registrierung dürfen nur einzelne Personen als Inhaber des Mitgliedskontos angegeben werden (d.h. keine Ehepaare oder Familien).
          </MediumText>
          <Box sx={{ marginBottom: 2 }}></Box>

          <MediumText>
            Ändern sich nach der Registrierung die angegebenen Daten, so ist der Käufer selbst verpflichtet, die Angaben in seinem Kundenkonto umgehend zu korrigieren. Holzfinder haftet für keine Schäden oder Ausfälle, die durch falsch hinterlegte Kundendaten entstehen.
          </MediumText>
          <Box sx={{ marginBottom: 2 }}></Box>

          <MediumText>
            Die Anmeldung erfolgt über die Emailadresse und ein Passwort, die Emailadresse muss nach der Registrierung verifiziert werden.
          </MediumText>
          <Box sx={{ marginBottom: 2 }}></Box>

          <MediumText>
            Registrierte Käufer müssen ihr Passwort geheim halten und den Zugang zu ihrem Kundenkonto sorgfältig sichern. Registrierte Käufer sind verpflichtet, Holzfinder umgehend zu informieren, wenn es Anhaltspunkte dafür gibt, dass ein Kundenkonto von Dritten missbraucht wurde. Holzfinder wird das Passwort eines registrierten Käufers nicht an Dritte weitergeben und einen registrierten Käufer nie per E-Mail oder Telefon nach seinem Passwort fragen.
          </MediumText>
          <Box sx={{ marginBottom: 2 }}></Box>

          <MediumText>
            Registrierte Käufer haften grundsätzlich für sämtliche Aktivitäten, die unter Verwendung ihres Kundenkontos vorgenommen werden. Hat der registrierte Käufer den Missbrauch seines Kundenkontos nicht zu vertreten, weil eine Verletzung der bestehenden Sorgfaltspflichten nicht vorliegt, so haftet der registrierte Käufer nicht.
          </MediumText>
          <Box sx={{ marginBottom: 2 }}></Box>

          <MediumText>
            Ein Käuferkonto ist nicht übertragbar.
          </MediumText>
          <Box sx={{ marginBottom: 2 }}></Box>

          <MediumText>
            Holzfinder behält sich das Recht vor, Kundenkonten von nicht vollständig durchgeführten Anmeldungen nach einer angemessenen Zeit zu löschen. Registrierte Käufer können Ihr Kundenkonto jederzeit kündigen. Für die Kündigungserklärung genügt eine E-Mail an die Emailadresse info@holzfinder.de.
          </MediumText>
          <Box sx={{ marginBottom: 2 }}></Box>

          <LargeText my={0.5}>
            Kaufabwicklung
          </LargeText>

          <MediumText>
            Alle Preise verstehen sich inklusive gesetzlicher Umsatzsteuer (Mehrwertsteuer).
          </MediumText>
          <Box sx={{ marginBottom: 2 }}></Box>


          <MediumText>
            Bestimmungen zur Zahlungsart, den Zahlungsfristen und der Abfuhr des Holzes werden durch Holzfinder geregelt.
          </MediumText>
          <Box sx={{ marginBottom: 2 }}></Box>

          <LargeText my={0.5}>
            Zahlung auf Rechnung
          </LargeText>


          <MediumText>
            Rechnungen bei Verkäufen auf Rechnung werden ausschließlich per E-Mail an den Kunden übermittelt. Sie sind innerhalb von 14 Tagen zur Zahlung fällig nach Verkaufsdatum und ohne jeglichen Abzug zu zahlen.
          </MediumText>
          <Box sx={{ marginBottom: 2 }}></Box>


          <LargeText my={0.5}>
            Rücktritt vom Kauf
          </LargeText>

          <MediumText>
            Ein Rücktritt vom Kauf ist explizit nicht im Kaufvertrag formuliert und damit ausgeschlossen, sofern der Verkäufer seine gesetzlichen Pflichten erfüllt hat.
            Der Käufer hat die Möglichkeit die angebotenen Holzlose vorab zu besichtigen.

          </MediumText>
          <Box sx={{ marginBottom: 2 }}></Box>



          <LargeText my={0.5}>
            Widerrufsrecht
          </LargeText>

          <MediumText>
            Der Käufer hat gemäß § 312g Abs.1 BGB das Recht, binnen 14 Tagen (§ 355 BGB) ab Vertragsabschluss den Kauf zu widerrufen.
            Er hat den Widerruf schriftlich an die auf der Rechnung angegebene Adresse der holzverkaufenden Stelle zu richten.
            Als weitere Möglichkeit kann der Käufer seinen Widerruf fernmündlich an den Kontakt für Rückfragen, der auf der Rechnung angegeben ist, richten.

          </MediumText>
          <Box sx={{ marginBottom: 2 }}></Box>



          <LargeText my={0.5}>
            Leistung/Gefahrenübergang und Abholung
          </LargeText>

          <MediumText>
            Das Holz gilt mit der Bereitstellung (Betätigung “Jetzt kostenpflichtig bestellen”-Button) als in den Mitbesitz des Käufers / der Käuferin übergeben. Mit der Übergabe des Holzes geht die Gefahr des zufälligen Untergangs und der zufälligen Verschlechterung auf den/die Käufer/-in über.
          </MediumText>
          <Box sx={{ marginBottom: 2 }}></Box>

          <MediumText>
            Die gekaufte Ware wird nicht geliefert. Sie kann vom Kunden nur an dem beim Kauf angegebenen Ort abgeholt werden (Lieferung frei Wald).
          </MediumText>
          <Box sx={{ marginBottom: 2 }}></Box>


          <MediumText>
            Die Abholung der Ware erfolgt durch den Kunden im Anschluss an den vollständigen Zahlungseingang vom im Angebot angegebenen Ort.
          </MediumText>
          <Box sx={{ marginBottom: 2 }}></Box>


          <MediumText>
            Der Kunde hat selbst dafür Sorge zu tragen, dass er die Ware eigenständig am angegebenen Ort abholen kann. Weder Verkäufer noch Holzfinder werden dem Kunden beim Aufladen und/oder Abtransport zur Verfügung stehen.
          </MediumText>
          <Box sx={{ marginBottom: 2 }}></Box>



          <MediumText>
            Ist der Verkäufer zertifiziert, gelten die einschlägigen Vorschriften des jeweiligen Zertifizierungssystems, damit ist die Einhaltung von Standards zur nachhaltigen und umweltgerechten Waldwirtschaft verbunden. Die Zertifizierung der Verkäufer ist auf der Rechnung angegeben. Bei Nichteinhalten der Zertifizierungsstandards und der nachfolgenden Vorschriften behalten sich Holzfinder und Verkäufer den künftigen Ausschluss des Käufers / der Käuferin von Holzverkäufen vor. Der/Die Käufer/-in muss sich selbst erkundigen welche Standards damit verbunden sind.
          </MediumText>
          <Box sx={{ marginBottom: 2 }}></Box>


          <LargeText my={0.5}>
            Arbeitssicherheit und Unfallverhütung
          </LargeText>



          <MediumText>
            Die Aufarbeitung von gekauftem Holz liegt ausschließlich in der Verantwortung des Käufers, sowohl Verkäufer als auch Holzfinder lehnen jegliche Haftung ab.
          </MediumText>

          <Box sx={{ marginBottom: 2 }}></Box>

          <MediumText>
            Personen, die mit der Motorsäge arbeiten, müssen die Teilnahme an einem Motorsägen-Grundlehrgang nachweisen. Anstelle eines Motorsägenlehrganges kann die Sachkunde für den Umgang mit der Motorsäge auch durch den Nachweis einer Berufsausbildung und/oder mehrjährigen beruflichen Tätigkeit in der Holzernte erbracht werden.
          </MediumText>

          <Box sx={{ marginBottom: 2 }}></Box>

          <MediumText>
            Motorsägen-Grundlehrgänge werden nur anerkannt, wenn sie nach den inhaltlichen und zeitlichen Vorgaben des Moduls A der DGUV-Information 214-059 durchgeführt werden und diese zusammen mit den inhaltlichen Schwerpunkten des Lehrganges in der Teilnahmebescheinigung bestätigt werden. Oder wenn sie mindestens den Anforderungen des Moduls A der DGUV-Information 214-059 entsprechen und von einem Unfallversicherungsträger anerkannt oder vom KWF bzw. einer anderen Zertifizierungsstelle zertifiziert sind. Beide Varianten gelten für alle absolvierten Motorsägen-Grundlehrgänge ab dem 01.01.2016.
          </MediumText>
          <Box sx={{ marginBottom: 2 }}></Box>


          <MediumText>
            Motorsägenlehrgänge die vor dem 01.01.2016 durchgeführt wurden und bis zum 31.12.2019 vom Landesbetrieb ForstBW anerkannt wurden, gelten weiterhin. Allerdings nur, wenn bei der Absolvierung des Lehrganges nachweislich praktische Übungen von Schnitttechniken am liegenden Holz durchgeführt wurden.
          </MediumText>
          <Box sx={{ marginBottom: 2 }}></Box>


          <MediumText>
            Der entsprechende Nachweis (Kopie ausreichend) ist bei der Arbeit im Wald mitzuführen und auf Verlangen vorzuzeigen.
          </MediumText>
          <Box sx={{ marginBottom: 2 }}></Box>



          <LargeText my={0.5}>
            Maschinen- und Geräteeinsatz
          </LargeText>

          <MediumText>
            Zulässig sind nur Maschinen, Geräte und Werkzeuge, die sich in betriebssicherem Zustand befinden. Beim Einsatz der Motorsäge darf nur Bio-Sägekettenhaftöl sowie Sonderkraftstoff (Alkylatbenzin) verwendet werden.
          </MediumText>
          <Box sx={{ marginBottom: 2 }}></Box>


          <MediumText>
            Das Befahren der Bestandesflächen ist verboten.
          </MediumText>
          <Box sx={{ marginBottom: 2 }}></Box>


          <MediumText>
            Anweisungen der zuständigen Revierleitung und/oder des Waldbesitzers / der Waldbesitzerin ist in jedem Fall Folge zu leisten.
          </MediumText>
          <Box sx={{ marginBottom: 2 }}></Box>



          <LargeText my={0.5}>
            Fahren auf Waldwegen
          </LargeText>

          <MediumText>
            Waldwege sind schonend, höchstens mit einer Geschwindigkeit von 30 km/h und nur an Werktagen in der Zeit zwischen Sonnenaufgang und Sonnenuntergang zu befahren. Die Benutzung der Waldwege erfolgt auf eigene Gefahr. Die Fahrerlaubnis bezieht sich ausschließlich auf die für die Aufarbeitung und den Transport des Holzes notwendigen Fahrten. Wege dürfen nicht durch Abstellen von Fahrzeugen versperrt werden. Auf den Waldwegen ist auf Erholungssuchende Rücksicht zu nehmen.
          </MediumText>
          <Box sx={{ marginBottom: 2 }}></Box>


          <LargeText my={0.5}>
            Holzaufbereitung und Holzlagerung
          </LargeText>


          <MediumText>
            Der Abtransport des Holzes ist bestandes-, boden- und wegeschonend durchzuführen. Wege, Gräben, Böschungen, Dolen und Durchlässe sind freizuhalten. Eventuelle Schäden sind vom Käufer / von der Käuferin in einer ihm/ihr gesetzten angemessenen Frist zu beheben. Geschieht dies nicht, so ist der waldbesitzende Verkäufer berechtigt, sie auf Kosten des Käufers / der Käuferin zu beseitigen oder beseitigen zu lassen.
          </MediumText>
          <Box sx={{ marginBottom: 2 }}></Box>


          <MediumText>
            Aufgearbeitetes Holz darf längstens bis zur auf der Rechnung genannten Abfuhrfrist im Wald gelagert werden. Dabei ist ein Mindestabstand von einem Meter zum Wegrand einzuhalten. An stehenden Bäumen darf kein Holz aufgeschichtet werden. Eine Abdeckung des Holzes ist nicht gestattet.
          </MediumText>
          <Box sx={{ marginBottom: 2 }}></Box>



          <MediumText>
            Ist die Abfuhr aufgrund naturschutzrechtlicher Bestimmungen des waldbesitzenden Verkäufers nicht möglich, so verlängert sich die Abfuhrfrist um die in den Bestimmungen genannte Sperrfrist.
          </MediumText>
          <Box sx={{ marginBottom: 2 }}></Box>


          <LargeText my={0.5}>
            Gewährleistung
          </LargeText>


          <MediumText>
            Rechtserhebliche Erklärungen und Anzeigen des Kunden in Bezug auf den Vertrag (z.B. Fristsetzung, Mängelanzeige oder Minderung), sind schriftlich, (z.B. Brief, E-Mail, Telefax) abzugeben. Gesetzliche Formvorschriften und weitere Nachweise insbesondere bei Zweifeln über die Legitimation des/der Erklärenden bleiben unberührt.
          </MediumText>
          <Box sx={{ marginBottom: 2 }}></Box>



          <MediumText>
            Die Gewährleistungsrechte des Käufers / der Käuferin richten sich ausschließlich nach den gesetzlichen Bestimmungen.
          </MediumText>
          <Box sx={{ marginBottom: 2 }}></Box>



          <LargeText my={0.5}>
            Eigentumsvorbehalt beim Warenkauf
          </LargeText>



          <MediumText>
            Bis zur vollständigen Zahlung des Kaufpreises bleibt das Holz im Eigentum und Mitbesitz des/der Waldbesitzenden. Der/die Käufer/-in verpflichtet sich, bis zur vollständigen Bezahlung des Kaufpreises nicht über die Sache zu verfügen.
          </MediumText>
          <Box sx={{ marginBottom: 2 }}></Box>




          <MediumText>
            Bei vertragswidrigem Verhalten des Käufers / der Käuferin, insbesondere bei Nichtzahlung des fälligen Kaufpreises, ist der Verkäufer /die Waldbesitzende berechtigt, nach den gesetzlichen Vorschriften vom Vertrag zurückzutreten und die Ware aufgrund des Eigentumsvorbehalts herauszuverlangen.
          </MediumText>
          <Box sx={{ marginBottom: 2 }}></Box>




          <MediumText>
            Im Falle einer Verbindung oder Vermischung der Vorbehaltsware mit anderen Gegenständen, deren Eigentümer nicht der Verkäufer ist, verpflichtet sich der Kunde, dem Verkäufer Miteigentum an der neu entstandenen Sache in dem Verhältnis des Rechnungswertes der Vorbehaltsware zur übrigen Ware einzuräumen. Be- und Verarbeitung der Vorbehaltsware erfolgen für den Verkäufer als Hersteller im Sinne des § 950 BGB, ohne den Verkäufer zu verpflichten.
          </MediumText>
          <Box sx={{ marginBottom: 2 }}></Box>




          <LargeText my={0.5}>
            Schadenersatz/Haftung
          </LargeText>


          <MediumText>
            Gegenüber Kunden (d.h. soweit keine Gewährleistungsrechte des Käufers / der Käuferin betroffen sind) haftet Holzfinder, der Verkäufer, seine/ihre Bediensteten, beauftragte Dienstleister und deren Bediensteten im Rahmen der verschuldensabhängigen Haftung für Schäden – egal aus welchem Rechtsgrund – jeweils nur insoweit, als der Schaden von ihnen, ihren jeweiligen Organen, Erfüllungsgehilfen oder gesetzlichen Vertretern vorsätzlich oder grob fahrlässig verursacht wurde. Die Beschränkung auf Vorsatz und grobe Fahrlässigkeit gilt nicht für die Haftung für Schäden aus der Verletzung des Lebens, des Körpers oder der Gesundheit. Die Beschränkung auf Vorsatz und grobe Fahrlässigkeit gilt ferner nicht für die Haftung für Schäden, die aus der Verletzung einer wesentlichen Vertragspflicht (Pflicht, deren Erfüllung die ordnungsgemäße Durchführung des Vertrages überhaupt erst ermöglicht und auf deren Erfüllung der/die Käufer/-in regelmäßig vertrauen darf) resultieren.
          </MediumText>
          <Box sx={{ marginBottom: 2 }}></Box>



          <MediumText>
            Der/die Käufer/-in hat darauf zu achten, dass von dem von ihm/ihr erworbenen bzw. gelagerten Holz keine Gefahr für Dritte ausgeht und ggf. auf eigene Rechnung geeignete Maßnahmen zur Gefahrenabwehr zu ergreifen (Verkehrssicherungspflicht). Kommt der/die Käufer/-in dieser Verpflichtung nicht nach, kann der Verkäufer auf Rechnung des Käufers / der Käuferin tätig werden.
          </MediumText>

          <Box sx={{ marginBottom: 2 }}></Box>


          <MediumText>
            Soweit der/die Käufer/-in gemäß gesetzlicher Vorschriften haftet oder er/sie oder Dritte, deren Verschulden sich der/die Käufer/-in nach den gesetzlichen Vorschriften zurechnen lassen muss, schuldhaft vertragliche Pflichten verletzen, hat er/sie den Verkäufer seine/ihre Bediensteten sowie die beauftragte Dienstleister und deren Mitarbeitenden und die verkaufende Stelle und ihren Bediensteten von allen Ansprüchen Dritter einschließlich etwaiger Anwalts- und Prozesskosten und Zinsen freizustellen.
          </MediumText>
          <Box sx={{ marginBottom: 2 }}></Box>




          <LargeText my={0.5}>
            Schlussbestimmung
          </LargeText>



          <MediumText>
            Auf den Kaufvertrag findet das Recht der Bundesrepublik Deutschland Anwendung. Die Anwendung des UN-Kaufrechts ist ausgeschlossen. Diese Rechtswahl gilt nur insoweit, als dem/der Käufer/-in nicht der Schutz entzogen wird, der durch zwingende Bestimmungen des Rechts des Staates gewährt wird, in dem er seinen gewöhnlichen Aufenthalt hat.
          </MediumText>
          <Box sx={{ marginBottom: 2 }}></Box>


          <MediumText>
            Sollte eine Bestimmung dieser AGB unwirksam sein oder werden, so bleiben die übrigen Bestimmungen dieser AGB hiervon unberührt. An die Stelle der unwirksamen Bestimmung tritt, sofern vorhanden, die gesetzliche Regelung.
          </MediumText>
          <Box sx={{ marginBottom: 2 }}></Box>



          <MediumText>
            Ein Vertragstext wird nicht gespeichert und kann nach Abschluss des Bestellvorgangs nicht abgerufen werden.
          </MediumText>
          <Box sx={{ marginBottom: 2 }}></Box>


        </Box>
        <Box></Box>
      </Box>
    </Container >
  );
}
