import {
  Box,
  Button,
  Container,
  Divider,
  MenuItem,
  NativeSelect,
  Select,
  TextField,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "app/hooks";

import { LargeText } from "components/typography";
import {
  getLotLocations,
  selectLotLocationDistance,
  setDistance,
  setLocation,
} from "features/lotLocations/lotLocationsSlice";
import { useNavigate } from "react-router-dom";
import styles from "./Homepage.module.css";

export default function SearchBar() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const updateLocation = (event: any) => {
    dispatch(setLocation(event.target.value));
  };

  // const updateDistance = (event: any) => {
  //   console.log(event.target.value);
  //   dispatch(setDistance(event.target.value === 0 ? null : event.target.value));
  //   console.log(event.target.value);
  // };


  const updateDistance = (event: any) => {
    const selectedDistance = event.target.value;
    if (selectedDistance === 0) {
      dispatch(setDistance(undefined)); // Keine Entfernung auswählen
    } else {
      dispatch(setDistance(selectedDistance)); // Entfernung auswählen
    }
  };


  const searchForLots = () => {
    dispatch(getLotLocations);
    navigate("/lossuche");
  };
  const distance = useAppSelector(selectLotLocationDistance);
  return (
    <div className={styles.positionSearchContainer}>
      <div
        className={`${styles.positionSearchInnerContainer} ${styles.smartphoneContainer}`}
      >
        <div className={styles.positionSearchHeader}>
          <LargeText fWeight="700" color="#072D26">
            BRENNHOLZ IN DER UMGEBUNG FINDEN
          </LargeText>
        </div>
        <Container className={styles.positionSearchForm}>
          <Box
            className={`${styles.borderRadius} ${styles.positionSearchInputParent}`}
            bgcolor="white"
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                searchForLots();
              }
            }}
          >
            <TextField
              id="outlined-basic"
              // label="Ort oder Postleitzahl"
              placeholder="Ort oder Postleitzahl"
              variant="outlined"
              sx={{ flexGrow: 1 }}
              className={styles.positionSearchInput}
              onChange={updateLocation}
              InputLabelProps={{ shrink: false }} //shrink label props
            />
            <Divider orientation="vertical" flexItem />

            {/* <NativeSelect
              className={styles.positionSearchSelect}
              defaultValue={0}
              disableUnderline
              variant="filled"
              onChange={updateDistance}
              inputProps={{
                // "aria-label": "Without label",
                name: "kilometer",
                id: "uncontrolled-native",
              }}
              sx={{ borderBottom: "none" }}
            >
              <option value={0}>alle</option>
              <option value={2}>+2 km</option>
              <option value={5}>+5 km</option>
              <option value={10}>+10 km</option>
              <option value={25}>+25 km</option>
              <option value={50}>+50 km</option>
            </NativeSelect> */}
            <Select
              variant="standard"
              value={distance == undefined ? 0 : distance}
              label="Km"
              onChange={updateDistance}
              placeholder="+25"
              // onBlur={updateLotLocations}
              disableUnderline
              sx={{
                width: { xs: "80px", lg: "100px" },
                "&::before": { borderBottom: "none" },
              }}
            >
              <MenuItem value={0}> alle</MenuItem>
              <MenuItem value={2}>+ 2km</MenuItem>
              <MenuItem value={5}>+ 5km</MenuItem>
              <MenuItem value={10}>+ 10km</MenuItem>
              <MenuItem value={25}>+ 25km</MenuItem>
              <MenuItem value={50}>+ 50km</MenuItem>
            </Select>
          </Box>
          <Button
            sx={{ background: "#D9E868" }}
            className={`${styles.button} ${styles.positionSearchButton}`}
            onClick={searchForLots}
          >
            SUCHEN
          </Button>
        </Container>
      </div>
    </div>
  );
}
