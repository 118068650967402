import Grid2 from "@mui/material/Unstable_Grid2";
import { Outlet } from "react-router-dom";

export default function Customer() {
  return (
    <Grid2>
      <Outlet />
    </Grid2>
  );
}
