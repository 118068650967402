import { Typography, InputLabel, Box, Alert } from "@mui/material";
import { useAppDispatch, useAppSelector } from "app/hooks";
import HolzfinderDateInput from "components/inputs/HolzfinderDateInput";
import HolzfinderNumberInput from "components/inputs/HolzfinderNumberInput";
import HolzfinderSelect from "components/inputs/HolzfinderSelect";
import { selectAgent, setMaxWoodPerPeriod, setPeriodDuration } from "features/agent/agentSlice";
import { selectLotOffer, setPriceNet, setPublishedAt, setStatus } from "features/lotOffer/lotOfferSlice";
import { LotOfferStatus } from "interfaces/LotOfferStatus";
import AgentGrid from "./AgentGrid";
import { useState } from "react";



const AgentOptionsInput = {
  period_duration: {
    label: "Periodenlänge",
    default: "3 Monate",
    options: [

      {
        value: 2,
        label: "2 Monate",
      },
      {
        value: 3,
        label: "3 Monate",
      },
      {
        value: 4,
        label: "4 Monate",
      },
      {
        value: 6,
        label: "6 Monate",
      },
      {
        value: 12,
        label: "12 Monate",
      },
    ],
  },
  max_wood_per_period: {
    label: "Holzmengenbeschränkung in Fm",
  },
};


export default function AgentOptions(props: any) {

  const { error, setError } = props;

  const numberFormatter = new Intl.NumberFormat("de-De");
  const agentData = useAppSelector(selectAgent);
  const dispatch = useAppDispatch();
  const handleActivationChange = (event: any) => {
    dispatch(setPeriodDuration(event.target.value));
  };
  // const handleMaxWoodPerPeriodChange = (value: number) => {
  //   dispatch(setMaxWoodPerPeriod(value));
  // };
  const handleMaxWoodPerPeriodChange = (value: any) => {

    if (value < 0) {
      setError("Die Eingabe darf keine negative Zahl sein.");
      return;
    }

    if (value === "") {
      setError("");
      dispatch(setMaxWoodPerPeriod(null));
      return;
    }
    if (value === 0) {
      setError("");
      dispatch(setMaxWoodPerPeriod(0));
      return;
    }

    setError("");

    const parsedValue = parseInt(value);
    if (!isNaN(parsedValue)) {
      dispatch(setMaxWoodPerPeriod(parsedValue));
    }
    // console.log(value)
  };


  const isMaxWoodPerPeriodNull = agentData?.max_wood_per_period === null;

  return (
    <AgentGrid>
      <Typography
        fontWeight={"bold"}
        variant="h5"
        sx={{
          mt: 2,
          mb: 1,
          color: "#072D26",
        }}
      >
        Holzmengenbeschränkungen
      </Typography>

      {error && <Alert severity="error">{error}</Alert>}
      <InputLabel>{AgentOptionsInput.max_wood_per_period.label}</InputLabel>
      <HolzfinderNumberInput
        unit=""
        // label={AgentOptionsInput.price_net.label}

        value={agentData && agentData.max_wood_per_period !== null
          ? parseInt(numberFormatter.format(Number(agentData.max_wood_per_period)))
          : agentData && agentData.max_wood_per_period === 0
            ? null
            : ''}


        onChange={handleMaxWoodPerPeriodChange}
      />

      <HolzfinderSelect
        id={"period_duration"}
        label={AgentOptionsInput.period_duration.label}
        options={AgentOptionsInput.period_duration.options}
        currentValue={isMaxWoodPerPeriodNull ? 0 : agentData?.period_duration || AgentOptionsInput.period_duration.default}
        defaultValue={AgentOptionsInput.period_duration.default}
        onChange={handleActivationChange}

      />
    </AgentGrid>
  );
}
