import * as React from "react";
import { SVGProps } from "react";
const SvgLength = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 7.12" {...props}>
    <g data-name="Gruppe 672">
      <path d="m12.44 7.12-.86-.84 2.12-2.12H.8v-1.2h12.92L11.6.84l.84-.84L16 3.56Z" />
      <path
        data-name="trending_flat_FILL0_wght400_GRAD0_opsz48"
        d="m3.56 0 .86.84L2.3 2.96h12.9v1.2H2.28L4.4 6.28l-.84.84L0 3.56Z"
      />
    </g>
  </svg>
);
export default SvgLength;
