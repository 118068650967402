import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { BulkEditLotOffersInterface, fetchBulkEditLotOffers } from "./fetchBulkEdit";
import { RequestStatus } from "interfaces/RequestStatus";
import { getLotList } from "./lotListSlice";


export const bulkEdit = createAsyncThunk("lotList/fetchBulkEdit", async (args: BulkEditLotOffersInterface, { getState }) => {
    const { user } = getState() as RootState;
    const response = await fetchBulkEditLotOffers({ ...args, endpoint: user.role });
    return { headers: response.headers, data: response.data };
});

