import {
  FormControl,
  TextField,
  Box,
  Container,
  InputAdornment,
  IconButton,
  Button,
  Grid,
} from "@mui/material";

import { MediumText, XXLargeText } from "../../../../components/typography";
import styles from "./changePassword.module.css";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { resetPasswordSetPassword, UserErrors } from "features/user/userSlice";
import {
  patchUserPassword,
} from "features/user/userSlice";

import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export interface ChangePaswordErrors {
  password?: string[]
  password_confirmation?: string[]
}

export default function ResetPassword() {
  const loc = useLocation();
  const query = new URLSearchParams(loc.search);
  const token = query.get("token");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [password, setPassword] = useState("");
  const [password_confirmation, setPasswordConfirmation] = useState("");
  const [errors, setErrors] = useState<ChangePaswordErrors>({} as ChangePaswordErrors);
  const [successMessage, setSuccessMessage] = useState("");
  const handlePasswordChange = () => {
    if (token) {
      dispatch(resetPasswordSetPassword({
        token: token,
        password,
        password_confirmation,
      })).then((ev: any) => {
        if (ev.payload.data?.errors) {
          setErrors(ev.payload.data.errors);
        } else {
          setErrors({} as ChangePaswordErrors);
          setPassword("");
          setPasswordConfirmation("");
          setSuccessMessage("Ihr Passwort wurde erfolgreich geändert. Bitte loggen Sie sich erneut ein.");
        }
      });
    }
  };


  useEffect(() => {
    if (successMessage) {
      navigate("/login", {
        state: { successMessage },
      });
    }
  }, [successMessage, navigate]);
  if (!token) return <div></div>;
  return (
    <Box sx={{ display: "flex" }}>
      <Container
        maxWidth="xl"
        sx={{
          pt: 4,
          pb: 4,
          mb: 3,
        }}
        className={styles.changePasswordContainer}
      >
        <Grid container>
          <Grid item xs={12} md={6} >
            <Box sx={{ mt: 5 }}>
              <XXLargeText color="#072D26" fWeight="bold" mx="auto">
                Passwort setzen
              </XXLargeText>
            </Box>
            <FormControl
              sx={{
                width: { lg: "60%", md: "80%", xs: "100%" },
                pt: 2,
                gap: 1
              }}
              className={styles.form}
            >
              <MediumText>Neues Passwort</MediumText>
              <TextField
                error={!!errors["password"] as boolean}
                helperText={
                  !!errors["password"]
                    ? (errors["password"].join(", ") as string)
                    : ""
                }
                type={showNewPassword ? "text" : "password"}
                placeholder="Neues Passwort"
                className={styles.input}
                value={password}
                onChange={(event: any) => {
                  setPassword(event.target.value);
                  setErrors({} as ChangePaswordErrors);
                }}
                name={"new_password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowNewPassword(false)}
                        onMouseDown={() => setShowNewPassword(true)}
                      >
                        {" "}
                        <VisibilityOffOutlinedIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <MediumText>Neues Passwort wiederholen</MediumText>
              <TextField
                error={!!errors["password_confirmation"] as boolean}
                helperText={
                  !!errors["password_confirmation"]
                    ? (errors["password_confirmation"].join(", ") as string)
                    : ""
                }

                type={showRepeatPassword ? "text" : "password"}
                placeholder="Neues Passwort wiederholen"
                className={styles.input}
                value={password_confirmation}
                onChange={(event: any) => {
                  setPasswordConfirmation(event.target.value);
                  setErrors({} as ChangePaswordErrors);
                }}
                name={"new_password_confirmation"}
                sx={{ mb: 4 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowRepeatPassword(false)}
                        onMouseDown={() => setShowRepeatPassword(true)}
                      >
                        {" "}
                        <VisibilityOffOutlinedIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <div>
                <Button
                  type="submit"
                  sx={{
                    display: 'flex',
                    alignSelf: 'flex-start',
                    color: '#072D26',
                    my: 2,
                    backgroundColor: '#C4D661',
                    borderColor: '#C4D661',
                    borderRadius: 30,
                    fontSize: 'small',
                    paddingY: 1.5,
                    paddingX: 5,
                    '&:hover': {
                      backgroundColor: '#C0D904'
                    }
                  }}
                  className={`${styles.roundInputs} ${styles.sizeInputs}`}
                  variant="outlined"
                  onClick={handlePasswordChange}
                >
                  PASSWORT ÄNDERN

                </Button>
              </div>
            </FormControl>

          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
