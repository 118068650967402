import * as React from "react";
import { SVGProps } from "react";
const SvgTrustedSeller = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" {...props}>
    <g data-name="Gruppe 676" transform="translate(-176 -219)">
      <circle
        data-name="Ellipse 281"
        cx={20}
        cy={20}
        r={20}
        transform="translate(176 219)"
        fill="#d9e868"
      />
      <g data-name="Gruppe 675">
        <path d="M196 251a12.277 12.277 0 0 1-7.187-4.875A13.846 13.846 0 0 1 186 237.74v-7.14l10-3.6 10 3.6v7.14a13.846 13.846 0 0 1-2.812 8.385A12.277 12.277 0 0 1 196 251Zm0-1.86a11.232 11.232 0 0 0 5.859-4.305 11.9 11.9 0 0 0 2.266-7.1v-5.875L196 228.92l-8.125 2.94v5.88a11.9 11.9 0 0 0 2.266 7.095A11.232 11.232 0 0 0 196 249.14Zm0-10.11Z" />
        <path d="M197.518 243.711h-3.02v-2.2h-5.289l2.909-4.05h-1.462L196 230.09l5.344 7.371h-1.447l2.894 4.049h-5.273Zm-5.274-3.688Zm0 0h7.526l-2.908-4.049h1.37L196 232.93l-2.232 3.044h1.385Z" />
      </g>
    </g>
  </svg>
);
export default SvgTrustedSeller;
