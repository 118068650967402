import { Typography } from "@mui/material";
import { useAppSelector } from "app/hooks";
import { selectAgent } from "features/agent/agentSlice";
import AgentData from "./AgentData";
import SellerGrid from "./AgentGrid";

const CountyDataLabels = {
  name: "Landkreis",
  short_name: "Landkreis Abkürzung",
  eldat_reference_id: "Eldat Referenz ID",
};

export default function CountyInformation(props: any) {
  const agentData = useAppSelector(selectAgent);
  return (
    <SellerGrid>
      <Typography
        fontWeight={"bold"}
        variant="h5"
        sx={{
          mt: 2,
          mb: 1,
          color: "#072D26",
        }}
      >
        Landkreis Informationen
      </Typography>
      {
        agentData?.county.name ? (
          <AgentData
            label={CountyDataLabels.name}
            data={agentData?.county.name}
          />
        ) : (
          <></>
        )
      }
       {
        agentData?.county.short_name ? (
          <AgentData
            label={CountyDataLabels.short_name}
            data={agentData?.county.short_name}
          />
        ) : (
          <></>
        )
      }
       {
        agentData?.county.eldat_reference_id ? (
          <AgentData
            label={CountyDataLabels.eldat_reference_id}
            data={agentData?.county.eldat_reference_id}
          />
        ) : (
          <></>
        )
      }

    </SellerGrid>
  );
}
