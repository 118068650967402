import AccountSettingsPersonal from "./components/AccountSettingsPersonal";
import { MediumText } from "../../../components/typography";
import { Alert, Button, Container, Grid } from "@mui/material";
import Header from "components/header/Header";
import AccountSettingsBilling from "./components/AccountSettingsBilling";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { selectUserData, selectUserRole, updateFirstName, updateLastName } from "features/user/userSlice";
import { clearCustomerErrors, selectCustomer, updateCity, updateCountryId, updatePhone, updateStreet, updateStreetNumber, updateZipCode } from "features/customer/customerSlice";
import { useEffect, useState } from "react";
import { Form, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "app/store";
import { setFromShoppingCart } from "features/shoppingCart/shoppingCartSlice";
import { saveCustomerData } from "features/customer/fetchCustomerSettings";
import { saveUserData } from "features/user/fetchUserData";



const AccountSettings = () => {
  const navigate = useNavigate()

  const userRole = useAppSelector(selectUserRole);
  const [informationMissing, setInformationMissing] = useState(false)
  const [changesSaved, setChangesSaved] = useState(false)
  const dispatch = useAppDispatch();

  const customerData = useAppSelector(selectCustomer);
  const userData = useAppSelector(selectUserData)
  const [customerDataState, setCustomerDataState] = useState(customerData)
  const [zipCodeError, setZipCodeError] = useState("")
  const { first_name, last_name } = useAppSelector(selectUserData);
  const [firstAndLastName, setFirstAndLastName] = useState({first_name: first_name, last_name: last_name})

  useEffect(() => {
    dispatch(clearCustomerErrors())
    if (!first_name || !last_name || (userRole === "customer" && (!customerData.city || !customerData.street || !customerData.street_number || !customerData.zip_code || !customerData.country_id))) {
        setInformationMissing(true);
      } else {
        setInformationMissing(false);
      }
  }, [])

  useEffect(() => {
    setChangesSaved(false)
  }, [customerDataState, firstAndLastName])

  const location = useLocation()
  // console.log(location.state)
  const fromShoppingCart = useSelector((state: RootState) => state.shoppingCart.fromShoppingCart) as boolean;

  
  const patchCustomerSettings = async () => {
    let customerDataStateCopy = {...customerDataState}
    customerDataStateCopy.first_name = firstAndLastName.first_name
    customerDataStateCopy.last_name = firstAndLastName.last_name
    const response = await saveCustomerData({ customerData: customerDataStateCopy })
    return response
  };

  const patchUserData = async () => {
    const response = await saveUserData({ userData: {first_name: firstAndLastName.first_name, last_name: firstAndLastName.last_name, email: userData.email, created_at: userData.created_at, updated_at: userData.updated_at} });
    return response
  }

  const updateStoreBoth = () => {
    dispatch(updateStreet(customerDataState.street))
    dispatch(updateStreetNumber(customerDataState.street_number))
    dispatch(updateZipCode(customerDataState.zip_code))
    dispatch(updateCity(customerDataState.city))
    dispatch(updateCountryId(customerDataState.country_id))
    dispatch(updatePhone(customerDataState.phone))

    dispatch(updateFirstName(firstAndLastName.first_name))
    dispatch(updateLastName(firstAndLastName.last_name))
  }
  const updateStorePersonal = () => {
    dispatch(updateFirstName(firstAndLastName.first_name))
    dispatch(updateLastName(firstAndLastName.last_name))
  }

  return (
    <Container
      maxWidth="xl"
      sx={{
        pt: 4,
        pb: 4,
        mb: 3,
        // ml: 4,
        mt: 5,
      }}
    >
      <Form>
        {/* {informationMissing && (
          document.forms[0]?.elements && Object.values(document.forms[0].elements).some((element: any) => element.value === "") ? (
            <Alert
              severity="warning"
              sx={{
                borderRadius: 5,
                paddingX: 5,
                marginTop: -4,
                marginBottom: 3
              }}
            >
              Sie müssen die Informationen ausfüllen!
            </Alert>
          ) : null
        )} */}
        {changesSaved ? (
            <Alert
              severity="success"
              sx={{
              borderRadius: 5,
              paddingX: 5,
              marginTop: -4,
              marginBottom: 3
              }}
            >
              Die Änderungen wurden erfolgreich gespeichert.
            </Alert>
          ) : null
        }
        {informationMissing ? (
          <Alert
            severity="warning"
            sx={{
              borderRadius: 5,
              paddingX: 5,
              marginTop: -4,
              marginBottom: 3
            }}
          >
            Bitte füllen Sie die fehlenden Informationen aus!
          </Alert>
        ) : null
        }

        <Grid container>
          <Grid item xs={12} sx={{ mb: 3 }}>
            <Header header={" Ihr Benutzerprofil"} />
          </Grid>
          <Grid item xs={12} md={6} >
            <AccountSettingsPersonal firstAndLastName={firstAndLastName} setFirstAndLastName={setFirstAndLastName} />
          </Grid>
          {userRole === "customer" ?
            <Grid item xs={12} md={6} sx={{ marginTop: { xs: 4, md: 0 } }}>
              <AccountSettingsBilling zipCodeError={zipCodeError} customerData={customerDataState} setCustomerData={setCustomerDataState}/>
            </Grid> : null
          }
        </Grid>
        <div>
          <br />
          <MediumText>* Pflichtfeld</MediumText>
          <Button
            type="submit"
            variant="contained"
            onClick={() => {

              // trim inputs to validate 'emptyness'
              // siehe auch: https://stackoverflow.com/questions/23892547/what-is-the-best-way-to-trigger-change-or-input-event-in-react-js
              //@ts-ignore
              const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value').set;
              document.forms[0].querySelectorAll("input").forEach(input => {
                if(input.required) {
                  //@ts-ignore
                  nativeInputValueSetter.call(input, input.value.trim());
                  let event = new window.Event('input', { bubbles: true });
                  //@ts-ignore
                  event.simulated = true;
                  input.dispatchEvent(event);
                }
              })
              
              setInformationMissing(false)
              const isFormValid = document.forms[0].checkValidity(); // Check form validity

              if (isFormValid) {
                if(userRole !== "customer"){
                  patchUserData().then((result) => {
                    if(result.status === 200){
                      updateStorePersonal()
                      setChangesSaved(true)
                    }

                    if (fromShoppingCart) {
                      dispatch(setFromShoppingCart(false))
                      navigate('/bestelluebersicht')
                    }
                  })
                }
                else if(userRole === "customer") {
                  patchCustomerSettings().then((result) => {
                    if(result.status === 200){
                      updateStoreBoth()
                      setZipCodeError("")
                      setInformationMissing(false)
                      setChangesSaved(true)

                      if (fromShoppingCart) {
                        dispatch(setFromShoppingCart(false))
                        navigate('/bestelluebersicht')
                      }
                    }
                    else if(result.status === 422){
                      setZipCodeError(result.data?.data?.errors?.zip_code?.[0] || "")
                      setInformationMissing(true)
                    }

                  })
                }
              } else {
                setInformationMissing(true)
              }
            }}
            sx={{
              color: "#072D26",
              my: 4,
              backgroundColor: "#C4D661",
              borderColor: "#C4D661",
              borderRadius: 6,
              fontSize: "small",
              paddingY: 1.5,
              paddingX: 5,

              "&:hover": {
                backgroundColor: "#C0D904",
              },
            }}
          >

            <MediumText color="#072D26" fWeight="bold">
              Änderungen Speichern
            </MediumText>
          </Button>
        </div>
      </Form>
    </Container>
  );
};

export default AccountSettings;
