import { Box, Modal } from "@mui/material";
import { useState } from "react";
import LotOfferGrid from "./LotOfferGrid";
import LotOfferBigImage from "./LotOfferBigImage";
import LotOfferSmallImages from "./LotOfferSmallImages";
import { LotOfferLocationInterface } from "interfaces/LotOfferLocationInterface";
import Carousel from "./Carousel";
import CarouselStyles from "./Carousel.module.css";

interface InformationenProps {
  lotInformation: LotOfferLocationInterface;
}

//slide
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",

  bgcolor: "background.paper",
  border: "0.2px solid #000",
  boxShadow: 24,
  p: 1,
};


export default function LotOfferImages(props: InformationenProps) {
  const { lotInformation } = props;

  const lotOfferImages = lotInformation.image_urls;

  let smallImages = lotOfferImages.filter((image) => image !== lotOfferImages[0]);

  //slide
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [open, setOpen] = useState(false);

  const handleOpen = (value: number) => () => {
    setOpen(true);
    setCurrentImageIndex(value);
    // console.log(value)
  };
  const handleClose = () => setOpen(false);

  const baseURL = process.env.REACT_APP_API_DOMAIN;



  return (
    <LotOfferGrid>
      <Box>
        {
          lotOfferImages.length >= 1 ?

            <Box
              onClick={handleOpen(0)}
              sx={{
                aspectRatio: "16/9",
                maxWidth: "500px",
                maxHeight: "400px",
                width: "auto",
                height: "auto",
                objectFit: "cover",
                objectPosition: "left",
                cursor: "pointer",
              }}
            >
              <LotOfferBigImage lotOfferImage={lotOfferImages[0]}></LotOfferBigImage>
            </Box>
            : <Box
              sx={{
                aspectRatio: "16/9",
                maxWidth: "500px",
                maxHeight: "400px",
                width: "auto",
                height: "auto",
                objectFit: "cover",
                objectPosition: "left",

              }}
            >
              <LotOfferBigImage lotOfferImage={lotOfferImages[0]}></LotOfferBigImage>
            </Box>
        }

        <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
          <Box sx={style} className={CarouselStyles.mediaContainer}>
            <Carousel imageIndex={currentImageIndex} onClose={handleClose}>
              {lotOfferImages.map((image, index) => (
                <img
                  style={{
                    objectFit: "contain",
                    backgroundColor: "black",
                  }}
                  key={index}
                  src={baseURL + image}
                  alt=""
                />
              ))}
            </Carousel>
            {/* <Box
              component="img"
              src={url}
              alt="Bild eines HolzPolters"
              sx={{
                aspectRatio: "16/9",
                maxWidth: "1000px",
                maxHeight: "800px",
                width: "auto",
                height: "auto",
                objectFit: "cover",
                objectPosition: "left",
              }}
            /> */}
          </Box>
        </Modal>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDircetion: "row",
          gap: 2,
          mt: 2,
        }}
      >
        {smallImages.map((image, index) => {
          const imageIndex = index + 1;
          return (
            <Box
              key={index}
              onClick={handleOpen(imageIndex)}
              sx={{
                cursor: "pointer",
              }}
            >
              <LotOfferSmallImages key={index} lotOfferImage={image} />
            </Box>
          );
        })}
      </Box>

      {/* <Box
          component="img"
          src={first.url}
          sx={{
            maxWidth: "100%",
            width: "auto"
          }}
      />
      {

      } */}
    </LotOfferGrid>
  );
}
