import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { Box, Button, FormControl, FormControlLabel, IconButton, InputAdornment, styled, TextField } from "@mui/material";
import { useAppDispatch, useAppSelector } from "app/hooks";
import HolzfinderCheckbox from "components/inputs/HolzfinderCheckbox";
import { clearRegisterErrors, register, selectRegisterErrors } from "features/user/userSlice";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MediumLargeText, MediumText } from "../../../../components/typography";
import styles from "../Register.module.css";
import { clearShoppingCart } from "features/shoppingCart/shoppingCartSlice";

export default function RegisterForm() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const errors: any = useAppSelector(selectRegisterErrors) || {};

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password_confirmation, setPasswordConfirmation] = useState("");
  const [agbr, setAgbr] = useState(false);

  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [markAgb, setMarkAgb] = useState(false);

  const BrownBorderTextField = styled(TextField)`
    & label.Mui-focused {
      color: #d0c2a8;
    }
    & .MuiOutlinedInput-root {
      &.Mui-focused fieldset {
        border-color: #d0c2a8;
      }
    }
  `;

  function errorExists(name: string): boolean {
    return !!errors[name] as boolean;
  }
  function extractError(name: string): string {
    return !!errors[name] ? (errors[name].join(", ") as string) : "";
  }

  useEffect(() => {
    if (errors && errors.length > 0) {
      setTimeout(() => {
        dispatch(clearRegisterErrors());
      }, 10000);
    }
  }, [errors]);

  return (
    <Box>
      <FormControl
        sx={{
          width: { lg: "60%", md: "80%", xs: "100%" },
        }}
        className={styles.form}
      >
        <MediumLargeText fWeight="regular">Erstelle hier dein persönliches Kundenkonto und lege sofort los.</MediumLargeText>
        <p></p>
        <MediumText>E-Mail</MediumText>
        <TextField
          error={errorExists("email")}
          helperText={extractError("email")}
          type="email"
          value={email}
          placeholder="E-Mail"
          className={styles.inputStyle}
          onChange={(event: any) => {
            setEmail(event.target.value);
          }}
          name={"email"}
        />

        <MediumText>Passwort</MediumText>
        <TextField
          error={errorExists("password")}
          helperText={extractError("password")}
          type={showPassword1 ? "text" : "password"}
          placeholder="Passwort wählen"
          value={password}
          onChange={(event: any) => {
            setPassword(event.target.value);
          }}
          name={"password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => {
                    setShowPassword1(false);
                  }}
                  onMouseDown={() => {
                    setShowPassword1(true);
                  }}
                >
                  {" "}
                  <VisibilityOffOutlinedIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <MediumText>Passwort wiederholen</MediumText>

        <TextField
          error={errorExists("password_confirmation")}
          helperText={extractError("password_confirmation")}
          type={showPassword2 ? "text" : "password"}
          placeholder="Passwort wiederholen"
          className={styles.input}
          value={password_confirmation}
          onChange={(event: any) => {
            setPasswordConfirmation(event.target.value);
          }}
          name={"password_confirmation"}
          InputProps={{
            style: { borderColor: "#D0C2A8" },
            endAdornment: (
              <InputAdornment position="end">
                <IconButton aria-label="toggle password visibility" onClick={() => setShowPassword2(false)} onMouseDown={() => setShowPassword2(true)}>
                  {" "}
                  <VisibilityOffOutlinedIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Box display={"flex"}>

          <FormControlLabel
            name="agb_gdpr"
            label={
              <div>
                <span>Ich habe die </span>
                <Link to={'/agb'}>AGB</Link>
                <span> und die </span>
                <Link to={'/datenschutzerklaerung'}>Datenschutzerklärung </Link>
                <span> gelesen und akzeptiere diese. </span>
              </div>
            }
            control={<HolzfinderCheckbox required={true} />}
            onChange={() => {
              setAgbr(!agbr);
              if (!agbr) {
                setMarkAgb(false);
              }
            }}
            sx={{
              transition: "color 250ms ease",
              color: markAgb ? "#d32f2f" : "initial",
            }}
          />

          <div>
            <Button
              sx={{
                color: "#072D26",
                my: 2,
                mt: 2,
                backgroundColor: "#C4D661",
                borderColor: "#C4D661",
                borderRadius: 30,
                fontSize: "small",
                paddingY: 1.5,
                paddingX: 5,
                "&:hover": {
                  backgroundColor: "#C0D904",
                },
              }}
              onClick={() => {
                if (!agbr) {
                  setMarkAgb(true);
                } else {
                  dispatch(
                    register({
                      email,
                      password,
                      password_confirmation,
                    })
                  ).then((data: any) => {
                    if (data.type === "user/register/fulfilled") {
                      dispatch(clearShoppingCart())
                      navigate("/registrierung_erfolgreich");
                    }
                  });
                }
              }}
            >
              <MediumText fWeight="bold">Registrieren</MediumText>
            </Button>
          </div>
        </Box>
      </FormControl>
    </Box>
  );
}
