import {
  FormControl,
  TextField,
  Box,
  Container,
  InputAdornment,
  IconButton,
  Button,
  Grid,
} from "@mui/material";

import { MediumText, XXLargeText } from "../../../../components/typography";
import styles from "./changePassword.module.css";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { logout, UserErrors } from "features/user/userSlice";
import {
  patchUserPassword,
} from "features/user/userSlice";

// import { selectUserNewPassword, selectUserPass } from "features/user/userSlice";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { clearCustomer, clearCustomerErrors } from "features/customer/customerSlice";

export interface ChangePaswordErrors {
  current_password?: string[]
  password?: string[]
  password_confirmation?: string[]
}

export default function ChangePassword() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  const [success, setSuccess] = useState("")

  const [current_password, setCurrentPassword] = useState("");
  const [password, setPassword] = useState("");
  const [password_confirmation, setPasswordConfirmation] = useState("");
  const [errors, setErrors] = useState<ChangePaswordErrors>({} as ChangePaswordErrors);

  type FieldName = keyof ChangePaswordErrors;

  const handleInputChange = (field: FieldName, value: string) => {
    const updatedErrors = { ...errors, [field]: [] };
    setErrors(updatedErrors);
    switch (field) {
      case "current_password":
        setCurrentPassword(value);
        break;
      case "password":
        setPassword(value);
        break;
      case "password_confirmation":
        setPasswordConfirmation(value);
        break;
      default:
        break;
    }
  };

  const getFieldError = (field: FieldName) => {
    return !!errors[field] as boolean;
  };



  const getFieldHelperText = (field: FieldName) => {
    const fieldErrors = errors[field];
    return fieldErrors ? fieldErrors.join(", ") : "";
  };



  return (
    <Box sx={{ display: "flex" }}>
      <Container
        maxWidth="xl"
        sx={{
          pt: 4,
          pb: 4,
          mb: 3,
          // mt: 5,
        }}
        className={styles.changePasswordContainer}
      >
        <Grid container>
          <Grid item xs={12} md={6} >

            <Box sx={{ mt: 5 }}>
              <XXLargeText color="#072D26" fWeight="bold" mx="auto">
                Passwort ändern
              </XXLargeText>
            </Box>
            <FormControl
              sx={{
                width: { lg: "60%", md: "80%", xs: "100%" },
                pt: 2,
                gap: 1
              }}
              className={styles.form}
            >
              <MediumText>Aktuelles Passwort</MediumText>
              <TextField
                error={getFieldError("current_password")}
                helperText={getFieldHelperText("current_password")}
                type={showCurrentPassword ? "text" : "password"}
                placeholder="Aktuelles Passwort"
                className={styles.input}
                value={current_password}
                onChange={(event) =>
                  handleInputChange("current_password", event.target.value)
                }
                name={"current_password"}
                style={{ borderRadius: "26px" }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowCurrentPassword(false)}
                        onMouseDown={() => setShowCurrentPassword(true)}
                      >
                        {" "}
                        <VisibilityOffOutlinedIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <MediumText>Neues Passwort</MediumText>
              <TextField
                error={getFieldError("password")}
                helperText={getFieldHelperText("password")}
                type={showNewPassword ? "text" : "password"}

                placeholder="Neues Passwort"
                className={styles.input}
                value={password}
                onChange={(event) => handleInputChange("password", event.target.value)}
                name={"password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowNewPassword(false)}
                        onMouseDown={() => setShowNewPassword(true)}
                      >
                        {" "}
                        <VisibilityOffOutlinedIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <MediumText>Neues Passwort wiederholen</MediumText>
              <TextField
                error={getFieldError("password_confirmation")}
                helperText={getFieldHelperText("password_confirmation")}
                type={showRepeatPassword ? "text" : "password"}

                placeholder="Neues Passwort wiederholen"
                className={styles.input}
                value={password_confirmation}
                onChange={(event) =>
                  handleInputChange("password_confirmation", event.target.value)
                }
                name={"new_password_confirmation"}
                sx={{ mb: 4 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowRepeatPassword(false)}
                        onMouseDown={() => setShowRepeatPassword(true)}
                      >
                        {" "}
                        <VisibilityOffOutlinedIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {success.length > 0 ? <div style={{ color: "green", marginTop: "6px" }}>{success}</div> : null}
              <div>
                <Button
                  type="submit"
                  sx={{
                    display: 'flex',
                    alignSelf: 'flex-start',
                    color: '#072D26',
                    my: 2,
                    backgroundColor: '#C4D661',
                    borderColor: '#C4D661',
                    borderRadius: 30,
                    fontSize: 'small',
                    paddingY: 1.5,
                    paddingX: 5,
                    '&:hover': {
                      backgroundColor: '#C0D904'
                    }
                  }}
                  className={`${styles.roundInputs} ${styles.sizeInputs}`}
                  variant="outlined"
                  onClick={() => {
                    dispatch(patchUserPassword({
                      current_password,
                      password,
                      password_confirmation,
                    })).then((ev: any) => {
                      if (ev.payload.data?.errors) {
                        setErrors(ev.payload.data.errors);
                      }

                      else {
                        setErrors({} as ChangePaswordErrors);
                        setCurrentPassword("");
                        setPassword("");
                        setPasswordConfirmation("");
                        dispatch(logout())
                        dispatch(clearCustomer())
                        dispatch(clearCustomerErrors())

                        // navigate("/login");
                        navigate("/login", {
                          state: { successMessage: "Ihr Passwort wurde erfolgreich geändert. Bitte loggen Sie sich erneut ein" },
                        });
                        // setSuccess("")

                      }
                    });
                  }}
                >
                  PASSWORT ÄNDERN
                </Button>
              </div>
            </FormControl>

          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}